import React, { useState } from "react";
import Button from "components/Button";
import TextField from "components/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CloudinaryWidget from "components/CloudinaryWidget";
import {
  Container,
  ProductInformation,
  Update,
  Wrapper,
  SubHeading,
  EditColorFieldWrapper,
  EditColorInnerField,
} from "styles/pages/edit-products/editProductInformation";
import { Heading } from "styles/pages/edit-products/editProductSizes";

const EditColorInformation = ({
  product = { Product: {} },
  editColorsInformation,
}) => {
  const [colors, setColors] = useState(product?.Colors);
  const disabled = !!colors?.filter((c) => !c?.Name || !c?.HexCode)?.length;
  const printAreas = product?.PrintAreas?.filter(
    (p) => !(p?.printAreaName === "Front" || p?.printAreaName === "Back")
  );

  return (
    <Container>
      <ProductInformation>
        <Heading style={{ marginBottom: 30 }}>
          <SubHeading style={{ border: "none" }}>Product Colors</SubHeading>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() =>
              setColors([
                ...colors,
                {
                  Name: "",
                  HexCode: "",
                  Image: "",
                  BackImage: "",
                  ID: moment().unix() + colors?.length,
                  visible: true,
                },
              ])
            }
          >
            <AddCircleIcon style={{ fontSize: 30 }} />
          </IconButton>
        </Heading>

        {colors?.map((color, index) => (
          <Wrapper
            key={`EditProductColors${index}`}
            style={{
              borderBottom: "1px solid #e5e5e5",
              paddingBottom: 10,
              marginBottom: 30,
              flexDirection: "column",
            }}
          >
            <EditColorFieldWrapper>
              {color?.visible === false ? (
                <VisibilityOffIcon
                  style={{ color: "red", cursor: "pointer", marginRight: 20 }}
                  onClick={() => {
                    setColors(
                      colors?.map((s, i) => {
                        if (i !== index) {
                          return s;
                        }
                        return {
                          ...s,
                          visible: true,
                        };
                      })
                    );
                  }}
                />
              ) : (
                <VisibilityIcon
                  style={{
                    color: "lightseagreen",
                    cursor: "pointer",
                    marginRight: 20,
                  }}
                  onClick={() => {
                    setColors(
                      colors?.map((s, i) => {
                        if (i !== index) {
                          return s;
                        }
                        return {
                          ...s,
                          visible: false,
                        };
                      })
                    );
                  }}
                />
              )}

              <EditColorInnerField>
                <TextField
                  label="Name"
                  size="small"
                  value={color?.Name || ""}
                  onChange={(Name) => {
                    setColors(
                      colors?.map((s, i) => {
                        if (i !== index) {
                          return s;
                        }
                        return {
                          ...s,
                          Name,
                        };
                      })
                    );
                  }}
                />
                <TextField
                  label="Color"
                  size="small"
                  type="color"
                  value={color?.HexCode || ""}
                  onChange={(HexCode) => {
                    setColors(
                      colors?.map((s, i) => {
                        if (i !== index) {
                          return s;
                        }
                        return {
                          ...s,
                          HexCode,
                        };
                      })
                    );
                  }}
                />

                <CloudinaryWidget
                  label="Image"
                  value={color?.Image}
                  disabled={false}
                  setValue={(Image) =>
                    setColors(
                      colors?.map((s, i) => {
                        if (i !== index) {
                          return s;
                        }

                        const split = Image.split("/upload/");
                        // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;

                        return {
                          ...s,
                          // Image: Image?.includes("c_pad") ? Image : value,
                          Image:
                            !!Image && split?.length > 0
                              ? `${split[0]}/upload/${split[1]}`
                              : "",
                        };
                      })
                    )
                  }
                  folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${product?.Product?.Name}`}
                />

                <CloudinaryWidget
                  disabled={false}
                  label="Back Image"
                  value={color?.BackImage}
                  setValue={(BackImage) =>
                    setColors(
                      colors?.map((s, i) => {
                        if (i !== index) {
                          return s;
                        }

                        const split = BackImage.split("/upload/");
                        // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;

                        return {
                          ...s,
                          // BackImage: BackImage?.includes("c_pad")
                          //   ? BackImage
                          //   : value,

                          BackImage:
                            !!BackImage && split?.length > 0
                              ? `${split[0]}/upload/${split[1]}`
                              : "",
                        };
                      })
                    )
                  }
                  folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${product?.Product?.Name}`}
                />

                {printAreas?.map((p, x) => (
                  <CloudinaryWidget
                    disabled={false}
                    key={`PrintAreaColor${x}`}
                    label={`${p?.printAreaName} Image`}
                    value={color?.[`${p?.printAreaName}Image`]}
                    setValue={(image) =>
                      setColors(
                        colors?.map((s, i) => {
                          if (i !== index) {
                            return s;
                          }

                          const split = image.split("/upload/");
                          // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;

                          return {
                            ...s,

                            [`${p?.printAreaName}Image`]:
                              !!image && split?.length > 0
                                ? `${split[0]}/upload/${split[1]}`
                                : "",

                            // [`${p?.printAreaName}Image`]: image?.includes(
                            //   "c_pad"
                            // )
                            //   ? image
                            //   : value,
                          };
                        })
                      )
                    }
                    folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${product?.Product?.Name}`}
                  />
                ))}
              </EditColorInnerField>
              <IconButton
                aria-label="delete"
                disabled={!colors?.length}
                onClick={() => setColors(colors?.filter((s, i) => i != index))}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </EditColorFieldWrapper>
          </Wrapper>
        ))}

        <Divider />
      </ProductInformation>

      <Update>
        <Button
          disabled={disabled}
          title="Update"
          onClick={() => {
            const payload = {
              ID: product?.Product?.ID,
              colorsData: colors,
            };

            editColorsInformation(payload);
          }}
        />
      </Update>
    </Container>
  );
};

export default EditColorInformation;
