import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  max-width: 600px;
  width: 88vw;
  padding: 20px;
  @media (max-width: 600px) {
    max-width: 100%;
  }
`;
const Heading = styled.h2`
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin: 0;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  > button {
    background: ${COLORS.BLUE};
    height: 34px;
    text-transform: capitalize;
    color: ${COLORS.WHITE};
    :hover {
      background: ${COLORS.BLUE};
    }
  }
`;

const StoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
`;
const ButtonWrapper = styled.div`
  @media (max-width: 650px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`;

export { Container, Heading, Footer, StoreWrapper, ButtonWrapper };
