import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  orderSubmitNotification,
  cancelOrder,
  updateOrderStatus,
  updateSavedOrderData,
  saveArtFiles,
  downloadArtFile,
  getPaypalTransactionId,
  generateArtboard,
  changeOrderStatus,
} from 'actions/orders';
import { login } from 'actions/auth';
import { useHistory } from 'react-router-dom';
import {
  isSub,
  getDesignerSettingsFromProductId,
  getAdditionalTechniquesFromProductId,
} from 'selectors/products';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/Button';
import Slider from 'react-slick';
import OrderSubmitToProd from 'pages/warehouse/OrderSubmitToProd';
import UpdateOrderTracking from 'pages/warehouse/UpdateOrderTracking';
import BrowseLibrary from 'components/ArtWork/BrowseLibrary';
import {
  Container,
  Wrapper,
  OrderPreview,
  OrderDetail,
  OrderSummary,
  ProductOverview,
  ImagePreview,
  Details,
  OrderPushedButton,
  SendInvoice,
  CancelOrder,
  PrintAreasSlider,
} from 'styles/pages/warehouse/orderModal';
import PrintOrderSummaryModal from 'pages/warehouse/PrintOrderSummaryModal';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const OrderModal = ({
  modal,
  setModal,
  order,
  openModalPayment,
  pushOrderToMachine,
  user,
  sendOrderToPlant,
}) => {
  const products = useSelector((state) => state?.products?.data);
  const [printOrderSummaryModal, setPrintOrderSummaryModal] =
    useState(undefined);
  const [orderSubmitToProdModal, setOrderSubmitToProd] = useState(undefined);
  const [orderTrackingModal, setOrderTrackingModal] = useState(undefined);
  const orderLineItems = order?.ShirtlyOrderData?.OrderLines[0]?.OrderLineItems;
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const [browseLibrary, setBrowseLibrary] = useState(false);
  const [paypalTransactionId, setPaypalTransactionId] = useState(null);
  const printProvider = order?.ShirtlyOrderData?.PrintProvider;
  const dispatch = useDispatch();
  const history = useHistory();

  const isEU = order?.ShirtlyOrderData?.PrintProvider === 'EU';
  const isAU = order?.ShirtlyOrderData?.PrintProvider === 'AU';
  const isNY = order?.ShirtlyOrderData?.PrintProvider === 'NY';

  useEffect(async () => {
    if (order?.PaypalChargeInfo) {
      const response = await dispatch(
        getPaypalTransactionId({
          orderId: order?.PaypalChargeInfo?.id,
        })
      );
      if (response?.error) {
        return;
      }

      if (response?.name === 'RESOURCE_NOT_FOUND') {
        setPaypalTransactionId(order?.PaypalChargeInfo?.id);
      } else {
        setPaypalTransactionId(
          response?.purchase_units[0]?.payments?.captures[0]?.id
        );
      }
    }
  }, [order, setPaypalTransactionId]);

  return (
    <Dialog maxWidth="xl" open={modal} onClose={() => setModal(false)}>
      <Container>
        <Wrapper>
          {order?._id ? (
            <OrderPreview>
              <h1>Order {order?.ShirtlyOrderData?.ReceiptID}</h1>
              <h4>Products in this order</h4>

              <ProductOverview>
                <Slider {...settings}>
                  {orderLineItems?.map((orderLine, index) => {
                    const isProductSub = isSub(products, orderLine?.ProductID);
                    let designerSettings = getDesignerSettingsFromProductId(
                      products,
                      orderLine?.ProductID
                    );

                    const printType = orderLine?.PrintType;
                    const printAreas = orderLine?.printAreas;
                    const printAreasUploaded = printAreas?.filter(
                      (p) => p?.MockupSrc
                    );

                    if (printType !== 'DTG') {
                      designerSettings =
                        getAdditionalTechniquesFromProductId(
                          products,
                          orderLine?.ProductID,
                          false,
                          printType
                        ) || designerSettings;
                    }

                    const widthInInch =
                      orderLine?.ArtWorkCoordinates?.width >=
                      designerSettings?.width
                        ? designerSettings?.platenWidthInch
                        : (
                            (orderLine?.ArtWorkCoordinates?.width ||
                              0 * (designerSettings?.multiplier || 16)) / 255
                          ).toFixed(1);

                    const heightInInch =
                      orderLine?.ArtWorkCoordinates?.height >=
                      designerSettings?.height
                        ? designerSettings?.platenHeightInch
                        : (
                            (orderLine?.ArtWorkCoordinates?.height ||
                              0 * (designerSettings?.multiplier || 16)) / 255
                          ).toFixed(1);

                    const backWidthInInch =
                      orderLine?.BackArtWorkCoordinates?.width >=
                      designerSettings?.width
                        ? designerSettings?.platenWidthInch
                        : (
                            (orderLine?.BackArtWorkCoordinates?.width *
                              (designerSettings?.multiplier || 16)) /
                            255
                          ).toFixed(1);

                    const backHeightInInch =
                      orderLine?.BackArtWorkCoordinates?.height >=
                      designerSettings?.height
                        ? designerSettings?.platenHeightInch
                        : (
                            (orderLine?.BackArtWorkCoordinates?.height *
                              (designerSettings?.multiplier || 16)) /
                            255
                          ).toFixed(1);

                    let duraSoftArtWork = null;
                    let duraSoftBackArtWork = null;

                    if (orderLine?.PrintType === 'DURASOFT') {
                      const artworkImageSplit =
                        orderLine?.Artwork?.split('/upload/');

                      if (artworkImageSplit) {
                        duraSoftArtWork = `${
                          artworkImageSplit[0]
                        }/upload/w_${parseInt(
                          orderLine?.ArtWorkCoordinates?.width *
                            (designerSettings?.multiplier || 16)
                        )},h_${parseInt(
                          orderLine?.ArtWorkCoordinates?.height *
                            (designerSettings?.multiplier || 16)
                        )}/${artworkImageSplit[1]}`;
                      }

                      if (orderLine?.BackArtwork) {
                        const artworkBackImageSplit =
                          orderLine?.BackArtwork?.split('/upload/');

                        duraSoftBackArtWork = `${
                          artworkBackImageSplit[0]
                        }/upload/w_${parseInt(
                          orderLine?.BackArtWorkCoordinates?.width *
                            (designerSettings?.multiplier || 16)
                        )},h_${parseInt(
                          orderLine?.BackArtWorkCoordinates?.height *
                            (designerSettings?.multiplier || 16)
                        )}/${artworkBackImageSplit[1]}`;
                      }
                    }

                    return (
                      <PrintAreasSlider key={`orderLine${index}`}>
                        {printAreasUploaded?.length > 0 ? (
                          <Slider
                            {...settings}
                            dots={false}
                            arrows={true}
                            swipeToSlide={false}
                            infinite={false}
                          >
                            {printAreasUploaded?.map((p) =>
                              p?.MockupSrc ? (
                                <ImagePreview>
                                  <img src={p?.MockupSrc} alt="ProductImage" />
                                </ImagePreview>
                              ) : null
                            )}
                          </Slider>
                        ) : (
                          <ImagePreview>
                            <img
                              src={
                                orderLine?.MockupSrc ||
                                orderLine?.MarketingImagePath
                              }
                              alt="ProductImage"
                            />
                            {orderLine?.MockupBackSrc && (
                              <img
                                src={orderLine?.MockupBackSrc}
                                alt="ProductImage"
                              />
                            )}
                          </ImagePreview>
                        )}

                        <Details>
                          <div>
                            <h4>
                              Product&nbsp;:&nbsp;
                              <span>{orderLine?.Product}</span>
                            </h4>
                            <h4>
                              Fulfillment Cost&nbsp;:&nbsp;
                              <span>
                                ${orderLine?.FulfillmentCost?.toFixed(2)}
                              </span>
                            </h4>
                          </div>

                          <div>
                            <h4>
                              Quantity&nbsp;:&nbsp;
                              <span>{orderLine?.OliQuantity}</span>
                            </h4>
                            <h4>
                              Category&nbsp;:&nbsp;
                              <span>{orderLine?.Category}</span>
                            </h4>
                          </div>

                          <div>
                            <h4>
                              Print Provider&nbsp;:&nbsp;
                              <span>
                                {order?.ShirtlyOrderData?.PrintProvider}
                              </span>
                            </h4>
                            <h4>
                              Status&nbsp;:&nbsp;
                              <span>{order?.ShirtlyOrderData?.Status}</span>
                            </h4>
                          </div>

                          <div>
                            <h4>
                              Size&nbsp;:&nbsp;<span>{orderLine?.Size}</span>
                            </h4>
                            <h4>
                              Color&nbsp;:&nbsp;<span>{orderLine?.Color}</span>
                            </h4>
                            <h4>
                              Item ID&nbsp;:&nbsp;
                              <span>{orderLine?.OrderLineID}</span>
                            </h4>

                            <h4>
                              Date Created&nbsp;
                              <span>
                                {order?.ShirtlyOrderData?.DateCreated}
                              </span>
                            </h4>

                            {order?.OrderSubmitToProductionDate && (
                              <h4>
                                Production Date&nbsp;
                                <span>
                                  {order?.OrderSubmitToProductionDate}
                                </span>
                              </h4>
                            )}

                            {order?.OrderShippedDate && (
                              <h4>
                                Shipped Date&nbsp;
                                <span>{order?.OrderShippedDate}</span>
                              </h4>
                            )}
                          </div>
                          {orderLine?.PrintType && (
                            <div>
                              <h4>
                                Print Type&nbsp;:&nbsp;
                                <span>
                                  {orderLine?.PrintType}&nbsp;
                                  {orderLine?.Plus
                                    ? `[${orderLine?.Plus?.DtgPlusProcess?.substr(
                                        0,
                                        2
                                      )?.toUpperCase()} / ${
                                        orderLine?.Plus?.DtgPlusProcessColor
                                      }]`
                                    : null}
                                </span>
                              </h4>

                              {!printAreasUploaded?.length > 0 && (
                                <h4>
                                  Art Dimensions&nbsp;:&nbsp;
                                  <span>
                                    {widthInInch} X {heightInInch} inches
                                  </span>
                                </h4>
                              )}

                              {printAreasUploaded?.length > 0 &&
                                printAreasUploaded?.map((p) => {
                                  const {
                                    coordinates,
                                    designerSettings,
                                    printAreaName,
                                    customGraphicsdData,
                                  } = p;
                                  const { width = 0, height = 0 } =
                                    coordinates || {};
                                  const { multiplier } = designerSettings;

                                  const widthInInch =
                                    width >= designerSettings?.width
                                      ? designerSettings?.platenWidthInch
                                      : (
                                          (width * (multiplier || 16)) /
                                          255
                                        ).toFixed(1);

                                  const heightInInch =
                                    height >= designerSettings?.height
                                      ? designerSettings?.platenHeightInch
                                      : (
                                          (height * (multiplier || 16)) /
                                          255
                                        ).toFixed(1);

                                  if (
                                    widthInInch === '0.0' &&
                                    heightInInch === '0.0'
                                  ) {
                                    return null;
                                  }

                                  return (
                                    <h4>
                                      {printAreaName} Art
                                      Dimensions&nbsp;:&nbsp;
                                      <span>
                                        {widthInInch} X {heightInInch} inches
                                      </span>
                                    </h4>
                                  );
                                })}

                              {printAreasUploaded?.length > 0 &&
                                printAreasUploaded?.map((p) => {
                                  const { customGraphicsdData } = p;

                                  if (!customGraphicsdData?.length) return null;

                                  return (
                                    <h4>
                                      Download Production File&nbsp;:&nbsp;
                                      <span>
                                        <a
                                          rel="noreferrer"
                                          target="_blank"
                                          href={
                                            customGraphicsdData[0]
                                              ?.productionFile
                                          }
                                        >
                                          Download
                                        </a>
                                      </span>
                                    </h4>
                                  );
                                })}
                            </div>
                          )}

                          {!!orderLine?.ArtworkId && (
                            <h4>
                              Order Artwork ID&nbsp;:&nbsp;
                              <span>{orderLine?.ArtworkId}</span>
                            </h4>
                          )}
                          <div>
                            {!printAreasUploaded?.length > 0 && (
                              <h4>
                                Download&nbsp;
                                {orderLine?.aopVectorFile
                                  ? 'AOP Vector File'
                                  : 'Artwork'}
                                &nbsp;:&nbsp;
                                <span>
                                  {duraSoftArtWork ? (
                                    <a
                                      href={duraSoftArtWork}
                                      download
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      Download
                                    </a>
                                  ) : (
                                    <a
                                      href={
                                        orderLine?.Artwork ||
                                        orderLine?.aopVectorFile
                                      }
                                      rel="noreferrer"
                                      download
                                      target="_blank"
                                    >
                                      Download
                                    </a>
                                  )}
                                </span>
                              </h4>
                            )}

                            {printAreasUploaded?.length > 0 &&
                              printAreasUploaded?.map((p) => {
                                const {
                                  printAreaName,
                                  artwork,
                                  canvasArtBoardImage,
                                } = p;

                                return (
                                  <h4>
                                    Download&nbsp;
                                    {printAreaName}&nbsp;Artwork &nbsp;:&nbsp;
                                    <span>
                                      <a
                                        href={artwork || canvasArtBoardImage}
                                        download
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        Download
                                      </a>
                                    </span>
                                  </h4>
                                );
                              })}
                            <h4>
                              <Button
                                title="Download Art File"
                                containerStyle={{
                                  marginLeft: 20,
                                  background: 'white',
                                  boxShadow: 'none',
                                  padding: 0,
                                  textDecoration: 'underline',
                                  float: 'right',
                                  fontSize: 12,
                                  textTransform: 'none',
                                  fontWeight: ' bold',
                                }}
                                onClick={async () => {
                                  const payload = {
                                    emailid:
                                      order?.ShirtlyOrderData?.MasterUserEmail,
                                    id: orderLine?.ArtworkId,
                                  };
                                  const response = await dispatch(
                                    downloadArtFile(payload)
                                  );

                                  if (response?.uploadInfo) {
                                    window.open(
                                      response?.uploadInfo?.secure_url
                                    );
                                  } else {
                                    alert('No ArtFile saved for this product');
                                  }
                                }}
                              />
                              <Button
                                title="Upload Art File"
                                containerStyle={{
                                  marginLeft: 20,
                                  background: 'white',
                                  boxShadow: 'none',
                                  padding: 0,
                                  textDecoration: 'underline',
                                  float: 'right',
                                  fontSize: 12,
                                  textTransform: 'none',
                                  fontWeight: ' bold',
                                }}
                                onClick={async () => {
                                  const myWidget =
                                    window.cloudinary.createUploadWidget(
                                      {
                                        cloudName: 'big-oven-tees-inc',
                                        show_powered_by: false,
                                        uploadPreset: 'tnedst8q',
                                        sources: ['local', 'camera', 'url'],
                                        folder: `Shirtly/${order?.ShirtlyOrderData?.MasterUserEmail}/SavedFiles`,
                                      },
                                      async (error, result) => {
                                        if (
                                          !error &&
                                          result &&
                                          result.event === 'success'
                                        ) {
                                          const payload = {
                                            emailid:
                                              order?.ShirtlyOrderData
                                                ?.MasterUserEmail,
                                            saveData: {
                                              uploadInfo: result?.info,
                                              id: orderLine?.ArtworkId,
                                            },
                                          };

                                          await dispatch(saveArtFiles(payload));
                                          myWidget.close();
                                        }
                                      }
                                    );
                                  myWidget.open();
                                }}
                              />
                            </h4>
                          </div>

                          {orderLine?.BackArtwork &&
                            !printAreasUploaded?.length > 0 && (
                              <div style={{ gridTemplateColumns: '1fr 1fr' }}>
                                <h4>
                                  Download <br />
                                  Back Artwork&nbsp;:&nbsp;
                                  <span>
                                    <a
                                      href={
                                        duraSoftBackArtWork
                                          ? duraSoftBackArtWork
                                          : orderLine?.BackArtwork
                                      }
                                      download
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      Download
                                    </a>
                                  </span>
                                </h4>
                                <h4>
                                  Art Dimensions&nbsp;:&nbsp;
                                  <span>
                                    {backWidthInInch} X {backHeightInInch}{' '}
                                    inches
                                  </span>
                                </h4>
                              </div>
                            )}

                          {isProductSub && (
                            <div
                              style={{
                                gridTemplateColumns: '1fr',
                              }}
                            >
                              <h4>
                                Download Sublimation File&nbsp;:&nbsp;
                                <span
                                  style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }}
                                  onClick={async () => {
                                    const designerSettings =
                                      getDesignerSettingsFromProductId(
                                        products,
                                        orderLine?.ProductID
                                      );

                                    const payload = {
                                      user: emailid,
                                      artBoard: orderLine?.MarketingImageBase64,
                                      artWorkImage:
                                        printAreas[0]?.artwork?.replaceAll(
                                          'co_rgb:aaa9ad',
                                          'co_rgb:000000'
                                        ),
                                      artCord: {
                                        x:
                                          orderLine?.ArtWorkCoordinates?.x *
                                          designerSettings?.multiplier,
                                        y:
                                          orderLine?.ArtWorkCoordinates?.y *
                                          designerSettings?.multiplier,
                                      },
                                    };

                                    const url = await dispatch(
                                      generateArtboard(payload)
                                    );

                                    window.open(url);
                                  }}
                                >
                                  Download
                                </span>
                              </h4>
                            </div>
                          )}
                        </Details>

                        {orderLine?.DtgPlus && (
                          <Details>
                            <div>
                              <h4>
                                DTG Plus Process Name&nbsp;:&nbsp;
                                <span>
                                  {orderLine?.DtgPlus?.DtgPlusProcess}
                                </span>
                              </h4>
                              <h4>
                                DTG Plus Process Color&nbsp;:&nbsp;
                                <span>
                                  {orderLine?.DtgPlus?.DtgPlusProcessColor}
                                </span>
                              </h4>
                            </div>

                            <div>
                              <h4>
                                Download Vector File&nbsp;:&nbsp;
                                <span>
                                  <a
                                    href={orderLine?.DtgPlus?.DtgPlusVectorFile}
                                    download
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Download
                                  </a>
                                </span>
                              </h4>
                              <h4>
                                Download Mock File&nbsp;:&nbsp;
                                <span>
                                  <a
                                    href={orderLine?.DtgPlus?.dtgPlusMockFile}
                                    download
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Download
                                  </a>
                                </span>
                              </h4>
                            </div>
                            <div>
                              <div>
                                <img
                                  style={{ width: 200, margin: '20px auto' }}
                                  src={
                                    orderLine?.DtgPlus?.DtgPlusProcessColorImage
                                  }
                                  alt="dtg plus color file"
                                />
                                <div style={{ textAlign: 'center' }}>
                                  Selected DTG Plus Color{' '}
                                  <b>
                                    ({orderLine?.DtgPlus?.DtgPlusProcessColor})
                                  </b>
                                </div>
                              </div>
                              <div>
                                <img
                                  style={{ width: 200, margin: '20px auto' }}
                                  src={orderLine?.DtgPlus?.dtgPlusMockFile}
                                  alt="dtg plus mock file"
                                />
                                <div style={{ textAlign: 'center' }}>
                                  Selected DTG Plus Mock File
                                </div>
                              </div>
                            </div>
                          </Details>
                        )}
                      </PrintAreasSlider>
                    );
                  })}
                </Slider>
              </ProductOverview>
              <Button
                onClick={() => setBrowseLibrary(true)}
                title="User Art Library"
                containerStyle={{
                  width: '100%',
                  border: '1px solid #2379b4',
                  color: '#2379b4',
                  backgroundColor: 'transparent',
                  fontWeight: 'bold',
                  margin: '10px 0',
                }}
              />
            </OrderPreview>
          ) : (
            <OrderPreview>
              <h3>
                Due to some network error, Order is not pushed to production.
              </h3>
            </OrderPreview>
          )}
          {order?._id ? (
            <div>
              <OrderDetail>
                <h2>Order Details</h2>
                <h4>
                  Buyer Name:&nbsp;
                  <span>{order?.ShirtlyOrderData?.BuyerName}</span>
                </h4>

                <h4>
                  Address Line One:&nbsp;
                  <span>{order?.ShirtlyOrderData?.AddressLineOne}</span>
                  {order?.ShirtlyOrderData?.AddressLineTwo && (
                    <span>&nbsp;{order?.ShirtlyOrderData?.AddressLineTwo}</span>
                  )}
                  <span>,&nbsp;{order?.ShirtlyOrderData?.City},</span>
                  <span>&nbsp;{order?.ShirtlyOrderData?.StateProvince}</span>
                  <span>&nbsp;{order?.ShirtlyOrderData?.ZipPostal},</span>
                  <span>&nbsp;{order?.ShirtlyOrderData?.CountryString}</span>
                </h4>
              </OrderDetail>
              <OrderSummary>
                <h2>Order Summary</h2>
                {order?.ShirtlyOrderData?.Status == 'Shipped' && (
                  <h4>
                    Tracking Number:&nbsp;
                    <span>
                      <a
                        href={
                          isAU
                            ? order?.DataForMachine?.events?.find(
                                (e) => e?.action === 'shipped'
                              )?.trackingUrl
                            : order?.ShirtlyOrderData?.PrintProvider == 'CA'
                            ? `https://www.canadapost.ca/track-reperage/en#/details/${order?.ShirtlyOrderData?.TrackingNumber}`
                            : order?.ShirtlyOrderData?.TrackingNumber?.length <=
                              22
                            ? `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${order?.ShirtlyOrderData?.TrackingNumber}`
                            : `https://webtrack.dhlglobalmail.com/?trackingnumber=${order?.ShirtlyOrderData?.TrackingNumber}`
                        }
                        rel="noreferrer"
                        target="_blank"
                      >
                        {isAU
                          ? order?.DataForMachine?.events?.find(
                              (e) => e?.action === 'shipped'
                            )?.trackingNumber
                          : order?.ShirtlyOrderData?.TrackingNumber}
                      </a>
                    </span>
                  </h4>
                )}

                <h4>
                  User Email:&nbsp;
                  <span>{order?.ShirtlyOrderData?.MasterUserEmail}</span>
                  <span
                    style={{
                      float: 'right',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={async () => {
                      const request = await dispatch(
                        login({
                          email: user?.email,
                          password: user?.password,
                        })
                      );
                      if (request?.error) {
                        return;
                      }
                      history.replace('/');
                    }}
                  >
                    Impersonate
                  </span>
                </h4>

                <h4>
                  Shipping:&nbsp;
                  <span>
                    $
                    {(
                      order?.finalShippingCost ||
                      order?.ShirtlyOrderData?.TotalCostForShipping ||
                      0
                    ).toFixed(2)}
                    &nbsp;USD
                  </span>
                </h4>
                {(order?.StripeChargeInfo?.amount ||
                  (order?.PaypalChargeInfo?.purchase_units &&
                    order?.PaypalChargeInfo?.purchase_units[0]?.amount
                      ?.value)) && (
                  <h4>
                    Total:&nbsp;
                    {order?.StripeChargeInfo && (
                      <span>
                        {order?.StripeChargeInfo?.amount ? (
                          `$${order?.StripeChargeInfo?.amount / 100}`
                        ) : openModalPayment ? (
                          <span className="access-button">
                            Payment failed
                            <Button
                              title="Access Payment"
                              containerStyle={{
                                marginLeft: 20,
                                background: 'white',
                                boxShadow: 'none',
                                padding: 0,
                                textDecoration: 'underline',
                                float: 'right',
                                fontSize: 10,
                                textTransform: 'none',
                                fontWeight: ' bold',
                              }}
                              onClick={openModalPayment}
                            />
                          </span>
                        ) : null}
                        &nbsp;
                        {order?.StripeChargeInfo?.currency?.toUpperCase()}
                      </span>
                    )}
                    {order?.PaypalChargeInfo && (
                      <span>
                        {order?.PaypalChargeInfo?.purchase_units[0]?.amount
                          ?.value ? (
                          `$${order?.PaypalChargeInfo?.purchase_units[0]?.amount?.value} USD`
                        ) : openModalPayment ? (
                          <span className="access-button">
                            Payment failed
                            <Button
                              title="Access Payment"
                              containerStyle={{
                                marginLeft: 20,
                                background: 'white',
                                boxShadow: 'none',
                                padding: 0,
                                textDecoration: 'underline',
                                float: 'right',
                                fontSize: 10,
                                textTransform: 'none',
                                fontWeight: ' bold',
                              }}
                              onClick={openModalPayment}
                            />
                          </span>
                        ) : null}
                      </span>
                    )}
                    {!order?.PaypalChargeInfo && !order?.StripeChargeInfo && (
                      <span className="access-button">
                        Payment failed
                        <Button
                          title="Access Payment"
                          containerStyle={{
                            marginLeft: 20,
                            background: 'white',
                            boxShadow: 'none',
                            padding: 0,
                            textDecoration: 'underline',
                            float: 'right',
                            fontSize: 10,
                            textTransform: 'none',
                            fontWeight: ' bold',
                          }}
                          onClick={openModalPayment}
                        />
                      </span>
                    )}
                  </h4>
                )}
                {(order?.StripeChargeInfo?.id || paypalTransactionId) && (
                  <h4>
                    Transaction Id:&nbsp;
                    <span>
                      {order?.StripeChargeInfo?.id || paypalTransactionId}
                    </span>
                  </h4>
                )}
                {(order?.StripeChargeInfo || order?.PaypalChargeInfo) && (
                  <h4>
                    Payment Method:&nbsp;
                    <span>{order?.StripeChargeInfo ? 'Stripe' : 'Paypal'}</span>
                    {order?.StripeChargeInfo?.status === 'succeeded' && (
                      <span>
                        &nbsp;
                        <a
                          href={order?.StripeChargeInfo?.receipt_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          (Receipt)
                        </a>
                      </span>
                    )}
                  </h4>
                )}

                <div className="buttons">
                  {!isEU &&
                    !isAU &&
                    !isNY &&
                    !order?.isOrderPushedToMachine &&
                    pushOrderToMachine && (
                      <OrderPushedButton onClick={pushOrderToMachine}>
                        Push Order To Machine
                      </OrderPushedButton>
                    )}

                  {order?.ShirtlyOrderData?.Status !== 'Cancelled' && (
                    <CancelOrder
                      style={{ marginRight: 10 }}
                      onClick={async () => {
                        const payload = {
                          orderId: order?.ShirtlyOrderData?.ReceiptID,
                          printProvider: order?.ShirtlyOrderData?.PrintProvider,
                        };

                        const response = await dispatch(cancelOrder(payload));

                        if (
                          !response?.DeleteOrderResponse?.DeleteOrderResult
                            ?.ErrorMessage?._text ||
                          response?.status === 200
                        ) {
                          await dispatch(
                            updateOrderStatus({
                              user: order?.ShirtlyOrderData?.MasterUserEmail,
                              orderId: order?.ShirtlyOrderData?.ID,
                              status: 'Cancelled',
                            })
                          );

                          await dispatch(
                            updateSavedOrderData({
                              orderId: order?.ShirtlyOrderData?.ID,
                              status: 'Cancelled',
                            })
                          );
                          setModal(false);
                        }
                      }}
                    >
                      Cancel Order
                    </CancelOrder>
                  )}

                  <SendInvoice
                    onClick={async () => {
                      const { OrderLines } = order?.ShirtlyOrderData;
                      let fulfillmentCost = 0;

                      if (OrderLines?.length) {
                        const { OrderLineItems } = OrderLines[0];

                        for (let i = 0; i < OrderLineItems?.length; i++) {
                          fulfillmentCost += OrderLineItems[i]?.FulfillmentCost;
                        }
                      }

                      const totalInUsd = Number(
                        order?.grandTotalInUsd ||
                          order?.ShirtlyOrderData?.grandTotalInUSD
                      );

                      const shippingCost = Number(
                        order?.finalShippingCost ||
                          order?.ShirtlyOrderData?.ShippingCost
                      );

                      const finalFulfillmentCost = Number(
                        order?.fulfillmentCost || fulfillmentCost
                      );

                      await dispatch(
                        orderSubmitNotification({
                          orderData: order?.ShirtlyOrderData,
                          finalShippingCost: shippingCost,
                          fulfillmentCost: finalFulfillmentCost,
                          grandTotalInCan: Number(
                            order?.grandTotalInCan ||
                              order?.ShirtlyOrderData?.grandTotalInCAD
                          ),
                          grandTotalInUsd: totalInUsd,
                          shippingWithoutTax: Number(
                            order?.shippingWithoutTax ||
                              order?.ShirtlyOrderData?.ShippingCost
                          ),
                          totalTax:
                            order?.totalTax ||
                            Number(
                              (
                                totalInUsd -
                                (shippingCost + finalFulfillmentCost)
                              ).toFixed(2)
                            ),
                        })
                      );
                    }}
                  >
                    Send Invoice
                  </SendInvoice>

                  {order?.ShirtlyOrderData?.Status !== 'Cancelled' && (
                    <SendInvoice
                      onClick={async () => setOrderTrackingModal(true)}
                    >
                      Order Shipped
                    </SendInvoice>
                  )}
                  <SendInvoice onClick={() => setPrintOrderSummaryModal(true)}>
                    Print Order Summary
                  </SendInvoice>

                  <SendInvoice onClick={sendOrderToPlant}>
                    Send To {printProvider === 'CA' ? 'US' : 'CA'} Plant
                  </SendInvoice>

                  {order?.PaypalChargeInfo?.tokenExpired && (
                    <SendInvoice onClick={() => setOrderSubmitToProd(true)}>
                      Save Payment Data
                    </SendInvoice>
                  )}
                </div>
              </OrderSummary>
              {!!order?.captureImages?.length && (
                <div
                  style={{
                    backgroundColor: '#f2f2f2',
                    padding: 10,
                    width: '100%',
                    height: 400,
                  }}
                >
                  <h2 style={{ marginLeft: 10 }}>Product Print Preview</h2>
                  <div
                    style={{
                      margin: '0 auto',
                      display: 'block',
                      width: 400,
                    }}
                  >
                    <Slider {...settings}>
                      {order?.captureImages?.map((p) => (
                        <ImagePreview>
                          <img src={p?.liveImage} alt="ProductImage" />
                        </ImagePreview>
                      ))}
                    </Slider>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={{ paddingRight: 10 }}>
              <OrderPushedButton onClick={() => setOrderSubmitToProd(true)}>
                Push Order To Production
              </OrderPushedButton>
              <OrderPushedButton
                onClick={async () => {
                  const payload = {
                    user: order?.MasterUserEmail,
                    orderId: order?.ShirtlyOrderData?.ID,
                    status: 'AwaitingPayment',
                  };

                  await dispatch(changeOrderStatus(payload));
                  setOrderSubmitToProd(false);
                  setModal(false);
                }}
                style={{ marginRight: 10 }}
              >
                Push Order To Awaiting
              </OrderPushedButton>
            </div>
          )}
        </Wrapper>
      </Container>
      {orderSubmitToProdModal && (
        <OrderSubmitToProd
          order={order}
          open={orderSubmitToProdModal}
          setModal={(v) => setOrderSubmitToProd(v)}
          hideAllModal={() => {
            setOrderSubmitToProd(false);
            setModal(false);
          }}
        />
      )}

      {orderTrackingModal && (
        <UpdateOrderTracking
          order={order}
          open={orderTrackingModal}
          setModal={(v) => setOrderTrackingModal(v)}
          hideAllModal={() => {
            setOrderTrackingModal(false);
            setModal(false);
          }}
        />
      )}

      {browseLibrary && (
        <BrowseLibrary
          viewOnly={true}
          email={user?.email}
          orderLine={order}
          onDrop={(artwork) => {
            window.open(artwork?.secure_url);
          }}
          open={browseLibrary}
          toggle={() => {
            setBrowseLibrary(false);
          }}
        />
      )}
      {printOrderSummaryModal && (
        <PrintOrderSummaryModal
          order={order}
          modal={printOrderSummaryModal}
          setModal={() => setPrintOrderSummaryModal(undefined)}
        />
      )}
    </Dialog>
  );
};

export default OrderModal;
