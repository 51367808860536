import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Support } from 'styles/components/ArtWork/browseLibrary';

const CustomDialog = ({ open, setOpen, fetchData }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Take Down Procedure</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <b>Intellectual Property & Publicity Rights Disclaimer</b>
          <br />
          <br />
          Shirtly is a community built on respect and recognition of artists and
          designers. Users using the artwork database take full responsibility
          for the works they upload and display using Shirtly artwork database.
          This is reflected in the User Agreement. Use of the Shirtly website
          indicates continued acceptance of this Agreement.
          <br />
          <br />
          Several international treaties relate to intellectual property, but
          the laws are not uniform globally. However, it is strongly recommended
          that you check with the trademark/copyright office in your region or
          selling region of any artwork you are using.
          <br />
          <br />
          <b>
            Shirtly is legally not able to provide individual copyright,
            trademark, or publicity rights advice, or give personal opinions on
            these matters.
          </b>
          <br />
          <br />
          <br />
          <b>Notice & Takedown Procedure - Reports and Complaints</b>
          <br />
          <br />
          Shirtly respects the intellectual property rights of others and we ask
          our users to do the same.
          <br />
          <br />
          If you believe that your content has been used in a way that
          constitutes an infringement of your rights, please notify Shirtly's
          designated agent for complaints (email below) by sending a Notice and
          Takedown Report, which must include the following important
          information:
          <br />
          <br />
          1. an electronic or physical signature of the person authorized to act
          on behalf of the owner of the relevant matter;
          <br />
          <br />
          2. a description of the matter claimed to have been infringed;
          <br />
          <br />
          3. a description of where the claimed infringing content is located,
          Including URLs links.
          <br />
          <br />
          4. your address, telephone number, and email address;
          <br />
          <br />
          5. a statement by you that you have a good faith belief that the
          disputed use is not authorized by the owner, its agent, or the law;
          <br />
          <br />
          6. a statement by you, made under penalty of perjury, that:
          <br />
          <br />
          a. the above information is accurate; and
          <br />
          <br />
          b. you are authorized to act on behalf of the owner of the rights
          involved.
          <br />
          <br />
          You can email our team at:&nbsp;
          <Support href='mailto:support@shirtly.com'>
            support@shirtly.com
          </Support>
          <br />
          <br />
          Our team will review your case promptly, and if found the artwork
          infringes on intellectual property rights, the said artwork will be
          removed and deleted from the database immediately.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
