import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { getStores, removeStore } from 'actions/stores';
import StoresTableHead from 'pages/my-stores/stores/TableHead';
import StoresTableBody from 'pages/my-stores/stores/TableBody';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const Stores = ({ userEmail }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  useEffect(() => {
    return dispatch(getStores(userEmail || emailid));
  }, [dispatch, emailid, userEmail]);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table>
          <StoresTableHead />
          <TableBody>
            <StoresTableBody
              removeStore={async (store) => {
                await dispatch(
                  removeStore({
                    user: userEmail || emailid,
                    storeId: store.ID,
                  })
                );
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Stores;
