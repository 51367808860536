import React, { useState, useEffect } from "react";
import moment from "moment";
import { driver } from "driver.js";
import { useDispatch, useSelector } from "react-redux";
import { fabric } from "fabric";
import Dialog from "@material-ui/core/Dialog";
import { saveAdvanceArtFiles } from "actions/orders";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModalWrapper from "components/ModalWrapper";
import TextField from "components/TextField";
import Button from "components/Button";
import { generateMocks } from "actions/orders";
import { clearMocks } from "actions/designer";
import { showLoader, hideLoader } from "actions/loader";
import { SaveArtFile } from "styles/pages/my-stores/products/productCreator";

const renderAiMessage = (message, selectedAiCharaterImage) => {
  return `<div style='display:flex;gap:10px;align-items:center;justify-content:center;width:500px;'>
      <div>
        <img src=${selectedAiCharaterImage} style='width:100px;' />
      </div>
      <div style='font-size:22px;'>
        ${message}
      </div>
    </div>`;
};

const CustomDialog = ({
  open,
  setOpen,
  artwork,
  setMocks,
  colors,
  product,
  moveToNextStep,
  isAiActivated = false,
  selectedAiCharaterImage,
}) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (isAiActivated) {
      const driverObj = driver({
        animate: false,
        showProgress: false,
        allowKeyboardControl: false,

        steps: [
          {
            element: "#file-name",
            popover: {
              description: renderAiMessage(
                `Enter the file name,  give it a name then you will see Generate Mocks. Select and you have the option to download your mockup and or review in the Mocks Library.`,
                selectedAiCharaterImage
              ),
              side: "bottom",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: "#generate-mocks",
            popover: {
              description: renderAiMessage(
                `Click to generate Mock Ups.`,
                selectedAiCharaterImage
              ),
              side: "bottom",
              align: "start",
              showButtons: [],
            },
          },
        ],
      });

      driverObj.drive();
    }
  }, []);

  const uploadArtImage = async (canvas, element) =>
    new Promise(
      async (resolve) =>
        await canvas.loadFromJSON(element?.canvasJSON, async () => {
          dispatch(showLoader());
          const data = canvas.toDataURL({
            format: "png",
            multiplier: element?.designerSettings?.multiplier,
          });

          const API_ENDPOINT =
            "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";

          const fileData = new FormData();
          fileData.append("file", data);
          fileData.append("upload_preset", "tnedst8q");
          fileData.append("folder", `Shirtly/${emailid}/AdvanceArtFiles`);

          const r = await fetch(API_ENDPOINT, {
            method: "post",
            body: fileData,
          });
          const response = await r?.json();
          dispatch(hideLoader());
          resolve(response);
        })
    );

  const onSubmit = async () => {
    let printAreasCanvasJSON = artwork?.printAreas?.filter(
      (p) => p?.canvasJSON
    );

    let mockImagesResponse = [];

    for (let j = 0; j < printAreasCanvasJSON?.length; j++) {
      const row = printAreasCanvasJSON[j];
      const { designerSettings } = row;
      const { width, height } = designerSettings;

      const canvas = new fabric.Canvas("product-creator-canvas", {
        width,
        height,
      });
      canvas.clear();
      const data = await uploadArtImage(canvas, row);
      printAreasCanvasJSON[j].artworkUrl = data?.secure_url;
      const payload = {
        ArtId: moment().unix(),
        user: emailid,
        FileName: `${fileName}_${row?.printAreaName}`,
        AdvanceArtData: {
          CloudinaryObject: data,
          CanvasJsonObject: row?.canvasJSON,
        },
      };
      await dispatch(saveAdvanceArtFiles(payload));

      canvas.dispose();
    }

    document.getElementById("product-creator-canvas").style.display = "none";

    for (let i = 0; i < colors?.length; i++) {
      const color = colors[i];
      for (let j = 0; j < printAreasCanvasJSON?.length; j++) {
        const row = printAreasCanvasJSON[j];

        const { designerSettings, printAreaName } = row;
        const { left, top, width, height } = designerSettings;

        const artwork = row?.artworkUrl;
        const artworkImageSplit = artwork?.split("/upload/");
        const isSub = product?.Product?.PrintType === "SUB";
        const mainImageSplit =
          printAreaName === "Front"
            ? color?.Image?.split("/upload/")
            : color?.[`${printAreaName}Image`]?.split("/upload/");
        const payload = {
          mainImages: [
            `${mainImageSplit[0]}/upload/w_565,h_565/${mainImageSplit[1]}`,
          ],
          user: emailid,
          productName: product?.Product?.DisplayName,
          artImage: artworkImageSplit
            ? isSub
              ? `${artworkImageSplit[0]}/upload/w_${Math.round(
                  parseInt(width)
                )},h_${Math.round(parseInt(height))}/${artworkImageSplit[1]}`
              : `${artworkImageSplit[0]}/upload/w_${Math.round(
                  width
                )},h_${Math.round(height)}/${artworkImageSplit[1]}`
            : null,
          x: isSub
            ? Math.round(left / 2 + (565 - width) / 2)
            : Math.round((565 - width) / 2 + left / 2),
          y: top,
        };
        const response = await dispatch(generateMocks(payload));
        if (response?.error) {
          return;
        }
        mockImagesResponse = [...mockImagesResponse, ...response];

        if (printAreasCanvasJSON[j]?.canvasJSON) {
          const objects = printAreasCanvasJSON[j]?.canvasJSON?.objects;
          for (let k = 0; k < objects?.length; k++) {
            const element = objects[k];
            if (element?.type !== "image") {
              continue;
            }

            if (element?.src?.includes("/CustomGraphics/")) {
              const publicId = decodeURIComponent(
                `Shirtly/${element?.src?.split("/Shirtly/")[1]}`
              );
              await dispatch(
                clearMocks({
                  publicId: publicId?.split(".png")[0],
                })
              );
            }
          }
        }
      }
    }
    if (mockImagesResponse?.length > 0) {
      for (let i = 0; i < mockImagesResponse?.length; i++) {
        const splitValue = mockImagesResponse[i].split("/upload/");
        mockImagesResponse[
          i
        ] = `${splitValue[0]}/upload/b_white,fl_attachment/${splitValue[1]}`;
      }
      setMocks(mockImagesResponse);
    }
    setOpen();
    moveToNextStep();
  };

  return (
    <Dialog open={open} onClose={setOpen} maxWidth="md" fullWidth>
      <ModalWrapper className="about-us">
        {/* <DialogTitle>Save Advanced Art File</DialogTitle> */}
        <DialogTitle>Save Complete Design</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <SaveArtFile>
              <div style={{ display: "none" }}>
                <canvas
                  id="product-creator-canvas"
                  style={{ display: "none" }}
                ></canvas>
              </div>

              <TextField
                label="File Name"
                value={fileName}
                id="file-name"
                onChange={(v) => setFileName(v)}
              />

              <div
                id="generate-mocks"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  style={{ margin: 0 }}
                  title="Generate Mocks"
                  disabled={!fileName}
                  onClick={onSubmit}
                />
              </div>
            </SaveArtFile>
          </DialogContentText>
        </DialogContent>
      </ModalWrapper>
    </Dialog>
  );
};

export default CustomDialog;
