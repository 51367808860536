import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
    fontSize: '13px',
  },
}));

const CustomSelect = ({
  value,
  onChange,
  label,
  options,
  valueKey,
  labelKey,
  disabled,
  error,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      margin="dense"
    >
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        error={error}
        disabled={disabled}
        value={valueKey ? value[valueKey] : value ? value : ''}
        onChange={(e) => _.isFunction(onChange) && onChange(e.target.value, e)}
        label={label}
      >
        {options?.map((option, index) =>
          option.isNone ? (
            <MenuItem value="" key={`select${index}`}>
              <em>{option[valueKey] || ''}</em>
            </MenuItem>
          ) : (
            <MenuItem
              value={valueKey ? option[valueKey] : option}
              key={`select${index}`}
            >
              {option[labelKey] || option[valueKey] || option}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

CustomSelect.propTypes = {
  // value: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomSelect.defaultProps = {
  value: '',
  disabled: false,
};

export default CustomSelect;
