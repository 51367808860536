const row = (
  Name,
  Weight,
  WhitePrice,
  ColorPrice,
  HeatherPrice,
  ShirtLength,
  ChestWidth,
  HipsWidth,
  ShippingPackage
) => {
  return {
    Name,
    Weight,
    WhitePrice,
    ColorPrice,
    HeatherPrice,
    ShirtLength,
    ChestWidth,
    HipsWidth,
    ShippingPackage,
  };
};

export { row };
