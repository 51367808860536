const renderTexts = (texts, id) => {
  let html = "";

  for (let i = 0; i < texts.length; i++) {
    if (texts[i].text) {
      html += `<text 
                    font-family="${texts[i].font}" 
                    font-size="${texts[i].fontSize}"   
                    x="${texts[i].x}" 
                    y="${texts[i].y}" 
                    dominant-baseline="baseline" 
                    text-anchor="middle" 
                    style="font-display:block;font-weight:normal;fill:#000">
                        ${texts[i].text}
                </text>`;
    }
  }

  document.getElementById(id).innerHTML = html;
};

const renderImages = (images, id) => {
  let html = "";

  for (let i = 0; i < images.length; i++) {
    if (images[i].src) {
      html += `<image  x="${images[i].x}" y="${images[i].y}" width="${images[i].width}" height="${images[i].height}"  href='${images[i].src}' />`;
    }
  }

  document.getElementById(id).innerHTML = html;
};

export { renderTexts, renderImages };
