import styled from "styled-components";

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const HeaderRobotImg = styled.img`
  width: 100px;
`;

const AiAssistantActivatedButton = styled.div`
  background: #64c264;
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 20px;
`;

const Description = styled.div`
  margin-left: 115px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
`;

const SubTitle = styled.h4`
  margin: 5px 0 0;
  font-size: 14px;
  font-weight: normal;
`;

const Options = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 20px;
  justify-content: center;
`;

const Option = styled.div`
  text-align: center;
`;

const OptionHeader = styled.div`
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: bold;
`;

const OptionTitle = styled.div`
  border: 2px solid black;
  padding: 0 20px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 22px;
  display: flex;
  align-items: center;
  min-height: 60px;
  justify-content: center;

  svg {
    font-size: 40px;
  }

  :hover {
    background-color: #000;
    color: #fff;
  }
`;

const OptionSubTitle = styled.div`
  font-size: 10px;
`;

const StyledAIImages = styled.div`
  text-align: center;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  margin: 20px 20px;
  padding: 5px;
  opacity: 0.5;

  img {
    width: 100%;
  }
`;

const Iframe = styled.div`
  width: 50vw;
  height: 70vh;
  padding: 20px;

  iframe {
    width: 100%;
  }
`;

export {
  Container,
  Header,
  HeaderRobotImg,
  AiAssistantActivatedButton,
  Description,
  Title,
  SubTitle,
  Options,
  Option,
  OptionSubTitle,
  OptionTitle,
  OptionHeader,
  StyledAIImages,
  Iframe,
};
