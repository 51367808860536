const data = [
  {
    ProductId: 7,
    CategoryId: 1,
  },
  {
    ProductId: 13,
    CategoryId: 1,
  },
  {
    ProductId: 336,
    CategoryId: 1,
  },
  {
    ProductId: 340,
    CategoryId: 1,
  },
  {
    ProductId: 383,
    CategoryId: 1,
  },
  {
    ProductId: 407,
    CategoryId: 1,
  },
  {
    ProductId: 460,
    CategoryId: 1,
  },
  {
    ProductId: 6,
    CategoryId: 2,
  },
  {
    ProductId: 9,
    CategoryId: 2,
  },
  {
    ProductId: 12,
    CategoryId: 2,
  },
  {
    ProductId: 15,
    CategoryId: 2,
  },
  {
    ProductId: 381,
    CategoryId: 2,
  },
  {
    ProductId: 384,
    CategoryId: 2,
  },
  {
    ProductId: 385,
    CategoryId: 2,
  },
  {
    ProductId: 1,
    CategoryId: 3,
  },
  {
    ProductId: 2,
    CategoryId: 3,
  },
  {
    ProductId: 5,
    CategoryId: 3,
  },
  {
    ProductId: 13,
    CategoryId: 3,
  },
  {
    ProductId: 340,
    CategoryId: 3,
  },
  {
    ProductId: 383,
    CategoryId: 3,
  },
  {
    ProductId: 397,
    CategoryId: 3,
  },
  {
    ProductId: 407,
    CategoryId: 3,
  },
  {
    ProductId: 460,
    CategoryId: 3,
  },
  {
    ProductId: 10,
    CategoryId: 4,
  },
  {
    ProductId: 290,
    CategoryId: 4,
  },
  {
    ProductId: 375,
    CategoryId: 4,
  },
  {
    ProductId: 376,
    CategoryId: 4,
  },
  {
    ProductId: 379,
    CategoryId: 4,
  },
  {
    ProductId: 380,
    CategoryId: 4,
  },
  {
    ProductId: 399,
    CategoryId: 4,
  },
  {
    ProductId: 400,
    CategoryId: 4,
  },
  {
    ProductId: 405,
    CategoryId: 4,
  },
  {
    ProductId: 408,
    CategoryId: 4,
  },
  {
    ProductId: 409,
    CategoryId: 4,
  },
  {
    ProductId: 14,
    CategoryId: 5,
  },
  {
    ProductId: 291,
    CategoryId: 5,
  },
  {
    ProductId: 377,
    CategoryId: 5,
  },
  {
    ProductId: 378,
    CategoryId: 5,
  },
  {
    ProductId: 397,
    CategoryId: 6,
  },
  {
    ProductId: 398,
    CategoryId: 6,
  },
  {
    ProductId: 405,
    CategoryId: 6,
  },
  {
    ProductId: 410,
    CategoryId: 6,
  },
  {
    ProductId: 446,
    CategoryId: 6,
  },
  {
    ProductId: 450,
    CategoryId: 6,
  },
  {
    ProductId: 454,
    CategoryId: 6,
  },
  {
    ProductId: 455,
    CategoryId: 6,
  },
  {
    ProductId: 457,
    CategoryId: 6,
  },
  {
    ProductId: 459,
    CategoryId: 6,
  },
  {
    ProductId: 2,
    CategoryId: 15706,
  },
  {
    ProductId: 13,
    CategoryId: 15706,
  },
  {
    ProductId: 336,
    CategoryId: 15706,
  },
];

export default data;
