const columns = [
  { id: 'Status', label: 'Status', minWidth: 90 },
  { id: 'PrintProvider', label: 'Provider', minWidth: 90 },
  { id: 'Listing', label: 'Listing', minWidth: 100 },
  {
    id: 'OrderId',
    label: 'Order#',
    minWidth: 170,
  },
  {
    id: 'shopifyOrderPresentedID',
    label: 'StoreId',
    minWidth: 170,
  },
  {
    id: 'BuyerName',
    label: 'Buyer',
    minWidth: 170,
  },
  {
    id: 'OrderDate',
    label: 'Order Date',
    minWidth: 170,
  },
  {
    id: 'TotalCost',
    label: 'Total Cost (Exc. Tax)',
    minWidth: 170,
  },
  {
    id: 'TrackingNumber',
    label: 'Tracking Number',
    minWidth: 170,
  },
];

export { columns };
