import { useState } from "react";
import Button from "components/Button";
import COLORS from "shared/constants/colors";
import useFont from "hooks/fonts";
import { renderTexts } from "pages/customizer-tool/utils";
import {
  Container,
  Header,
  TextArea,
} from "styles/pages/customizer-tool/text-options";

const TextOptions = ({
  garmentPart,
  leftPocket,
  setLeftPocket,
  rightSleeve,
  setRightSleeve,
  back,
  setBack,
  renderModel,
}) => {
  const [font] = useFont();
  const [text, setText] = useState("");
  const disabled = !text?.trim();

  return (
    <Container>
      <Header>
        <h3>Text</h3>
        <h4>
          Edit your text below, or click on the field you'd like to edit
          directly on your design.
        </h4>
      </Header>

      <TextArea
        placeholder="Enter Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      <Button
        title="Add Text"
        disabled={disabled}
        containerStyle={{
          backgroundColor: COLORS.DARK_BLUE,
          color: COLORS.WHITE,
          fontWeight: "normal",
          boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
          textTransform: "none",
          borderRadius: "3px",
          fontSize: "16px",
          lineHeight: "1.5",
          marginBottom: 20,
          width: "100%",
          opacity: disabled ? 0.3 : 1,
        }}
        onClick={() => {
          const value = { text, fontSize: 22, font: font[0] };
          if (garmentPart === "left-pocket") {
            const texts = [...leftPocket.texts, { ...value, x: 1350, y: 1300 }];
            renderTexts(texts, "left-pocket-texts");
            setLeftPocket({ ...leftPocket, texts });
          } else if (garmentPart === "right-sleeve") {
            const texts = [...rightSleeve.texts, { ...value, x: 400, y: 620 }];
            renderTexts(texts, "right-sleeve-texts");
            setRightSleeve({
              ...rightSleeve,
              texts,
            });
          } else if (garmentPart === "back") {
            const texts = [...back.texts, { ...value, x: 400, y: 1150 }];
            renderTexts(texts, "back-texts");
            setBack({
              ...back,
              texts,
            });
          }
          renderModel();
          setText("");
        }}
      />
    </Container>
  );
};

export default TextOptions;
