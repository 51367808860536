import styled from "styled-components";

const Logo = styled.img`
  padding: 15px 38px;
  box-sizing: content-box;
  max-width: 150px;
  margin: 0 auto;
`;
const ItemText = styled.p`
  font-weight: 600;
`;

const Blinking = styled.svg`
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
  width: 10px;
  height: 10px;

  @keyframes blink {
    from,
    to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-moz-keyframes blink {
    from,
    to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-webkit-keyframes blink {
    from,
    to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-ms-keyframes blink {
    from,
    to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @-o-keyframes blink {
    from,
    to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;

const LogoWrapper = styled.div`
  background-color: #fff;
  border-radius: 15px;
  margin: 15px;
  display: flex;
`;

const DrawerContainer = styled.div`
  .active {
    .MuiListItem-button {
      color: #fff;
      background-color: #000;
      .MuiListItemIcon-root svg {
        fill: #fff;
      }
    }
  }
  .MuiListItemIcon-root {
    svg {
      fill: #000;
    }
  }
  .MuiListItem-button {
    :hover {
      .MuiListItemIcon-root {
        svg {
          fill: #fff;
        }
        color: #fff;
      }
    }
  }
  .MuiDrawer-paper {
    height: 100%;
  }
  .MuiCollapse-container,
  .MuiCollapse-root {
    border-radius: 25px;
    overflow: hidden;
    width: calc(100% - 20px);
    margin: 0 auto;
  }
  .MuiCollapse-wrapperInner {
    padding: 15px 0;
    border-radius: 25px;
    overflow: hidden;
    background: #000;
  }
`;

export { Logo, ItemText, Blinking, LogoWrapper, DrawerContainer };
