import { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import useColor from "hooks/colors";
import {
  Colors,
  Color,
  Container,
  Row,
} from "styles/pages/customizer-tool/colors";

const ColorOptions = ({ selectedMaterial, renderModel, setMaterialId }) => {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setMaterialId(pathIds[newValue]);
  };
  const [colors, color, setColor] = useColor();

  const pathIds = [];

  for (
    let index = 0;
    index < document.querySelectorAll("#svgPathContainer path").length;
    index++
  ) {
    const p = document.querySelectorAll("#svgPathContainer path")[index];
    if (p.id && p.attributes["fill"]) {
      pathIds.push(p.id);
    }
  }

  useEffect(() => {
    if (color) {
      document.getElementById(selectedMaterial).setAttribute("fill", color);

      renderModel();
      setColor("");
    }
  }, [color, selectedMaterial, renderModel, setColor]);

  useEffect(() => {
    if (pathIds?.length > 0) {
      setMaterialId(pathIds[0]);
    }
  }, []);

  return (
    <Container>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="off"
      >
        {pathIds?.map((p) => (
          <Tab
            key={p}
            style={{ minWidth: "auto" }}
            icon={
              <CheckBoxIcon
                style={{
                  color: document.getElementById(p)?.attributes["fill"]?.value,
                }}
              />
            }
            label={p?.replace("ZONE", "").replace("(", "").replace(")", "")}
          />
        ))}
      </Tabs>

      <Row>
        {/ZONE/.test(selectedMaterial) && (
          <Colors>
            {colors?.map((c) => (
              <Color
                key={c}
                onClick={() => setColor(c)}
                style={{ background: c }}
              />
            ))}
          </Colors>
        )}
      </Row>
    </Container>
  );
};

export default ColorOptions;
