import React from 'react';
import TextField from 'components/TextField';
import LogoImage from 'assets/images/shirtly-logo-small.png';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { CloudWrapper } from 'styles/pages/dtg-plus';

const isImageValid = (url) =>
  url?.includes('.png') ||
  url?.includes('.jpeg') ||
  url?.includes('.jpg') ||
  url?.includes('.svg');

const openCloudinaryWidget = (folder, cb) => {
  const myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: 'big-oven-tees-inc',
      show_powered_by: false,
      uploadPreset: 'tnedst8q',
      sources: ['local', 'camera', 'url'],
      folder,
    },
    (error, result) => {
      if (!error && result && result.event === 'success') {
        cb(result?.info?.secure_url);
        myWidget.close();
      }
    }
  );
  myWidget.open();
};

const CloudinaryWidget = ({
  value,
  setValue,
  folderPath,
  label,
  disabled = true,
}) => (
  <CloudWrapper>
    <img
      className='logo'
      alt='cloudinary'
      src={isImageValid(value) ? value : LogoImage}
    />
    <TextField
      label={label}
      size='small'
      value={value || ''}
      onChange={(v) => setValue(v)}
      disabled={disabled}
    />
    <CloudUploadIcon
      className='upload-icon'
      onClick={() => {
        openCloudinaryWidget(folderPath, (v) => setValue(v));
      }}
    />
  </CloudWrapper>
);

export default CloudinaryWidget;
