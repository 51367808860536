import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import ProductSubstitueModal from 'pages/order-details/ProductSubstitueModal';
import { getProductByProductId } from 'selectors/products';
import {
  ProductStockContainer,
  ProductStockHeader,
} from 'styles/pages/order-details';

const ProductStock = ({
  productStock,
  color,
  size,
  orderLine,
  setOrderLine,
  header = true,
  order,
}) => {
  const [modal, setModal] = useState(false);
  const products = useSelector((state) => state?.products?.data);
  const product = getProductByProductId(products, orderLine?.ProductID);
  const substituteProducts = product?.SubstituteProducts;
  const printProvider = order?.PrintProvider;
  const warehouseAbbr = printProvider === 'US' ? 'NJ' : 'ON';

  const productNotAvailable =
    order?.PrintProvider === 'AU'
      ? productStock?.status === 'in-stock'
      : !!productStock?.find((s) => {
          const ontarioWareHouseData = s?.warehouses?.find(
            (w) => w?.warehouseAbbr === warehouseAbbr
          );

          return (
            (s?.qty === 0 ||
              !ontarioWareHouseData ||
              ontarioWareHouseData?.qty === 0) &&
            s?.colorName?.includes(color) &&
            s?.sizeName === size
          );
        });

  return (
    <ProductStockContainer>
      {productNotAvailable &&
        order?.PrintProvider !== 'AU' &&
        !!substituteProducts?.length &&
        header && (
          <ProductStockHeader>
            <h3>Current Stock Information</h3>
            <Button title="Substitutes" onClick={() => setModal(true)} />
          </ProductStockHeader>
        )}
      {productStock && order?.PrintProvider !== 'AU'
        ? productStock?.map((s, i) => {
            if (s?.colorName?.includes(color) && s?.sizeName === size) {
              const ontarioWareHouseData = s?.warehouses?.find(
                (w) => w?.warehouseAbbr === warehouseAbbr
              );

              return (
                <p
                  key={`${s?.colorName}_${i}`}
                  style={{
                    color: 'white',
                    padding: 5,
                    borderBottom: 5,
                    borderRadius: 5,
                    textAlign: 'right',
                    fontWeight: 'bold',
                    backgroundColor:
                      ontarioWareHouseData && ontarioWareHouseData?.qty > 0
                        ? 'green'
                        : s?.qty === 0
                        ? 'red'
                        : 'orange',
                  }}
                >
                  Current Stock available for {s?.colorName}
                  &nbsp;/&nbsp;
                  {s?.sizeName}&nbsp;:&nbsp;
                  <span style={{ fontSize: 18 }}>
                    {ontarioWareHouseData
                      ? ontarioWareHouseData?.qty === 0
                        ? s?.qty
                        : ontarioWareHouseData?.qty
                      : s?.qty}
                  </span>
                  {s?.qty === 0 ? (
                    <span>&nbsp; (Fulfillment days: N/A)</span>
                  ) : (
                    (!ontarioWareHouseData ||
                      (ontarioWareHouseData &&
                        ontarioWareHouseData?.qty === 0)) && (
                      <span>
                        &nbsp; (Takes extra 3-4 business days to fulfill order)
                      </span>
                    )
                  )}
                </p>
              );
            }

            return null;
          })
        : null}

      {order?.PrintProvider === 'AU' && productStock && (
        <p
          style={{
            color: 'white',
            padding: 5,
            borderBottom: 5,
            borderRadius: 5,
            textAlign: 'right',
            fontWeight: 'bold',
            backgroundColor: productNotAvailable ? 'green' : 'red',
          }}
        >
          Current Stock {productNotAvailable ? 'available' : 'not available'}
          for {color}&nbsp;/&nbsp;{size}&nbsp;:&nbsp;
          <span style={{ fontSize: 18 }}>{productStock?.warehouseStock}</span>
        </p>
      )}

      {!!modal && (
        <ProductSubstitueModal
          open={!!modal}
          setOpen={() => setModal(undefined)}
          data={substituteProducts}
          onProductSelect={(p) => {
            setOrderLine({
              ...orderLine,
              Category: p?.Product?.CategoryIds[0]?.Name,
              CategoryID: p?.Product?.CategoryIds[0]?.CatId,
              Product: p?.Product?.Name,
              ProductID: p?.Product?.ID,
              Size: undefined,
              SizeID: undefined,
              Color: undefined,
              ColorID: undefined,
              MockupSrc: undefined,
              MockupBackSrc: undefined,
              ArtworkId: null,
              Artwork: null,
              previewImage: false,
              previewBackImage: false,
              dtgPlus: null,
              plus: null,
              BackArtworkId: null,
              BackArtwork: null,
              mainBackImage: null,
              artWorkBackImage: null,
              Sizes: p?.Sizes?.filter((s) => s?.visible !== false),
              Colors: p?.Colors?.filter((s) => s?.visible !== false),
              MarketingImagePath: p?.Product?.MarketingImagePath,
              BackImage: p?.Product?.MarketingBackImagePath,
              MarketingImageBase64: p?.Product?.MarketingImageBase64,
              RemainingDecorativeTechniques:
                p?.RemainingDecorativeTechniques?.map((v) => ({
                  ...v,
                  selected: false,
                })),
              BackArtWorkCoordinates: {
                x: 0,
                y: 0,
              },
              ArtWorkCoordinates: {
                x: 0,
                y: 0,
              },
              printAreas: p?.PrintAreas?.map((p, i) => ({
                ...p,
                selected: i === 0,
              })),
              productStock: null,
            });
            setModal(false);
          }}
        />
      )}
    </ProductStockContainer>
  );
};

export default ProductStock;
