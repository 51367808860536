import React, { useState } from 'react';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import TextField from 'components/TextField';
import Button from 'components/Button';
import {
  CardField,
  Footer,
  Cvv,
  AddCardWrapper,
  StyledCloseIcon,
} from 'styles/pages/account-setting/passwordForm';

const AddCardDialog = ({ open = false, hide, saveCard }) => {
  const [state, setState] = useState({
    cardNumber: undefined,
    cardExpMonth: undefined,
    cardExpYear: undefined,
    cvv: undefined,
  });

  const disabled =
    !state?.cardNumber ||
    state?.cardNumber?.trim()?.length < 12 ||
    !state?.cardExpMonth ||
    state?.cardExpMonth?.trim()?.length < 2 ||
    !state?.cardExpYear ||
    state?.cardExpYear?.trim()?.length < 2 ||
    !state?.cvv ||
    state?.cvv?.trim()?.length < 3;

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={() => _.isFunction(hide) && hide()}
    >
      <AddCardWrapper>
        <StyledCloseIcon onClick={() => _.isFunction(hide) && hide()} />
        <CardField>
          <h2>Add Credit/Debit Card</h2>
          <TextField
            fullWidth
            size='small'
            type='number'
            placeholder='Card Number'
            value={state?.cardNumber || ''}
            onChange={(cardNumber) => {
              if (cardNumber?.toString()?.trim()?.length <= 16) {
                setState({ ...state, cardNumber });
              }
            }}
          />
          <Cvv>
            <TextField
              fullWidth
              size='small'
              type='number'
              value={state?.cardExpMonth || ''}
              placeholder='Exp Month'
              onChange={(cardExpMonth) => {
                if (cardExpMonth?.toString()?.trim()?.length <= 2) {
                  setState({ ...state, cardExpMonth });
                }
              }}
            />
            <TextField
              fullWidth
              value={state?.cardExpYear || ''}
              size='small'
              type='number'
              placeholder='Exp Year'
              onChange={(cardExpYear) => {
                if (cardExpYear?.toString()?.trim()?.length <= 2) {
                  setState({ ...state, cardExpYear });
                }
              }}
            />
            <TextField
              fullWidth
              size='small'
              type='number'
              placeholder='CVV'
              value={state?.cvv || ''}
              onChange={(cvv) => {
                if (cvv?.toString()?.trim()?.length <= 4) {
                  setState({ ...state, cvv });
                }
              }}
            />
          </Cvv>
        </CardField>

        <Footer>
          <Button
            title='Add Card'
            disabled={disabled}
            onClick={() => {
              saveCard(state);
              hide();
            }}
          />
        </Footer>
      </AddCardWrapper>
    </Dialog>
  );
};

export default AddCardDialog;
