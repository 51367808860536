import styled from "styled-components";
import { Button } from "@material-ui/core";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  &.MuiButton-outlined {
    border-color: ${({ selected }) => (selected ? "inherit" : "#ccc")};
    color: ${({ selected }) => (selected ? "#1976d2" : "#ccc")};
  }
`;
