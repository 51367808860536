/* eslint-disable no-undef */
import { useState, useEffect, useCallback } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import ModelOptions from "pages/customizer-tool/ModelOptions";
import ModelWtihGarments from "pages/customizer-tool/ModelWtihGarments";

const Model = () => {
  let object;
  // let width = window.innerWidth;
  // let height = window.innerHeight;

  let width = 600;
  let height = window.innerHeight - 100;

  // create a scene
  const scene = new THREE.Scene();

  const [selectedMaterial, setMaterialId] = useState("");
  const [garmentPart, setGarmentPart] = useState("left-pocket");

  const [leftPocket, setLeftPocket] = useState({
    images: [],
    texts: [],
  });
  const [rightSleeve, setRightSleeve] = useState({ images: [], texts: [] });
  const [back, setBack] = useState({ images: [], texts: [] });

  // Text style copy replacement

  // Render the model into the scene
  const renderModel = useCallback(() => {
    // Clothes style
    const pathSvg = document
      .getElementById("svgPathContainer")
      .querySelector("svg");

    // Serialize dom to string
    const svgData = new XMLSerializer().serializeToString(pathSvg);

    // text style
    const textSvg = document
      .getElementById("svgTextContainer")
      .querySelector("svg");

    // Serialize dom to string
    const svgTextData = new XMLSerializer().serializeToString(textSvg);

    // Create a new texture and take the width and height of svg
    // The texture style requires a certain proportion to cover the entire model, and the icons within the texture have special position requirements.
    const canvas = document.createElement("canvas");
    canvas.width = pathSvg.width.baseVal.value;
    canvas.height = pathSvg.height.baseVal.value;

    const ctx = canvas.getContext("2d");

    // Create a vector of clothing styles and add it to the canvas later.
    const img = document.createElement("img");
    img.setAttribute(
      "src",
      "data:image/svg+xml;base64," +
        window.btoa(unescape(encodeURIComponent(svgData)))
    );

    // Create new material textures by layering images
    img.onload = function () {
      // Add clothing styles to the canvas
      ctx.drawImage(img, 0, 0);

      // The surface texture of the clothes and the small holes enhance the sense of reality.
      const patternImg = document.createElement("img");
      patternImg.width = 100;
      patternImg.height = 100;
      patternImg.src = "img/pattern.png";

      patternImg.onload = function () {
        // style pattern
        ctx.globalAlpha = 0.4;
        ctx.scale(0.3, 0.3);
        // Create a pattern that repeats this image (base image)
        const pattern = ctx.createPattern(patternImg, "repeat");
        ctx.fillStyle = pattern;
        ctx.fillRect(0, 0, canvas.width * 3.33, canvas.height * 3.33);
        ctx.globalAlpha = 1;
        ctx.scale(3.33, 3.33);

        // Create a text style carrier and add it to the canvas canvas later.
        const textImg = document.createElement("img");
        textImg.setAttribute(
          "src",
          "data:image/svg+xml;base64," +
            window.btoa(unescape(encodeURIComponent(svgTextData)))
        );

        textImg.onload = function () {
          ctx.drawImage(textImg, 0, 0);

          // Generate new texture
          const texture = new THREE.Texture(canvas);
          texture.needsUpdate = true;
          const textureMaterial = new THREE.MeshPhongMaterial({
            map: texture,
          });

          loadModal(textureMaterial);
        };
      };
    };

    // Load a model
    function loadModal(textureMaterial) {
      // Model loading manager, which can monitor the progress of model loading (optional)
      const manager = new THREE.LoadingManager();
      const loader = new THREE.OBJLoader2(manager);
      // Load model file
      loader.load("model.obj", function (data) {
        // Avoid reloading models
        if (object != null) {
          scene.remove(object);
        }
        // The specific data object of the model (you can console data related information)
        object = data.detail.loaderRootNode;

        // Override the model's material texture
        object.traverse(function (node) {
          if (node.isMesh) {
            node.material = textureMaterial;
            node.geometry.uvsNeedUpdate = true;
          }
        });

        // Scale model
        const scale = height / 3;
        object.scale.set(scale, scale, scale);

        // Model location information
        object.position.set(0, -scale * 1.35, 0);
        object.rotation.set(0, Math.PI / 2, 0);

        // shadow
        object.receiveShadow = true;
        object.castShadow = true;

        // Put the model into the scene
        scene.add(object);
      });
    }
  }, []);

  const load = async () => {
    const container = document.querySelector("#model");
    const camera = new THREE.PerspectiveCamera(30, width / height, 100, 1200);
    camera.position.set(500, 0, 0);
    scene.add(camera);
    // Added track controller to support 360° viewing
    const controls = new OrbitControls(camera, container);
    controls.minDistance = 200;
    controls.maxDistance = 700;
    controls.update();
    // Ambient light to illuminate the scene
    scene.add(new THREE.AmbientLight(0x666666));
    // Direct light, illuminating individual textures on the model, with shadow effects
    const light = new THREE.DirectionalLight(0xdfebff, 0.3);
    const d = 300;
    light.position.set(500, 100, 80);
    light.castShadow = true;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    light.shadow.camera.left = -d;
    light.shadow.camera.right = d;
    light.shadow.camera.top = d;
    light.shadow.camera.bottom = -d;
    light.shadow.camera.far = 100;
    light.shadowDarkness = 0.5;
    light.shadowCameraVisible = true;
    scene.add(light);
    // 多角度不同的灯光
    const lights = [
      {
        color: 0xffffff,
        intensity: 0.53,
        position: { x: -500, y: 320, z: 500 },
        lookAt: { x: 0, y: 0, z: 0 },
      },
      {
        color: 0xffffff,
        intensity: 0.3,
        position: { x: 200, y: 50, z: 500 },
        lookAt: { x: 0, y: 0, z: 0 },
      },
      {
        color: 0xffffff,
        intensity: 0.4,
        position: { x: 0, y: 100, z: -500 },
        lookAt: { x: 0, y: 0, z: 0 },
      },
      {
        color: 0xffffff,
        intensity: 0.3,
        position: { x: 1, y: 0, z: 0 },
        lookAt: { x: 0, y: 0, z: 0 },
      },
      {
        color: 0xffffff,
        intensity: 0.3,
        position: { x: -1, y: 0, z: 0 },
        lookAt: { x: 0, y: 0, z: 0 },
      },
    ];
    lights.forEach(function (light) {
      const dlight = new THREE.DirectionalLight(light.color, light.intensity);
      const p = light.position;
      const l = light.lookAt;
      dlight.position.set(p.x, p.y, p.z);
      dlight.lookAt(l.x, l.y, l.z);
      scene.add(dlight);
    });
    // 加载完整的衣服设计稿
    const res = await fetch("img/pattern.svg");
    const svg = await res.text();
    // 注入两个相同的 svg
    document.querySelector("#svgPathContainer").innerHTML = svg;
    document.querySelector("#svgTextContainer").innerHTML = svg;
    // 移除 path 的 text 标签部分，衣服样式
    document
      .querySelectorAll("#svgPathContainer text")
      .forEach((t) => t.remove());
    // 移除 text 的 path 标签部分，文字样式
    document
      .querySelectorAll("#svgTextContainer path")
      .forEach((p) => p.remove());
    // 获取衣服样式中可以换颜色（可定制）的部分

    renderModel();

    // Render the entire scene
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    renderer.setSize(width, height);
    renderer.setClearColor(0x000000, 0);
    container.appendChild(renderer.domElement);
    // 开启阴影效果，柔和过渡
    renderer.gammaInput = true;
    renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.soft = true;
    window.addEventListener("resize", () => {
      width = 600;
      height = window.innerHeight - 100;

      // width = window.innerWidth;
      // height = window.innerHeight;

      // 当视窗宽度小于高度时，则以正方形的方式缩放
      // if (width < height) {
      //   height = width;
      // }
      // 镜头要跟随移动
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    });
    function render() {
      renderer.render(scene, camera);
    }
    function animate() {
      requestAnimationFrame(animate);
      controls.update();
      render();
    }
    animate();
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "500px 1fr",
          marginTop: "20px",
        }}
      >
        <ModelOptions
          selectedMaterial={selectedMaterial}
          renderModel={renderModel}
          setMaterialId={setMaterialId}
          garmentPart={garmentPart}
          leftPocket={leftPocket}
          setLeftPocket={setLeftPocket}
          rightSleeve={rightSleeve}
          setRightSleeve={setRightSleeve}
          back={back}
          setBack={setBack}
        />

        <ModelWtihGarments
          garmentPart={garmentPart}
          setGarmentPart={setGarmentPart}
        />
      </div>

      <div id="svgTextContainer" className="hide"></div>
      <div id="svgPathContainer" className="hide"></div>
    </div>
  );
};

export default Model;
