import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Datepicker from 'components/DatePicker';
import Button from 'components/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from 'components/TextField';
import {
  updateOrderStatus,
  updateSavedOrderData,
  orderShippedNotification,
  closeBcOrder,
  closeWooOrder,
  closeShopifyOrder,
  closeBigCartelOrder,
} from 'actions/orders';
import { ModalContainer, Footer } from 'styles/pages/my-stores/registration';

const UpdateOrderTracking = ({ open, setModal, order, hideAllModal }) => {
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [shippedDate, setShippedDate] = useState(null);
  const dispatch = useDispatch();

  return (
    <Dialog maxWidth='md' open={open} onClose={() => setModal(false)}>
      <ModalContainer>
        <h1>Enter Tracking Number</h1>
        <TextField
          fullWidth
          placeholder='Tracking Number'
          value={trackingNumber || ''}
          onChange={(v) => setTrackingNumber(v)}
        />
        <Datepicker
          label='Shipped Date'
          value={shippedDate}
          onChange={(v) => setShippedDate(v)}
        />
        <Footer>
          <Button
            disabled={!trackingNumber || !shippedDate}
            variant='text'
            title='Submit'
            onClick={async () => {
              const orderLineItems =
                order?.ShirtlyOrderData?.OrderLines[0]?.OrderLineItems;

              await dispatch(
                await updateOrderStatus({
                  user: order?.ShirtlyOrderData?.MasterUserEmail,
                  orderId: order?.ShirtlyOrderData?.ID,
                  trackingNumber,
                  status: 'Shipped',
                  productionDate: order?.ShirtlyOrderData?.ProductionDate,
                  shipDate: moment(shippedDate).format('YYYY-MM-DD'),
                })
              );

              await dispatch(
                updateSavedOrderData({
                  orderId: order?.ShirtlyOrderData?.ID,
                  status: 'Shipped',
                  trackingNumber,
                  shipDate: moment(shippedDate).format('YYYY-MM-DD'),
                })
              );

              await dispatch(
                orderShippedNotification({
                  order: {
                    ReceiptID: order?.ShirtlyOrderData?.ReceiptID,
                    Marketplace: order?.ShirtlyOrderData?.Marketplace,
                    BuyerName: order?.ShirtlyOrderData?.BuyerName,
                    CountryString: order?.ShirtlyOrderData?.CountryString,
                    MasterUserEmail: order?.ShirtlyOrderData?.MasterUserEmail,
                  },
                  orderId: order?.ShirtlyOrderData?.ID,
                  trackingNumber: trackingNumber,
                  shipDate: moment(shippedDate).format('YYYY-MM-DD'),
                })
              );

              if (
                order?.ShirtlyOrderData?.Store?.MarketplaceType === 'Shopify'
              ) {
                await dispatch(
                  closeShopifyOrder({
                    shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                    orderId: order?.ShirtlyOrderData?.ID,
                    user: order?.ShirtlyOrderData?.MasterUserEmail,
                  })
                );
              } else if (
                order?.ShirtlyOrderData?.Store?.MarketplaceType ===
                'WooCommerce'
              ) {
                await dispatch(
                  closeWooOrder({
                    shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                    orderId: order?.ShirtlyOrderData?.ID,
                    user: order?.ShirtlyOrderData?.MasterUserEmail,
                  })
                );
              } else if (
                order?.ShirtlyOrderData?.Store?.MarketplaceType ===
                'BigCommerce'
              ) {
                await dispatch(
                  closeBcOrder({
                    shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                    orderId: order?.ShirtlyOrderData?.ID,
                    user: order?.ShirtlyOrderData?.MasterUserEmail,
                    trackingNumber: trackingNumber[0]?.value,
                    item: orderLineItems?.map((o) => ({
                      order_product_id: o?.MarketplaceDetails?.id,
                      quantity: o?.MarketplaceDetails?.quantity,
                    })),
                  })
                );
              } else if (
                order?.ShirtlyOrderData?.Store?.MarketplaceType === 'BigCartel'
              ) {
                await dispatch(
                  closeBigCartelOrder({
                    shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                    orderId: order?.ShirtlyOrderData?.ID,
                    user: order?.ShirtlyOrderData?.MasterUserEmail,
                  })
                );
              }

              setTrackingNumber(null);
              hideAllModal();
            }}
          />
        </Footer>
      </ModalContainer>
    </Dialog>
  );
};

export default UpdateOrderTracking;
