const LINKS = [
  {
    title: 'Products & Pricing',
    link: '#products-pricing',
  },
  {
    title: "What's new",
    link: '#whats-new',
  },
  {
    title: 'How it Works',
    link: '#how-it-works',
  },
  {
    title: 'Key Features',
    link: '#key-features',
  },
  {
    title: 'Product Bundling',
    link: '#product-bundling',
  },
];

export default LINKS;
