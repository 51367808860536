import React, { createRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/Button';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import { Container } from 'styles/pages/warehouse/orderModal';

const pageStyle = `
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

const PrintOrderSummaryModal = ({ modal, setModal, orders }) => {
  const printRef = createRef(undefined);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Bulk Order Summary`,
    pageStyle,
  });

  return (
    <Dialog maxWidth='xl' open={modal} onClose={() => setModal(false)}>
      <Container style={{ padding: 20 }}>
        <Button
          onClick={handlePrint}
          startIcon={<PrintIcon />}
          title='Print Packaging Slip'
          containerStyle={{
            float: 'right',
            color: '#fff',
            backgroundColor: '#000',
            fontWeight: 'bold',
            margin: '10px 0',
          }}
        />
        <div ref={printRef}>
          {orders?.orders?.map((order, i) => {
            return (
              <React.Fragment
                key={Number(order?.ShirtlyOrderData?.ProductID) + i}>
                <div style={{ padding: 20 }}>
                  <h1>
                    Shirtly&nbsp;(
                    {order?.ShirtlyOrderData?.PrintProvider === 'CA'
                      ? 'Canada'
                      : 'USA'}
                    )
                  </h1>

                  {order?.ShirtlyOrderData?.PrintProvider === 'CA' ? (
                    <h3>
                      122 Middleton Street
                      <br />
                      Brantford ON N3S 7V7
                    </h3>
                  ) : (
                    <h3>
                      8742 Buffalo Avenue
                      <br />
                      Niagara Falls NY 14303
                    </h3>
                  )}

                  <h3>
                    Order PO Number : {order?.ShirtlyOrderData?.ReceiptID}
                  </h3>
                  <hr />

                  <table
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      margin: '20px 0',
                    }}>
                    <tr>
                      <th
                        align='left'
                        style={{
                          border: '1px solid #ccc',
                          padding: 10,
                        }}>
                        Invoice To
                      </th>
                      <th
                        align='left'
                        style={{ border: '1px solid #ccc', padding: 10 }}>
                        Ship To
                      </th>
                      <th
                        align='left'
                        style={{ border: '1px solid #ccc', padding: 10 }}>
                        Terms
                      </th>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid #ccc', padding: 10 }}>
                        {order?.ShirtlyOrderData?.AddressLineOne},
                        {order?.ShirtlyOrderData?.AddressLineTwo?.length > 0
                          ? `${order?.ShirtlyOrderData?.AddressLineTwo},`
                          : null}
                        <br />
                        {order?.ShirtlyOrderData?.City},
                        {order?.ShirtlyOrderData?.StateProvince},
                        {order?.ShirtlyOrderData?.ZipPostal}
                        <br />
                        {order?.ShirtlyOrderData?.CountryString}
                      </td>
                      <td style={{ border: '1px solid #ccc', padding: 10 }}>
                        {order?.ShirtlyOrderData?.AddressLineOne},
                        {order?.ShirtlyOrderData?.AddressLineTwo?.length > 0
                          ? `${order?.ShirtlyOrderData?.AddressLineTwo},`
                          : null}
                        <br />
                        {order?.ShirtlyOrderData?.City},
                        {order?.ShirtlyOrderData?.StateProvince},
                        {order?.ShirtlyOrderData?.ZipPostal}
                        <br />
                        {order?.ShirtlyOrderData?.CountryString}
                      </td>
                      <td style={{ border: '1px solid #ccc', padding: 10 }}>
                        <b>
                          {order?.StripeChargeInfo
                            ? 'Stripe'
                            : order?.PaypalChargeInfo
                            ? 'Paypal'
                            : 'Payment Pending'}
                        </b>
                      </td>
                    </tr>
                  </table>

                  <table
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      margin: '20px 0',
                    }}>
                    <tr>
                      <th
                        align='left'
                        style={{
                          border: '1px solid #ccc',
                          padding: 10,
                          background: '#000',
                          color: '#fff',
                        }}>
                        Design
                      </th>
                      <th
                        align='left'
                        style={{
                          border: '1px solid #ccc',
                          padding: 10,
                          background: '#000',
                          color: '#fff',
                        }}>
                        Area
                      </th>
                      <th
                        align='left'
                        style={{
                          border: '1px solid #ccc',
                          padding: 10,
                          background: '#000',
                          color: '#fff',
                        }}>
                        Product
                      </th>
                      <th
                        align='left'
                        style={{
                          border: '1px solid #ccc',
                          padding: 10,
                          background: '#000',
                          color: '#fff',
                        }}>
                        Color
                      </th>
                      <th
                        align='left'
                        style={{
                          border: '1px solid #ccc',
                          padding: 10,
                          background: '#000',
                          color: '#fff',
                        }}>
                        Qty
                      </th>
                      <th
                        align='left'
                        style={{
                          border: '1px solid #ccc',
                          padding: 10,
                          background: '#000',
                          color: '#fff',
                        }}>
                        Size
                      </th>
                      <th
                        align='left'
                        style={{
                          border: '1px solid #ccc',
                          padding: 10,
                          background: '#000',
                          color: '#fff',
                        }}>
                        Fulfillment Cost (USD)
                      </th>
                    </tr>
                    {order?.ShirtlyOrderData?.OrderLines[0]?.OrderLineItems?.map(
                      (d) => (
                        <tr key={d?.OrderID}>
                          <td style={{ border: '1px solid #ccc', padding: 10 }}>
                            <img
                              src={d?.MarketingImagePath}
                              alt='MockupSrc'
                              width='50'
                            />
                          </td>
                          <td style={{ border: '1px solid #ccc', padding: 10 }}>
                            {d?.MockupBackSrc && d?.MockupSrc
                              ? 'Front/Back'
                              : 'Front'}
                          </td>
                          <td style={{ border: '1px solid #ccc', padding: 10 }}>
                            {d?.Product}
                          </td>
                          <td style={{ border: '1px solid #ccc', padding: 10 }}>
                            {d?.Color}
                          </td>
                          <td style={{ border: '1px solid #ccc', padding: 10 }}>
                            {d?.OliQuantity}
                          </td>
                          <td style={{ border: '1px solid #ccc', padding: 10 }}>
                            {d?.Size}
                          </td>
                          <td style={{ border: '1px solid #ccc', padding: 10 }}>
                            ${d?.FulfillmentCost}
                          </td>
                        </tr>
                      )
                    )}
                  </table>
                  {order?.StripeChargeInfo && (
                    <div>
                      <div
                        style={{
                          textAlign: 'right',
                          fontWeight: 'bold',
                          fontSize: 18,
                          marginBottom: 10,
                        }}>
                        Total Shipping (USD) : $&nbsp;
                        {order?.ShirtlyOrderData?.TotalCostForShipping}
                      </div>
                      <div
                        style={{
                          textAlign: 'right',
                          fontWeight: 'bold',
                          fontSize: 18,
                        }}>
                        Total Cost&nbsp;
                        <span style={{ textTransform: 'uppercase' }}>
                          ({order?.StripeChargeInfo?.currency})
                        </span>
                        : $&nbsp;
                        {(order?.StripeChargeInfo?.amount / 100).toFixed(2)}
                      </div>
                    </div>
                  )}

                  {order?.PaypalChargeInfo && (
                    <div>
                      <div
                        style={{
                          textAlign: 'right',
                          fontWeight: 'bold',
                          fontSize: 18,
                          marginBottom: 10,
                        }}>
                        Total Shipping (USD) : $&nbsp;
                        {order?.ShirtlyOrderData?.TotalCostForShipping}
                      </div>
                      <div
                        style={{
                          textAlign: 'right',
                          fontWeight: 'bold',
                          fontSize: 18,
                        }}>
                        Total Cost&nbsp;
                        <span style={{ textTransform: 'uppercase' }}>
                          (
                          {
                            order?.PaypalChargeInfo?.purchase_units[0]?.amount
                              ?.currency_code
                          }
                          )
                        </span>
                        : $&nbsp;
                        {Number(
                          order?.PaypalChargeInfo?.purchase_units[0]?.amount
                            ?.value
                        ).toFixed(2)}
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </Dialog>
  );
};

export default PrintOrderSummaryModal;
