import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Checkbox from "components/CheckBox";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/Button";
import { getUserInformation } from "actions/user";
import { getSKUFromProductId, isSub } from "selectors/products";
import OrderInformation from "pages/order-details/OrderInformation";
import CustomerInformation from "pages/order-details/CustomerInformation";
import Stripe from "components/Stripe";
import Image from "components/Image";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { AUTH_LOGOUT } from "actions/auth";
import { INITIATE_STORES } from "actions/stores";
import { INITIAL_SYNC_PRODUCTS } from "actions/sync-products";
import ErrorMessage from "components/ErrorMessage";
import ProductionOrderStatus from "pages/order-details/ProductionOrderStatus";
import PaymentErrorDialog from "pages/order-details/PaymentErrorDialog";
import ProductStock from "pages/order-details/ProductStock";
import CaptureImages from "pages/order-details/CaptureImages";
import EcoIcon from "@material-ui/icons/Eco";
import InboxIcon from "@material-ui/icons/Inbox";
// import InfoIcon from "@material-ui/icons/Info";
import { ROUTES } from "shared/constants/routes";
import {
  stripeCharge,
  getAllCards,
  removeCard,
  calculateTax,
  paypalCharge,
  checkJWTtoken,
} from "actions/stripe";
import {
  submitOrderToCanada,
  submitOrderToUSA,
  updateOrderStatus,
  saveOrderData,
  orderSubmitNotification,
  orderDurasoftNotification,
  orderAOPNotification,
  updateOrderPackingMode,
  saveAllCosts,
  getSSActiveWearProductData,
  submitOrderToEU,
  submitOrderToAU,
  getAUSplantStock,
  submitOrderToNY,
  orderTuffPrintTransferNotification,
  orderGiftMessageNotification,
} from "actions/orders";
import { json2xml } from "utils/jsonToXmlParser";
import {
  Container,
  OrderDetail,
  Heading,
  Panel,
  MoreDetail,
  OrderTotal,
  Total,
  ProductImage,
  SlickContainer,
  PackingOptions,
  PackingOption,
  MessageTextarea,
  AddMessageButtonWrapper,
} from "styles/pages/order-details/orderSummary";
import { USD_TO_CAD_CONVERSION_RATE } from "constants/currency";

const XMLParser = require("react-xml-parser");

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const getFulfillmentCost = (order = {}) => {
  const { OrderLines } = order;
  if (OrderLines?.length) {
    const { OrderLineItems } = OrderLines[0];
    let fulfillmentCost = 0;

    for (let i = 0; i < OrderLineItems?.length; i++) {
      fulfillmentCost += OrderLineItems[i]?.FulfillmentCost;
    }

    return Number(fulfillmentCost.toFixed(2)) || 0;
  }
};

export const getFulfillmentCostWithTax = (order = {}, taxInfo = []) => {
  const { OrderLines } = order;
  if (OrderLines?.length) {
    const { OrderLineItems } = OrderLines[0];
    let fulfillmentCost = 0;

    for (let i = 0; i < OrderLineItems?.length; i++) {
      let fCost = OrderLineItems[i]?.FulfillmentCost;
      const printAreas = OrderLineItems[i]?.printAreas;

      for (let j = 0; j < printAreas.length; j++) {
        const customGraphicsdData = printAreas[j]?.customGraphicsdData;

        if (!!customGraphicsdData?.length) {
          for (let k = 0; k < customGraphicsdData.length; k++) {
            fCost +=
              customGraphicsdData[k]?.shirtlyChargeAmountInUsd *
              OrderLineItems[i]?.OliQuantity;
          }
        }
      }

      const taxOnItem = getTaxCost(taxInfo, order, OrderLineItems[i].Category);
      const taxOnFulfillment = Number(((fCost * taxOnItem) / 100).toFixed(2));

      fulfillmentCost += fCost + taxOnFulfillment;
    }

    return Number(fulfillmentCost.toFixed(2)) || 0;
  }
};

const getOrderLineTax = (order = {}, taxInfo = []) => {
  const { OrderLines } = order;
  if (OrderLines?.length) {
    const { OrderLineItems } = OrderLines[0];
    let tax = 0;

    for (let i = 0; i < OrderLineItems?.length; i++) {
      const fCost = OrderLineItems[i]?.FulfillmentCost;
      const taxOnItem = getTaxCost(taxInfo, order, OrderLineItems[i].Category);
      const taxOnFulfillment = Number(((fCost * taxOnItem) / 100).toFixed(2));

      tax += taxOnFulfillment;
    }

    return Number(tax.toFixed(2)) || 0;
  }
};

export const isVatIncluded = (tax = [], order, countries) => {
  const selectedPrintProviderTaxInfo = tax.find(
    (c) => c?.PrintProvider === order?.PrintProvider
  );

  if (selectedPrintProviderTaxInfo) {
    const selectedDestinationCountry =
      selectedPrintProviderTaxInfo?.TaxCharges?.find(
        (c) => c?.ShipppingDestinationCountry === order?.CountryString
      );

    const selectedCountry = countries?.find(
      (c) =>
        c?.code2 === selectedDestinationCountry?.ShipppingDestinationCountry
    );

    return selectedCountry?.region === "Europe";

    // if (selectedDestinationCountry) {
    //   const selectedTaxCharge = selectedDestinationCountry?.TaxCharges?.find(
    //     (c) => c?.ShippingState === order?.StateProvince
    //   );

    //   return selectedTaxCharge?.VatIncluded || false;
    // }
    // return false;
  }
  return false;
};

export const getTaxCost = (tax = [], order, category) => {
  // const approvedStateReSaleCertificate =
  //   !!order?.userCertificate &&
  //   !!order?.userCertificate?.find((r) => r?.status === "approved");

  if (order?.PrintProvider === "EU") return 23;

  if (order?.PrintProvider === "AU") return 10;

  if (order?.PrintProvider === "NY") return 8;

  if (order?.PrintProvider === "US" && order?.CountryString == "US") return 8;
  // return approvedStateReSaleCertificate ? 0 : 8;

  const isKidOrInfant = category === "Kids" || category === "Infant";
  const country = order?.CountryString;
  const state = order?.StateProvince;

  if (isKidOrInfant && country == "CA" && state == "ON") {
    return 5;
  } else if (isKidOrInfant && country == "CA" && state == "NS") {
    return 5;
  } else if (isKidOrInfant && country == "CA" && state == "PE") {
    return 6;
  }

  const selectedPrintProviderTaxInfo = tax.find(
    (c) => c?.PrintProvider === order?.PrintProvider
  );

  if (selectedPrintProviderTaxInfo) {
    const selectedDestinationCountry =
      selectedPrintProviderTaxInfo?.TaxCharges?.find(
        (c) => c?.ShipppingDestinationCountry === country
      );
    if (selectedDestinationCountry) {
      const selectedTaxCharge = selectedDestinationCountry?.TaxCharges?.find(
        (c) => c?.ShippingState === state
      );

      return selectedTaxCharge?.TaxCharge || 0;
    }
    return 0;
  }
  return 0;
};

const OrderSummary = ({
  order = {},
  shippingCostInfo,
  refresh,
  machineStatus,
  orderPrintProviders,
  addMessage,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paymentModal, setPaymentModal] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [cards, setCards] = useState(undefined);
  const [message, setMessage] = useState(order?.message || "");
  const [taxInfo, setTaxInfo] = useState(undefined);
  const [paymentError, setPaymentError] = useState(undefined);
  const [orderLineItemsProductStock, setOrderLineItemsProductStock] = useState(
    []
  );
  const { OrderLines: orderLines } = order;
  const { OrderLineItems: orderLineItems } = orderLines?.length
    ? orderLines[0]
    : {};
  const products = useSelector((state) => state?.products?.data);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const countries = useSelector((state) => state?.countries?.data);
  const [packingMode, setPackingMode] = useState(undefined);
  const currency = useSelector((state) => state?.currency?.value);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!user) {
        const request = await dispatch(getUserInformation(emailid));
        if (request?.error) {
          return;
        }
        setUser(request);
      }
    };
    fetchUserDetails();
  }, [dispatch, emailid, user]);

  const updatePackingRequest = async (packingMode) => {
    await dispatch(
      updateOrderPackingMode({
        user: emailid,
        orderId: order?.ID,
        packingMode,
      })
    );
  };

  useEffect(() => {
    const fetchCards = async () => {
      if (!cards) {
        const cards = await dispatch(
          getAllCards({
            user: emailid,
          })
        );

        if (cards.error) {
          return;
        }

        setCards(cards);
      }
    };
    fetchCards();
  }, [dispatch, setCards, emailid, cards]);

  useEffect(() => {
    const fetchTaxInfo = async () => {
      if (!cards) {
        const tax = await dispatch(calculateTax());

        if (tax.error) {
          return;
        }

        setTaxInfo(tax);
      }
    };
    fetchTaxInfo();
  }, [dispatch, emailid]);

  useEffect(() => {
    const updatePacking = async () => {
      await updatePackingRequest("normal");
      refresh();
    };

    if (!order?.PackingMode) {
      updatePacking();
    } else {
      setPackingMode(order?.PackingMode);
    }
  }, [order]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (order?.PrintProvider === "AU") {
      let data = [];
      for await (const lineItem of orderLineItems) {
        const selectedProduct = _.find(
          products,
          (o) => o?.Product.ID === lineItem?.ProductID
        );

        const skuData = selectedProduct?.SKUdata;
        const sku = skuData?.find(
          (s) =>
            s?.colorName === lineItem?.Color && s?.sizeName === lineItem?.Size
        );
        const productStock = await dispatch(
          getAUSplantStock({
            sku: sku?.sku,
          })
        );

        data.push(productStock);
      }
      setOrderLineItemsProductStock(data);
    } else if (
      orderLineItems?.length > 0 &&
      !orderLineItemsProductStock?.length
    ) {
      let data = [];
      for await (const lineItem of orderLineItems) {
        const productStock = await dispatch(
          getSSActiveWearProductData({
            id: lineItem?.ProductID,
            printProvider: order?.PrintProvider,
          })
        );
        data.push(productStock);
      }
      setOrderLineItemsProductStock(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderLineItems]);

  const addExtraShippingCostIfPPNotMatch = () => {
    let count = 0;
    let quantity = 0;
    let value = 0;
    for (let i = 0; i < order?.OrderLines[0]?.OrderLineItems.length; i++) {
      const element = order?.OrderLines[0]?.OrderLineItems[i];
      const product = products?.find(
        (p) => p?.Product?.ID === element?.ProductID
      );
      const isPPSame = product?.PrintProviders.find(
        (p) => p?.ProviderName === order?.PrintProvider
      );

      if (!isPPSame) {
        count += 1;
        quantity += Number(element?.OliQuantity);
      }
    }

    if (count >= 1 && order?.PrintProvider === "US") {
      value = 8.9;
    } else if (count >= 1 && order?.PrintProvider === "CA") {
      value = 12.8;
    }

    if (value > 0) {
      if (quantity === 1) {
        return value;
      } else {
        if (order?.PrintProvider === "US") {
          return value + (quantity - 1);
        } else if (order?.PrintProvider === "CA") {
          return value + (quantity - 1) * 8;
        }
      }
    }

    return 0;
  };

  const shippingCost =
    Number(shippingCostInfo?.cost) + addExtraShippingCostIfPPNotMatch() || 0;

  const pendingStateReSaleCertificate =
    !!order?.userCertificate &&
    !!order?.userCertificate?.find((r) => !r?.status);

  // const approvedStateReSaleCertificate =
  //   !!order?.userCertificate &&
  //   !!order?.userCertificate?.find((r) => r?.status === "approved");

  const declinedStateReSaleCertificate =
    !!order?.userCertificate &&
    !!order?.userCertificate?.find((r) => r?.status === "declined");

  const taxPercentageOnOrder = getTaxCost(taxInfo, order);
  const shippingTaxCost = Number(
    ((shippingCost * taxPercentageOnOrder) / 100).toFixed(2)
  );
  const finalShippingCost =
    shippingCost + shippingTaxCost + (packingMode === "eco" ? 1 : 0);
  const totalOrderCostInUSD = Number(
    (finalShippingCost + getFulfillmentCostWithTax(order, taxInfo)).toFixed(2)
  );

  const subTotal = getFulfillmentCostWithTax(order, taxInfo);
  const grandTotalInUsd = Number(subTotal + finalShippingCost).toFixed(2);

  const getPrintProviderName = () => {
    const { printProviders, index } = orderPrintProviders;
    const selectedPP = printProviders[index];
    const shippingDestination =
      order?.CountryString !== "US" && order?.CountryString !== "CA"
        ? "Intl"
        : order?.CountryString;

    const shippingCostData = _.find(
      selectedPP?.ShippingCosts,
      (c) => c?.ShippingDestination === shippingDestination
    );

    return shippingCostData?.Title;
  };

  let totalQuantity = 0;

  for (let i = 0; i < orderLineItems.length; i++) {
    const o = orderLineItems[i];
    totalQuantity += +o?.OliQuantity;
  }

  const saveOrderCost = async () => {
    let totalTaxOnLineItems = 0;
    let fulfillmentCostWithoutTax = 0;

    for (let i = 0; i < orderLineItems.length; i++) {
      const o = orderLineItems[i];
      const fulfillmentCost = o?.FulfillmentCost;

      const taxOnItem = getTaxCost(taxInfo, order, o?.Category);

      const taxOnFulfillment = Number(
        ((fulfillmentCost * taxOnItem) / 100).toFixed(2)
      );

      totalTaxOnLineItems += taxOnFulfillment;
      fulfillmentCostWithoutTax += fulfillmentCost;
    }

    const payload = {
      orderId: order?.ID,
      user: emailid,
      allCost: {
        totalFullfillmentCost: subTotal,
        shippingCost,
        shippingTaxCost,
        finalShippingCost,
        totalOrderCostInUSD,
        totalOrderCostInCAD: Number(
          (totalOrderCostInUSD * USD_TO_CAD_CONVERSION_RATE)?.toFixed(2)
        ),
        totalTaxOnLineItems,
        fulfillmentCostWithoutTax,
      },
    };
    await dispatch(saveAllCosts(payload));
  };

  const paymentButtonVisibility = order?.Status === "AwaitingPayment";

  return (
    <>
      <ErrorMessage>
        Any claims for misprinted/damaged/defective items must be submitted
        within 30 days after the product has been received. For packages lost in
        transit, all claims must be submitted no later than 30 days after the
        estimated delivery date. Claims deemed an error on our part are covered
        at our expense. For lost packages that are not caused by our error, we
        will make our best effort to claim the loss on your behalf and re-print
        your order if successful.
      </ErrorMessage>

      {(order?.Status === "InProduction" || order?.Status === "Shipped") &&
        machineStatus && (
          <ProductionOrderStatus
            orderDetail={order}
            machineStatus={machineStatus}
          />
        )}

      <Container>
        <OrderDetail>
          <Heading>Order Details ({order?.ReceiptID})</Heading>
          <Panel>
            <MoreDetail>
              {orderLineItems?.map((lineItem, index) => {
                const sku = getSKUFromProductId(products, lineItem.ProductID);
                const isProductSub = isSub(products, lineItem.ProductID);
                const printAreas = lineItem?.printAreas;
                const productStock = orderLineItemsProductStock[index];

                const customGraphicsData = lineItem?.printAreas?.filter(
                  (p) => !!p?.customGraphicsdData
                );

                let days = {
                  minDays: 0,
                  maxDays: 0,
                };

                const product = _.find(
                  products,
                  (p) => p?.Product?.ID === lineItem.ProductID
                );

                let fulfillmentCost = lineItem?.FulfillmentCost;

                if (!!customGraphicsData?.length) {
                  for (let x = 0; x < customGraphicsData.length; x++) {
                    const printArea = customGraphicsData[x];
                    for (
                      let y = 0;
                      y < printArea?.customGraphicsdData.length;
                      y++
                    ) {
                      const element = printArea?.customGraphicsdData[y];

                      fulfillmentCost +=
                        element?.shirtlyChargeAmountInUsd *
                        lineItem.OliQuantity;
                    }
                  }
                }

                const taxOnItem = getTaxCost(
                  taxInfo,
                  order,
                  lineItem?.Category
                );

                const taxOnFulfillment = Number(
                  ((fulfillmentCost * taxOnItem) / 100).toFixed(2)
                );

                const printType = lineItem.PrintType;

                if (product?.Product?.PrintType === printType) {
                  days = {
                    minDays: product?.Product?.defaultMinimumFulfillDays || 0,
                    maxDays: product?.Product?.defaultMaximumFulfillDays || 0,
                  };
                } else {
                  const p = product?.AdditionalTechniquesArtSettings?.find(
                    (p) => p?.name === printType
                  );

                  if (p) {
                    const { minFulfillmentDays, maxFulfillmentDays } =
                      product?.AdditionalTechniquesArtSettings?.find(
                        (p) => p?.name === printType
                      );
                    days = {
                      minDays: minFulfillmentDays || 0,
                      maxDays: maxFulfillmentDays || 0,
                    };
                  }
                }

                const availableArtworkPrintArea = printAreas?.filter(
                  (p) => p?.artworkId || p?.canvasJSON
                );

                return (
                  <div className="parent">
                    <div key={`orderLineItem${index}`} className="grid">
                      {availableArtworkPrintArea?.length > 0 ? (
                        <SlickContainer>
                          <Slider {...settings}>
                            {availableArtworkPrintArea?.map((p) => {
                              if (
                                p?.canvasJSON &&
                                !p?.canvasJSON?.objects?.length
                              )
                                return null;

                              return (
                                <ProductImage>
                                  <Image
                                    imageStyle={
                                      isProductSub
                                        ? {
                                            height: "auto !important",
                                          }
                                        : { height: "auto" }
                                    }
                                    src={p?.MockupSrc}
                                    alt={lineItem.OrderLineID}
                                  />
                                </ProductImage>
                              );
                            })}
                          </Slider>
                        </SlickContainer>
                      ) : lineItem?.aopVectorFile ? (
                        <ProductImage>
                          <Image
                            imageStyle={{ height: "auto" }}
                            src={lineItem.MarketingImagePath}
                            alt={lineItem.OrderLineID}
                          />
                        </ProductImage>
                      ) : (
                        <React.Fragment>
                          {lineItem.MockupSrc && (
                            <ProductImage>
                              <Image
                                imageStyle={
                                  isProductSub
                                    ? {
                                        height: "auto !important",
                                      }
                                    : { height: "auto" }
                                }
                                src={lineItem.MockupSrc}
                                alt={lineItem.OrderLineID}
                              />
                            </ProductImage>
                          )}
                          {lineItem?.MockupBackSrc && (
                            <ProductImage>
                              <Image
                                imageStyle={
                                  isProductSub
                                    ? {
                                        height: "auto !important",
                                      }
                                    : { height: "auto" }
                                }
                                src={lineItem.MockupBackSrc}
                                alt={lineItem.OrderLineID}
                              />
                            </ProductImage>
                          )}
                        </React.Fragment>
                      )}

                      <ul>
                        <li>
                          <span>SKU:</span>
                          <span>{sku}</span>
                        </li>
                        <li>
                          <span>COLOR:</span>
                          <span>{lineItem.Color}</span>
                        </li>
                        <li>
                          <span>SIZE:</span>
                          <span>{lineItem.Size}</span>
                        </li>
                        <li>
                          <span>QUANTITY:</span>
                          <span>{lineItem.OliQuantity}</span>
                        </li>
                        <li>
                          <span>DECORATION:</span>
                          <span>
                            {printType === "Plus"
                              ? "TUFF PRINT TRANSFER"
                              : printType}
                          </span>
                        </li>

                        {customGraphicsData?.map((d) =>
                          d?.customGraphicsdData?.map((c) => (
                            <li key={c?.printAreaName}>
                              <span>Custom Graphic ({d?.printAreaName}):</span>
                              <span>
                                {c?.user} (+ $
                                {c?.shirtlyChargeAmountInUsd *
                                  lineItem.OliQuantity}
                                )
                              </span>
                            </li>
                          ))
                        )}

                        {days?.minDays && days?.maxDays ? (
                          <li>
                            <span>Decoration Fulfillment times:</span>
                            <span className="glow">
                              {days?.minDays} –{days?.maxDays} business days
                            </span>
                          </li>
                        ) : null}

                        {lineItem?.DtgPlus && (
                          <>
                            <li>
                              <span>DTG PROCESS:</span>
                              <span>{lineItem?.DtgPlus?.DtgPlusProcess}</span>
                            </li>
                            <li>
                              <span>DTG COLOR:</span>
                              <span>
                                {lineItem?.DtgPlus?.DtgPlusProcessColor}
                              </span>
                            </li>
                          </>
                        )}
                        {lineItem?.Plus && (
                          <>
                            <li>
                              <span>TUFF PRINT TRANSFER PROCESS:</span>
                              <span>{lineItem?.Plus?.DtgPlusProcess}</span>
                            </li>
                            <li>
                              <span>TUFF PRINT TRANSFER COLOR:</span>
                              <span>{lineItem?.Plus?.DtgPlusProcessColor}</span>
                            </li>
                          </>
                        )}

                        <li
                          style={{
                            borderTop: "1px solid #efefef",
                            marginTop: 5,
                            paddingTop: 10,
                          }}
                        >
                          <span>Fulfillment Cost:</span>
                          <span>
                            $
                            {currency === "USD"
                              ? fulfillmentCost?.toFixed(2)
                              : (
                                  Number(fulfillmentCost) *
                                  USD_TO_CAD_CONVERSION_RATE
                                )?.toFixed(2)}
                            &nbsp;{currency}
                          </span>
                        </li>
                        <li>
                          <span>Tax ({taxOnItem}%):</span>
                          <span>
                            $
                            {currency === "USD"
                              ? taxOnFulfillment
                              : (
                                  Number(taxOnFulfillment) *
                                  USD_TO_CAD_CONVERSION_RATE
                                ).toFixed(2)}
                            &nbsp;{currency}
                          </span>
                        </li>
                        <li>
                          <span>Final Fulfillment Cost:</span>
                          <span>
                            $
                            {currency === "USD"
                              ? (fulfillmentCost + taxOnFulfillment)?.toFixed(2)
                              : (
                                  Number(fulfillmentCost + taxOnFulfillment) *
                                  USD_TO_CAD_CONVERSION_RATE
                                )?.toFixed(2)}
                            &nbsp;{currency}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <ProductStock
                      order={order}
                      productStock={productStock}
                      color={lineItem?.Color}
                      size={lineItem?.Size}
                      header={false}
                    />
                  </div>
                );
              })}
            </MoreDetail>
          </Panel>
        </OrderDetail>
        <div>
          {!!order?.captureImages?.length && (
            <OrderTotal className="capture-images">
              <div className="order-summary">Product Printed Preview</div>
              <CaptureImages order={order} />
            </OrderTotal>
          )}

          <OrderTotal style={{ padding: "15px 20px" }}>
            <div className="order-summary">Message for customer</div>
            <MessageTextarea
              rows={8}
              value={message}
              maxLength={200}
              onChange={(v) => setMessage(v?.target?.value)}
            />
            <AddMessageButtonWrapper>
              <Button
                title="Add"
                disabled={!message?.trim()}
                onClick={() => addMessage(message)}
              />
            </AddMessageButtonWrapper>
          </OrderTotal>
          <OrderTotal>
            {/* <div>
              {order?.PrintProvider === "US" &&
                order?.CountryString == "US" && (
                  <p
                    style={{
                      color:
                        approvedStateReSaleCertificate ||
                        declinedStateReSaleCertificate
                          ? "white"
                          : "black",
                      padding: "5px",
                      borderBottom: "5px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      backgroundColor: approvedStateReSaleCertificate
                        ? "green"
                        : declinedStateReSaleCertificate
                        ? "red"
                        : "transparent",
                      margin: 0,
                    }}
                  >
                    {pendingStateReSaleCertificate ? (
                      "Certificate uploaded, waiting for approval."
                    ) : approvedStateReSaleCertificate ? (
                      "Certificate Approved"
                    ) : declinedStateReSaleCertificate ? (
                      <>
                        Certificate declined, please upload&nbsp;
                        <Link
                          to={ROUTES.ACCOUNT_SETTINGS.LINK}
                          style={{ color: "white" }}
                        >
                          New Certificate
                        </Link>
                        &nbsp;to avoid tax.
                      </>
                    ) : (
                      <>
                        <h3 style={{ marginTop: 0 }}>Re-sale Certificates</h3>
                        <p style={{ margin: 0 }}>
                          If you are registered for sales tax collection, you
                          can apply for re-sale certificate. Valid re-sale
                          certificates gives you sales tax freedom on orders
                          made through Shirtly.
                        </p>

                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            border: "1px solid orange",
                            padding: "5px",
                            margin: "10px 0",
                            borderRadius: "10px",
                            background: "orange",
                            color: "white",
                          }}
                        >
                          <InfoIcon />
                          <p style={{ margin: 0 }}>
                            The certificates will be reviewed, we will inform
                            you.
                          </p>
                        </div>
                        <Button
                          title="Submit Certificate"
                          containerStyle={{
                            marginTop: 10,
                            width: 160,
                            float: "right",
                            padding: 5,
                            fontSize: 10,
                          }}
                          onClick={() =>
                            history.push(ROUTES.ACCOUNT_SETTINGS.LINK)
                          }
                        />
                      </>
                    )}
                  </p>
                )}
            </div> */}

            <div className="order-summary">Order Summary</div>
            <div>
              <span>Subtotal</span>
              <span>
                $
                {currency === "USD"
                  ? subTotal
                  : (Number(subTotal) * USD_TO_CAD_CONVERSION_RATE).toFixed(2)}
                &nbsp;{currency}
              </span>
            </div>
            <div>
              <span>
                Shipping
                <b>
                  &nbsp; (
                  {orderPrintProviders?.printProviders?.length > 1
                    ? getPrintProviderName()
                    : order?.PrintProvider === "US"
                    ? order?.CountryString === "US"
                      ? "USPS First Class Mail"
                      : "USPS First Class Mail"
                    : order?.PrintProvider === "EU"
                    ? countries?.find((c) => c?.code2 === order?.CountryString)
                        ?.region === "Europe"
                      ? "Tracked Shipping Europe"
                      : "Tracked Shipping Worldwide"
                    : order?.CountryString === "CA"
                    ? "Expedite Parcel"
                    : order?.CountryString === "US"
                    ? "Tracked Packet - USA"
                    : "Tracked Packet - International"}
                  )
                </b>
              </span>
              <span>
                $
                {currency === "USD"
                  ? shippingCost?.toFixed(2)
                  : (
                      Number(shippingCost) * USD_TO_CAD_CONVERSION_RATE
                    )?.toFixed(2)}
                &nbsp;{currency}
              </span>
            </div>

            <div>
              <span>Shipping Tax ({taxPercentageOnOrder}%)</span>
              <span>
                $
                {currency === "USD"
                  ? shippingTaxCost
                  : (
                      Number(shippingTaxCost) * USD_TO_CAD_CONVERSION_RATE
                    ).toFixed(2)}
                &nbsp;
                {currency}
              </span>
            </div>
            <div>
              <span>Final Shipping Cost</span>
              <span>
                $
                {currency === "USD"
                  ? finalShippingCost?.toFixed(2)
                  : (
                      Number(finalShippingCost) * USD_TO_CAD_CONVERSION_RATE
                    )?.toFixed(2)}
                &nbsp;{currency}
              </span>
            </div>
            {packingMode === "eco" && (
              <div>
                <span>Eco-Bag Cost</span>
                <span>
                  ${currency === "USD" ? 1 : 1 * USD_TO_CAD_CONVERSION_RATE}
                  &nbsp;{currency}
                </span>
              </div>
            )}
            <div>
              <span>Total ({currency})</span>
              <span>
                <Total>
                  $
                  {currency === "USD"
                    ? totalOrderCostInUSD
                    : (
                        Number(totalOrderCostInUSD) * USD_TO_CAD_CONVERSION_RATE
                      ).toFixed(2)}
                  &nbsp;{currency}
                </Total>
              </span>
            </div>

            {currency !== "CAD" && (
              <div>
                <span>Total (CAD)</span>
                <span>
                  <Total>
                    $
                    {(totalOrderCostInUSD * USD_TO_CAD_CONVERSION_RATE).toFixed(
                      2
                    )}
                    &nbsp;CAD
                  </Total>
                </span>
              </div>
            )}

            {order?.Status === "AwaitingPayment" && (
              <PackingOptions singleBox={totalQuantity !== 1}>
                <PackingOption
                  selected={packingMode === "normal"}
                  onClick={async () => {
                    if (packingMode !== "normal") {
                      setPackingMode("normal");
                      await updatePackingRequest("normal");
                      refresh();
                    }
                  }}
                >
                  <InboxIcon />
                  <div>Normal Packing</div>
                </PackingOption>
                {totalQuantity === 1 && (
                  <PackingOption
                    eco={true}
                    selected={packingMode === "eco"}
                    onClick={async () => {
                      if (packingMode !== "eco") {
                        setPackingMode("eco");
                        await updatePackingRequest("eco");
                        refresh();
                      }
                    }}
                  >
                    <EcoIcon />
                    <div>
                      Eco-Bag Packing ($
                      {currency === "USD" ? 1 : 1 * USD_TO_CAD_CONVERSION_RATE}
                      &nbsp;{currency})
                    </div>
                  </PackingOption>
                )}
              </PackingOptions>
            )}

            {paymentButtonVisibility && (
              <div style={{ display: "block" }}>
                <p style={{ fontSize: 16, textDecoration: "underline" }}>
                  Counterfeit Policing and IP protection Policy
                </p>
                <p>
                  This policy is Shirtly’s effort to stop the production of
                  counterfeit trademark designs.
                </p>
                <p>
                  If a design is found to be in non-compliance with this policy
                  your order will be cancelled. If you are found to continue to
                  violate this policy after being notified, you will be removed
                  from Shirtly permanently. This is a zero-tolerance policy.
                </p>
                <p>
                  Please review your designs carefully and ensure you are not
                  using images within your designs that are a blatant and direct
                  copy of obvious trademark images. Shirtly will not make any
                  concession in no circumstance moving forward.
                </p>
                <p style={{ color: "red", fontWeight: "bold" }}>
                  If your order is in violation of this policy your order will
                  be cancelled and Shirtly will charge only 10% and refund rest
                  of the transaction.
                </p>

                <Checkbox
                  value={agreeTerms}
                  onChange={() => setAgreeTerms(!agreeTerms)}
                  label="I understand, agree and support this effort."
                />
              </div>
            )}

            {user?.YotiVerificationStatus !== "Approve" && (
              <p style={{ color: "red", fontWeight: "bold" }}>
                Please complete the KYC from&nbsp;
                <Link to={ROUTES.DASHBOARD.LINK} style={{ color: "red" }}>
                  Dashboard
                </Link>
              </p>
            )}

            {paymentButtonVisibility &&
              user?.YotiVerificationStatus === "Approve" && (
                <Button
                  disabled={
                    !agreeTerms ||
                    !Number(finalShippingCost) ||
                    (order?.PrintProvider === "AU" &&
                      !!orderLineItemsProductStock?.find(
                        (d) => d?.status === "out-of-stock"
                      ))
                  }
                  onClick={async () => {
                    const isTokenValid = await dispatch(checkJWTtoken(token));
                    if (isTokenValid?.tokenExpired === true) {
                      await saveOrderCost();
                      setPaymentModal(true);
                    } else {
                      dispatch({ type: INITIATE_STORES });
                      dispatch({ type: AUTH_LOGOUT });
                      dispatch({ type: INITIAL_SYNC_PRODUCTS });
                    }
                  }}
                  variant="text"
                  title="Checkout"
                />
              )}
          </OrderTotal>
          <OrderInformation orderDetail={order} machineStatus={machineStatus} />
          <CustomerInformation showEdit={false} orderDetail={order} />
        </div>

        {paymentModal && (
          <Stripe
            order={order}
            marketplace={order?.Marketplace}
            payableAmount={grandTotalInUsd}
            open={paymentModal}
            cards={cards}
            vatIncluded={isVatIncluded(taxInfo, order, countries)}
            removeCards={async (card) => {
              const request = await dispatch(
                removeCard({
                  user: emailid,
                  cardNumber: card?.cardNumber,
                })
              );

              if (request?.error) {
                return;
              }

              setCards(undefined);
            }}
            hide={async (data) => {
              if (data) {
                let charge;
                if (data.paypal) {
                  const payload = {
                    orderId: data.orderId,
                    user: emailid,
                    yotiVerificationStatus: user?.YotiVerificationStatus,
                  };
                  charge = await dispatch(paypalCharge(payload, token));

                  if (charge.error) {
                    alert("Payment issue, please try again after sometime.");
                    return;
                  }
                } else {
                  const payload = {
                    user: emailid,
                    saveCard: data?.saveCard,
                    cardDetails: data?.cardDetails,
                    // amount: 100,
                    ID: order?.ID,
                    currency: data?.paymentInCanadian ? "CAD" : "USD",
                    yotiVerificationStatus: user?.YotiVerificationStatus,
                    ReceiptID: order?.ReceiptID,
                    amount: data.paymentInCanadian
                      ? parseInt(
                          (
                            totalOrderCostInUSD * USD_TO_CAD_CONVERSION_RATE
                          ).toFixed(2) * 100
                        )
                      : parseInt(totalOrderCostInUSD * 100),
                  };

                  charge = await dispatch(stripeCharge(payload, token));

                  if (charge.error) {
                    alert("Payment issue, please try again after sometime.");
                    return;
                  }

                  if (
                    !charge ||
                    !charge?.status ||
                    charge?.status !== "succeeded" ||
                    charge?.statusCode === 402 ||
                    charge?.statusCode === 400 ||
                    charge?.type === "StripeCardError" ||
                    charge?.type === "StripeInvalidRequestError" ||
                    charge?.rawType === "card_error" ||
                    charge?.rawType === "invalid_request_error" ||
                    charge?.code === "card_declined"
                  ) {
                    setPaymentError(charge);
                    return;
                  }
                }

                const totalTax =
                  shippingTaxCost + getOrderLineTax(order, taxInfo);

                const shippingWithoutTax = shippingCost;
                const fulfillmentCost = getFulfillmentCost(order);

                const xml =
                  order?.PrintProvider === "EU" ||
                  order?.PrintProvider === "AU" ||
                  order?.PrintProvider === "NY"
                    ? null
                    : json2xml(
                        order,
                        products,
                        emailid,
                        isVatIncluded(taxInfo, order, countries),
                        data?.iossNumber,
                        orderPrintProviders
                      );

                const orderLineItems = order?.OrderLines[0]?.OrderLineItems;
                const isEmb = !!orderLineItems?.find(
                  (o) => o?.PrintType === "EMB"
                );

                let printLogisticPayload = {};
                let auPayload = {};
                let nyPayload = {};

                if (order?.PrintProvider === "EU") {
                  const orderLines = order?.OrderLines[0];
                  const orderLineItems = orderLines?.OrderLineItems;

                  let artworks = [];
                  let mockups = [];
                  let placements = [];
                  let inventory_items = [];

                  for (let i = 0; i < orderLineItems.length; i++) {
                    const o = orderLineItems[i];
                    const product = products?.find(
                      (p) => p?.Product?.ID === o?.ProductID
                    );

                    const sku =
                      product?.SKUdata?.find(
                        (s) =>
                          s?.colorName === o?.Color && s?.sizeName === o?.Size
                      )?.sku || "";

                    let layouts = [];
                    for (let j = 0; j < o?.printAreas.length; j++) {
                      const p = o?.printAreas[j];
                      const {
                        designerSettings,
                        coordinates = {},
                        canvasJSON,
                      } = p;
                      const widthInInches =
                        coordinates?.width >= designerSettings?.width
                          ? designerSettings?.platenWidthInch
                          : (
                              (coordinates.width *
                                designerSettings?.multiplier) /
                              255
                            ).toFixed(1);

                      const heightInInches =
                        coordinates?.height >= designerSettings?.height
                          ? designerSettings?.platenHeightInch
                          : (
                              (coordinates.height *
                                designerSettings?.multiplier) /
                              255
                            ).toFixed(1);

                      const { multiplier } = designerSettings || {};
                      const { width, height, x = 0, y = 0 } = coordinates || {};

                      const canvasCenterPoint =
                        parseFloat(designerSettings?.width) / 2;
                      const newX = parseFloat(x);
                      const imageCenter = parseFloat(width) / 2;

                      let xPos;

                      if (newX > canvasCenterPoint) {
                        xPos =
                          canvasCenterPoint - parseFloat(width) + imageCenter;
                      } else if (newX < canvasCenterPoint) {
                        const newValue = newX + imageCenter + 1;
                        xPos =
                          newValue === canvasCenterPoint
                            ? 0
                            : newValue - canvasCenterPoint;
                        xPos = xPos === 1 ? 0 : xPos;
                      }

                      const yPos = parseInt(y);

                      let artworkSplit = p?.artwork?.split("/upload/");
                      let newArtwork = "";
                      if (artworkSplit) {
                        newArtwork = `${artworkSplit[0]}/upload/h_${Math.round(
                          heightInInches * 150
                        )},w_${Math.round(widthInInches * 150)},x_${Math.round(
                          x
                        )},y_${Math.round(y)}/${
                          artworkSplit[1]
                        }?${moment().unix()}_${p?.printAreaName}_${i}_${j}`;
                      }

                      const artwork_token = `Artwork_Token_${order?.ReceiptID}_${p?.printAreaName}_${i}_${j}`;

                      const mockup_token = `Mockup_Token_${order?.ReceiptID}_${p?.printAreaName}_${i}_${j}`;

                      const placement_token = `Placement_Token_${order?.ReceiptID}_${p?.printAreaName}_${i}_${j}`;

                      if (p?.artworkId || !!canvasJSON) {
                        layouts.push({
                          artwork_token,
                          mockup_token,
                          placement_token,
                          location: p?.printAreaName?.toLowerCase(),
                        });

                        artworks.push({
                          token: artwork_token,
                          url:
                            p?.canvasArtBoardImage ||
                            newArtwork.replace(".jpg", ".png"),
                        });
                        mockups.push({
                          token: mockup_token,
                          url: p?.MockupSrc,
                        });
                        placements.push({
                          token: placement_token,
                          x_offset: !!canvasJSON ? 0 : Math.round(xPos),
                          y_offset: !!canvasJSON ? 0 : Math.round(yPos),
                        });
                      }
                    }
                    inventory_items.push({
                      sku: sku,
                      quantity: o?.OliQuantity,
                      description: o?.Product,
                      layouts,
                    });
                  }

                  printLogisticPayload = {
                    job_id: order?.ReceiptID,
                    job_provider: "Shirtly",
                    special_handling: false,
                    cancelled: false,
                    inventory_items,
                    placements,
                    mockups,
                    artworks,
                    shipments: [
                      {
                        type: "Standard",
                        transport_data: [
                          {
                            company: "Shirtly",
                            name: order?.BuyerName,
                            surname: "",
                            address_1: order?.AddressLineOne,
                            address_2: order?.AddressLineTwo,
                            city: order?.City,
                            "post-code": order?.ZipPostal,
                            country: order?.CountryString,
                            phone: "",
                            email: order?.MasterUserEmail,
                          },
                        ],
                      },
                    ],
                  };
                } else if (order?.PrintProvider === "AU") {
                  const orderLines = order?.OrderLines[0];
                  const orderLineItems = orderLines?.OrderLineItems;

                  let inventory_items = [];

                  for (let i = 0; i < orderLineItems.length; i++) {
                    const o = orderLineItems[i];
                    const product = products?.find(
                      (p) => p?.Product?.ID === o?.ProductID
                    );

                    const sku =
                      product?.SKUdata?.find(
                        (s) =>
                          s?.colorName === o?.Color && s?.sizeName === o?.Size
                      )?.sku || "";

                    let layouts = [];
                    for (let j = 0; j < o?.printAreas.length; j++) {
                      const p = o?.printAreas[j];
                      const {
                        designerSettings,
                        coordinates = {},
                        canvasJSON,
                      } = p;
                      const widthInInches =
                        coordinates?.width >= designerSettings?.width
                          ? designerSettings?.platenWidthInch
                          : (
                              (coordinates.width *
                                designerSettings?.multiplier) /
                              255
                            ).toFixed(1);

                      const heightInInches =
                        coordinates?.height >= designerSettings?.height
                          ? designerSettings?.platenHeightInch
                          : (
                              (coordinates.height *
                                designerSettings?.multiplier) /
                              255
                            ).toFixed(1);

                      const { x = 0, y = 0 } = coordinates || {};

                      // const { width, x = 0, y = 0 } = coordinates || {};

                      // const canvasCenterPoint =
                      //   parseFloat(designerSettings?.width) / 2;
                      // const newX = parseFloat(x);
                      // const imageCenter = parseFloat(width) / 2;

                      // let xPos;

                      // if (newX > canvasCenterPoint) {
                      //   xPos = canvasCenterPoint - parseFloat(width) + imageCenter;
                      // } else if (newX < canvasCenterPoint) {
                      //   const newValue = newX + imageCenter + 1;
                      //   xPos =
                      //     newValue === canvasCenterPoint
                      //       ? 0
                      //       : newValue - canvasCenterPoint;
                      //   xPos = xPos === 1 ? 0 : xPos;
                      // }

                      const xPos = parseInt(x * 0.0846);
                      const yPos = parseInt(y * 0.0846);

                      let artworkSplit = p?.artwork?.split("/upload/");
                      let newArtwork = "";
                      if (artworkSplit) {
                        newArtwork = `${artworkSplit[0]}/upload/h_${Math.round(
                          Number(heightInInches) * 25.4
                        )},w_${Math.round(
                          Number(widthInInches) * 25.4
                        )},x_${Math.round(x)},y_${Math.round(y)}/${
                          artworkSplit[1]
                        }?${moment().unix()}_${p?.printAreaName}_${i}_${j}`;
                      }

                      if (p?.artworkId || !!canvasJSON) {
                        layouts.push({
                          artwork:
                            p?.canvasArtBoardImage ||
                            newArtwork.replace(".jpg", ".png"),
                          preview: p?.MockupSrc,
                          side: p?.printAreaName?.toUpperCase(),
                          position: {
                            x: !!canvasJSON ? 0 : Math.round(xPos * 25.4),
                            y: !!canvasJSON ? 0 : Math.round(yPos * 25.4),
                            width: Math.round(widthInInches * 25.4),
                            height: Math.round(heightInInches * 25.4),
                          },
                        });
                      }
                    }
                    inventory_items.push({
                      sku: sku.replace("-one-size", "").toUpperCase(),
                      quantity: o?.OliQuantity,
                      id: `${
                        order?.ReceiptID + Math.floor(Math.random() * 100000)
                      }`,
                      prints: layouts,
                    });
                  }

                  auPayload = {
                    id: order?.ReceiptID,
                    addressFrom: {
                      address1: "9 Florence St",
                      address2: "",
                      city: "Teneriffe",
                      postalCode: "QLD 4005",
                      country: "Australia",
                      region: "Yuggera Country",
                      firstName: "Fulfillment",
                      lastName: "Plant",
                      email: "sales@theprintbar.com",
                      phone: "",
                    },
                    addressTo: {
                      address1: order?.AddressLineOne,
                      address2: order?.AddressLineTwo,
                      city: order?.City,
                      postalCode: order?.ZipPostal,
                      country: order?.CountryString,
                      region: order?.StateProvince,
                      firstName: order?.BuyerName?.split(" ")[0],
                      lastName:
                        order?.BuyerName?.split(" ")?.length > 1
                          ? order?.BuyerName?.split(" ")[1]
                          : " ",
                      email: order?.MasterUserEmail,
                      phone: "",
                    },
                    shipping: {
                      code:
                        order?.CountryString === "AU"
                          ? "express"
                          : "international",
                      notes: "",
                    },
                    items: inventory_items,
                  };
                } else if (order?.PrintProvider === "NY") {
                  nyPayload = {
                    customer_order_id: order?.ReceiptID,
                    items: order?.OrderLines[0]?.OrderLineItems?.map((o) => {
                      const front = o?.printAreas?.find(
                        (p) => p?.printAreaName === "Front"
                      );

                      const back = o?.printAreas?.find(
                        (p) => p?.printAreaName === "back"
                      );

                      const product = products?.find(
                        (p) => p?.Product?.ID === o?.ProductID
                      );

                      const sku =
                        product?.SKUdata?.find(
                          (s) =>
                            s?.colorName === o?.Color && s?.sizeName === o?.Size
                        )?.sku || "";

                      return {
                        item_sku: sku,
                        qty: o?.OliQuantity,
                        front_mockup: front?.MockupSrc || "",
                        back_mockup: back?.MockupSrc || "",
                        front_artwork: front?.canvasArtBoardImage || "",
                        back_artwork: back?.canvasArtBoardImage || "",
                      };
                    }),
                    shipping: {
                      full_name: order?.BuyerName,
                      ship_address_1: order?.AddressLineOne,
                      ship_address_2: order?.AddressLineTwo,
                      city: order?.City,
                      state: order?.StateProvince,
                      zip_code: order?.ZipPostal,
                      country_code: order?.CountryString,
                      phone_number: "",
                      email: order?.MasterUserEmail,
                    },
                    return_to: {
                      full_name: "Fulfillment Plant",
                      ship_address_1: "420 E German St",
                      ship_address_2: "",
                      city: "Herkimer",
                      state: "NY",
                      zip_code: "13350",
                      country_code: "United States",
                      phone_number: "",
                      email: "",
                    },
                    shipping_carrier:
                      order?.CountryString === "US" ? "USPS" : "Asendia",
                    shipping_service:
                      order?.CountryString === "US" ? "First" : "ePAQPlus",
                    test_mode: false,
                  };
                }

                const submitOrderResponse =
                  order?.PrintProvider === "CA" || isEmb
                    ? await dispatch(submitOrderToCanada(xml, token))
                    : order?.PrintProvider === "US"
                    ? await dispatch(submitOrderToUSA(xml, token))
                    : order?.PrintProvider === "EU"
                    ? await dispatch(
                        submitOrderToEU(printLogisticPayload, token)
                      )
                    : order?.PrintProvider === "NY"
                    ? await dispatch(submitOrderToNY(nyPayload, token))
                    : await dispatch(submitOrderToAU(auPayload, token));

                if (
                  (order?.PrintProvider === "EU" &&
                    submitOrderResponse?.status !== 200) ||
                  (order?.PrintProvider === "AU" &&
                    submitOrderResponse?.status === "failed") ||
                  (order?.PrintProvider === "NY" &&
                    submitOrderResponse?.status === "error")
                ) {
                  alert(
                    "Payment completed but due to some technical problem order didnot went to production. Please contact support@shirtly.com"
                  );
                  return;
                }

                const parseOrderResponse =
                  order?.PrintProvider === "EU" ||
                  order?.PrintProvider === "AU" ||
                  order?.PrintProvider === "NY"
                    ? true
                    : new XMLParser().parseFromString(submitOrderResponse);

                const errorCode =
                  order?.PrintProvider === "EU" ||
                  order?.PrintProvider === "AU" ||
                  order?.PrintProvider === "NY"
                    ? null
                    : parseOrderResponse.getElementsByTagName("ErrorCode");

                if (errorCode?.length) {
                  if (errorCode[0].value != 0) {
                    alert(
                      "Error with the order, please contact shirtly for more details at support@shirtly.com"
                    );
                    return;
                  }
                }

                const orderStatus = await dispatch(
                  await updateOrderStatus({
                    user: emailid,
                    orderId: order?.ID,
                    status: "InProduction",
                    productionDate: moment().format("YYYY-MM-DD"),
                  })
                );

                for (
                  let i = 0;
                  i < orderStatus?.OrderLines[0]?.OrderLineItems.length;
                  i++
                ) {
                  const lineItem =
                    orderStatus?.OrderLines[0]?.OrderLineItems[i];
                  const productStock = await dispatch(
                    getSSActiveWearProductData({
                      id: lineItem?.ProductID,
                      printProvider: order?.PrintProvider,
                    })
                  );
                  for (let j = 0; j < productStock.length; j++) {
                    const s = productStock[j];
                    if (
                      s?.colorName?.includes(lineItem?.Color) &&
                      s?.sizeName === lineItem?.Size
                    ) {
                      const ontarioWareHouseData = s?.warehouses?.find(
                        (w) => w?.warehouseAbbr === "ON"
                      );

                      const obj = {
                        ontarioWareHouseData,
                        qty: s?.qty,
                        color: s?.colorName,
                        size: s?.sizeName,
                        timestamp: moment().unix(),
                      };

                      if (
                        !orderStatus?.OrderLines[0]?.OrderLineItems[i]
                          ?.productStock
                      ) {
                        orderStatus.OrderLines[0].OrderLineItems[
                          i
                        ].productStock = [obj];
                      } else {
                        orderStatus.OrderLines[0].OrderLineItems[
                          i
                        ].productStock.push(obj);
                      }
                    }
                  }
                }

                const shipMethod =
                  orderPrintProviders?.printProviders?.length > 1
                    ? getPrintProviderName()
                    : order?.PrintProvider === "US"
                    ? order?.CountryString === "US"
                      ? "USPS First Class Mail"
                      : "USPS First Class Mail"
                    : order?.CountryString === "CA"
                    ? "Expedite Parcel"
                    : order?.CountryString === "US"
                    ? "Tracked Packet - USA"
                    : "Tracked Packet - International";

                await dispatch(
                  saveOrderData({
                    orderData: {
                      OrderSubmitToProductionDate:
                        moment().format("YYYY-MM-DD"),
                      ShirtlyOrderData: {
                        ...orderStatus,
                        grandTotalInUSD: grandTotalInUsd,
                        grandTotalInCAD: Number(
                          grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE
                        ).toFixed(2),
                      },
                      EuPlantData: printLogisticPayload,
                      AuPlantData: auPayload,
                      NyPlantData: submitOrderResponse,
                      StripeChargeInfo: data?.paypal ? null : charge,
                      PaypalChargeInfo: data?.paypal ? charge : null,
                      grandTotalInUsd: Number(grandTotalInUsd),
                      grandTotalInCan: Number(
                        (grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE).toFixed(
                          2
                        )
                      ),
                      finalShippingCost,
                      totalTax,
                      shippingWithoutTax,
                      fulfillmentCost,
                      shipMethod,
                    },
                  })
                );

                await dispatch(
                  orderSubmitNotification({
                    orderData: order,
                    grandTotalInUsd: Number(grandTotalInUsd),
                    grandTotalInCan: Number(
                      (grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE).toFixed(2)
                    ),
                    finalShippingCost,
                    totalTax,
                    shippingWithoutTax,
                    fulfillmentCost,
                  })
                );

                const isDuraSoft = orderLineItems?.find(
                  (p) => p?.PrintType === "DURASOFT"
                );

                const isAOP = orderLineItems?.find(
                  (p) => p?.PrintType === "AOP"
                );

                const isPlus = orderLineItems?.find(
                  (p) => p?.PrintType === "Plus"
                );

                if (message?.trim()?.length > 0) {
                  const payload = {
                    orderData: order,
                    printProvider: order?.PrintProvider,
                    message,
                  };
                  await dispatch(orderGiftMessageNotification(payload));
                }

                if (isDuraSoft) {
                  await dispatch(
                    orderDurasoftNotification({
                      orderData: order,
                      grandTotalInUsd: Number(grandTotalInUsd),
                      grandTotalInCan: Number(
                        (grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE).toFixed(
                          2
                        )
                      ),
                      finalShippingCost,
                      // taxInCad: Number(taxInCad),
                    })
                  );
                }

                if (isPlus) {
                  await dispatch(
                    orderTuffPrintTransferNotification({
                      orderData: order,
                      grandTotalInUsd: Number(grandTotalInUsd),
                      grandTotalInCan: Number(
                        (grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE).toFixed(
                          2
                        )
                      ),
                      finalShippingCost,
                      // taxInCad: Number(taxInCad),
                    })
                  );
                }

                if (isAOP) {
                  await dispatch(
                    orderAOPNotification({
                      orderData: order,
                      grandTotalInUsd: Number(grandTotalInUsd),
                      grandTotalInCan: Number(
                        (grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE).toFixed(
                          2
                        )
                      ),
                      finalShippingCost,
                      // taxInCad: Number(taxInCad),
                    })
                  );
                }

                setPaymentModal(false);
                _.isFunction(refresh) && refresh();
              } else {
                setPaymentModal(false);
              }
            }}
          />
        )}

        {paymentError && (
          <PaymentErrorDialog
            open={!!paymentError}
            error={paymentError}
            setOpen={() => setPaymentError(undefined)}
          />
        )}
      </Container>
    </>
  );
};

export default OrderSummary;
