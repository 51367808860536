import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button';
import { chargeInformation, paypalChargeInformation } from 'actions/stripe';
import Dialog from '@material-ui/core/Dialog';
import TextField from 'components/TextField';
import { json2xml } from 'utils/jsonToXmlParser';
import {
  submitOrderToCanada,
  submitOrderToUSA,
  updateOrderStatus,
  saveOrderData,
} from 'actions/orders';
import { ModalContainer, Footer } from 'styles/pages/my-stores/registration';

const XMLParser = require('react-xml-parser');

const OrderSubmitToProd = ({ open, setModal, order, hideAllModal }) => {
  const [transactionId, setTransactionId] = useState(null);
  const [paypalTransactionId, setPaypalTransactionId] = useState(null);
  const products = useSelector((state) => state?.products?.data);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const dispatch = useDispatch();

  return (
    <Dialog maxWidth="md" open={open} onClose={() => setModal(false)}>
      <ModalContainer>
        <h1>Enter Transaction ID (Stripe) </h1>
        <TextField
          fullWidth
          disabled={!!paypalTransactionId}
          placeholder="Transaction ID"
          value={transactionId || ''}
          onChange={(v) => setTransactionId(v)}
        />

        <h1>Enter Transaction ID (Paypal) </h1>
        <TextField
          fullWidth
          disabled={!!transactionId}
          placeholder="Transaction ID"
          value={paypalTransactionId || ''}
          onChange={(v) => setPaypalTransactionId(v)}
        />

        <Footer>
          <Button
            disabled={!transactionId && !paypalTransactionId}
            variant="text"
            title={
              order?.ShirtlyOrderData?.Status === 'InProduction' ||
              order?.ShirtlyOrderData?.Status === 'Shipped'
                ? 'Save Data'
                : 'Submit order to machine'
            }
            onClick={async () => {
              const charge = paypalTransactionId
                ? await dispatch(
                    paypalChargeInformation({
                      transactionId: paypalTransactionId,
                      emailid:
                        order?.MasterUserEmail ||
                        order?.ShirtlyOrderData?.MasterUserEmail,
                      receiptId:
                        order?.ReceiptID || order?.ShirtlyOrderData?.ReceiptID,
                    })
                  )
                : await dispatch(
                    chargeInformation({
                      transactionId,
                      emailid: order?.MasterUserEmail,
                      receiptId: order?.ReceiptID,
                    })
                  );
              if (charge?.error) {
                return;
              }

              const xml = json2xml(
                charge?.ShirtlyOrderData,
                products,
                order?.MasterUserEmail
              );

              const submitOrderResponse =
                charge?.ShirtlyOrderData?.PrintProvider === 'CA'
                  ? await dispatch(
                      submitOrderToCanada(
                        xml?.replaceAll('&', ' and ')?.replace(/>\s+</g, '><'),
                        token
                      )
                    )
                  : await dispatch(
                      submitOrderToUSA(
                        xml?.replaceAll('&', ' and ')?.replace(/>\s+</g, '><'),
                        token
                      )
                    );

              const parseOrderResponse = new XMLParser().parseFromString(
                submitOrderResponse
              );

              const errorMessage =
                parseOrderResponse.getElementsByTagName('ErrorMessage');

              if (errorMessage?.length) {
                if (
                  !!errorMessage[0]?.value?.trim()?.length &&
                  !errorMessage[0]?.value?.includes(
                    'already exists in the Database'
                  )
                ) {
                  alert(errorMessage[0].value);
                  return;
                }
              }

              const orderStatus = await dispatch(
                await updateOrderStatus({
                  user:
                    order?.MasterUserEmail ||
                    order?.ShirtlyOrderData?.MasterUserEmail,
                  orderId: charge?.ShirtlyOrderData?.ID,
                  status: charge?.ShirtlyOrderData?.Status,
                  productionDate:
                    order?.OrderSubmitToProductionDate ||
                    moment().format('YYYY-MM-DD'),
                  shipDate: order?.OrderShippedDate,
                  trackingNumber: order?.ShirtlyOrderData?.TrackingNumber,
                })
              );

              await dispatch(
                saveOrderData({
                  orderData: {
                    OrderSubmitToProductionDate:
                      order?.OrderSubmitToProductionDate ||
                      moment().format('YYYY-MM-DD'),
                    ShirtlyOrderData: orderStatus,
                    PaypalChargeInfo: paypalTransactionId
                      ? charge?.PaypalChargeInfo
                      : null,
                    StripeChargeInfo: transactionId
                      ? charge?.StripeChargeInfo
                      : null,
                    // DataForMachine: xml,
                  },
                })
              );

              setTransactionId(null);
              hideAllModal();
            }}
          />
        </Footer>
      </ModalContainer>
    </Dialog>
  );
};

export default OrderSubmitToProd;
