import styled from "styled-components";
import CloseIcon from "assets/svg/CloseIcon";

const Container = styled.div`
  height: auto;
  width: 100%;
  max-width: 100%;
  transition: all ease-out 0.25s;
  position: relative;
  background-clip: padding-box;
`;
const Header = styled.div`
  position: relative;

  h4 {
    font-size: 12px;
    font-weight: normal;
    font-family: "Inter600";
    margin: 0;
  }

  h3 {
    font-size: 16px;
    font-weight: 100;
    font-family: "Inter800";
    margin: 0;
  }
`;
const CloseIconStyle = styled(CloseIcon)`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;
const SearchWrapper = styled.div`
  display: flex;
  grid-gap: 15px;
  margin-bottom: 25px;
`;
const ArtworksLibrary = styled.div`
  min-height: 150px;
  /* max-height: ${(props) =>
    props?.scroll ? "calc(100vh - 352px)" : "auto"}; */
  overflow-y: auto;

  padding-top: 20px;

  > div {
    display: grid;
    align-items: start;
    transition: all ease-out 0.25s;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Procedure = styled.span`
  color: rgb(34, 128, 193);
  text-decoration: underline;
  cursor: pointer;
`;

const ShirtlyCharges = styled.span`
  color: rgb(34, 128, 193);
  float: right;
`;

const Support = styled.a`
  color: rgb(34, 128, 193);
  text-decoration: underline;
  cursor: pointer;
`;

const FontPickerWrapper = styled.div`
  width: 100%;
  #font-picker {
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 5px;
    & > button {
      background: transparent;
      height: 55px;
      font-size: 20px;
    }
    & > ul {
      z-index: 9;
      background: white;
      top: 58px;
      overflow: auto;
      li {
        height: auto;
        border-bottom: 1px solid #ccc;
        font-size: 22px;

        button {
          padding: 10px;
        }
      }
    }
  }

  .font-name:hover {
    background-color: #ffffff1a;
  }
`;

const HexColorPickerWrapper = styled.div`
  margin: 30px 0px;
  .react-colorful {
    display: block;
    margin: 0 auto;
    .react-colorful__saturation {
      height: 185px;
    }
  }
`;

const ChipsTitle = styled.h4``;
const Chips = styled.div``;
const ChipsWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
`;

const chipStyle = {
  backgroundColor: "#000",
  color: "#fff",
  height: 26,
  marginRight: 5,
};

const LayersContainer = styled.div`
  margin: 5px;
  width: 100%;
  /* padding: 20px; */
`;

const LayersWrapper = styled.div`
  /* border-top: 1px solid #ccc; */
`;

const Layer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  cursor: pointer;
  padding: 12px;

  /* img {
    background-image: url(https://thumbs.dreamstime.com/b/imitation-transparent-background-seamless-vector-illustration-69028332.jpg);
    background-size: 400%;
    background-repeat: no-repeat;
  } */
`;

const LayerActions = styled.div`
  display: flex;
  grid-gap: 10px;
  justify-content: flex-end;
  flex: 1;
  svg {
    cursor: pointer;
    font-size: 18px;
  }
`;

const TextLayer = styled.div`
  /* background-image: url(https://thumbs.dreamstime.com/b/imitation-transparent-background-seamless-vector-illustration-69028332.jpg); */
  /* background-size: 400%;
  background-repeat: no-repeat; */
  /* min-width: 60px;
  min-height: 60px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ddd;
  background: #f4511e;
  width: 24px;
  height: 24px;
  border-radius: 3px;
`;

const AlignIcons1 = styled.div`
  display: flex;
  grid-gap: 10px;
  align-items: center;
  flex: 1;
`;

const AlignIcons = styled.div`
  display: flex;
  /* grid-gap: 10px; */
  align-items: center;
  justify-content: space-between;
  flex: 1;

  svg {
    /* border: 1px solid #ccc; */
    /* padding: 5px; */
    color: #3f4652;
    font-size: 18px;
    cursor: pointer;
  }

  /* .MuiFormLabel-root {
    font-size: 12px;
  } */

  .MuiOutlinedInput-input {
    padding: 8px !important;
    font-size: 15px;
    width: 100px;
  }

  .MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -8px;
    margin-top: 3px;
  }

  .MuiAutocomplete-hasPopupIcon
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
  .MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
  }
`;

const UpperLayer = styled.div`
  border-bottom: 1px solid #484f5a;
  color: #ddd;
  border-radius: 3px;
  background: ${({ selected }) => (selected ? "#272c33" : "inherit")};
`;

const LayerCenter = styled.div``;

const Rotate = styled.div`
  display: flex;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  flex: 1;

  .MuiSlider-root,
  .MuiSlider-thumb {
    color: #000;
  }
`;

const OutOfBondWarning = styled.div`
  background: orange;
  padding: 5px 0;
  text-align: center;
  color: white;
  font-weight: bold;
`;

const OutOfBondRed = styled.div`
  background: red;
  padding: 5px 0;
  text-align: center;
  color: white;
  font-weight: bold;
`;

const PreviewText = styled.div`
  background-image: url(https://thumbs.dreamstime.com/b/imitation-transparent-background-seamless-vector-illustration-69028332.jpg);
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 10px;
  width: 100%;
  background-size: 100%;
`;

const PaginationWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  .MuiPaginationItem-sizeSmall {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
`;

const CustomGraphicWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  flex: 1;
  cursor: pointer;
  gap: 4px;

  background: ${({ selected }) => (selected ? "#3fc7ba" : "#eee")};
  color: ${({ selected }) => (selected ? "#fff" : "#000")};

  svg {
    color: ${({ selected }) => (selected ? "#fff" : "#000")};
  }

  :hover {
    background-color: #3fc7ba;
  }
`;

const OptionLabel = styled.h3`
  margin: 0;
  font-size: 10px;
`;

const LayersCustomize = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #e4e4e4;
  position: fixed;
  color: #3f4652;
  left: 420px;
  top: 0;
  right: 0;
  padding: 0 24px 0 10px;
  white-space: nowrap;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  button.action-button {
    &.MuiButton-contained {
      background-color: #3f4652;
      color: #fff;
      font-size: 12px;
      width: 92px;
      text-wrap: auto;
      height: 50px;
    }
  }
  button.action-button1 {
    &.MuiButton-contained {
      background-color: white;
      border: 1px solid #3f4652;
      color: #3f4652;
      font-size: 12px;
      width: 98px;
      text-wrap: auto;
      height: 50px;
      cursor: pointer;
    }
  }
`;

const LayerText = styled.div`
  font-size: 15px;
  letter-spacing: 0.15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ddd;
`;

const Title = styled.h3`
  font-weight: 100;
  font-family: "Inter800";
  margin: 0px;
  font-size: 15px;
  color: rgb(255, 255, 255);
`;

export {
  Title,
  LayerText,
  LayersCustomize,
  OptionLabel,
  Options,
  Option,
  Container,
  Header,
  CloseIconStyle,
  SearchWrapper,
  ArtworksLibrary,
  Procedure,
  Support,
  ShirtlyCharges,
  FontPickerWrapper,
  HexColorPickerWrapper,
  ChipsWrapper,
  ChipsTitle,
  Chips,
  chipStyle,
  LayersContainer,
  LayersWrapper,
  Layer,
  LayerActions,
  TextLayer,
  LayerCenter,
  AlignIcons,
  UpperLayer,
  Rotate,
  OutOfBondWarning,
  OutOfBondRed,
  PreviewText,
  PaginationWrapper,
  CustomGraphicWrapper,
  AlignIcons1,
};
