import { get } from '@axios';
import { showLoader, hideLoader } from 'actions/loader';
import { updateField } from 'actions/common';
import { requests } from 'shared/requests';

const SET_GOOGLE_FONTS = 'SET_GOOGLE_FONTS';

const getGoogleFonts = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const fonts = await get({ url: requests.googleFonts });
      dispatch(updateField(SET_GOOGLE_FONTS, { fonts }));
    } catch (error) {
      console.log(error);
    }
    dispatch(hideLoader());
  };
};

export { SET_GOOGLE_FONTS, getGoogleFonts };
