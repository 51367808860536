import * as a from 'actions/categories';

const initial = {
  data: [],
  error: null,
};

const categories = (state = initial, action = null) => {
  switch (action.type) {
    case a.SET_CATEGORIES:
      return {
        ...state,
        data: action.categories,
        error: null,
      };

    default:
      return state;
  }
};

export default categories;
