import styled from "styled-components";

const Container = styled.div`
  margin: 0 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const AiAssist = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderRobotImg = styled.img`
  width: 100px;
`;

const AiAssistantActivatedButton = styled.div`
  background: #64c264;
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 20px;
`;

const Body = styled.div``;

const Title = styled.h2`
  margin: 0;
`;

const Option = styled.div`
  padding: 5px;
  border: 1px solid black;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const Options = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;

  img {
    width: 100px;
    height: 100px;
  }
`;

const StyledAIImages = styled.div`
  text-align: center;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  margin: 60px 20px 20px;
  padding: 5px;
  opacity: 0.5;

  img {
    width: 100%;
  }
`;

const OptionHeader = styled.div`
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
`;

const OptionTitle = styled.div`
  border: 2px solid black;
  padding: 0 20px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 22px;
  display: flex;
  align-items: center;
  min-height: 60px;
  justify-content: center;

  svg {
    font-size: 40px;
  }

  :hover {
    background-color: #000;
    color: #fff;
  }
`;

const OptionSubTitle = styled.div`
  font-size: 10px;
`;

const VideoOption = styled.div`
  text-align: center;
`;

const Iframe = styled.div`
  width: 50vw;
  height: 70vh;
  padding: 20px;

  iframe {
    width: 100%;
  }
`;

export {
  OptionHeader,
  OptionTitle,
  OptionSubTitle,
  Container,
  Header,
  HeaderRobotImg,
  AiAssistantActivatedButton,
  Title,
  Body,
  Options,
  StyledAIImages,
  Option,
  AiAssist,
  VideoOption,
  Iframe,
};
