import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getProductAdditionalImages } from 'selectors/products';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 500,
  },
}));

const ArtWorkAdditionalImages = ({ orderLine = {} }) => {
  const classes = useStyles();
  const products = useSelector((state) => state?.products?.data);
  const additionalImages = getProductAdditionalImages(
    products,
    orderLine?.ProductID
  );

  return additionalImages?.length > 0 ? (
    <div>
      {additionalImages?.map((a, i) => (
        <Tooltip
          classes={{ tooltip: classes.customWidth }}
          key={`additionalImage${i}`}
          placement='top'
          arrow
          title={
            <img
              src={a?.secure_url}
              alt={a?.secure_url}
              style={{ width: 500, height: 500 }}
            />
          }>
          <img
            alt={a?.secure_url}
            src={a?.secure_url}
            style={{
              width: 100,
              height: 100,
              border: '1px solid #ccc',
              marginRight: 10,
              padding: 5,
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      ))}
    </div>
  ) : null;
};

export default ArtWorkAdditionalImages;
