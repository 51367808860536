import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const CustomTextField = ({ label, value, onChange, ...rest }) => (
  <TextField
    label={label}
    variant='outlined'
    value={value}
    onChange={(e) => _.isFunction(onChange) && onChange(e.target.value)}
    {...rest}
  />
);

CustomTextField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default CustomTextField;
