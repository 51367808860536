import styled from 'styled-components';

const Title = styled.div`
  width: 388px;
  font-size: 23px;
  line-height: 32px;
  color: #35495e;
  font-family: 'Inter600';
  background: #ffd363;
  padding: 10px 22px;
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;

  .close-icon {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  img {
    margin-right: 20px;
  }
  @media (max-width: 500px) {
    width: 100vw;
  }
`;

const NotificationList = styled.ul`
  list-style: none;
  padding: 0 20px;
  max-width: 388px;
  height: calc(100vh - 64px);
  overflow: auto;

  li {
    padding: 10px;
    font-size: 16px;
    line-height: 25px;
    position: relative;
    border-bottom: 1px solid #cccccc;
    cursor: pointer;

    :hover {
      background-color: #e1e1e1;
    }
    img {
      max-height: 150px;
      display: block;
      margin: 0 auto 10px;
    }
    p {
      margin-top: 0;
    }
    /* span {
      position: absolute;
      left: -66px;
      top: -6px;
      font-family: 'Inter600';
      font-size: 18px;
      color: #000;
      line-height: 25px;
      display: inline-flex;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #f7f8fa;
      align-items: center;
      justify-content: center;
    } */
  }
  @media (max-width: 500px) {
    width: 100%;
    padding-right: 20px;
    max-width: 100%;
  }
`;

const NoNotifications = styled.p`
  text-align: center;
  font-family: 'Inter700';
  font-size: 16px;
  margin: 20px;
`;
export { Title, NotificationList, NoNotifications };
