import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "components/TextField";
import Button from "components/Button";
import Tooltip from "@material-ui/core/Tooltip";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Slider from "react-slick";
import Tabs from "components/Tabs";
import {
  Container,
  ProductInformation,
  Update,
  ImageContainer,
  ArtDiv,
  SubHeading,
} from "styles/pages/edit-products/editProductInformation";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const EditProductDesignerSettings = ({
  product,
  editAdditionalTechniquesArtSettings,
  updatePrintArea,
}) => {
  const [printAreas, setPrintAreas] = useState(product?.PrintAreas);

  const [additionalTechniquesArtSettings, setAdditionalTechniquesArtSettings] =
    useState(product?.AdditionalTechniquesArtSettings);

  const isSub = product?.Product?.PrintType === "SUB";

  const isNY = product?.Product?.PrintProvider === "NY";

  const handleAdditionalAreaSettingChange = (
    parentIndex,
    artSettingsIndex,
    data
  ) =>
    setAdditionalTechniquesArtSettings(
      additionalTechniquesArtSettings?.map((b, x) => {
        if (parentIndex !== x) {
          return b;
        }
        return {
          ...b,
          artSettings: b?.artSettings?.map((c, y) => {
            if (y !== artSettingsIndex) {
              return c;
            }
            return {
              ...c,
              areaSettings: {
                ...c?.areaSettings,
                ...data,
              },
            };
          }),
        };
      })
    );

  const handlePrintAreasDesignerChange = (index, data) =>
    setPrintAreas(
      printAreas?.map((b, x) => {
        if (index !== x) {
          return b;
        }
        return {
          ...b,
          designerSettings: {
            ...b?.designerSettings,
            ...data,
          },
        };
      })
    );

  let tabOptions = [];

  if (additionalTechniquesArtSettings?.length > 0) {
    for (let i = 0; i < additionalTechniquesArtSettings.length; i++) {
      const element = additionalTechniquesArtSettings[i];
      const artSettings = element?.artSettings;
      tabOptions.push({
        title: element.name,
        children: (
          <ProductInformation key={element?.name}>
            <Slider {...settings}>
              {artSettings?.map((artSetting, j) => {
                const areaName = artSetting?.areaName;
                const areaSettings = artSetting?.areaSettings;
                const isLocked = areaSettings?.locked;

                return (
                  <div key={`${areaName}_${i}_${j}`}>
                    <h4>
                      {artSetting?.areaName} ({element?.name})
                    </h4>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={2} md={2}>
                        <TextField
                          label="Machine Width (Inch)"
                          size="small"
                          type="number"
                          disabled={isLocked}
                          value={areaSettings?.platenWidthInch || 0}
                          onChange={(platenWidthInch) =>
                            handleAdditionalAreaSettingChange(i, j, {
                              platenWidthInch: Number(platenWidthInch),
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <TextField
                          label="Machine Height (Inch)"
                          size="small"
                          type="number"
                          disabled={isLocked}
                          value={areaSettings?.platenHeightInch || 0}
                          onChange={(platenHeightInch) =>
                            handleAdditionalAreaSettingChange(i, j, {
                              platenHeightInch: Number(platenHeightInch),
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <TextField
                          label="Multiplier"
                          size="small"
                          type="number"
                          disabled={true}
                          value={areaSettings?.multiplier || 0}
                        />
                      </Grid>

                      {element?.name === "Plus" && (
                        <Grid item xs={6} sm={2} md={2}>
                          <TextField
                            label="Art Board Price"
                            size="small"
                            type="number"
                            value={areaSettings?.artboardPrice || 0}
                            onChange={(artboardPrice) =>
                              handleAdditionalAreaSettingChange(i, j, {
                                artboardPrice: Number(artboardPrice),
                              })
                            }
                          />
                        </Grid>
                      )}

                      <Tooltip title={isLocked ? "Locked" : "Un-Locked"}>
                        {isLocked ? (
                          <LockIcon
                            onClick={() =>
                              handleAdditionalAreaSettingChange(i, j, {
                                locked: false,
                              })
                            }
                            style={{
                              marginTop: 15,
                              marginLeft: 20,
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        ) : (
                          <LockOpenIcon
                            onClick={() => {
                              handleAdditionalAreaSettingChange(i, j, {
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                width: 0,
                                height: 0,
                                locked: true,
                              });
                            }}
                            style={{
                              marginTop: 15,
                              marginLeft: 20,
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        )}
                      </Tooltip>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                      <Grid item xs={6} sm={2} md={2}>
                        <TextField
                          label="Top"
                          size="small"
                          type="number"
                          disabled={!isLocked}
                          value={areaSettings?.top || 0}
                          onChange={(top) =>
                            handleAdditionalAreaSettingChange(i, j, {
                              top: Number(top),
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <TextField
                          label="Bottom"
                          size="small"
                          type="number"
                          disabled={!isLocked}
                          value={areaSettings?.bottom || 0}
                          onChange={(bottom) =>
                            handleAdditionalAreaSettingChange(i, j, {
                              bottom: Number(bottom),
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <TextField
                          label="Left"
                          size="small"
                          type="number"
                          disabled={!isLocked}
                          value={areaSettings?.left || 0}
                          onChange={(left) =>
                            handleAdditionalAreaSettingChange(i, j, {
                              left: Number(left),
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <TextField
                          label="Right"
                          size="small"
                          type="number"
                          disabled={!isLocked}
                          value={areaSettings?.right || 0}
                          onChange={(right) =>
                            handleAdditionalAreaSettingChange(i, j, {
                              right: Number(right),
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <TextField
                          label="Width"
                          size="small"
                          type="number"
                          disabled={
                            !areaSettings?.platenWidthInch ||
                            !areaSettings?.platenHeightInch ||
                            !isLocked
                          }
                          value={areaSettings?.width || 0}
                          onChange={(width) => {
                            const multiplier = Number(
                              (areaSettings?.platenWidthInch *
                                (isSub || isNY ? 300 : 255)) /
                                width
                            ).toFixed(1);
                            const height = Math.round(
                              (areaSettings?.platenHeightInch *
                                (isSub || isNY ? 300 : 255)) /
                                multiplier
                            );

                            handleAdditionalAreaSettingChange(i, j, {
                              width: Number(width),
                              multiplier: Number(multiplier),
                              height: Number(height),
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <TextField
                          label="Height"
                          size="small"
                          type="number"
                          value={areaSettings?.height || 0}
                          disabled={
                            !areaSettings?.platenWidthInch ||
                            !areaSettings?.platenHeightInch ||
                            !isLocked
                          }
                          onChange={(height) => {
                            const multiplier = Number(
                              (areaSettings?.platenHeightInch *
                                (isSub || isNY ? 300 : 255)) /
                                height
                            ).toFixed(1);
                            const width = Math.round(
                              (areaSettings?.platenWidthInch *
                                (isSub || isNY ? 300 : 255)) /
                                multiplier
                            );

                            handleAdditionalAreaSettingChange(i, j, {
                              height: Number(height),
                              width: Number(width),
                              multiplier: Number(multiplier),
                            });
                          }}
                        />
                      </Grid>
                    </Grid>

                    <ImageContainer>
                      <img
                        alt="art-setting"
                        src={
                          artSetting?.image ||
                          product?.Product?.MarketingImagePath
                        }
                      />
                      <ArtDiv
                        style={{
                          top: `${areaSettings?.top}px`,
                          left: `${areaSettings?.left}px`,
                          right: `${areaSettings?.right}px`,
                          bottom: `${areaSettings?.bottom}px`,
                          width: `${areaSettings?.width}px`,
                          height: `${areaSettings?.height}px`,
                          boxShadow:
                            isSub || isNY
                              ? "4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff"
                              : "none",
                        }}
                      ></ArtDiv>
                    </ImageContainer>
                  </div>
                );
              })}
            </Slider>
          </ProductInformation>
        ),
      });
    }
  }

  return (
    <Container>
      <React.Fragment>
        <ProductInformation>
          <SubHeading>Product Designer Settings</SubHeading>

          <Slider {...settings}>
            {printAreas?.map((printArea, index) => {
              const designerSettings = printArea?.designerSettings;
              const isLocked = !!designerSettings?.locked;

              return (
                <div
                  key={`${printArea?.printAreaName} ${product?.Product?.PrintType} ${index}`}
                >
                  <h4>
                    {printArea?.printAreaName} ({product?.Product?.PrintType})
                  </h4>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={2} md={2}>
                      <TextField
                        label="Machine Width (Inch)"
                        size="small"
                        type="number"
                        disabled={isLocked}
                        value={designerSettings?.platenWidthInch || 0}
                        onChange={(platenWidthInch) =>
                          handlePrintAreasDesignerChange(index, {
                            platenWidthInch: Number(platenWidthInch),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      <TextField
                        label="Machine Height (Inch)"
                        size="small"
                        type="number"
                        disabled={isLocked}
                        value={designerSettings?.platenHeightInch || 0}
                        onChange={(platenHeightInch) => {
                          handlePrintAreasDesignerChange(index, {
                            platenHeightInch: Number(platenHeightInch),
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      <TextField
                        label="Multiplier"
                        size="small"
                        type="number"
                        disabled={true}
                        value={designerSettings?.multiplier || 0}
                      />
                    </Grid>
                    <Tooltip title={isLocked ? "Locked" : "Un-Locked"}>
                      {isLocked ? (
                        <LockIcon
                          onClick={() => {
                            handlePrintAreasDesignerChange(index, {
                              locked: false,
                            });
                          }}
                          style={{
                            marginTop: 15,
                            marginLeft: 20,
                            cursor: "pointer",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <LockOpenIcon
                          onClick={() => {
                            handlePrintAreasDesignerChange(index, {
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              width: 0,
                              height: 0,
                              locked: true,
                            });
                          }}
                          style={{
                            marginTop: 15,
                            marginLeft: 20,
                            cursor: "pointer",
                            color: "#000",
                          }}
                        />
                      )}
                    </Tooltip>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item xs={6} sm={2} md={2}>
                      <TextField
                        label="Top"
                        size="small"
                        type="number"
                        disabled={!isLocked}
                        value={designerSettings?.top || 0}
                        onChange={(top) =>
                          handlePrintAreasDesignerChange(index, {
                            top: Number(top),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      <TextField
                        label="Bottom"
                        size="small"
                        type="number"
                        disabled={!isLocked}
                        value={designerSettings?.bottom || 0}
                        onChange={(bottom) =>
                          handlePrintAreasDesignerChange(index, {
                            bottom: Number(bottom),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      <TextField
                        label="Left"
                        size="small"
                        type="number"
                        disabled={!isLocked}
                        value={designerSettings?.left || 0}
                        onChange={(left) =>
                          handlePrintAreasDesignerChange(index, {
                            left: Number(left),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      <TextField
                        label="Right"
                        size="small"
                        type="number"
                        disabled={!isLocked}
                        value={designerSettings?.right || 0}
                        onChange={(right) =>
                          handlePrintAreasDesignerChange(index, {
                            right: Number(right),
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      <TextField
                        label="Width"
                        size="small"
                        type="number"
                        disabled={
                          !designerSettings?.platenWidthInch ||
                          !designerSettings?.platenHeightInch ||
                          !isLocked
                        }
                        value={designerSettings?.width || 0}
                        onChange={(width) => {
                          const multiplier = Number(
                            (designerSettings?.platenWidthInch *
                              (isSub ? 300 : 255)) /
                              width
                          ).toFixed(1);
                          const height = Math.round(
                            (designerSettings?.platenHeightInch *
                              (isSub ? 300 : 255)) /
                              multiplier
                          );
                          handlePrintAreasDesignerChange(index, {
                            width: Number(width),
                            multiplier: Number(multiplier),
                            height: Number(height),
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      <TextField
                        label="Height"
                        size="small"
                        type="number"
                        value={designerSettings?.height || 0}
                        disabled={
                          !designerSettings?.platenWidthInch ||
                          !designerSettings?.platenHeightInch ||
                          !isLocked
                        }
                        onChange={(height) => {
                          const multiplier = Number(
                            (designerSettings?.platenHeightInch *
                              (isSub ? 300 : 255)) /
                              height
                          ).toFixed(1);
                          const width = Math.round(
                            (designerSettings?.platenWidthInch *
                              (isSub ? 300 : 255)) /
                              multiplier
                          );

                          handlePrintAreasDesignerChange(index, {
                            width: Number(width),
                            multiplier: Number(multiplier),
                            height: Number(height),
                          });
                        }}
                      />
                    </Grid>
                  </Grid>

                  <ImageContainer>
                    <img src={printArea?.printAreaMarketingImage} />
                    <ArtDiv
                      style={{
                        top: `${designerSettings?.top || 0}px`,
                        left: `${designerSettings?.left || 0}px`,
                        right: `${designerSettings?.right || 0}px`,
                        bottom: `${designerSettings?.bottom || 0}px`,
                        width: `${designerSettings?.width || 0}px`,
                        height: `${designerSettings?.height || 0}px`,
                        boxShadow:
                          isSub || isNY
                            ? "4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff"
                            : "none",
                      }}
                    ></ArtDiv>
                  </ImageContainer>
                </div>
              );
            })}
          </Slider>
        </ProductInformation>
        <div
          style={{
            borderBottom: "2px solid #e5e5e5",
            marginBottom: "20px",
            paddingBottom: "20px",
          }}
        >
          <Update>
            <Button
              title="Update"
              onClick={() => updatePrintArea(printAreas)}
            />
          </Update>
        </div>
      </React.Fragment>

      {tabOptions?.length > 0 && (
        <>
          <Tabs tabs={tabOptions} />

          <Update style={{ margin: 0 }}>
            <Button
              title="Update"
              onClick={() => {
                const payload = {
                  ID: product?.Product?.ID,
                  additionalTechniquesArtSettings:
                    additionalTechniquesArtSettings?.map((a) => {
                      if (
                        a?.name === "Plus" &&
                        a?.artSettings?.find(
                          (b) => b?.areaSettings?.artboardPrice > 0
                        )
                      ) {
                        return a;
                      }

                      if (a?.name !== "Plus") {
                        return a;
                      }

                      return {
                        ...additionalTechniquesArtSettings?.find(
                          (p) => p?.name === "DTGPlus"
                        ),
                        name: "Plus",
                      };
                    }),
                };

                editAdditionalTechniquesArtSettings(payload);
              }}
            />
          </Update>
        </>
      )}
    </Container>
  );
};

export default EditProductDesignerSettings;
