import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  display: flex;
  grid-column-gap: 30px;
  padding: 15px;

  > div {
    flex: 1;
    > div {
      margin-top: 20px;
      margin-bottom: 20px;
      min-height: 417px;
      background-color: ${COLORS.LIGHT_GREY};
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    }
  }
  h4 {
    margin: 0;
    font-size: 14px;
  }
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;
const IconUpload = styled.div``;
const MarketingUpload = styled.div``;
const Description = styled.div``;

export { Container, IconUpload, MarketingUpload, Description };
