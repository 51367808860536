import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { columns } from "pages/orders/table/columns";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#000",
    fontSize: 14,
    fontFamily: "Inter600",
  },
}))(TableCell);

const OrderTableHead = ({ category, toggleOrderForPayment }) => {
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const isAdmin =
    emailid === "support@shirtly.com" || emailid === "mlamothe@docpartners.ca";

  let newColumns =
    category === "AwaitingPayment"
      ? [
          ...columns,
          {
            id: "copyOrder",
            label: "Duplicate Order",
            minWidth: 155,
          },
        ]
      : columns;

  if (isAdmin && category === "AwaitingPayment") {
    newColumns = [
      ...newColumns,
      {
        id: "moveToProduction",
        label: "Move To Production",
        minWidth: 155,
      },
    ];
  }

  if (category === "PendingDetails" || category === "AwaitingPayment") {
    newColumns = [
      ...newColumns,
      {
        id: "void",
        label: "Void",
        minWidth: 100,
      },
      {
        id: "removeOrder",
        label: "Remove Order",
        minWidth: 100,
      },
    ];
  }

  return (
    <TableHead>
      <TableRow>
        {newColumns.map((column, index) => (
          <StyledTableCell
            key={`${column.id}thead${index}`}
            align={column.align}
            style={{ minWidth: column.minWidth }}
          >
            {column.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderTableHead;
