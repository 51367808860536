import styled from "styled-components";
import { Typography } from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  margin-top: 10px;
`;

export const Title = styled.h3``;

export const LayerContainer = styled.div``;

export const LayerWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  border: 1px dashed #ccc;
  padding: 30px 20px 20px;
  position: relative;
`;

export const LayerRow = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
`;

export const StyledDeleteIcon = styled(DeleteOutlinedIcon)`
  color: red;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 2px;
`;

export const AxisTitle = styled(Typography)`
  padding-top: 2px;
`;
