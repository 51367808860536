import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { updateField } from 'actions/common';
import { SET_USER_EMAIL, USER_INITIATE, forgotPassword } from 'actions/user';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/Button';
import TextField from 'components/TextField';
import CloseIcon from 'assets/svg/CloseIcon';

import {
  Container,
  Header,
  Body,
  ActionButton,
} from 'styles/pages/home/forgotPassword';

const ForgotPasswordDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [emailSent, setEmailSent] = useState(false);
  const user = useSelector((state) => state?.user);
  const homeWebPage = useSelector((state) => state?.homeWebPage);
  const homeData = homeWebPage?.find((p) => p?.name === 'Header') || {};
  const { components } = homeData;
  const { Image } = components || {};
  const email = user?.email;

  const disabled = !email?.valid;

  const close = () => {
    dispatch(updateField(USER_INITIATE));
    _.isFunction(onClose) && onClose();
  };

  return (
    <Dialog maxWidth='md' open={open} onClose={close}>
      <Container>
        <CloseIcon className='close-icon' onClick={onClose} />
        <Header>
          {!emailSent && <img src={Image} alt='logo' />}
          {!emailSent && <h4>Forgot Password?</h4>}
          {emailSent ? (
            <p
              style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>
              Email containing credentials sent successfully to registered
              email!
            </p>
          ) : (
            <p>
              Please enter your email address and we’ll send you instructions on
              how to reset your password.
            </p>
          )}
        </Header>
        <Body>
          {!emailSent && (
            <TextField
              fullWidth
              size='small'
              label='Email address'
              error={!!(email?.value && !email?.valid)}
              helperText={
                email?.value && !email?.valid ? 'Enter valid email' : null
              }
              value={email?.value || ''}
              onChange={(v) =>
                dispatch(updateField(SET_USER_EMAIL, { email: v }))
              }
            />
          )}

          {emailSent && (
            <Button
              containerStyle={{ float: 'right', width: 100 }}
              variant='contained'
              title='OK'
              onClick={close}
            />
          )}

          <ActionButton>
            {!emailSent && (
              <>
                <Button
                  disabled={disabled}
                  variant='contained'
                  title='Send new password'
                  onClick={async () => {
                    const request = await dispatch(
                      forgotPassword({ email: email?.value })
                    );

                    if (request?.error) {
                      return;
                    }

                    setEmailSent(true);
                  }}
                />
                <Button
                  variant='contained'
                  title='Cancel, back to login '
                  onClick={close}
                />
              </>
            )}
          </ActionButton>
        </Body>
      </Container>
    </Dialog>
  );
};

ForgotPasswordDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ForgotPasswordDialog;
