import _ from 'lodash';

const getCategoriesFromProducts = (products = []) => {
  let categories = [];
  _.forEach(products, (item) => {
    categories = _.uniqBy(
      _.concat(categories, item.Product.CategoryIds),
      'CatId'
    );
  });

  return categories;
};

export { getCategoriesFromProducts };
