import { INITIAL_FIELD_STATE } from 'reducers/common';

const initial = {
  name: { ...INITIAL_FIELD_STATE },
  email: { ...INITIAL_FIELD_STATE },
  company: { ...INITIAL_FIELD_STATE },
  address1: { ...INITIAL_FIELD_STATE },
  address2: { ...INITIAL_FIELD_STATE },
  city: { ...INITIAL_FIELD_STATE },
  state: { ...INITIAL_FIELD_STATE },
  country: { ...INITIAL_FIELD_STATE },
  zip: { ...INITIAL_FIELD_STATE },
  password: { ...INITIAL_FIELD_STATE, MINIMUM_PASSWORD_LENGTH: 5 },
  confirmPassword: { ...INITIAL_FIELD_STATE, MINIMUM_PASSWORD_LENGTH: 5 },
  agreeTermsAndCondition: false,
};

export { initial };
