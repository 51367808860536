import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getWebPage } from "actions/webPages";
import { updateField } from "actions/common";
import { SET_USER_EMAIL } from "actions/user";
import Header from "pages/home/Header";
import Banner from "pages/home/Banner";
import Works from "pages/home/Works";
import Footer from "pages/home/Footer";
import ProductCatalog from "pages/home/ProductCatalog";
import RegisterBanner from "pages/home/RegisterBanner";
import Reviews from "pages/home/Reviews";
import Integration from "pages/home/Integration";
import KeyFeatures from "pages/home/KeyFeatures";
import FulfillMent from "pages/home/Fulfillment";
import DashboardWebPage from "pages/web-pages/Dashboard";
import SplashImage from "assets/images/vizuail_spash.jpeg";

import { Container } from "styles/pages/home";

const Home = () => {
  const dispatch = useDispatch();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [isSplah, setIsSplash] = useState(true);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [data, setData] = useState(null);
  const [loginDialog, setLoginDialog] = useState(false);
  const [signUpDialog, setSignUpDialog] = useState(false);
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
  const [dashboardData, setDashboardData] = useState();

  const toggleLoginDialog = () => setLoginDialog(!loginDialog);
  const toggleSignUpDialog = () => setSignUpDialog(!signUpDialog);
  const toggleForgotPasswordDialog = () =>
    setForgotPasswordDialog(!forgotPasswordDialog);

  useEffect(() => {
    const fetchData = async () => {
      const request = await dispatch(getWebPage({ page: "Dashboard" }));
      if (request?.error) {
        return;
      }
      setDashboardData(request);
    };
    if (!dashboardData) fetchData();
  }, [dispatch, dashboardData]);

  useEffect(() => {
    const getWebPageData = async () => {
      const data = await dispatch(getWebPage({ page: "LandingPage" }));
      setData(() => data);
    };

    getWebPageData();
  }, []);

  useEffect(() => {
    const keys = Object.keys(params);
    if (keys?.length > 0 && params?.emailId) {
      dispatch(updateField(SET_USER_EMAIL, { email: params?.emailId }));
      setLoginDialog(true);
    }
  }, [params]);

  useEffect(() => {
    setTimeout(() => {
      setIsSplash(false);
      setTimeout(() => {
        setLoginDialog(true);
      }, 500);
    }, 3000);
  }, []);

  return (
    <Container>
      <Header
        loginDialog={loginDialog}
        signUpDialog={signUpDialog}
        forgotPasswordDialog={forgotPasswordDialog}
        toggleLoginDialog={toggleLoginDialog}
        toggleSignUpDialog={toggleSignUpDialog}
        toggleForgotPasswordDialog={toggleForgotPasswordDialog}
        data={data?.find((d) => d?.name === "Header")}
      />

      <div
        style={{
          background: `url(${SplashImage})`,
          backgroundSize: "contain",
          height: "56.5vh",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "black",
        }}
      />

      {/* <Banner
        data={data?.find((d) => d?.name === "Banner")}
        toggleLoginDialog={toggleLoginDialog}
        toggleSignUpDialog={toggleSignUpDialog}
        dashboardData={dashboardData}
      /> */}
      {/* {dashboardData && dashboardData?.length > 0 && (
        <DashboardWebPageWrapper>
          <h3>Enhancements</h3>
          <DashboardWebPage data={dashboardData} isAdmin={false} />
        </DashboardWebPageWrapper>
      )} */}
      {/* <Integration />
      <Works data={data?.find((d) => d?.name === "How It Works")} />
      <ProductCatalog
        toggleLoginDialog={toggleLoginDialog}
        toggleSignUpDialog={toggleSignUpDialog}
        data={data?.find((d) => d?.name === "Product & Pricing")}
      /> */}
      {/* <KeyFeatures data={data?.find((d) => d?.name === "KEY FEATURES")} /> */}
      {/* <FulfillMent
        data={data?.find((d) => d?.name === "Fulfillment Done Differently")}
      /> */}
      {/* <Reviews data={data?.find((d) => d?.name === "User Reviews")} /> */}
      {/* <RegisterBanner
        data={data?.find((d) => d?.name === "What’s New")}
        toggleSignUpDialog={toggleSignUpDialog}
        toggleLoginDialog={toggleLoginDialog}
      /> */}
      <Footer />
    </Container>
  );
};

export default Home;
