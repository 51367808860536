import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { yotiCredentials, userYotiVerified } from 'actions/user';
import { updateField } from 'actions/common';
import { HIDE_YOTI_MODAL } from 'actions/yoti';
import { sendEmail } from 'actions/orders';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ModalContainer } from 'styles/pages/dashboard';

const YotiVerificationModal = () => {
  const dispatch = useDispatch();
  const [yoti, setYoti] = useState();
  const modalVisibility = useSelector((state) => state?.yoti?.visibility);
  const auth = useSelector((state) => state?.auth);

  const fetchYotiCredentials = async () => {
    const data = await dispatch(
      yotiCredentials({ user: auth?.profile?.emailid })
    );
    setYoti(data);
  };

  useEffect(() => {
    if (modalVisibility) {
      window.addEventListener('message', async function (event) {
        if (event.data.eventType === 'SUCCESS') {
          await dispatch(
            sendEmail({
              user: auth?.profile?.emailid,
              subject: 'Documents Submitted Successfully for Verification !',
            })
          );
          await dispatch(
            userYotiVerified(`verify=success&user=${auth?.profile?.emailid}`)
          );
          window.location.reload();
        } else if (event.data.eventType === 'ERROR') {
          await dispatch(
            userYotiVerified(`verify=declined&user=${auth?.profile?.emailid}`)
          );
          window.location.reload();
        }
      });
      fetchYotiCredentials();
    } else {
      window.removeEventListener('message');
      setYoti(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisibility]);

  return (
    <Dialog
      open={modalVisibility}
      maxWidth="lg"
      onClose={() => dispatch(updateField(HIDE_YOTI_MODAL))}
    >
      <ModalContainer>
        <DialogTitle>KYC (Know Your Customer)</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!!yoti && (
              <iframe
                title="yoti"
                allow="camera"
                src={`https://api.yoti.com/idverify/v1/web/index.html?sessionID=${yoti?.sessionId}&sessionToken=${yoti?.clientSessionToken}`}
                style={{ height: '70vh', width: '100%', border: 'none' }}
              />
            )}
          </DialogContentText>
        </DialogContent>
      </ModalContainer>
    </Dialog>
  );
};

export default YotiVerificationModal;
