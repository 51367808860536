import * as a from 'actions/user';
import { initial } from 'reducers/user/initialState';

const user = (state = initial, action = null) => {
  switch (action.type) {
    case a.USER_INITIATE:
      return initial;

    case a.SET_USER_EMAIL:
      const email = action.email.toLowerCase();
      return {
        ...state,
        email: {
          ...state.email,
          value: email,
          valid:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              email
            ),
        },
      };

    case a.SET_USER_PASSWORD:
      const password = action.password.replace(/[\s]/g, '');
      return {
        ...state,
        password: {
          ...state.password,
          value: password,
          valid:
            !!action.password &&
            action.password.length >= state.password.MINIMUM_PASSWORD_LENGTH,
        },
      };

    case a.SET_USER_CONFIRM_PASSWORD:
      const confirmPassword = action.confirmPassword.replace(/[\s]/g, '');
      return {
        ...state,
        confirmPassword: {
          ...state.confirmPassword,
          value: confirmPassword,
          valid: !!action.confirmPassword,
        },
      };

    case a.UPDATE_USER_FIELD:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          value: action.value,
          valid: !!action?.value,
        },
      };

    case a.TOGGLE_TERMS_AND_CONDITION:
      return {
        ...state,
        agreeTermsAndCondition: !state.agreeTermsAndCondition,
      };

    default:
      return state;
  }
};
export default user;
