const columns = [
  { id: 'MyStores', label: 'My Stores', minWidth: 90 },
  { id: 'Marketplace', label: 'Marketplace', minWidth: 100 },
  {
    id: 'StoreName',
    label: 'Store Name',
    minWidth: 170,
  },
  {
    id: 'Link',
    label: 'Link',
    minWidth: 170,
  },
  {
    id: 'Remove',
    label: 'Remove Store',
    minWidth: 170,
  },
];

export { columns };
