import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from 'components/Button';
import Checkbox from 'components/CheckBox';
import {
  Container,
  ProductInformation,
  SelectCategories,
  Update,
  SubHeading,
} from 'styles/pages/edit-products/editProductInformation';

const EditProductArtwork = ({
  product = { Product: {} },
  editProductArtworkSettings,
}) => {
  const [greyOut, setGreyOut] = useState(product?.ArtworkSettings?.greyOut);
  const [allColors, setAllColors] = useState(
    product?.ArtworkSettings?.allColors
  );

  useEffect(() => {
    const artworkSettings = product?.ArtworkSettings;
  }, [product]);

  return (
    <Container>
      <SubHeading>Product Artwork Settings</SubHeading>

      <SelectCategories>
        <Grid container spacing={1}>
          <Checkbox
            label='Greyout Design'
            checked={greyOut}
            onChange={(v) => {
              setGreyOut(v);
              setAllColors(false);
            }}
          />
          <Checkbox
            label='All Colored Design'
            checked={allColors}
            onChange={(v) => {
              setGreyOut(false);
              setAllColors(v);
            }}
          />
        </Grid>
      </SelectCategories>

      <Update>
        <Button
          title='Update'
          disabled={!greyOut && !allColors}
          onClick={() => {
            const payload = {
              ID: product?.Product?.ID,
              artworkSettingsData: {
                greyOut,
                allColors,
              },
            };
            editProductArtworkSettings(payload);
          }}
        />
      </Update>
    </Container>
  );
};

export default EditProductArtwork;
