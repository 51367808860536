import styled from 'styled-components';
const Container = styled.div``;
const Wrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  padding: 30px 15px;
  text-align: center;

  .textSection {
    text-align: center;
    font-family: 'Inter800';
    h2 {
      padding: 0 15px;
      margin: 0;
      text-align: center;
      line-height: 64px;
    }
    p {
      text-align: center;
      font-family: 'Inter400';
      margin: 0;
      line-height: 32px;
    }
    @media (min-width: 1200px) {
      h2 {
        font-size: 36px;
      }
    }
    @media (max-width: 768px) {
      h2 {
        margin: 0 auto;
      }
    }
  }
`;
const CardWrapper = styled.div`
  text-align: center;
  max-width: 350px;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  h4 {
    margin-top: 10px;
    line-height: 32px;
    font-size: 24px;
    font-family: 'Inter600';
    margin: 0 0 8px 0;
  }
  .card-image {
    width: 100%;
    max-width: 150px;
    height: 160px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  p {
    margin: 0;
    line-height: 26px;
    font-size: 16px;
    font-family: 'Inter400';
  }
`;
const ImageSection = styled.div`
  display: flex;
  align-items: center;
  margin: 40px auto 0 auto;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export { Container, Wrapper, CardWrapper, ImageSection };
