const DECORATING_TECHNIQUES = [
  {
    DisplayName: 'DTG',
    FullName: 'Direct To Garment',
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456604/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/dtg_aobgz3.png',
  },
  {
    DisplayName: 'EMB',
    FullName: 'Embroidery',
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456604/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/embroidery_ixztqg.png',
  },
  {
    DisplayName: 'SUB',
    FullName: 'Sublimation',
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456603/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/sublimation_zrg3kk.png',
  },
  {
    DisplayName: 'AOP',
    FullName: 'All Over Print',
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456604/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/alloverprint_osqy5g.png',
  },
  {
    DisplayName: 'DURASOFT',
    FullName: 'Heat Transfer',
    ImageUrl:
      'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620456604/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/durasoft_okeuhn.png',
  },
  {
    DisplayName: 'ScreenPrint',
    FullName: 'Screen Print',
    ImageUrl:
      'https://images.shirtly.com/image/upload/v1647441505/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/screen-printer_qrdewd.png',
  },
];

export { DECORATING_TECHNIQUES };
