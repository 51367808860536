const row = (
  Status,
  PrintProvider,
  Listing,
  OrderId,
  shopifyOrderPresentedID,
  BuyerName,
  OrderDate,
  TotalCost,
  TrackingNumber
) => {
  return {
    Status,
    PrintProvider,
    Listing,
    OrderId,
    shopifyOrderPresentedID,
    BuyerName,
    OrderDate,
    TotalCost,
    TrackingNumber,
  };
};

export { row };
