import Sidebar from "pages/ai-mock-up-generator/Sidebar";
import FooterLogo from "pages/ai-mock-up-generator/FooterLogo";
import HeaderLogo from "pages/ai-mock-up-generator/HeaderLogo";
import Welcome from "pages/ai-mock-up-generator/Welcome";
import Splash from "pages/ai-mock-up-generator/Splash";
import BeginWithAiAssistant from "pages/ai-mock-up-generator/BeginWithAiAssistant";
import SelectAiAssistant from "pages/ai-mock-up-generator/SelectAiAssistant";
import AIGeneratedImagesRight from "components/ArtWork/AIGeneratedImagesRight";
import RenderGeneratedImages from "components/ArtWork/AiDesignPhases/PrevAIGeneratedImages";
import ChooseProduct from "pages/ai-mock-up-generator/ChooseProduct";
import ArtworkTool from "pages/ai-mock-up-generator/ArtworkTool";
import RightBody from "pages/ai-mock-up-generator/RightBody";
import MockLibrary from "pages/ai-mock-up-generator/MockLibrary";
import Context, { Provider, Steps } from "pages/ai-mock-up-generator/context";
import { Provider as AIProvider } from "components/ArtWork/aiGeneratedImagesContext";
import Logo from "assets/images/shirtly-premium-logo.png";
import { Container, RightSection } from "styles/pages/ai-mock-up-generator";
import {
  Container as RContainer,
  Wrapper as RWrapper,
  Header as RHeader,
} from "styles/pages/ai-mock-up-generator/right-sidebar";

const AiDesignStudio = () => (
  <>
    <RightSection>
      <AIGeneratedImagesRight />
    </RightSection>

    <RContainer>
      <RHeader>
        <img src={Logo} />
      </RHeader>
      <RWrapper>
        <RenderGeneratedImages />
      </RWrapper>
    </RContainer>
  </>
);

const Wrapper = () => {
  const { state } = Context();

  const hideSideBar =
    state.step === Steps.Welcome ||
    state.step === Steps.BeginWithAiAssitant ||
    state.step === Steps.SelectAiAssistant;
  const showRightBody =
    state.step !== Steps.AIDesginStudio &&
    state.step !== Steps.Welcome &&
    state.step !== Steps.BeginWithAiAssitant &&
    state.step !== Steps.SelectAiAssistant &&
    state.step !== Steps.MockLibrary &&
    state.step !== Steps.Products;

  if (state?.step === Steps.Splash) {
    return (
      <AIProvider>
        <Splash />
      </AIProvider>
    );
  }

  return (
    <Container hideSideBar={hideSideBar}>
      {(state.step === Steps.Welcome ||
        state.step === Steps.BeginWithAiAssitant ||
        state.step === Steps.SelectAiAssistant ||
        state.step === Steps.MockLibrary) && <HeaderLogo />}
      {!hideSideBar && <Sidebar />}

      {showRightBody && (
        <RightSection>
          {state.step === Steps.MockUp && <ArtworkTool />}
        </RightSection>
      )}

      {state.step === Steps.Products && (
        <AIProvider>
          <ChooseProduct />
        </AIProvider>
      )}

      {state.step === Steps.SelectAiAssistant && (
        <AIProvider>
          <SelectAiAssistant />
        </AIProvider>
      )}

      {state.step === Steps.Welcome && (
        <AIProvider>
          <Welcome />
        </AIProvider>
      )}

      {state.step === Steps.BeginWithAiAssitant && (
        <AIProvider>
          <BeginWithAiAssistant />
        </AIProvider>
      )}

      {state.step === Steps.AIDesginStudio && (
        <AIProvider>
          <AiDesignStudio />
        </AIProvider>
      )}

      {state?.step === Steps.MockLibrary && (
        <div style={{ width: "calc(-400px + 100vw)" }}>
          <MockLibrary />
        </div>
      )}

      {showRightBody && <RightBody />}

      <FooterLogo />
    </Container>
  );
};

const AIMockUpGenerator = () => (
  <Provider>
    <Wrapper />
  </Provider>
);

export default AIMockUpGenerator;
