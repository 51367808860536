import { post } from "@axios";
import { updateField } from "actions/common";
import { requests } from "shared/requests";
import { USER_INITIATE } from "actions/user";
import { showLoader, hideLoader } from "actions/loader";
import { SHOW_ERROR_MODAL } from "actions/errorModal";

const AUTH_SUCCESS = "AUTH_SUCCESS";
const AUTH_ERROR = "AUTH_ERROR";
const AUTH_LOGOUT = "AUTH_LOGOUT";

const signUp = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.signUp, data });
      dispatch(hideLoader());

      dispatch(updateField(USER_INITIATE));
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(AUTH_ERROR, { error: error.message }));
      return { error: true, message: error.message };
    }
  };
};

const ipSignUp = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.IPSignUp, data });
      dispatch(hideLoader());

      dispatch(updateField(USER_INITIATE));
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(AUTH_ERROR, { error: error.message }));
      return { error: true, message: error.message };
    }
  };
};

const login = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.login, data });
      dispatch(hideLoader());

      if (request?.error) {
        return { error: true, message: request.error };
      }

      if (request?.Status === "In-Active") {
        return {
          error: true,
          message: "Your account is deactivated. Please contact to Shirtly.",
        };
      }

      dispatch(
        updateField(AUTH_SUCCESS, {
          data: {
            emailid: request?.User?.Email,
            YotiVerified: request?.YotiVerified,
            YotiVerificationStatus: request?.YotiVerificationStatus,
            User_Id: request?.User_Id,
            FullName: request?.User?.FullName,
            password: request?.User?.PasswordHash,
            ID: request?.ID,
            token: request?.token,
          },
        })
      );
      dispatch(updateField(USER_INITIATE));
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(AUTH_ERROR, { error: error.message }));
      return { error: true, message: error.message };
    }
  };
};

const ipLogin = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.IPLogin, data });
      dispatch(hideLoader());

      if (request?.error) {
        return { error: true, message: request.error };
      }

      if (request?.Status === "In-Active") {
        return {
          error: true,
          message: "Your account is deactivated. Please contact to Shirtly.",
        };
      }

      dispatch(
        updateField(AUTH_SUCCESS, {
          data: {
            emailid: request?.User?.Email,
            User_Id: request?.User_Id,
            FullName: request?.User?.FullName,
            ID: request?.ID,
            token: request?.token,
            IPUser: request?.IPUser,
            emailConfirmed: request?.User?.EmailConfirmed,
          },
        })
      );
      dispatch(updateField(USER_INITIATE));
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(AUTH_ERROR, { error: error.message }));
      return { error: true, message: error.message };
    }
  };
};

export {
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  signUp,
  login,
  ipSignUp,
  ipLogin,
};
