import React, { createContext, useState, useContext, useMemo } from "react";

const Context = createContext();

export const Provider = ({ children }) => {
  const [state, setState] = useState({
    phase1Completed: false,
    prevAiGeneratedImages: [],
  });

  const a = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );

  return <Context.Provider value={a}>{children}</Context.Provider>;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => useContext(Context);
