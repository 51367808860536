import React, { useState, useEffect } from 'react';
import TextField from 'components/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from 'components/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CloudinaryWidget from 'components/CloudinaryWidget';
import {
  Heading,
  AddedProduct,
  Container,
  Update,
  SubHeading,
  FieldWrapper,
  EUFieldWrapper,
} from 'styles/pages/edit-products/editProductSizes';

const EditProductSizes = ({ product, updateProductSizes }) => {
  const [sizes, setSizes] = useState(product?.Sizes);
  const printProviders = product?.PrintProviders;
  const isEuPrintProvider = printProviders?.find(
    (p) => p?.ProviderName === 'EU'
  );

  const isAuPrintProvider = printProviders?.find(
    (p) => p?.ProviderName === 'AU'
  );

  const isNYPrintProvider = printProviders?.find(
    (p) => p?.ProviderName === 'NY'
  );

  const printType = product?.Product?.PrintType;

  const markUpPercentage = !!isEuPrintProvider
    ? isEuPrintProvider?.markUpPercentage
    : !!isAuPrintProvider
    ? isAuPrintProvider?.markUpPercentage
    : isNYPrintProvider?.markUpPercentage;

  const whitePriceCondition =
    product?.Product?.PrintType === 'DTG' ||
    product?.Product?.PrintType === 'EMB' ||
    product?.Product?.PrintType === 'DURASOFT';

  useEffect(() => {
    let newSizes = sizes;
    const printType = product?.Product?.PrintType;

    for (let i = 0; i < newSizes?.length; i++) {
      const key = [`${printType}Price`];
      newSizes[i][key] = newSizes[i][key] || 0;
    }

    setSizes(() => newSizes);
  }, []);

  return (
    <Container>
      <Heading>
        <SubHeading>Product Sizes</SubHeading>
        <AddCircleIcon
          className="add-circle-icon"
          onClick={() =>
            setSizes([
              ...sizes,
              {
                Name: '',
                ColoredPrice: '',
                ID: moment().unix() + sizes?.length,
                visible: true,
              },
            ])
          }
        />
      </Heading>
      <div>
        {sizes?.map((size, index) => (
          <React.Fragment key={`EditProductSizes${index}`}>
            <AddedProduct>
              <div>
                {size?.visible === false ? (
                  <VisibilityOffIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setSizes(
                        sizes?.map((s, i) => {
                          if (i != index) {
                            return s;
                          }
                          return {
                            ...s,
                            visible: true,
                          };
                        })
                      );
                    }}
                  />
                ) : (
                  <VisibilityIcon
                    style={{ color: 'lightseagreen', cursor: 'pointer' }}
                    onClick={() => {
                      setSizes(
                        sizes?.map((s, i) => {
                          if (i != index) {
                            return s;
                          }
                          return {
                            ...s,
                            visible: false,
                          };
                        })
                      );
                    }}
                  />
                )}
              </div>

              <FieldWrapper>
                <TextField
                  label="Name"
                  value={size?.Name}
                  size="small"
                  onChange={(Name) => {
                    setSizes(
                      sizes?.map((s, i) => {
                        if (i != index) {
                          return s;
                        }
                        return {
                          ...s,
                          Name,
                        };
                      })
                    );
                  }}
                />

                {(!!isEuPrintProvider ||
                  !!isAuPrintProvider ||
                  !!isNYPrintProvider) && (
                  <EUFieldWrapper>
                    {whitePriceCondition && (
                      <TextField
                        label="Original White Price"
                        value={size?.originalWhitePrice?.toString() || 0}
                        size="small"
                        type="number"
                        onChange={(originalWhitePrice) => {
                          const v = Number(originalWhitePrice);
                          setSizes(
                            sizes?.map((s, i) => {
                              if (i !== index) {
                                return s;
                              }
                              return {
                                ...s,
                                originalWhitePrice: v,
                                WhitePrice: Number(
                                  ((markUpPercentage * v) / 100 + v).toFixed(2)
                                ),
                              };
                            })
                          );
                        }}
                      />
                    )}

                    <TextField
                      label="Original Colored Price"
                      value={size?.originalColoredPrice?.toString() || 0}
                      size="small"
                      type="number"
                      onChange={(originalColoredPrice) => {
                        const v = Number(originalColoredPrice);
                        setSizes(
                          sizes?.map((s, i) => {
                            if (i !== index) {
                              return s;
                            }
                            return {
                              ...s,
                              originalColoredPrice: v,
                              ColoredPrice: Number(
                                ((markUpPercentage * v) / 100 + v).toFixed(2)
                              ),
                            };
                          })
                        );
                      }}
                    />

                    <TextField
                      label={`Original ${product?.Product?.PrintType} Price`}
                      value={
                        size?.[
                          `${product?.Product?.PrintType}OriginalPrice`
                        ]?.toString() || '0'
                      }
                      size="small"
                      type="number"
                      onChange={(p) => {
                        const v = Number(p);
                        setSizes(
                          sizes?.map((s, i) => {
                            if (i !== index) {
                              return s;
                            }
                            return {
                              ...s,
                              [`${product?.Product?.PrintType}OriginalPrice`]:
                                v,
                              [`${product?.Product?.PrintType}Price`]: Number(
                                ((markUpPercentage * v) / 100 + v).toFixed(2)
                              ),
                            };
                          })
                        );
                      }}
                    />
                  </EUFieldWrapper>
                )}

                {whitePriceCondition && (
                  <TextField
                    label="White Price"
                    disabled={
                      !!isEuPrintProvider ||
                      !!isAuPrintProvider ||
                      !!isNYPrintProvider
                    }
                    value={size?.WhitePrice?.toString()}
                    size="small"
                    type="number"
                    onChange={(WhitePrice) => {
                      setSizes(
                        sizes?.map((s, i) => {
                          if (i !== index) {
                            return s;
                          }
                          return {
                            ...s,
                            WhitePrice: Number(WhitePrice),
                          };
                        })
                      );
                    }}
                  />
                )}

                <TextField
                  label="Colored Price"
                  value={size?.ColoredPrice?.toString()}
                  disabled={
                    !!isEuPrintProvider ||
                    !!isAuPrintProvider ||
                    !!isNYPrintProvider
                  }
                  size="small"
                  type="number"
                  onChange={(ColoredPrice) => {
                    setSizes(
                      sizes?.map((s, i) => {
                        if (i !== index) {
                          return s;
                        }
                        return {
                          ...s,
                          ColoredPrice: Number(ColoredPrice),
                        };
                      })
                    );
                  }}
                />

                <TextField
                  label={`${product?.Product?.PrintType} Price`}
                  value={
                    size?.[`${product?.Product?.PrintType}Price`]?.toString() ||
                    '0'
                  }
                  size="small"
                  disabled={
                    !!isEuPrintProvider ||
                    !!isAuPrintProvider ||
                    !!isNYPrintProvider
                  }
                  type="number"
                  onChange={(p) => {
                    setSizes(
                      sizes?.map((s, i) => {
                        if (i != index) {
                          return s;
                        }
                        return {
                          ...s,
                          [`${product?.Product?.PrintType}Price`]: Number(p),
                        };
                      })
                    );
                  }}
                />

                {product?.RemainingDecorativeTechniques?.map((v) => (
                  <TextField
                    label={`${v?.DisplayName} Price`}
                    value={size?.[`${v?.DisplayName}Price`]?.toString() || '0'}
                    size="small"
                    type="number"
                    disabled={
                      !!isEuPrintProvider ||
                      !!isAuPrintProvider ||
                      !!isNYPrintProvider
                    }
                    onChange={(p) => {
                      setSizes(
                        sizes?.map((s, i) => {
                          if (i != index) {
                            return s;
                          }
                          return {
                            ...s,
                            [`${v?.DisplayName}Price`]: Number(p),
                          };
                        })
                      );
                    }}
                  />
                ))}

                {printType === 'AOP' && (
                  <CloudinaryWidget
                    label="Template Url"
                    value={size?.templateUrl}
                    disabled={false}
                    setValue={(templateUrl) =>
                      setSizes(
                        sizes?.map((s, i) => {
                          if (i !== index) {
                            return s;
                          }
                          return {
                            ...s,
                            templateUrl,
                          };
                        })
                      )
                    }
                    folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${product?.Product?.Name}/TemplateUrl`}
                  />
                )}
              </FieldWrapper>

              <DeleteIcon
                className="delete-icon"
                onClick={() => setSizes(sizes?.filter((s, i) => i != index))}
              />
            </AddedProduct>
            <Divider />
          </React.Fragment>
        ))}
      </div>
      <Update>
        <Button
          title="Update"
          disabled={!sizes?.length}
          onClick={() => {
            const payload = {
              ID: product?.Product?.ID,
              sizeData: sizes,
            };

            updateProductSizes(payload);
          }}
        />
      </Update>
    </Container>
  );
};

export default EditProductSizes;
