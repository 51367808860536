import React from "react";
import _ from "lodash";
import Button from "components/Button";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { columns } from "pages/orders/table/columns";
import { rows } from "pages/orders/table/rows";
import { ROUTES } from "shared/constants/routes";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  td: {
    fontSize: 14,
    padding: "13px 16px",
    fontFamily: "Inter400",
    "& img": {
      width: 100,
    },
    "& a": {
      color: "#337ab7",
      textDecoration: "none",
    },
  },
  tr: {
    transition: "all ease-out 0.25s",
    "&:nth-of-type(odd)": {
      background: "linear-gradient(60deg, #fff, #eee)",
    },
    "&:nth-of-type(even)": {
      background: "linear-gradient(-45deg, #fff, #eee)",
    },
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      background: "linear-gradient(60deg, #fff, #eee)",
    },
    "&:nth-of-type(even)": {
      background: "linear-gradient(-45deg, #fff, #eee)",
    },
    "&:hover": {
      cursor: "pointer",
      background: "white",
      boxShadow: "10px 10px 30px #d9d9d9, -10px -10px 30px #d9d9d9",
      position: "relative",
    },
  },
}))(TableRow);

const Orders = ({
  orders,
  onOrderSelect,
  category,
  voidOrder,
  copyOrder,
  toggleOrderForPayment,
  changeOrderStatus,
  removeOrder,
}) => {
  const currency = useSelector((state) => state?.currency?.value);
  const classes = useStyles();
  const history = useHistory();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const isAdmin =
    emailid === "support@shirtly.com" || emailid === "mlamothe@docpartners.ca";

  const data = rows(orders, currency);

  let newColumns =
    category === "AwaitingPayment"
      ? [
          ...columns,
          {
            id: "copyOrder",
            label: "Copy Order",
            minWidth: 100,
          },
        ]
      : columns;

  if (isAdmin && category === "AwaitingPayment") {
    newColumns = [
      ...newColumns,
      {
        id: "moveToProduction",
        label: "Move To Production",
        minWidth: 155,
      },
    ];
  }

  if (category === "PendingDetails" || category === "AwaitingPayment") {
    newColumns = [
      ...newColumns,
      {
        id: "void",
        label: "Void",
        minWidth: 100,
      },
      {
        id: "removeOrder",
        label: "Remove Order",
        minWidth: 100,
      },
    ];
  }

  return data?.length > 0 ? (
    data.map((row, index) => (
      <StyledTableRow
        key={`OrdersTable${index}`}
        hover
        tabIndex={-1}
        onClick={() => {
          if (_.isFunction(onOrderSelect)) {
            onOrderSelect(orders[index]);
            return;
          }
          if (orders[index]?.Store?.ShopName) {
            history.push(
              `${ROUTES.ORDER_DETAILS.LINK}/${
                orders[index].ID
              }/${encodeURIComponent(orders[index]?.Store?.ShopName)}`
            );
          } else {
            history.push(`${ROUTES.ORDER_DETAILS.LINK}/${orders[index].ID}`);
          }
        }}
      >
        {newColumns.map((column, columnIndex) => (
          <TableCell
            className={classes.td}
            key={`${column.id}tbody${columnIndex}${index}`}
            align={column.align}
          >
            {column.id === "removeOrder" ? (
              <DeleteIcon
                style={{ color: "red", cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeOrder(orders[index]);
                }}
              />
            ) : column.id === "void" ? (
              <Tooltip title={`I don't want to fulfill this order by Shirtly`}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    voidOrder(orders[index]);
                  }}
                  style={{
                    background: "transparent",
                    width: "100%",
                    color: "red",
                    border: "1px solid red",
                    textAlign: "center",
                    padding: "3px 0px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  Void
                </div>
              </Tooltip>
            ) : column.id === "TrackingNumber" ? (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {row[column.id]}
              </span>
            ) : column.id === "copyOrder" ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  copyOrder(orders[index]);
                }}
                style={{
                  background: "transparent",
                  width: "100%",
                  color: "#000",
                  border: "1px solid #000",
                  textAlign: "center",
                  padding: "3px 0px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                Duplicate Order
              </div>
            ) : column?.id === "moveToProduction" ? (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (_.isFunction(changeOrderStatus)) {
                    changeOrderStatus(orders[index]);
                  }
                }}
                containerStyle={{
                  background: "#000",
                  color: "#fff",
                }}
                title="Move"
              />
            ) : (
              row[column.id]
            )}
          </TableCell>
        ))}
      </StyledTableRow>
    ))
  ) : (
    <StyledTableRow key={`NodataFound`} hover tabIndex={-1}>
      <TableCell
        className={classes.td}
        key="tbodynodatafound"
        colSpan={newColumns.length}
      >
        No Data Found
      </TableCell>
    </StyledTableRow>
  );
};

export default Orders;
