import * as a from 'actions/inventory';

const initial = {
  data: [],
  error: null,
};

const inventory = (state = initial, action = null) => {
  switch (action.type) {
    case a.SET_INVENTORY:
      return {
        ...state,
        data: action.inventory,
        error: null,
      };
    case a.SET_INVENTORY_ERROR:
      return {
        ...state,
        error: action.error,
        data: null,
      };
    default:
      return state;
  }
};

export default inventory;
