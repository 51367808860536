import {
  getSKUFromProductId,
  isSub,
  getPrintTypeFromProductId,
} from "selectors/products";
import moment from "moment";

const regExpr = /[^a-zA-Z0-9- ]/g;

const json2xmlPrintAreas = (
  order,
  products,
  orderLineItem,
  vatIncluded,
  iossNumber
) => {
  let items = "";
  const salesValueInEuro = (
    (orderLineItem?.FulfillmentCost +
      (orderLineItem?.FulfillmentCost * 40) / 100) *
    1.18
  ).toFixed(2);
  const printAreas = orderLineItem?.printAreas;
  const availablePrintArtwork = orderLineItem?.aopVectorFile
    ? printAreas
    : printAreas?.filter((p) => p?.artwork);

  const availableCanvasJSONPrintArtwork = orderLineItem?.aopVectorFile
    ? printAreas
    : printAreas?.filter((p) => p?.canvasJSON);

  const sku = getSKUFromProductId(products, orderLineItem?.ProductID);
  const printType =
    orderLineItem?.PrintType ||
    getPrintTypeFromProductId(products, orderLineItem?.ProductID);
  const isOrderLineSub = isSub(products, orderLineItem?.ProductID);
  const productName = orderLineItem?.Product;

  const vat = vatIncluded
    ? `(VALUE = €${salesValueInEuro} / QTY = ${orderLineItem.OliQuantity}${
        iossNumber ? ` / IOSS = ${iossNumber}` : ""
      })`
    : "";

  items += `<Item>
    <SKU>${sku.replace(regExpr, "")}</SKU>
    <Descrip>${
      orderLineItem.DtgPlus
        ? `[${printType}] ${vat} ${productName}`
        : orderLineItem.Plus
        ? `[TUFF PRINT TRANSFER][${orderLineItem?.Plus?.DtgPlusProcess?.substr(
            0,
            2
          )?.toUpperCase()}/${orderLineItem?.Plus?.DtgPlusProcessColor}/${
            orderLineItem?.dtgColorName?.SwatchCode
          }] ${vat} ${productName}`
        : `[${printType}] ${vat} ${productName}`
    }</Descrip>
    <Color>${orderLineItem.Color}</Color>
    <Qty>${orderLineItem.OliQuantity}</Qty>
    <Size>${orderLineItem.Size}</Size>
    <Type>${printType}</Type>
    <Style>${sku.split("-")[0]?.replace(regExpr, "")}</Style>
    <ProcessMode>Piece</ProcessMode>`;

  if (availableCanvasJSONPrintArtwork?.length > 0) {
    for (let j = 0; j < availableCanvasJSONPrintArtwork?.length; j++) {
      const row = availableCanvasJSONPrintArtwork[j];
      const { MockupSrc, printAreaName, canvasArtBoardImage } = row;

      items += `
        ${
          orderLineItem?.aopVectorFile
            ? `
            <Design>
              <Location>${printAreaName?.toUpperCase()}</Location>
              <Thumb>${orderLineItem.MarketingImagePath}</Thumb>
              <SourceFile>${orderLineItem.MarketingImagePath}</SourceFile>
              <Width>0</Width>
              <Height>0</Height>
              <LOffset>0</LOffset>
              <TOffset>0</TOffset>
              <WhiteUnderbaseRequired>false</WhiteUnderbaseRequired>
            </Design>`
            : `<Design>
            <Location>${printAreaName?.toUpperCase()}</Location>
            <Thumb>${MockupSrc}</Thumb>
            <SourceFile>${canvasArtBoardImage}</SourceFile>
            <Width>0</Width>
            <Height>0</Height>
            <LOffset>0</LOffset>
            <TOffset>0</TOffset>
            <WhiteUnderbaseRequired>false</WhiteUnderbaseRequired>
          </Design>`
        }`;
    }
  } else {
    for (let j = 0; j < availablePrintArtwork?.length; j++) {
      const row = availablePrintArtwork[j];

      const {
        designerSettings,
        coordinates = {},
        artwork,
        MockupSrc,
        printAreaName,
        customGraphicsdData,
      } = row;

      const { multiplier } = designerSettings || {};
      const { width, height, x = 0, y = 0 } = coordinates || {};

      const artWidth = parseFloat(width * multiplier);
      const artHeight = parseFloat(height * multiplier);
      const canvasCenterPoint = parseFloat(designerSettings?.width) / 2;
      const newX = parseFloat(x);
      const imageCenter = parseFloat(width) / 2;

      let xPos;

      if (newX > canvasCenterPoint) {
        xPos = canvasCenterPoint - parseFloat(width) + imageCenter;
      } else if (newX < canvasCenterPoint) {
        const newValue = newX + imageCenter + 1;
        xPos =
          newValue === canvasCenterPoint ? 0 : newValue - canvasCenterPoint;
        xPos = xPos === 1 ? 0 : xPos;
      }

      const yPos = parseInt(y);

      let artworkSplit = artwork?.split("/upload/");
      let newArtwork = "";
      if (artworkSplit) {
        newArtwork = `${artworkSplit[0]}/upload/x_${Math.round(
          x
        )},y_${Math.round(y)}/${artworkSplit[1]}?${moment().unix()}_front`;
      }

      items += `
        ${
          orderLineItem?.aopVectorFile
            ? `
            <Design>
              <Location>${printAreaName?.toUpperCase()}</Location>
              <Thumb>${orderLineItem.MarketingImagePath}</Thumb>
              <SourceFile>${orderLineItem.MarketingImagePath}</SourceFile>
              <Width>0</Width>
              <Height>0</Height>
              <LOffset>0</LOffset>
              <TOffset>0</TOffset>
              <WhiteUnderbaseRequired>false</WhiteUnderbaseRequired>
            </Design>`
            : `<Design>
            <Location>${printAreaName?.toUpperCase()}</Location>
            <Thumb>${MockupSrc}</Thumb>
            <SourceFile>${
              !!customGraphicsdData?.length
                ? customGraphicsdData[0]?.productionFile
                : isOrderLineSub
                ? newArtwork.replace(".jpg", ".png")
                : newArtwork.replace(".jpg", ".png")
            }</SourceFile>
            <Width>${Math.round(artWidth)}</Width>
            <Height>${Math.round(artHeight)}</Height>
            <LOffset>${Math.round(xPos * multiplier)}</LOffset>
            <TOffset>${Math.round(yPos * multiplier)}</TOffset>
            <WhiteUnderbaseRequired>false</WhiteUnderbaseRequired>
          </Design>`
        }`;
    }
  }
  items += "</Item>";

  return items;
};

export { json2xmlPrintAreas };
