import * as a from 'actions/errorModal';

const initial = {
  visibility: false,
};

const reducer = (state = initial, action = null) => {
  switch (action.type) {
    case a.SHOW_ERROR_MODAL:
      return {
        ...state,
        visibility: true,
      };
    case a.HIDE_ERROR_MODAL:
      return {
        ...state,
        visibility: false,
      };
    default:
      return state;
  }
};

export default reducer;
