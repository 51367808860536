import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 40px;
`;

const StyledAIImages = styled.div`
  text-align: center;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  margin: 20px 20px;
  padding: 5px;

  img {
    width: 100%;
  }
`;

const SubTitle = styled.h2`
  margin: 0;
  font-size: 34px;
`;

const Options = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const Option = styled.div`
  text-align: center;
  width: 280px;
`;

const OptionTitle = styled.div`
  border: 2px solid black;
  padding: 10px 26px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 28px;

  :hover {
    background-color: #000;
    color: #fff;
  }
`;

const OptionSubTitle = styled.div`
  font-size: 12px;
`;

const SplashContainer = styled.div`
  &.fade-in {
    opacity: 0;
    transition: opacity 0.5s;
  }

  &.fade-in.active {
    opacity: 1;
  }

  &.fade-out {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &.fade-out.active {
    opacity: 0;
  }
`;

export {
  Container,
  Title,
  StyledAIImages,
  SubTitle,
  Options,
  Option,
  OptionTitle,
  OptionSubTitle,
  SplashContainer,
};
