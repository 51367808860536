import Logo from "assets/images/shirtly-premium-logo.png";
import { StyledHeader } from "styles/pages/ai-mock-up-generator";

const HeaderLogo = () => (
  <StyledHeader>
    <img src={Logo} />
  </StyledHeader>
);

export default HeaderLogo;
