import USAFlag from 'assets/images/usa-flag.png';
import CADFlag from 'assets/images/canada-flag.png';
import EUFlag from 'assets/images/eu-flag.png';
import AUFlag from 'assets/images/aus-flag.jpeg';

const printProvidersData = [
  {
    ProviderName: 'CA',
    ProviderFlag: CADFlag,
    ShippingCosts: [
      {
        Id: 2217,
        ShippingDestination: 'US',
        PrimaryCost: 0,
        SecondaryCost: 0,
        Title: 'Tracked Packet - USA',
        ShippingTimeMinInDays: 0,
        ShippingTimeMaxInDays: 0,
      },
      {
        Id: 2357,
        ShippingDestination: 'CA',
        PrimaryCost: 0,
        SecondaryCost: 0,
        Title: 'Expedited Parcel',
        ShippingTimeMinInDays: 0,
        ShippingTimeMaxInDays: 0,
      },
      {
        Id: 2497,
        ShippingDestination: 'Intl',
        PrimaryCost: 0,
        SecondaryCost: 0,
        Title: 'Tracked Packet - International',
        ShippingTimeMinInDays: 0,
        ShippingTimeMaxInDays: 0,
      },
    ],
  },
  {
    ProviderName: 'US',
    ProviderFlag: USAFlag,
    ShippingCosts: [
      {
        Id: 2637,
        ShippingDestination: 'US',
        PrimaryCost: 0,
        SecondaryCost: 0,
        Title: 'USPS First Class Mail',
        ShippingTimeMinInDays: 0,
        ShippingTimeMaxInDays: 0,
      },
      {
        Id: 2637,
        ShippingDestination: 'CA',
        PrimaryCost: 0,
        SecondaryCost: 0,
        Title: 'USPS First Class Mail',
        ShippingTimeMinInDays: 0,
        ShippingTimeMaxInDays: 0,
      },
      {
        Id: 2637,
        ShippingDestination: 'Intl',
        PrimaryCost: 0,
        SecondaryCost: 0,
        Title: 'USPS First Class Mail',
        ShippingTimeMinInDays: 0,
        ShippingTimeMaxInDays: 0,
      },
    ],
  },
  {
    ProviderName: 'EU',
    ProviderFlag: EUFlag,
    ShippingCosts: [
      {
        Id: 2637,
        ShippingDestination: 'EU',
        PrimaryCost: 0,
        SecondaryCost: 0,
        Title: 'USPS First Class Mail',
        ShippingTimeMinInDays: 0,
        ShippingTimeMaxInDays: 0,
      },
      {
        Id: 2637,
        ShippingDestination: 'Intl',
        PrimaryCost: 0,
        SecondaryCost: 0,
        Title: 'USPS First Class Mail',
        ShippingTimeMinInDays: 0,
        ShippingTimeMaxInDays: 0,
      },
      {
        Id: 2637,
        ShippingDestination: 'US',
        PrimaryCost: 0,
        SecondaryCost: 0,
        Title: 'USPS First Class Mail',
        ShippingTimeMinInDays: 0,
        ShippingTimeMaxInDays: 0,
      },
      {
        Id: 2637,
        ShippingDestination: 'CA',
        PrimaryCost: 0,
        SecondaryCost: 0,
        Title: 'USPS First Class Mail',
        ShippingTimeMinInDays: 0,
        ShippingTimeMaxInDays: 0,
      },
    ],
  },
  {
    ProviderName: 'AU',
    ProviderFlag: AUFlag,
    ShippingCosts: [
      {
        Id: Math.floor(Math.random() * 98989898),
        ShippingDestination: 'AU',
        PrimaryCost: 9,
        SecondaryCost: 0.75,
        Title: 'Express Post',
        ShippingTimeMinInDays: 1,
        ShippingTimeMaxInDays: 7,
      },
      {
        Id: Math.floor(Math.random() * 989898933),
        ShippingDestination: 'NZ',
        PrimaryCost: 8,
        SecondaryCost: 0.75,
        Title: 'Air International',
        ShippingTimeMinInDays: 1,
        ShippingTimeMaxInDays: 7,
      },
      {
        Id: Math.floor(Math.random() * 9898934898),
        ShippingDestination: 'Intl',
        PrimaryCost: 11.25,
        SecondaryCost: 1.5,
        Title: 'Air International',
        ShippingTimeMinInDays: 1,
        ShippingTimeMaxInDays: 7,
      },
    ],
  },
  {
    ProviderName: 'NY',
    ProviderFlag: USAFlag,
    ShippingCosts: [
      {
        Id: Math.floor(Math.random() * 9898665489898),
        ShippingDestination: 'US',
        PrimaryCost: 4.36,
        SecondaryCost: 0.5,
        Title: 'USPS First Class',
        ShippingTimeMinInDays: 1,
        ShippingTimeMaxInDays: 7,
      },
      {
        Id: Math.floor(Math.random() * 935689898933),
        ShippingDestination: 'CA',
        PrimaryCost: 5.98,
        SecondaryCost: 0.5,
        Title: 'Asendia ePaq Plus',
        ShippingTimeMinInDays: 1,
        ShippingTimeMaxInDays: 7,
      },
      {
        Id: Math.floor(Math.random() * 989834545934898),
        ShippingDestination: 'Intl',
        PrimaryCost: 9.57,
        SecondaryCost: 0.5,
        Title: 'Asendia ePaq Plus',
        ShippingTimeMinInDays: 1,
        ShippingTimeMaxInDays: 7,
      },
    ],
  },
];

const duplicateProductInformationPayload = (product) => ({
  productData: {
    ...product?.Product,
    ID: null,
  },
});

export { printProvidersData, duplicateProductInformationPayload };
