import _ from "lodash";
import { useSelector } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import {
  CategoriesContainer,
  CategoryBox,
  IconWrapper,
} from "styles/pages/my-stores/products/productCreator";

const Categories = ({ categoryId, onChange }) => {
  const categories = useSelector((state) => state?.categories?.data);
  return (
    <CategoriesContainer>
      {categories?.map((c, index) => (
        <CategoryBox
          key={c?.CatId}
          onClick={() => {
            const category = _.find(categories, (o) => o?.CatId === c?.CatId);
            onChange({
              category,
              product: undefined,
              colors: [],
            });
          }}
        >
          <IconWrapper
            style={{
              background: categoryId === c?.CatId ? "#000" : "#F4F4F4",
            }}
          >
            {categoryId === c?.CatId ? <CheckIcon /> : null}
          </IconWrapper>

          <div
            style={{
              backgroundImage: `url(${c?.MarketingImagePath})`,
              width: "150px",
              height: "150px",
              backgroundSize: index === 0 ? "cover" : "80%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 20%",
            }}
          />

          {/* <img
            src={c?.MarketingImagePath}
            alt={c?.DisplayName}
            width="100"
            height="100"
          /> */}
          <p>{c?.DisplayName}</p>
        </CategoryBox>
      ))}
    </CategoriesContainer>
  );
};

export default Categories;
