import { Tooltip } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { Steps } from "pages/ai-mock-up-generator/context";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import getRandomColor from "utils/getRandomColor";
import CancelIcon from "@material-ui/icons/Cancel";
import { default as ImageComponent } from "components/Image";
import {
  Container,
  ProductName,
  ChangeProduct,
  ProductColor,
  ProductColorTitle,
  ProductColors,
} from "styles/components/ArtWork/product-tab";

import {
  ProductColorWrapper,
  GeneratedMockups,
} from "styles/pages/my-stores/products/productCreator";

const ProductTab = ({ state, setState }) => (
  <Container>
    <ProductName>
      <div>{state?.product?.Product?.Name}</div>
      <ChangeProduct
        title="Change Product"
        endIcon={<SyncAltIcon />}
        onClick={() => setState({ ...state, step: Steps.Products })}
      />
    </ProductName>

    <ProductColor>
      <ProductColorTitle>Product Colors</ProductColorTitle>
      <ProductColors>
        {state?.product?.Colors?.map((color, index) => (
          <Tooltip title={color?.Name} key={`Colors${color.ID}`}>
            <div
              style={{
                background: color?.HexCode,
              }}
              onClick={() => {
                setState((p) => ({
                  ...p,
                  artwork: {
                    ...p.artwork,
                    printAreas: p?.artwork?.printAreas?.map((p) => ({
                      ...p,
                      printAreaMarketingImage:
                        p?.printAreaName === "Front"
                          ? color?.Image
                          : color[`${p?.printAreaName}Image`],
                    })),
                  },
                  product: {
                    ...p.product,
                    Colors: state?.product?.Colors?.map((c, j) => ({
                      ...c,
                      selected: index === j,
                    })),
                  },
                }));
              }}
            >
              {color?.selected && (
                <DoneOutlineOutlinedIcon style={{ color: getRandomColor() }} />
              )}
            </div>
          </Tooltip>
        ))}
      </ProductColors>
    </ProductColor>

    <ProductColor>
      <ProductColorTitle>Selected Colors</ProductColorTitle>

      <ProductColorWrapper>
        <GeneratedMockups style={{ margin: 0 }}>
          {state?.colors?.map((color, index) => (
            <span className="cross">
              <ImageComponent
                src={color?.Image}
                imageStyle={{ width: "100px" }}
              />
              {state?.colors?.length > 1 && (
                <CancelIcon
                  className="download-icon"
                  style={{ top: 4, right: 4 }}
                  onClick={() => {
                    setState({
                      ...state,
                      colors: state?.colors?.filter((c, j) => index !== j),
                    });
                  }}
                />
              )}
            </span>
          ))}
        </GeneratedMockups>
      </ProductColorWrapper>
    </ProductColor>
  </Container>
);

export default ProductTab;
