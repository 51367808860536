import styled from "styled-components";

const Container = styled.div`
  background-color: #fff;

  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 8px;
  }
  .slick-prev,
  .slick-next {
    display: block !important;
    z-index: 999;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #455880;
    font-size: 30px;
  }
`;
const Wrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  padding: 30px 15px;

  h2.heading {
    font-family: "Inter800";
    color: #18191f;
    margin: 0;
    text-align: center;
    padding-bottom: 25px;
    text-transform: capitalize;
    @media (min-width: 1200px) {
      font-size: 36px;
    }
  }

  .cardSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    padding: 0 15px;
  }
  @media (min-width: 1200px) {
    padding: 64px 15px;
    h2.heading {
      padding-bottom: 48px;
      line-height: 54px;
    }
  }
`;

const CardWrapper = styled.div`
  padding: 38px 42px;
  border: 1px solid rgba(69, 88, 128, 0.2);
  border-radius: 4px;
  margin: 0 15px;
  > p {
    margin: 0;
    color: #455880;
    font-size: 18px;
    font-family: "Inter400";
    line-height: 32px;
  }
  .userDes {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    h4 {
      font-size: 22px;
      line-height: 27px;
      font-family: "Inter500";
      color: #1e266d;
      margin: 0;
    }
    span {
      font-size: 18px;
      line-height: 32px;
      font-family: "Inter400";
      color: #455880;
    }
  }
  .userImg {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #1494db;
    margin: 40px auto 22px;
    img {
      width: 100%;
    }
  }
`;
export { Container, Wrapper, CardWrapper };

// import styled from 'styled-components';
// import COLORS from 'shared/constants/colors';

// const Container = styled.div`
//   color: ${COLORS.BLACK};
//   padding: 30px 0;
// `;
// const Wrapper = styled.div`
//   margin-right: auto;
//   margin-left: auto;
//   max-width: 1200px;
//   width: 100%;
// `;

// const Heading = styled.h2`
//   text-transform: uppercase;
//   text-align: center;
//   margin: 0 0 56px;
//   font-size: 28px;
//   line-height: 42px;
//   @media (max-width: 1023.98px) {
//     margin: 0 auto 38px;
//     max-width: 70%;
//     font-size: 21px;
//     line-height: 28px;
//   }
// `;
// const Separator = styled.div`
//   display: block;
//   width: 70px;
//   height: 3px;
//   background: #000;
//   margin: 0 auto;
//   position: relative;
//   top: -32px;
//   @media (max-width: 1023.98px) {
//     top: -24px;
//   }
// `;

// const CardWrapper = styled.div`
//   &:focus {
//     outline: none;
//   }
// `;

// const Card = styled.div`
//   background: #fff;
//   display: grid;
//   padding: 15px;
//   border-radius: 4px;
//   min-height: 240px;
//   margin: 0 15px;
//   &:focus {
//     outline: 0;
//   }
//   div {
//     &:first-child {
//       display: flex;
//       align-items: center;
//       h3 {
//         text-transform: uppercase;
//         margin: 0;
//         font-size: 24px;
//         line-height: 36px;
//       }
//       h5 {
//         color: #000;
//         font-size: 18px;
//         margin-top: 0;
//         line-height: 27px;
//         margin-bottom: 20px;
//       }
//       @media (max-width: 1023.98px) {
//         h3 {
//           font-size: 18px;
//           line-height: 30px;
//         }
//         h5 {
//           font-size: 18px;
//           line-height: 24px;
//         }
//       }
//     }
//   }
//   p {
//     align-self: start;
//     margin: 0;
//     font-size: 16px;
//     line-height: 24px;
//     @media (max-width: 1023.98px) {
//       font-size: 12px;
//       line-height: 19px;
//     }
//   }
//   img {
//     align-self: center;
//     margin-right: 2em;
//     width: 100%;
//     max-width: 100px;
//     border-radius: 50%;
//   }
//   @media (min-width: 1024px) {
//     min-height: 300px;
//     border-radius: 4px;
//   }
// `;
// const Review = styled.div`
// .slick-prev {
//   left: -30px;
// }
// .slick-prev,
// .slick-next {
//   display: block !important;
//   z-index: 999;
// }
// .slick-prev:before,
// .slick-next:before {
//   color: #2280c1;
//   font-size: 30px;
// }
// `;

// export { Container, Wrapper, Heading, Separator, Review, CardWrapper, Card };
