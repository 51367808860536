import { post } from '@axios';
import { showLoader, hideLoader } from 'actions/loader';
import { requests } from 'shared/requests';
import { updateField } from 'actions/common';
import { SHOW_ERROR_MODAL } from 'actions/errorModal';

const INITIATE_STORES = 'INITIATE_STORES';
const SET_STORES = 'SET_STORES';
const SET_STORES_ERROR = 'SET_STORES_ERROR';

const wooAuth = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const response = await post({
        url: requests.wooAuth,
        data,
      });
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return { error };
    }
  };
};

const getStores = (emailid) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const stores = await post({
        url: requests.getStores,
        data: {
          user: emailid,
        },
      });

      dispatch(updateField(SET_STORES, { stores }));
    } catch (error) {
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(SET_STORES_ERROR, { error }));
    }
    dispatch(hideLoader());
  };
};

const getRandomUserStores = (emailid) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const stores = await post({
        url: requests.getStores,
        data: {
          user: emailid,
        },
      });

      return stores;
    } catch (error) {
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(SET_STORES_ERROR, { error }));
    }
    dispatch(hideLoader());
  };
};

const removeStore = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      await post({
        url: requests.removeStore,
        data,
      });
      await dispatch(getStores(data?.user));
    } catch (error) {
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(SET_STORES_ERROR, { error }));
    }
    dispatch(hideLoader());
  };
};

export {
  getStores,
  removeStore,
  SET_STORES,
  SET_STORES_ERROR,
  INITIATE_STORES,
  wooAuth,
  getRandomUserStores,
};
