import { useState } from 'react';
import TextField from 'components/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from 'components/Button';
import Checkbox from 'components/CheckBox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Container,
  LeftSection,
  RightSection,
  RadioSelection,
  Styles,
  Brands,
  Promos,
} from 'styles/pages/create-product/productNameStyle';

const ProductNameStyle = () => {
  const [value, setValue] = useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Container>
      <LeftSection>
        <TextField fullWidth size='small' label='Name' />
        <TextField fullWidth size='small' label='Display Name' />
        <RadioSelection>
          <p>Active</p>
          <RadioGroup
            row
            aria-label=''
            name=''
            value={value}
            onChange={handleChange}>
            <FormControlLabel
              value='yes'
              control={<Radio color='primary' />}
              label='Yes'
            />
            <FormControlLabel value='no' control={<Radio />} label='No' />
          </RadioGroup>
        </RadioSelection>
        <RadioSelection>
          <p>Affiliate Commission NOT Applicable</p>
          <RadioGroup
            row
            aria-label=''
            name=''
            value={value}
            onChange={handleChange}>
            <FormControlLabel
              value='yes'
              control={<Radio color='primary' />}
              label='Yes'
            />
            <FormControlLabel value='no' control={<Radio />} label='No' />
          </RadioGroup>
        </RadioSelection>
        <TextField fullWidth size='small' label='HS Code' />
        <Button variant='text' title='Save' />
      </LeftSection>
      <RightSection>
        <Styles>
          <p>Style</p>
          <Checkbox label='T-Shirt' />
          <Checkbox label='Tank Tops' />
          <Checkbox label='Raglans' />
          <Checkbox label='Long Sleeves' />
          <Checkbox label='Bodysuits' />
        </Styles>
        <Brands>
          <p>Brands</p>
          <Checkbox label='American Apparel' />
          <Checkbox label='Authentic TShirt Company' />
          <Checkbox label='Bella+Canvas' />
          <Checkbox label=' Code Five' />
          <Checkbox label='Fleece Factory' />
        </Brands>
        <Promos>
          <p>Promos</p>
          <Checkbox label='Popular' />
          <Checkbox label='New' />
        </Promos>
      </RightSection>
    </Container>
  );
};
export default ProductNameStyle;
