import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Image from 'components/Image';
import Button from 'components/Button';
import SearchBar from 'components/SearchBar';
import { SuccessIcon } from 'styles/pages/sync-product';
import {
  Container,
  ItemGroup,
  Item,
  ProductLink,
  ProductImage,
  Footer,
} from 'styles/pages/edit-products/productSubstitute';
import { SearchWrapper } from 'styles/pages/browse-catalog/details.js';

const ProductSubstitute = ({ editProductSubstituteInformation, product }) => {
  const products = useSelector((state) => state?.products?.data);
  const [search, setSearch] = useState(undefined);
  const [state, setState] = useState({
    substituteProducts: [],
    products: [],
  });

  const filterProductsByPrintProviders = () => {
    if (!products?.length) return [];

    const selectedProductPP = product?.PrintProviders;
    let newProducts = [];

    for (let i = 0; i < products.length; i++) {
      const p = products[i];
      const productPP = p?.PrintProviders;
      for (let j = 0; j < productPP.length; j++) {
        const exist = !!selectedProductPP?.filter(
          (q) => q?.ProviderName === productPP[j]?.ProviderName
        )?.length;
        if (exist) {
          newProducts.push(p);
          break;
        }
      }
    }
    return newProducts;
  };

  useEffect(() => {
    const filteredProducts = filterProductsByPrintProviders();

    setState({
      ...state,
      products: search
        ? filteredProducts?.filter(
            (p) =>
              _.includes(
                p?.Product?.DisplayName?.toLowerCase(),
                search.toLowerCase()
              ) && p?.Product?.ID !== product?.Product?.ID
          )
        : filteredProducts?.filter(
            (p) => p?.Product?.ID !== product?.Product?.ID
          ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (!state?.products?.length) {
      const filteredProducts = filterProductsByPrintProviders();

      setState({
        ...state,
        substituteProducts: product?.SubstituteProducts || [],
        products: filteredProducts?.filter(
          (p) => p?.Product?.ID !== product?.Product?.ID
        ),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Container>
      <SearchWrapper style={{ gridTemplateColumns: '1fr' }}>
        <SearchBar
          fetchAllOrders={() => setSearch(undefined)}
          searchById={(value) => setSearch(value || undefined)}
        />
      </SearchWrapper>

      <ItemGroup style={{ marginTop: 15 }}>
        {state?.products?.map((item, index) => {
          const productImage = item?.Product?.MarketingImagePath;

          return (
            <Item
              key={`productsCatalog${index}`}
              style={{
                border: !!state?.substituteProducts?.find(
                  (s) => s === item?.Product?.ID
                )
                  ? '1px solid green'
                  : 'inherit',
              }}
              onClick={() => {
                const isExist = state?.substituteProducts?.find(
                  (s) => s === item?.Product?.ID
                );

                if (!!isExist) {
                  setState({
                    ...state,
                    substituteProducts: state?.substituteProducts?.filter(
                      (k) => k !== item?.Product?.ID
                    ),
                  });
                } else if (
                  !state?.substituteProducts?.find(
                    (l) => l === item?.Product?.Id
                  )
                ) {
                  setState({
                    ...state,
                    substituteProducts: [
                      ...state?.substituteProducts,
                      item?.Product?.ID,
                    ],
                  });
                }
              }}
            >
              <ProductLink>
                <ProductImage>
                  <Image src={productImage} alt="product-water-mark" />
                </ProductImage>

                {!!state?.substituteProducts?.find(
                  (s) => s === item?.Product?.ID
                ) && <SuccessIcon />}

                <div
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Inter600',
                    fontSize: 16,
                  }}
                >
                  {item?.Product?.DisplayName}
                </div>
              </ProductLink>
            </Item>
          );
        })}
      </ItemGroup>

      <Footer>
        <Button
          title="Save"
          onClick={() => {
            const payload = {
              ID: product?.Product?.ID,
              substituteProducts: state?.substituteProducts,
            };

            editProductSubstituteInformation(payload);
          }}
        />
      </Footer>
    </Container>
  );
};

export default ProductSubstitute;
