import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Button from 'components/Button';
import Checkbox from 'components/CheckBox';
import TextField from 'components/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from 'components/Select';
import {
  ssaActiveWearUSProducts,
  ssActiveWearCAProducts,
} from 'actions/products';
import { printProvidersData } from 'pages/edit-products/data';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  Container,
  SelectCategories,
  Update,
  Wrapper,
  StockPartnerInput,
  SubHeading,
  PrintProvideFieldWrapper,
  PrintProviderWrapper,
} from 'styles/pages/edit-products/editProductInformation';

const options = ['S&S ACTIVE WEAR', 'ALPHA BRODER', 'SANMAR', 'NONE'];

const EditPrintProviderInformation = ({
  product = { Product: {} },
  updateProductPrintProviders,
}) => {
  const dispatch = useDispatch();
  const [printProviders, setPrintProviders] = useState(null);
  const [printProvider, setPrintProvider] = useState('CA');

  useEffect(() => {
    if (!product?.PrintProviders) {
      setPrintProviders(
        printProvidersData?.map((p) => ({ ...p, checked: true }))
      );
    } else {
      setPrintProviders(
        product?.PrintProviders?.map((p) => ({ ...p, checked: true }))
      );
    }
  }, [dispatch]);

  const disabled = () => {
    const checkedPrintProviders = printProviders?.filter((p) => p?.checked);

    if (!checkedPrintProviders || !checkedPrintProviders?.length) {
      return true;
    }

    for (let j = 0; j < checkedPrintProviders?.length; j++) {
      for (let k = 0; k < checkedPrintProviders[j].ShippingCosts?.length; k++) {
        const shippingCost = checkedPrintProviders[j].ShippingCosts[k];
        const {
          Title,
          PrimaryCost,
          SecondaryCost,
          ShippingTimeMinInDays,
          ShippingTimeMaxInDays,
        } = shippingCost;

        if (
          !Title ||
          PrimaryCost < 0 ||
          SecondaryCost < 0 ||
          ShippingTimeMaxInDays < 0 ||
          ShippingTimeMinInDays < 0
        ) {
          return true;
        }
      }
    }

    return false;
  };

  return (
    <Container>
      <PrintProviderWrapper>
        <SubHeading>Print Providers</SubHeading>
        <div className="add">
          <Select
            options={['CA', 'US', 'EU', 'AU', 'NY']}
            value={printProvider}
            onChange={(v) => setPrintProvider(v)}
          />
          <AddCircleOutlineIcon
            className="icon"
            onClick={() => {
              let pp = printProviders.find(
                (p) => p?.ProviderName === printProvider
              );

              if (!pp) {
                pp = printProvidersData.find(
                  (p) => p?.ProviderName === printProvider
                );
                // pp = {
                //   ...printProviders[0],
                //   ProviderName: printProvider,
                // };
              }

              setPrintProviders([...printProviders, pp]);
            }}
          />
        </div>
      </PrintProviderWrapper>

      <SelectCategories>
        <Grid container spacing={1}>
          {printProviders?.map((p, i) => {
            const providerName = p?.ProviderName;

            return (
              <Grid key={`ProviderName${i}`} item xs={12} sm={12} md={12}>
                <Checkbox
                  label={p?.ProviderName}
                  checked={p?.checked}
                  onChange={(v) =>
                    setPrintProviders(
                      printProviders?.map((o, j) =>
                        i === j ? { ...o, checked: v } : o
                      )
                    )
                  }
                />
                {p?.ShippingCosts?.map((s, j) => (
                  <Wrapper key={`ShippingCosts${j}`}>
                    <b style={{ margin: '10px 20px 20px 0' }}>
                      {s?.ShippingDestination}
                    </b>
                    <PrintProvideFieldWrapper>
                      <TextField
                        label="Title"
                        size="small"
                        value={s?.Title}
                        disabled={!p?.checked}
                        onChange={(value) => {
                          setPrintProviders(
                            printProviders?.map((p, index) => {
                              if (index !== i) {
                                return p;
                              }

                              return {
                                ...p,
                                ShippingCosts: p?.ShippingCosts.map((sc, k) => {
                                  if (k !== j) {
                                    return sc;
                                  }
                                  return {
                                    ...sc,
                                    Title: value,
                                  };
                                }),
                              };
                            })
                          );
                        }}
                      />
                      <TextField
                        label="Primary Cost"
                        size="small"
                        value={s?.PrimaryCost}
                        type="number"
                        disabled={!p?.checked}
                        onChange={(value) => {
                          setPrintProviders(
                            printProviders?.map((p, index) => {
                              if (index !== i) {
                                return p;
                              }

                              return {
                                ...p,
                                ShippingCosts: p?.ShippingCosts.map((sc, k) => {
                                  if (k !== j) {
                                    return sc;
                                  }
                                  return {
                                    ...sc,
                                    PrimaryCost: Number(value),
                                  };
                                }),
                              };
                            })
                          );
                        }}
                      />
                      <TextField
                        label="Secondary Cost"
                        size="small"
                        value={s?.SecondaryCost}
                        type="number"
                        disabled={!p?.checked}
                        onChange={(value) => {
                          setPrintProviders(
                            printProviders?.map((p, index) => {
                              if (index !== i) {
                                return p;
                              }

                              return {
                                ...p,
                                ShippingCosts: p?.ShippingCosts.map((sc, k) => {
                                  if (k !== j) {
                                    return sc;
                                  }
                                  return {
                                    ...sc,
                                    SecondaryCost: Number(value),
                                  };
                                }),
                              };
                            })
                          );
                        }}
                      />
                      <TextField
                        label="Ship Min Days"
                        size="small"
                        value={s?.ShippingTimeMinInDays}
                        type="number"
                        disabled={!p?.checked}
                        onChange={(value) => {
                          setPrintProviders(
                            printProviders?.map((p, index) => {
                              if (index !== i) {
                                return p;
                              }

                              return {
                                ...p,
                                ShippingCosts: p?.ShippingCosts.map((sc, k) => {
                                  if (k !== j) {
                                    return sc;
                                  }
                                  return {
                                    ...sc,
                                    ShippingTimeMinInDays: Number(value),
                                  };
                                }),
                              };
                            })
                          );
                        }}
                      />
                      <TextField
                        label="Ship Max Days"
                        size="small"
                        value={s?.ShippingTimeMaxInDays}
                        type="number"
                        disabled={!p?.checked}
                        onChange={(value) => {
                          setPrintProviders(
                            printProviders?.map((p, index) => {
                              if (index !== i) {
                                return p;
                              }

                              return {
                                ...p,
                                ShippingCosts: p?.ShippingCosts.map((sc, k) => {
                                  if (k !== j) {
                                    return sc;
                                  }
                                  return {
                                    ...sc,
                                    ShippingTimeMaxInDays: Number(value),
                                  };
                                }),
                              };
                            })
                          );
                        }}
                      />
                    </PrintProvideFieldWrapper>
                  </Wrapper>
                ))}

                <SubHeading>Stock Partner</SubHeading>
                <StockPartnerInput>
                  <Select
                    label="Select Supplier"
                    options={options}
                    disabled={!p?.checked}
                    value={p?.supplier}
                    onChange={async (supplier) => {
                      if (supplier === options[0]) {
                        const data =
                          p?.ProviderName === 'CA'
                            ? await dispatch(ssActiveWearCAProducts())
                            : await dispatch(ssaActiveWearUSProducts());

                        setPrintProviders(
                          printProviders?.map((p, index) => {
                            if (index !== i) {
                              return p;
                            }
                            return {
                              ...p,
                              ssInventoryList: data,
                              supplier,
                              productCode: '',
                            };
                          })
                        );
                      } else {
                        setPrintProviders(
                          printProviders?.map((p, index) => {
                            if (index !== i) {
                              return p;
                            }
                            return {
                              ...p,
                              ssInventoryList: undefined,
                              supplier,
                              productCode: '',
                            };
                          })
                        );
                      }
                    }}
                  />

                  {p?.supplier === options[0] && (
                    <Autocomplete
                      value={p?.selectedSSInventory}
                      options={p?.ssInventoryList || []}
                      getOptionLabel={(option) =>
                        `${option?.brandName}-${option?.styleName}`
                      }
                      onChange={(event, newValue) => {
                        setPrintProviders(
                          printProviders?.map((p, index) => {
                            if (index !== i) {
                              return p;
                            }
                            return {
                              ...p,
                              selectedSSInventory: newValue,
                              productCode: newValue?.styleID,
                            };
                          })
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Inventory Product"
                        />
                      )}
                    />
                  )}
                  {(p?.supplier === options[1] ||
                    p?.supplier === options[2]) && (
                    <TextField
                      label="Enter Product Code / Number"
                      value={p?.productCode}
                      onChange={(v) =>
                        setPrintProviders(
                          printProviders?.map((p, index) => {
                            if (index !== i) {
                              return p;
                            }
                            return {
                              ...p,
                              productCode: v?.toLowerCase(),
                              selectedSSInventory: undefined,
                            };
                          })
                        )
                      }
                    />
                  )}
                </StockPartnerInput>

                {providerName !== 'CA' && providerName !== 'US' && (
                  <>
                    <SubHeading>Shirtly Mark Up Percentage</SubHeading>
                    <StockPartnerInput>
                      <TextField
                        label="Company Name"
                        value={p?.companyName || ''}
                        onChange={(companyName) =>
                          setPrintProviders(
                            printProviders?.map((q, index) => {
                              if (index !== i) {
                                return q;
                              }
                              return {
                                ...q,
                                companyName,
                              };
                            })
                          )
                        }
                      />

                      <TextField
                        label="Mark Up Percentage"
                        type="number"
                        value={p?.markUpPercentage || 0}
                        onChange={(markUpPercentage) =>
                          setPrintProviders(
                            printProviders?.map((q, index) => {
                              if (index !== i) {
                                return q;
                              }
                              return {
                                ...q,
                                markUpPercentage: Number(markUpPercentage),
                              };
                            })
                          )
                        }
                      />
                    </StockPartnerInput>
                  </>
                )}
              </Grid>
            );
          })}
        </Grid>
      </SelectCategories>

      <Update>
        <Button
          title="Update"
          disabled={disabled()}
          onClick={() => {
            const selectedPP = printProviders?.filter((p) => p?.checked);
            const printProvidersData = selectedPP.map((p) =>
              _.omit(p, ['checked'])
            );

            const payload = {
              ID: product?.Product?.ID,
              printProvidersData: printProvidersData.map((p) => ({
                ...p,
                ssInventoryList: undefined,
                supplier: p?.supplier === options[3] ? '' : p?.supplier,
              })),
              // printProvidersData,
            };

            updateProductPrintProviders(payload);
          }}
        />
      </Update>
    </Container>
  );
};

export default EditPrintProviderInformation;
