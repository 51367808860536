import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import OrderTable from 'pages/orders/table';
import OrderFilters from 'pages/orders/OrderFilter';
import Pagination from 'components/Pagination';
import {
  getOrders,
  userSubmittedOrder,
  changeOrderStatus,
} from 'actions/orders';
import OrderModal from 'pages/warehouse/orderModal';
import AutomationPayment from 'pages/orders/AutomationPayment';

const UserOrderTable = ({ user }) => {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState(undefined);
  const [category, setCategory] = useState('PendingDetails');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(undefined);
  const prevCategory = useRef();

  useEffect(async () => {
    if (user) {
      const orders = await dispatch(
        getOrders({
          emailid: user?.User,
          state: category,
          skip: 0,
        })
      );
      if (!orders.error) {
        setOrders(orders);
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    const getOrdersData = async () => {
      let skip = currentPage;

      if (category !== prevCategory.current) {
        prevCategory.current = category;
        skip = 1;
      }

      const orders = await dispatch(
        getOrders({
          emailid: user?.User,
          state: category,
          skip: (skip - 1) * 10,
        })
      );
      if (!orders.error) {
        setOrders(orders);
      }
    };
    getOrdersData();
  }, [dispatch, category, currentPage]);

  return (
    <>
      <OrderFilters
        updateCategory={(v) => {
          setCategory(v);
          setCurrentPage(1);
        }}
        selected={category}
        orderCounts={orders?.orderCounts}
      />

      <AutomationPayment
        user={user}
        category={category}
        fetchAllOrders={async () => {
          const orders = await dispatch(
            getOrders({
              emailid: user?.User,
              state: 'PendingDetails',
              skip: 0,
            })
          );
          if (!orders.error) {
            setOrders(orders);
          }
          setCategory('PendingDetails');
        }}
      />

      <OrderTable
        orders={orders?.orders}
        category={category}
        changeOrderStatus={async (order) => {
          const payload = {
            user: order?.MasterUserEmail,
            orderId: order?.ID,
            status: 'InProduction',
          };
          await dispatch(changeOrderStatus(payload));
          setCategory('PendingDetails');
        }}
        onOrderSelect={async (order) => {
          if (order?.Status === 'InProduction' || order?.Status === 'Shipped') {
            const orderDetail = await dispatch(
              userSubmittedOrder({
                ReceiptID: order?.ReceiptID,
              })
            );

            if (orderDetail) {
              setSelectedOrder({
                ...orderDetail,
                ReceiptID: order?.ReceiptID,
                MasterUserEmail: order?.MasterUserEmail,
              });
            } else {
              setSelectedOrder({
                ShirtlyOrderData: order,
                ReceiptID: order?.ReceiptID,
                MasterUserEmail: order?.MasterUserEmail,
              });
            }
          }
        }}
      />

      <Pagination
        totalCount={
          category === 'PendingDetails'
            ? orders?.orderCounts?.PendingDetails
            : category === 'AwaitingPayment'
            ? orders?.orderCounts?.AwaitingPayment
            : category === 'InProduction'
            ? orders?.orderCounts?.InProduction
            : category === 'Shipped'
            ? orders?.orderCounts?.Shipped
            : orders?.orderCounts?.Cancelled
        }
        take={10}
        page={currentPage}
        onChange={(e, v) => setCurrentPage(v)}
      />

      <OrderModal
        order={selectedOrder}
        modal={!!selectedOrder}
        user={user?.User}
        setModal={() => setSelectedOrder(undefined)}
      />
    </>
  );
};

export default UserOrderTable;
