import Paper from "@material-ui/core/Paper";
import PersonIcon from "@material-ui/icons/Person";
import Pagination from "components/Pagination";
import SearchBar from "components/SearchBar";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import {
  SearchWrapper,
  UserEmail,
  SwitchWrapper,
  AdminUserContainer,
  SideBarHeader,
  SideBarBody,
  SideBarFooter,
  HeaderText,
  Impersonate,
  Filters,
} from "styles/pages/admin-users";

const UsersTable = ({
  users,
  take,
  currentPage,
  setCurrentPage,
  setSelectedUser,
  selectedUser,
  searchUser,
  fetchAllUser,
  updateUserStatus,
  loginToUser,
  getAllUsers,
  userAutomation,
  setUserAutomation,
}) => (
  <>
    <AdminUserContainer component={Paper}>
      <SideBarHeader>
        <HeaderText>
          <h2>
            Users <span>({users?.userCounts?.TotalUsers})</span>
          </h2>
        </HeaderText>
        <SearchWrapper>
          <SearchBar
            searchById={(v) => searchUser(v)}
            fetchAllOrders={fetchAllUser}
            searchOnEnter={true}
          />
        </SearchWrapper>
      </SideBarHeader>

      <Filters>
        <p onClick={getAllUsers}>All Users</p>
        <p onClick={() => setUserAutomation(!userAutomation)}>Auto Users</p>
      </Filters>

      <SideBarBody>
        {!!users?.users?.length ? (
          users?.users.map((user) => (
            <div key={user?.User}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  textAlign: "left",
                  borderRadius: "5px",
                  padding: "0 10px",
                  margin: "5px 0",
                  background:
                    selectedUser && user && selectedUser?.User === user?.User
                      ? "#000"
                      : "#fff",
                  color:
                    selectedUser && user && selectedUser?.User === user?.User
                      ? "#fff"
                      : "#18191F",
                }}
              >
                {user?.User ? (
                  <>
                    <UserEmail onClick={() => setSelectedUser(user)}>
                      <PersonIcon />
                      <div>
                        <p className="user-name">
                          {user?.UserData?.User?.FullName}
                        </p>
                        <p className="user-email">{user?.User}</p>
                      </div>
                    </UserEmail>
                    <SwitchWrapper>
                      <Tooltip
                        arrow={true}
                        title={user?.UserData?.Status}
                        placement="bottom"
                      >
                        <Switch
                          color="primary"
                          checked={
                            user?.UserData?.Status === "Active" ? true : false
                          }
                          onChange={(e, value) => {
                            const payload = {
                              user: user?.User,
                              status: value ? "Active" : "In-Active",
                            };
                            updateUserStatus(payload);
                          }}
                        />
                      </Tooltip>
                      <Impersonate onClick={() => loginToUser(user)}>
                        Impersonate
                      </Impersonate>
                    </SwitchWrapper>
                  </>
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <div>
            <div>
              <h3>No user found</h3>
            </div>
          </div>
        )}
      </SideBarBody>
      <SideBarFooter>
        {users?.userCounts?.TotalUsers > 1 && (
          <div style={{ margin: 10 }}>
            <Pagination
              totalCount={users?.userCounts?.TotalUsers}
              take={take}
              page={currentPage}
              showFirstButton={false}
              showLastButton={false}
              hidePrevButton={true}
              hideNextButton={true}
              onChange={(e, v) => setCurrentPage(v)}
            />
          </div>
        )}
      </SideBarFooter>
    </AdminUserContainer>
    {/* <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Wrapper>
                <Header>
                  <h2>Users ({users?.userCounts?.TotalUsers})</h2>
                  <SearchWrapper>
                    <SearchBar
                      searchById={(v) => searchUser(v)}
                      fetchAllOrders={fetchAllUser}
                    />
                  </SearchWrapper>
                  <EventNoteIcon
                    onClick={getAllUsers}
                    style={{
                      cursor: 'pointer',
                      marginLeft: 20,
                      color: 'green',
                    }}
                  />
                </Header>
              </Wrapper>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!users?.users?.length ? (
            users?.users.map((user) => (
              <TableRow key={user?.User}>
                <TableCell
                  component='th'
                  scope='row'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    background:
                      selectedUser && user && selectedUser?.User === user?.User
                        ? '#000'
                        : 'inherit',
                    color:
                      selectedUser && user && selectedUser?.User === user?.User
                        ? '#fff'
                        : '#888888',
                  }}>
                  {user?.User ? (
                    <>
                      <UserEmail onClick={() => setSelectedUser(user)}>
                        <PersonIcon />
                        <div>
                          <p>{user?.User}</p>
                          <p>{user?.UserData?.User?.FullName}</p>
                        </div>
                      </UserEmail>
                      <SwitchWrapper>
                        <Tooltip
                          arrow={true}
                          title={user?.UserData?.Status}
                          placement='bottom'>
                          <Switch
                            color='primary'
                            checked={
                              user?.UserData?.Status === 'Active' ? true : false
                            }
                            onChange={(e, value) => {
                              const payload = {
                                user: user?.User,
                                status: value ? 'Active' : 'In-Active',
                              };
                              updateUserStatus(payload);
                            }}
                          />
                        </Tooltip>
                        <div onClick={() => loginToUser(user)}>Impersonate</div>
                      </SwitchWrapper>
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>
                <h3>No user found</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {users?.userCounts?.TotalUsers > 1 && (
        <div style={{ margin: 10 }}>
          <Pagination
            totalCount={users?.userCounts?.TotalUsers}
            take={take}
            page={currentPage}
            showFirstButton={false}
            showLastButton={false}
            hidePrevButton={true}
            hideNextButton={true}
            onChange={(e, v) => setCurrentPage(v)}
          />
        </div>
      )}
    </TableContainer>
  */}
  </>
);

export default UsersTable;
