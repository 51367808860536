import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const LinearProgressWithLabel = (props) => {
  return (
    <Box>
      <Typography variant='body2' color='textSecondary'>
        {props?.title}
      </Typography>
      <Box display='flex' alignItems='center'>
        <Box width='100%' mr={1}>
          <LinearProgress variant='determinate' {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant='body2' color='textSecondary'>{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Modal = ({ setOpen, open, data }) => {
  const handleClose = () => setOpen(false);

  const progress =
    data?.currentOrder === data?.totalOrders
      ? 100
      : (data?.currentOrder * 100) / data?.totalOrders;

  const childProgress =
    data?.childCurrentOrder === data?.childTotalOrders
      ? 100
      : (data?.childCurrentOrder * 100) / data?.childTotalOrders;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='lg'
      disableBackdropClick>
      <DialogTitle>{data?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <LinearProgressWithLabel value={progress} />
          {!!data?.childTotalOrders && (
            <LinearProgressWithLabel
              value={childProgress}
              title={data?.childProgressTitle}
            />
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
