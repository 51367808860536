import { get, post } from '@axios';
import { showLoader, hideLoader } from 'actions/loader';
import { updateField } from 'actions/common';
import { requests } from 'shared/requests';
import { SHOW_ERROR_MODAL } from 'actions/errorModal';

const SET_INVENTORY = 'SET_INVENTORY';
const SET_INVENTORY_ERROR = 'SET_INVENTORY_ERROR';

const getInventoryData = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await get({ url: requests.getInventory });

      dispatch(updateField(SET_INVENTORY, { inventory: request }));
    } catch (error) {
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(SET_INVENTORY_ERROR, { error }));
    }
    dispatch(hideLoader());
  };
};

const technoInventoryCA = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.technoInventoryCA, data });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
    }
  };
};

const technoInventoryUSA = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({ url: requests.technoInventoryUSA, data });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
    }
  };
};

const technoInventoryCAPaymentProfile = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await get({
        url: requests.technoInventoryCApaymentProfile,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
    }
  };
};

const technoInventoryUSAPaymentProfile = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await get({
        url: requests.technoInventoryUSApaymentProfile,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
    }
  };
};

const technoInventoryCASubmitOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.technoInventoryCASubmitOrder,
        data: { orderData: data },
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
    }
  };
};

const technoInventoryUSASubmitOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.technoInventoryUSASubmitOrder,
        data: { orderData: data },
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
    }
  };
};

const saveSSActiveWearCAStockOrderData = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.saveSSActiveWearCAStockOrderData,
        data: { orderData: data[0] },
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
    }
  };
};

const saveSSActiveWearUSAStockOrderData = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.saveSSActiveWearUSAStockOrderData,
        data: { orderData: data[0] },
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
    }
  };
};

const getStockSubmittedOrders = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getStockSubmittedOrders,
        data: { supplier: data },
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
    }
  };
};

export {
  SET_INVENTORY,
  SET_INVENTORY_ERROR,
  getInventoryData,
  technoInventoryCA,
  technoInventoryUSA,
  technoInventoryCAPaymentProfile,
  technoInventoryUSAPaymentProfile,
  technoInventoryCASubmitOrder,
  technoInventoryUSASubmitOrder,
  saveSSActiveWearCAStockOrderData,
  saveSSActiveWearUSAStockOrderData,
  getStockSubmittedOrders,
};
