import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalWrapper from 'components/ModalWrapper';
import CancelIcon from '@material-ui/icons/Cancel';

const Modal = ({ open, setOpen, data }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalWrapper>
        <CancelIcon className='close-icon' onClick={handleClose} />
        <DialogTitle>Notification</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }}>
            {data?.notification?.image && (
              <img
                style={{ maxWidth: 200 }}
                src={data?.notification?.image}
                alt='notification'
              />
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: data?.notification?.description,
              }}
            />
          </DialogContentText>
        </DialogContent>
      </ModalWrapper>
    </Dialog>
  );
};

export default Modal;
