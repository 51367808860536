import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  padding: 20px;
  img {
    max-width: 500px;
    width: 100%;
    height: auto;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export { Container };
