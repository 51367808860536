import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import Modal from "components/NotificationsDrawer/NotificationDialog";
import { getAllNotifications } from "actions/notifications";
import CloseIcon from "assets/svg/CloseIcon";
import WarningImage from "assets/images/warning.png";
import {
  Title,
  NotificationList,
  NoNotifications,
} from "styles/components/NotificationsDrawer";

const NotificationDrawer = ({ open, toggle }) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState(null);

  const getNotificationsData = async () => {
    const data = await dispatch(getAllNotifications());
    setNotifications(() => data);
  };

  // useEffect(() => {
  //   getNotificationsData();
  // }, [dispatch]);

  return (
    <React.Fragment>
      <Drawer open={open} onClose={toggle} anchor="right">
        <Title>
          <img src={WarningImage} alt="WarningImage" />
          Shirtly Update
          <CloseIcon className="close-icon" onClick={toggle} />
        </Title>
        {notifications?.length ? (
          <NotificationList>
            {notifications?.map((n, i) => {
              const imageSplit = n?.notification?.image?.split("/upload/");
              return (
                <li
                  key={n?.id}
                  onClick={() => {
                    setNotification(n);
                    toggle();
                  }}
                >
                  {/* <span>{i + 1}</span> */}
                  {n?.notification?.image && (
                    <img
                      src={`${imageSplit[0]}/upload/b_auto:predominant,c_pad,h_150,w_313/${imageSplit[1]}`}
                      alt="notification"
                    />
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: n?.notification?.description,
                    }}
                  />
                </li>
              );
            })}
          </NotificationList>
        ) : (
          <NoNotifications>No Notifications yet!!</NoNotifications>
        )}
      </Drawer>
      <Modal
        open={!!notification}
        data={notification}
        setOpen={() => setNotification(null)}
      />
    </React.Fragment>
  );
};

export default NotificationDrawer;
