import React from "react";
import AdvanceTool from "components/ArtWork/AdvanceTool";
import { Container } from "styles/components/ArtWork";

const ArtWork = ({
  orderLine = {},
  onDrop,
  setCoordinates,
  onPreview,
  setOrderLine,
  onFabricDrop,
  onFabricRemove,
  product,
  plus,
  dtgPlusProcess,
  printType,
  colors,
  state,
  setState,
  handleNext,
  mocks,
  options,
  option,
  setOption,
}) => {
  return (
    <Container>
      <AdvanceTool
        options={options}
        option={option}
        setOption={setOption}
        printType={printType}
        orderLine={{ ...orderLine, aiGenerateImages: true }}
        onFabricDrop={onFabricDrop}
        setCoordinates={setCoordinates}
        onFabricRemove={onFabricRemove}
        onPreview={onPreview}
        setOrderLine={setOrderLine}
        product={product}
        plus={plus}
        dtgPlusProcess={dtgPlusProcess}
        onDrop={onDrop}
        isAiGeneratedMockUps={true}
        colors={colors}
        state={state}
        setState={setState}
        handleNext={handleNext}
        mocks={mocks}
      />
    </Container>
  );
};

export default ArtWork;
