import React from 'react';
import Switch from 'components/Switch';
import ImageSection from 'pages/account-settings/ImageSection';
import ErrorMessage from 'components/ErrorMessage';
import {
  Container,
  Form,
  TextSection,
  SwitchWrapper,
  Instructions,
  NoCardFound,
} from 'styles/pages/account-setting/passwordForm';

const AutomationForm = ({
  handleChange,
  automation,
  setAutomation,
  cards,
  syncedProducts,
  deleteUnSyncedOrders,
  handleDeleteUnSyncedOrdersChange,
}) => (
  <>
    <ErrorMessage className="warning">
      We highly recommend you to Sync all the products first before turning on
      Automation. Once automation is turned ON the system will start mapping the
      Order Line Items with Synced Products. This will remove all the Order Line
      Items from the order if that Line Item hasn't been synced.
    </ErrorMessage>

    <ErrorMessage className="error">
      Orders submitted by Automation can not be cancelled, modified or refunded.
      We are not responsible if the shipping address is in-correct or missing
      something.
    </ErrorMessage>

    <Container>
      <TextSection>
        <Form>
          {cards?.length > 0 && syncedProducts?.length > 0 ? (
            <SwitchWrapper>
              <span className="title">Automation</span>
              <Switch
                checked={automation}
                handleChange={() => {
                  setAutomation(() => !automation);
                  handleChange(!automation);
                }}
              />
            </SwitchWrapper>
          ) : (
            <NoCardFound>
              {!cards?.length
                ? 'No Card Found !'
                : !syncedProducts?.length
                ? 'Please sync at-least 1 product'
                : ''}
            </NoCardFound>
          )}
          <Instructions>
            <h2>There are {syncedProducts?.length || 0} synced products</h2>
            <h2>Instructions</h2>
            <ul>
              <li>
                Automation is only valid for <b>Shopify</b> Orders.
              </li>
              <li>
                If shipping address is missing from the order, order will be
                filtered out.
              </li>
              <li>
                Orders will be synced twice a day. Syncing time is 9:00 AM EST
                and 2:00 PM EST.
              </li>
              <li>
                If your Shop Product is not Synced that Product will be filtered
                out from the Order.
              </li>
              <li>
                Automation selects Print Provider bases on Shipping Address.
                <div>Shipping Provider</div>
                <table>
                  <thead>
                    <tr>
                      <th>Destination</th>
                      <th>Print Provider</th>
                      <th>Shipment Provider</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>United States</td>
                      <td>US Plant</td>
                      <td>USPS First Class Mail</td>
                    </tr>
                    <tr>
                      <td>Canada</td>
                      <td>CA Plant</td>
                      <td>Canada Post</td>
                    </tr>
                    <tr>
                      <td>International</td>
                      <td>US Plant</td>
                      <td>USPS First Class Mail</td>
                    </tr>
                  </tbody>
                </table>
              </li>

              <li>
                Automation selects Print Provider bases on Products.
                <table>
                  <thead>
                    <tr>
                      <th>Products</th>
                      <th>Print Provider</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>AK(Athletic Knit)</td>
                      <td>CA Plant</td>
                    </tr>
                    <tr>
                      <td>EGMONT Jacket</td>
                      <td>CA Plant</td>
                    </tr>
                  </tbody>
                </table>
              </li>

              <li>
                Please make sure you have Credit/Debit card saved in&nbsp;
                <b>Payment Options</b>
              </li>
            </ul>
          </Instructions>
          <Instructions>
            <h2>Rules</h2>
            <div className="delete-checkbox">
              <SwitchWrapper>
                <span className="title">Delete Un-Synced Orders</span>
                <Switch
                  checked={deleteUnSyncedOrders}
                  handleChange={() => {
                    handleDeleteUnSyncedOrdersChange(!deleteUnSyncedOrders);
                  }}
                />
              </SwitchWrapper>
              <p>
                Turning <b>ON</b> this rule will delete all the orders present
                in Pending Details as per those orders contains Line Items that
                are not synced. Automation will apply this rule after order
                syncing and pushing those orders to Awaiting Payment.
              </p>
            </div>
          </Instructions>
        </Form>
      </TextSection>
      <ImageSection />
    </Container>
  </>
);

export default AutomationForm;
