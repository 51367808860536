import * as a from 'actions/auth';

const initial = {
  authenticated: false,
  profile: {},
  error: null,
};

const auth = (state = initial, action = null) => {
  switch (action.type) {
    case a.AUTH_SUCCESS:
      return {
        ...state,
        authenticated: true,
        profile: {
          ...state.profile,
          ...action.data,
        },
        error: null,
      };

    case a.AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        profile: {},
        error: action.error,
      };

    case a.AUTH_LOGOUT:
      return initial;

    default:
      return state;
  }
};

export default auth;
