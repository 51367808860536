import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { showLoader, hideLoader } from "actions/loader";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Context from "../aiGeneratedImagesContext";
import {
  Container,
  FooterActions,
  RadiosWrapper,
  StyledImageWrapper,
  Note,
} from "styles/components/ArtWork/saveai-images-to-library";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const SaveAIImagesToLibrary = ({ reload }) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  const { state, setState } = Context();
  const [value, setValue] = React.useState("1");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  if (!state?.saveAIImagesToLibrary) return null;

  const handleClose = () =>
    setState({ ...state, saveAIImagesToLibrary: false });

  const save = async () => {
    const API_ENDPOINT =
      "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";
    const fileData = new FormData();
    fileData.append("file", state?.saveAIImagesToLibrary);
    fileData.append("upload_preset", "tnedst8q");
    fileData.append("folder", `Shirtly/${emailid}/AIArtFiles`);
    dispatch(showLoader());
    await fetch(API_ENDPOINT, {
      method: "post",
      body: fileData,
    });
    dispatch(hideLoader());

    handleClose();

    if (value === "1") {
      reload();
    }

    alert("Your selected image has been saved to AI Library.");
  };

  return (
    <Dialog onClose={handleClose} open>
      <Container>
        <DialogTitle onClose={handleClose}>Save to Library</DialogTitle>
        <MuiDialogContent dividers>
          <StyledImageWrapper>
            <img src={state?.saveAIImagesToLibrary} alt="" />
          </StyledImageWrapper>
          <RadiosWrapper>
            <FormControl component="fieldset">
              <RadioGroup name="gender1" value={value} onChange={handleChange}>
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label="Save to the library, I want to use this design for mockups"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio />}
                  label="Save to the library and continue generating more AI images."
                />
              </RadioGroup>
            </FormControl>
          </RadiosWrapper>
          {/* {value === "1" && (
            <Note>
              This will end the session and will clear the session history.
            </Note>
          )} */}
        </MuiDialogContent>
        <MuiDialogActions>
          <FooterActions>
            <Button variant="contained" onClick={save} color="primary">
              Save
            </Button>
          </FooterActions>
        </MuiDialogActions>
      </Container>
    </Dialog>
  );
};

export default SaveAIImagesToLibrary;
