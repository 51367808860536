import React from 'react';
import COLORS from 'shared/constants/colors';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { columns } from './columns';

const useStyles = makeStyles({
  th: {
    color: COLORS.BODY_TEXT,
    fontSize: '13px',
  },
});

const DescriptionTableHead = () => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            className={classes.th}
            key={column.id}
            align={column.align}
            style={{ minWidth: column.minWidth }}>
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default DescriptionTableHead;
