import React from 'react';
import Button from 'components/Button';
import Dialog from '@material-ui/core/Dialog';
import { ModalContainer, Footer } from 'styles/pages/my-stores/registration';

const RetrieveErrorDialog = ({ open, setModal, message }) => (
  <Dialog maxWidth='md' open={open} onClose={() => setModal(false)}>
    <ModalContainer>
      <h1>Retrieval Error</h1>
      <p>{message}</p>
      <p>
        <b>Please re-connect your store again.</b>
      </p>
      <Footer>
        <Button variant='text' title='Ok' onClick={() => setModal(false)} />
      </Footer>
    </ModalContainer>
  </Dialog>
);

export default RetrieveErrorDialog;
