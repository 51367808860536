import React from "react";
import Context from "./aiGeneratedImagesContext";
import PrevAIGeneratedImages from "components/ArtWork/AiDesignPhases/PrevAIGeneratedImages";
import InnovativeImg from "assets/images/innovative.jpeg";
import Logo from "assets/images/ai-design-studio-logo.png";
import {
  LeftWrapper,
  HeaderLogo,
  LeftHeader,
  ArchieveMessageWrapper,
  ArchieveMessageTitle,
  ArchieveMessages,
} from "styles/components/ArtWork/ai-generated-images";

const AIGeneratedImagesLeft = () => {
  const { state } = Context();

  return (
    <LeftWrapper>
      <LeftHeader>
        <HeaderLogo style={{ width: "200px" }} src={Logo} />
      </LeftHeader>

      <ArchieveMessageWrapper>
        <ArchieveMessageTitle>Session History</ArchieveMessageTitle>
        <ArchieveMessages>
          {state?.prevAiGeneratedImages?.length > 1 && (
            <PrevAIGeneratedImages state={state} />
          )}
        </ArchieveMessages>
      </ArchieveMessageWrapper>

      <LeftHeader>
        <HeaderLogo src={InnovativeImg} />
      </LeftHeader>
    </LeftWrapper>
  );
};

export default AIGeneratedImagesLeft;
