import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 458px;
`;

export const Row = styled.div`
  justify-content: center;
  margin: 20px 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const Colors = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Color = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 4px;
  box-shadow: 0 0 2px #1b1b1b;
  cursor: pointer;
`;
