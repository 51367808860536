import styled from "styled-components";
const Container = styled.div``;
const Wrapper = styled.div`
  max-width: 1140px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  margin: 0 auto;
  display: flex;
  h2 {
    font-family: "Inter800";
    margin: 0;
    padding: 10px 0;
  }
  .textSection {
    flex: 1;
  }
  .storeSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex: 1;
    p {
      position: relative;
      padding: 4px 0 4px 34px;
      margin: 20px;
      ::after {
        content: "";
        display: inline-block;
        width: 9px;
        height: 32px;
        background-color: #2ec5ce;
        position: absolute;
        left: -8px;
        top: 0;
      }
      ::before {
        content: "";
        display: inline-block;
        width: 9px;
        height: 32px;
        background-color: #000;
        position: absolute;
        left: -20px;
        top: 0;
      }
    }
  }
  @media (min-width: 1200px) {
    padding: 80px 15px;

    h2 {
      font-size: 36px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    .textSection {
      text-align: center;
    }
  }
  /* @media (max-width: 650px) {
    .storeSection {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  } */
`;
export { Container, Wrapper };
