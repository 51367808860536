import styled from 'styled-components';
const Container = styled.div`
  background-color: #fff;
`;
const Wrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  padding: 30px 15px;
  .textSection {
    h2 {
      font-family: 'Inter800';
      color: #18191f;
      line-height: 64px;
      margin: 0;
      text-align: center;
    }
    @media (min-width: 1200px) {
      h2 {
        font-size: 36px;
      }
    }
    @media (max-width: 768px) {
      h2 {
        margin: 0 auto;
      }
    }
  }
  .logoSection {
    padding: 1px 0;
    background: linear-gradient(
      90deg,
      rgba(192, 204, 218, 0.1) 0%,
      rgba(192, 204, 218, 0.6) 50.38%,
      rgba(192, 204, 218, 0.1) 100%
    );
    > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      padding: 28px 0;
      background: #fff;
      img,
      svg {
        margin: 10px 26px;
        width: 14%;
      }
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 0;
  }
`;
export { Container, Wrapper };
