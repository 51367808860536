const columns = [
  { id: 'Name', label: 'Name', minWidth: 90 },
  { id: 'Weight', label: 'Weight', minWidth: 100 },
  {
    id: 'WhitePrice',
    label: 'White Price	',
    minWidth: 100,
  },
  {
    id: 'ColorPrice',
    label: 'Color Price	',
    minWidth: 100,
  },
  {
    id: 'HeatherPrice',
    label: 'Heather Price	',
    minWidth: 100,
  },
  {
    id: 'ShirtLength',
    label: 'Shirt Length	',
    minWidth: 100,
  },
  {
    id: 'ChestWidth',
    label: 'Chest Width',
    minWidth: 100,
  },
  {
    id: 'HipsWidth',
    label: 'Hips Width',
    minWidth: 100,
  },
  {
    id: 'ShippingPackage',
    label: 'Shipping Package',
    minWidth: 100,
  },
];

export { columns };
