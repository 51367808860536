import styled from "styled-components";
const Container = styled.div``;
const Wrapper = styled.div`
  max-width: 1140px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 15px;
  margin: 0 auto;
  .imgSection {
    grid-area: 1 / 3 / 3 / 5;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .webHeaderText {
    grid-area: 1 / 1 / 1/ 3;
    h2 {
      font-family: 'Inter800';
      margin: 0;
      line-height: 64px;
      @media (min-width: 1200px){
      font-size: 36px;
      }
    }
    p {
      font-family: 'Inter400';
      margin: 0;
      line-height: 32px;
      font-size: 18px;
    }
    button {
      background-color: #000;
      padding: 12px 32px;
      border-radius: 6px;
      box-shadow: none;
      line-height: 24px;
      font-size: 16px;
      color: #fff;
      text-transform: initial;
      margin: 23px 0 70px 0;
    }
  }
  .webBoxWrapper {
    grid-area: 2 / 1 / 2 / 3;
    .box {
      .image-wrapper {
        width: 50px;
        height: 50px;
        border-radius: 10px;
      }
    }
  }
  @media (min-width: 1200px) {
    padding: 50px 15px;

  }
  @media (max-width: 833px) {
    .webHeaderText {
        grid-area: 1 / 1 / 1/ 5;
        text-align: center;
        button {
          margin: 23px 0;
        }
      }
      .webBoxWrapper {
        grid-area: 3 / 1 / 3 / 5;

      }
      .imgSection {
        grid-area: 2 / 1 / 2 / 5;
      }
    }
  }

  .textSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto auto;
    grid-column-gap: 20px;
    .box {
      position: relative;
      border-bottom: 1px solid rgba(69, 88, 128, 0.2);
      max-width: 390px;
      width: 100%;
      margin: 0 auto 30px auto;
      display: flex;
      align-items: flex-start;
      grid-gap: 30px;
      :last-child {
        border: none;
        margin-bottom: 0;
      }
      :first-child {
        margin-top: 30px;
      }
      svg {
        background: #4c504f;
        padding: 5px;
        min-width: 42px;
        height: 38px;
        border-radius: 4px;
        margin-right: 32px;
      }
      h4 {
        font-size: 22px;
        font-family: 'Inter600';
        padding-bottom: 16px;
        margin: 0;
      }
      p {
        font-size: 18px;
        margin-top: 0;
      }
    }
  }
  .text {
  }
  @media (min-width: 1200px) {
    h2 {
      font-size: 36px;
    }
    .textSection {
      .box {
        p {
          margin: 18px 0;
        }
        :last-child {
        border: none;
        margin-bottom: 30px;
      }
   
    }
  }
`;

export { Container, Wrapper };
