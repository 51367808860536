import React from "react";
import Loader from "assets/gif/ai-design-studio-loader.gif";
import Context from "../aiGeneratedImagesContext";
import {
  PhaseContainer,
  PhaseHeader,
  PhaseTitle,
  PhaseWrapper,
} from "styles/components/ArtWork/ai-design-phases";

import { Phase2Loading } from "styles/components/ArtWork/ai-generated-images";

const Phase2 = () => {
  const { state } = Context();

  return (
    <PhaseContainer>
      <PhaseHeader>
        <PhaseTitle>Design Phase 2</PhaseTitle>
      </PhaseHeader>

      <PhaseWrapper>
        <Phase2Loading>
          {state?.generatedImages?.length > 0
            ? "Please change the description to re-generate new images."
            : "Now watch your design come to life."}
          {!!state?.loading && (
            <span className="loading">
              <img src={Loader} />
            </span>
          )}
        </Phase2Loading>
      </PhaseWrapper>
    </PhaseContainer>
  );
};

export default Phase2;
