import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  display: grid;
  margin: 15px 0;
  grid-column-gap: 12px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  .pending-details {
    p {
      color: #000;
    }
  }
  .awaiting-payment {
    p {
      color: #57b894;
    }
  }
  .in-production {
    p {
      color: #ffd363;
    }
  }
  .shipped {
    p {
      color: #000;
    }
  }
  .cancelled {
    p {
      color: #d44f6a;
    }
  }
  > div {
    padding: 15px 26px;
    border-radius: 10px;
    transition: all ease-out 0.25s;
    background: ${COLORS.WHITE};
    border: 1px solid transparent;
    cursor: pointer;
    width: 100%;
    p {
      font-size: 16px;
      font-family: "Inter600";
      margin: 0;
    }
    :hover {
      border: 1px solid #ddd;
    }
    :last-child {
      margin: 0;
    }
    span {
      font-size: 26px;
      font-family: "Inter700";
      text-shadow: none;
      border-radius: 20px;
      color: #18191f;
      line-height: 27px;
    }
    &.selected {
      background: ${COLORS.BLUE};
      color: #ffffff !important;
      span {
        color: #ffffff !important;
      }
    }
  }
  @media (max-width: 550px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    > div {
      min-width: auto;
      padding: 15px;
      p {
        font-size: 14px;
      }
      span {
        font-size: 20px;
      }
    }
  }
`;

export { Container };
