import styled from "styled-components";

const Container = styled.div`
  margin: 60px;
  border: 1px solid black;
  background: white;
  padding: 80px 0;
  position: relative;
  display: grid;
  grid-template-columns: ${({ hideSideBar }) =>
    hideSideBar ? "auto" : "220px 1fr 220px"};
`;

const RightSection = styled.div`
  background-color: #fff;
  padding: 10px;
  overflow: auto;
`;

const StyledFooter = styled.div`
  position: absolute;
  bottom: -27px;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;

  img {
    border: 1px solid black;
    padding: 10px;
    width: 125px;
    background: #fff;
  }

  h3 {
    margin: 0;
  }
`;

const NextPhaseFooter = styled.div`
  margin-bottom: 100px;
  text-align: right;
  margin-top: 20px;
  button {
    background-color: #000;
    color: #fff;
    :hover {
      background-color: #000;
      color: #fff;
    }
  }
`;

const StyledHeader = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  top: -54px;

  img {
    width: 300px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
`;

export { Container, RightSection, StyledFooter, NextPhaseFooter, StyledHeader };
