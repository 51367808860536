import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
const CustomButton = ({
  title,
  startIcon,
  endIcon,
  variant,
  onClick,
  containerStyle = {},
  disabled,
  ...rest
}) => (
  <Button
    style={{ ...containerStyle, fontFamily: 'Inter500', boxShadow: 'none' }}
    disabled={disabled}
    startIcon={startIcon}
    endIcon={endIcon}
    variant={variant}
    onClick={(e) => _.isFunction(onClick) && onClick(e)}
    {...rest}
  >
    {title}
  </Button>
);

CustomButton.propTypes = {
  title: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  variant: PropTypes.oneOf(['contained', 'text', 'outlined']),
  onClick: PropTypes.func,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
};

CustomButton.defaultProps = {
  variant: 'contained',
  disabled: false,
};

export default CustomButton;
