import React from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { Container } from 'styles/components/RichTextEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const getHtml = (v) => draftToHtml(convertToRaw(v.getCurrentContent()));

export const initiateEditorState = (html) => {
  if (!html) {
    return EditorState.createEmpty();
  }

  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  return EditorState.createWithContent(contentState);
};

const RichTextEditor = ({ value, onChange, title }) => (
  <Container>
    <Editor
      editorState={value}
      onEditorStateChange={(v) => onChange(v)}
      placeholder={title}
    />
  </Container>
);

export default RichTextEditor;
