import { get } from '@axios';
import { showLoader, hideLoader } from 'actions/loader';
import { updateField } from 'actions/common';
import { requests } from 'shared/requests';
import { SHOW_ERROR_MODAL } from 'actions/errorModal';

const SET_COUNTRIES = 'SET_COUNTRIES';
const SET_COUNTRIES_ERROR = 'SET_COUNTRIES_ERROR';

const getCountries = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const countries = await get({ url: requests.getCountries });
      dispatch(updateField(SET_COUNTRIES, { countries }));
    } catch (error) {
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(SET_COUNTRIES_ERROR, { error }));
    }
    dispatch(hideLoader());
  };
};

export { SET_COUNTRIES, SET_COUNTRIES_ERROR, getCountries };
