import React, { createContext, useState, useContext, useMemo } from "react";

const fulfillmentPlants = ["CA", "US", "EU", "AU", "NY"];

const Context = createContext();

export const Steps = Object.freeze({
  Welcome: 0,
  Products: 1,
  AIDesginStudio: 2,
  MockUp: 3,
  Order: 4,
  BeginWithAiAssitant: 5,
  SelectAiAssistant: 6,
  MockLibrary: 7,
  Splash: 9,
});

export const Provider = ({ children }) => {
  const [state, setState] = useState({
    step: Steps.Splash,
    category: undefined,
    product: undefined,
    colors: [],
    plus: undefined,
    printTypeTechniqueSelected: true,
    selectedFulfillmentPant: fulfillmentPlants[0],
    artwork: {},
    aiActivated: false,
    selectedAiCharaterImage: null,
    securityLayer: "on",
  });

  const a = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );

  return <Context.Provider value={a}>{children}</Context.Provider>;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => useContext(Context);
