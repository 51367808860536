import styled from 'styled-components';

const statusButtonStyle = {
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'center',
  borderRadius: '4px',
  padding: '0.25em 1em',
  width: 'auto',
  minWidth: '175px',
  textAlign: 'center',
  display: 'inline-flex',
  flex: 0,
  boxShadow: '4px 4px 10px #d9d9d9, -4px -4px 10px #fff',
};

const Shipped = styled.div`
  ${statusButtonStyle}
  border: 1px solid #1ab394;
  background: #1ab394;
  color: white;
`;

const Cancelled = styled.div`
  ${statusButtonStyle}
  border: 1px solid red;
  background: transparent;
  color: red;
`;

const Pending = styled.div`
  ${statusButtonStyle}
  border: 1px solid #ffae18;
  background: transparent;
  color: #ffae18;
`;

const AwaitingPayment = styled.div`
  ${statusButtonStyle}
  border: 1px solid #ff6600;
  background: transparent;
  color: #ff6600;
`;

const InProduction = styled.div`
  ${statusButtonStyle}
  border: 1px solid #7c2aff;
  background: transparent;
  color: #7c2aff;
`;

export { Shipped, Cancelled, Pending, AwaitingPayment, InProduction };
