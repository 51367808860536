import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import OrderTableHead from "pages/orders/table/Head";
import Body from "pages/orders/table/Body";
import { TableWrapper } from "styles/pages/orders";

const OrderTable = ({
  orders = [],
  onOrderSelect,
  category,
  voidOrder,
  copyOrder,
  toggleOrderForPayment,
  changeOrderStatus,
  removeOrder,
}) => {
  return (
    <TableWrapper>
      <TableContainer className="container" component={Paper}>
        <Table>
          <OrderTableHead
            category={category}
            toggleOrderForPayment={toggleOrderForPayment}
          />
          <TableBody>
            <Body
              removeOrder={removeOrder}
              orders={orders}
              onOrderSelect={onOrderSelect}
              category={category}
              voidOrder={voidOrder}
              copyOrder={copyOrder}
              toggleOrderForPayment={toggleOrderForPayment}
              changeOrderStatus={changeOrderStatus}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default OrderTable;
