import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { editOrderCustomerInformation } from "actions/orders";
import TextField from "components/TextField";
import Select from "components/Select";
import { getCountries } from "actions/countries";
import Header from "pages/order-details/CustomerInformationHeader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "components/Switch";
import {
  Container,
  Wrapper,
  Panel,
  OrderInfo,
  FulfillmentDisclaimer,
  BodySection,
} from "styles/pages/order-details/customerInformation";

const CustomerInformation = ({
  orderDetail = {},
  refresh,
  showEdit = true,
}) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => setEdit(!edit);

  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const countries = useSelector((state) => state?.countries?.data);
  const states = _.find(
    countries,
    (o) => o.code2 === orderDetail?.CountryString
  )?.states;

  const [statesList, setStateList] = useState(undefined);

  const initialState = {
    BuyerName: orderDetail?.BuyerName,
    AddressLineOne: orderDetail?.AddressLineOne,
    AddressLineTwo: orderDetail?.AddressLineTwo,
    City: orderDetail?.City,
    StateProvince: orderDetail?.StateProvince,
    CountryString: orderDetail?.CountryString,
    ZipPostal: orderDetail?.ZipPostal,
    PrintProvider: orderDetail?.PrintProvider,
    isMilitaryAddress: orderDetail?.isMilitaryAddress,
    militaryAddress: orderDetail?.militaryAddress,
  };
  const [state, setState] = useState(undefined);

  useEffect(() => {
    const loadCountries = async () => {
      if (!countries?.length) {
        dispatch(getCountries());
      }
    };
    loadCountries();
  }, [dispatch, countries]);

  return (
    <>
      <Container>
        <Wrapper edit={edit}>
          {/* {orderDetail?.Status !== 'Shipped' &&
            orderDetail?.Status !== 'Cancelled' &&
            orderDetail?.Status !== 'InProduction' && ( */}
          <Header
            showEdit={
              orderDetail?.Status !== "Shipped" &&
              orderDetail?.Status !== "Cancelled" &&
              orderDetail?.Status !== "InProduction"
            }
            edit={edit}
            orderDetail={orderDetail}
            // showEdit={showEdit}
            onSave={async () => {
              const valid = !!(
                state?.BuyerName?.length &&
                state?.AddressLineOne?.length &&
                state?.City?.length &&
                state?.StateProvince?.length &&
                state?.CountryString?.length &&
                state?.ZipPostal?.length &&
                state?.PrintProvider?.length &&
                (state?.isMilitaryAddress ? state?.militaryAddress : true)
              );

              if (valid) {
                const payload = {
                  ...state,
                  militaryAddress: state?.isMilitaryAddress
                    ? state?.militaryAddress
                    : null,
                  user: emailid,
                  ID: orderDetail?.ID,
                  Status: orderDetail?.Status,
                  PrintProviderChanged:
                    state?.PrintProvider !== orderDetail?.PrintProvider,
                  OrderLineItems: orderDetail?.OrderLines[0]?.OrderLineItems,
                };

                const response = await dispatch(
                  editOrderCustomerInformation(payload)
                );

                if (response.error) {
                  return;
                }
                _.isFunction(toggleEdit) && toggleEdit();
                _.isFunction(refresh) && refresh();
              } else {
                alert("Please fill all the fields!");
              }
            }}
            onCancel={() => toggleEdit()}
            onEdit={() => {
              setState(initialState);
              toggleEdit();
            }}
          />
          {/* )} */}
          <Panel className={edit ? "edit" : ""}>
            <BodySection>
              <OrderInfo edit={edit}>
                <li>
                  <span>Sold To:</span>
                  <span>
                    <p>{orderDetail.BuyerName}</p>
                    <p>{orderDetail.BuyerEmail}</p>
                  </span>
                </li>

                <li>
                  <span>Fulfillment Plant:</span>
                  {edit ? (
                    <Select
                      label="Fulfillment Plant"
                      labelKey="name"
                      valueKey="value"
                      options={[
                        { value: "CA", name: "Canadian Fulfillment Plant" },
                        { value: "US", name: "USA Fulfillment Plant" },
                        {
                          value: "NY",
                          name: "NY Phone Cases Fulfillment Plant",
                        },
                        { value: "EU", name: "Europe Fulfillment Plant" },
                        { value: "AU", name: "Australia Fulfillment Plant" },
                      ]}
                      value={{ value: state.PrintProvider || "" }}
                      onChange={(PrintProvider) =>
                        setState({
                          ...state,
                          PrintProvider,
                        })
                      }
                    />
                  ) : (
                    <span>{orderDetail.PrintProvider}</span>
                  )}
                </li>
              </OrderInfo>
              <OrderInfo>
                <li>
                  <span>Ship To:</span>
                  {edit ? (
                    <TextField
                      fullWidth
                      label="Ship To"
                      size="small"
                      value={state.BuyerName}
                      onChange={(BuyerName) =>
                        setState({ ...state, BuyerName })
                      }
                    />
                  ) : (
                    <span>{orderDetail.BuyerName}</span>
                  )}
                </li>
                <li>
                  <span>Military Address:</span>
                  <Switch
                    disabled={!edit}
                    handleChange={(e) =>
                      setState({
                        ...state,
                        isMilitaryAddress: e.target.checked,
                      })
                    }
                    checked={
                      edit
                        ? state?.isMilitaryAddress
                        : orderDetail?.isMilitaryAddress
                    }
                  />

                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        style={{ padding: '0 5px' }}
                        disabled={!edit}
                        name='isMilitaryAddress'
                        checked={
                          edit
                            ? state?.isMilitaryAddress
                            : orderDetail?.isMilitaryAddress
                        }
                        onChange={(e) =>
                          setState({
                            ...state,
                            isMilitaryAddress: e.target.checked,
                          })
                        }
                      />
                    }
                    label=''
                  /> */}
                </li>
              </OrderInfo>
            </BodySection>
            <BodySection>
              <OrderInfo edit={edit}>
                <li>
                  <span>Address Line 1:</span>
                  {edit ? (
                    <TextField
                      fullWidth
                      size="small"
                      label="Address Line 1"
                      value={state.AddressLineOne}
                      onChange={(AddressLineOne) =>
                        setState({ ...state, AddressLineOne })
                      }
                    />
                  ) : (
                    <span>{orderDetail.AddressLineOne}</span>
                  )}
                </li>
                <li>
                  <span>Country:</span>
                  {edit ? (
                    <Select
                      label="Country"
                      options={countries}
                      labelKey="name"
                      valueKey="code2"
                      value={{ code2: state.CountryString || "" }}
                      onChange={(CountryString) => {
                        const states = _.find(
                          countries,
                          (o) => o.code2 === CountryString
                        )?.states;
                        setStateList(states);
                        setState({
                          ...state,
                          CountryString,
                          StateProvince: "",
                        });
                      }}
                    />
                  ) : (
                    <span>{orderDetail.CountryString}</span>
                  )}
                </li>
                <li>
                  <span>City:</span>
                  {edit ? (
                    <TextField
                      fullWidth
                      size="small"
                      label="City"
                      value={state.City}
                      onChange={(City) => setState({ ...state, City })}
                    />
                  ) : (
                    <span>{orderDetail.City}</span>
                  )}
                </li>
                {(state?.isMilitaryAddress ||
                  orderDetail?.isMilitaryAddress) && (
                  <li>
                    <span>For Military Address</span>
                    {edit ? (
                      <Select
                        label="For Military Address"
                        options={["APO", "FPO"]}
                        value={state?.militaryAddress}
                        onChange={(v) => {
                          setState({
                            ...state,
                            militaryAddress: v,
                          });
                        }}
                      />
                    ) : (
                      <span>{orderDetail.militaryAddress}</span>
                    )}
                  </li>
                )}
              </OrderInfo>
              <OrderInfo edit={edit}>
                <li>
                  <span>Address Line 2:</span>
                  {edit ? (
                    <TextField
                      fullWidth
                      size="small"
                      label="Address Line 2"
                      value={state.AddressLineTwo}
                      onChange={(AddressLineTwo) =>
                        setState({ ...state, AddressLineTwo })
                      }
                    />
                  ) : (
                    <span>{orderDetail.AddressLineTwo}</span>
                  )}
                </li>

                <li>
                  <span>State:</span>
                  {edit ? (
                    <Select
                      label="State/Province"
                      labelKey="name"
                      valueKey="code"
                      options={
                        state?.isMilitaryAddress
                          ? [].concat(
                              [
                                { name: "AE", code: "AE" },
                                { name: "AA", code: "AA" },
                                { name: "AP", code: "AP" },
                              ],
                              statesList || states
                            )
                          : statesList || states
                      }
                      value={{ code: state.StateProvince || "" }}
                      onChange={(StateProvince) =>
                        setState({
                          ...state,
                          StateProvince,
                        })
                      }
                    />
                  ) : (
                    <span>
                      {orderDetail.militaryAddress
                        ? `${orderDetail.militaryAddress} ${orderDetail.StateProvince}`
                        : orderDetail.StateProvince}
                    </span>
                  )}
                </li>
                <li>
                  <span>Zip/Postal Code:</span>
                  {edit ? (
                    <TextField
                      fullWidth
                      size="small"
                      value={state.ZipPostal}
                      label="Zip/Postal Code"
                      onChange={(ZipPostal) =>
                        setState({ ...state, ZipPostal })
                      }
                    />
                  ) : (
                    <span>{orderDetail.ZipPostal}</span>
                  )}
                </li>
              </OrderInfo>
            </BodySection>

            {edit && state?.PrintProvider !== orderDetail?.PrintProvider && (
              <FulfillmentDisclaimer>
                This change will effect your Order Line Items.
                <br />
                It will only get Synced product information saved according to
                the Print Providers.
              </FulfillmentDisclaimer>
            )}
          </Panel>
        </Wrapper>
      </Container>
    </>
  );
};
export default CustomerInformation;
