import { useState, useEffect } from "react";
import Context, { Steps } from "pages/ai-mock-up-generator/context";
import SplashImage from "assets/images/vizuail_spash.jpeg";
import { SplashContainer } from "styles/pages/ai-mock-up-generator/welcome";

const Splash = () => {
  const { setState, state } = Context();
  const [isSplah, setIsSplash] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsSplash(false);
      setTimeout(() => {
        setState({ ...state, step: Steps.Welcome });
      }, 500);
    }, 2500);
  }, []);

  return (
    <SplashContainer className={isSplah ? "fade-in active" : "fade-out active"}>
      <img src={SplashImage} alt="Splash" style={{ width: "100%" }} />
    </SplashContainer>
  );
};

export default Splash;
