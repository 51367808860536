import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={27}
      height={27}
      viewBox='0 0 27 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#prefix__clip0)' fill='#fff'>
        <path d='M11.819 16.03l4.39-2.53-4.39-2.53v5.06z' />
        <path d='M25.053 0H1.947A1.947 1.947 0 000 1.947v23.106C0 26.128.872 27 1.947 27h23.106A1.947 1.947 0 0027 25.053V1.947A1.947 1.947 0 0025.053 0zm-3.118 13.514s0 2.738-.347 4.058a2.114 2.114 0 01-1.487 1.487c-1.32.347-6.601.347-6.601.347s-5.267 0-6.601-.361a2.114 2.114 0 01-1.487-1.487c-.348-1.306-.348-4.058-.348-4.058s0-2.738.348-4.058a2.157 2.157 0 011.487-1.5c1.32-.348 6.601-.348 6.601-.348s5.28 0 6.601.361a2.114 2.114 0 011.487 1.487c.361 1.32.347 4.072.347 4.072z' />
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h27v27H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
