import styled from "styled-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";

const Container = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: 410px 1fr;
  grid-gap: 24px;
  @media (max-width: 790px) {
    grid-template-columns: 1fr;
  }
`;

const Form = styled.div`
  margin: 15px 0;
  display: grid;
  gap: 20px;
  margin-top: 0;
  margin-bottom: 25px;
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Submit = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
`;
const TextSection = styled.div`
  .add-payment {
    button {
      background-color: rgb(21, 148, 219);
      font-size: 16px;
      border-radius: 6px;
      padding: 12px;
      line-height: 1.5;
      color: rgb(255, 255, 255);
      font-family: "Inter500";
      width: 100%;
      text-transform: capitalize;
      box-shadow: none;
    }
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span.title {
    font-family: "Inter600";
    font-size: 14px;
  }
`;

const Instructions = styled.div`
  h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  ul {
    padding-left: 16px;
    border-top: 1px solid #cccccc;
    margin: 0;
    padding-top: 20px;
    font-family: "Inter500";
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 10px 0;
  }

  td,
  th {
    border: 1px solid #ccc;
    text-align: left;
    padding: 8px;
  }

  div.delete-checkbox {
    border-top: 1px solid #cccccc;
    padding-top: 20px;
    font-family: "Inter500";
    p {
      color: red;
    }
  }
`;

const CardInstructions = styled.p`
  font-family: "Inter600";
  font-size: 14px;
  margin: 0;
`;
const NoCardFound = styled.p`
  color: red;
  font-family: "Inter600";
  font-size: 14px;
  margin: 0 0 20px 0;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${(props) => (props?.selected ? "green" : "#ccc")};
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  margin-bottom: 10px;
  flex: 1;
  margin-top: 12px;
  background-color: white;
  position: relative;
`;

const DeleteCard = styled.div`
  margin-left: 10px;
  cursor: pointer;
  svg {
    color: red;
  }
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SuccessIcon = styled(CheckCircleIcon)`
  position: absolute;
  right: -8px;
  top: -8px;
  color: green;
  border-radius: 50%;
  background-color: white;
`;

const CardField = styled.div`
  > div {
    margin-bottom: 15px;
  }
`;

const Footer = styled.div`
  button {
    background-color: rgb(21, 148, 219);
    font-size: 16px;
    border-radius: 6px;
    padding: 12px;
    line-height: 1.5;
    color: rgb(255, 255, 255);
    font-family: "Inter500";
    width: 100%;
    text-transform: capitalize;
    box-shadow: none;
    :hover {
      background-color: rgb(21, 148, 219);
      color: rgb(255, 255, 255);
    }
  }
`;

const Cvv = styled.div`
  display: flex;
  grid-gap: 15px;
`;

const AddCardWrapper = styled.div`
  padding: 0 30px 30px 30px;
  position: relative;
  h2 {
    font-family: "Inter700";
    color: #000;
    text-align: center;
    margin: 20px 0;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const Title = styled.h2`
  text-decoration: underline;
`;

const ResaleCertificates = styled.div``;

const ResaleCertificate = styled.div`
  display: flex;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;

  h3 {
    margin: 0;
    flex: 1;
  }
`;

const Flask = styled.div`
  svg {
    color: orange;
  }
`;

export {
  Flask,
  Container,
  Form,
  Submit,
  TextSection,
  SwitchWrapper,
  Instructions,
  CardInstructions,
  NoCardFound,
  CardWrapper,
  DeleteCard,
  CardContainer,
  SuccessIcon,
  CardField,
  Footer,
  Cvv,
  AddCardWrapper,
  StyledCloseIcon,
  Title,
  ResaleCertificates,
  ResaleCertificate,
};
