export const DUMMY_PRODUCT = {
  Product: {
    ID: 7,
    Index: 0,
    Active: true,
    LowestPrice: 0,
    SKU: '',
    PrintType: '',
    Name: '',
    DisplayName: '',
    Icon: '',
    MockupPath: '',
    ArtworkSpecsPath: '',
    SizeChartPath: '',
    MarketingImagePath: '',
    MarketingBackImagePath: '',
    Description: '',
    CategoryIds: [],
    ProductTags: [],
    MarketingImageBase64: '',
    defaultMaximumFulfillDays: 7,
    defaultMinimumFulfillDays: 3,
    weight: 0.5,
    premiumProduct: false,
    screenPrinting: false,
  },
  Sizes: [],
  Colors: [],
  PrintProviders: [
    {
      ProviderName: 'CN',
      ProviderFlag: '',
      ShippingCosts: [
        {
          Id: 2217,
          ShippingDestination: 'US',
          PrimaryCost: 12,
          SecondaryCost: 5,
          Title: 'Standard',
          ShippingTimeMinInDays: 6,
          ShippingTimeMaxInDays: 9,
          carrierCode: 'chengda',
        },
        {
          Id: 2357,
          ShippingDestination: 'CA',
          PrimaryCost: 12,
          SecondaryCost: 5,
          Title: 'Standard',
          ShippingTimeMinInDays: 10,
          ShippingTimeMaxInDays: 14,
          carrierCode: 'chengda',
        },
        {
          Id: 2497,
          ShippingDestination: 'Intl',
          PrimaryCost: 20,
          SecondaryCost: 7,
          Title: 'Standard',
          ShippingTimeMinInDays: 14,
          ShippingTimeMaxInDays: 18,
          carrierCode: 'chukou1',
        },
      ],
      supplier: '',
      productCode: '',
      companyName: 'Huge POD',
      markUpPercentage: 15,
    },
  ],
  DesignerSettings: {
    platenWidthInch: 12,
    platenHeightInch: 16,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: 191,
    height: 255,
    multiplier: 16,
  },
  PrintAreas: [],
};
