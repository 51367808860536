import GeneratedMocks from "pages/my-stores/products/GeneratedMocks";

const MockLibrary = () => (
  <div>
    <h1
      style={{
        margin: 0,
        paddingBottom: "4px",
        borderBottom: "1px solid #000",
      }}
    >
      Mocks Library
    </h1>
    <GeneratedMocks />
  </div>
);

export default MockLibrary;
