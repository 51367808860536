import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModalWrapper from "components/ModalWrapper";

const AboutDialog = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalWrapper className="about-us">
        <DialogTitle>About Us</DialogTitle>
        <DialogContent>
          <DialogContentText>
            In late 2023, we embarked on our journey with a clear vision: to
            transform the promotional products industry. Fueled by innovation
            and a passion for creativity, we aimed to develop a unique toolset
            empowered by advanced AI capabilities. Our mission was
            straightforward—to convert creative ideas into unique, memorable
            designs that make a lasting impact.
            <br />
            <br />
            Harnessing the latest technological advancements, we strive to
            empower everyone to become a designer, achieving incredible results
            with ease. As we grow and evolve, our commitment to providing
            exceptional service and innovative solutions remains steadfast. We
            are enthusiastic about the future and the countless opportunities it
            offers for our clients and the industry. Together, we are redefining
            the promotional products market, turning dreams into reality, and
            making creativity accessible to everyone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </ModalWrapper>
    </Dialog>
  );
};

export default AboutDialog;
