import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 100;
  font-family: "Inter800";
  margin: 0;
  color: rgb(255, 255, 255);
`;

export const SubTitle = styled.h4`
  font-size: 12px;
  font-weight: normal;
  font-family: "Inter600";
  margin: 0;
  color: rgb(255, 255, 255);
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 2fr auto;
  gap: 10px;
  margin-top: 20px;

  .MuiFormLabel-root {
    color: #ffffffc7;
  }

  .MuiOutlinedInput-root,
  .MuiFormLabel-root.Mui-focused {
    border-radius: 6px;
    color: #fff;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
    border-width: 1px;
  }
`;

export const Body = styled.div`
  margin-top: 20px;
`;

export const Actions = styled.div`
  display: none;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  right: 0;
  left: 0;
  gap: 10px;
  bottom: 0;
  align-items: center;
`;

export const Content = styled.div`
  position: relative;
  :hover {
    ${Actions} {
      display: flex;
    }
  }
`;

export const Logo = styled.img`
  width: 100%;
  margin: 0 auto;
  display: block;
`;
