import styled from "styled-components";

export const Container = styled.div``;

export const Options = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

export const Option = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #000;
  cursor: pointer;

  width: 85px;
  background-color: ${({ selected }) => (selected ? "#3a3a3c" : "#fff")};
  color: ${({ selected }) => (selected ? "#fff" : "#3a3a3c")};
  padding: 6px 0;

  :hover {
    background-color: #000;
    color: #fff;
  }

  h5 {
    margin: 0;
    font-size: 12px;
    font-family: "Inter600";
  }

  svg {
    font-size: 40px;
  }
`;

export const Body = styled.div`
  display: flex;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
`;
