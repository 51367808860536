import { Button } from "@material-ui/core";
import {
  Container,
  StyledButton,
} from "styles/pages/customizer-tool/model-with-garments";

const ModelWithGarments = ({ garmentPart, setGarmentPart }) => {
  const isLeftPocketSelected = garmentPart === "left-pocket";
  const isRightSleeveSelected = garmentPart === "right-sleeve";
  const isBackSelected = garmentPart === "back";

  return (
    <Container>
      <div id="model"></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <StyledButton
          onClick={() => setGarmentPart("left-pocket")}
          variant="outlined"
          selected={isLeftPocketSelected}
        >
          Left Pocket
        </StyledButton>

        <StyledButton
          onClick={() => setGarmentPart("right-sleeve")}
          variant="outlined"
          selected={isRightSleeveSelected}
        >
          Right Sleeve
        </StyledButton>

        <StyledButton
          onClick={() => setGarmentPart("back")}
          variant="outlined"
          selected={isBackSelected}
        >
          Back
        </StyledButton>
      </div>
    </Container>
  );
};

export default ModelWithGarments;
