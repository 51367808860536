import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
`;

const DashboardWebPageWrapper = styled.div`
  padding: 0 20px;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  padding: 0px 15px;
  display: flex;
  gap: 20px;

  @media (max-width: 650px) {
    flex-direction: column;
  }

  h2 {
    display: none;
  }
`;

const NotificationsWrapper = styled.div`
  width: 300px;
  margin-top: 20px;
`;

export { Container, DashboardWebPageWrapper, NotificationsWrapper };
