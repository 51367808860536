import React from 'react';
import ErrorMessage from 'components/ErrorMessage';

const ArtWorkErrorMessages = ({ orderLine = {} }) => (
  <>
    {orderLine.PrintType === 'DTG' && (
      <ErrorMessage>
        Note: Always centre your artwork design in the design box and not to the
        design box placement in the mockup image. The top of the design box will
        always be set up 1-2'' down form the neckline of the garment selected
        when actually produced. The design box will always be centered to the
        garment when being produced.
      </ErrorMessage>
    )}
    {orderLine.PrintType === 'DURASOFT' && (
      <ErrorMessage>
        Note: Some mock up images may not appear centered on the design board.
        You can size the image down to have it centered for creating your store
        mock-ups. When you place an order ALWAYS center and size (to your
        desired size) to the design board, even if it looks off center to the
        mock-up background image. Production will ensure it is centered when
        actually printed.
      </ErrorMessage>
    )}
  </>
);

export default ArtWorkErrorMessages;
