import * as a from 'actions/tags';

const initial = {
  data: [],
  error: null,
};

const tags = (state = initial, action = null) => {
  switch (action.type) {
    case a.SET_TAGS:
      return {
        ...state,
        data: action.tags,
        error: null,
      };
    case a.SET_TAGS_ERROR:
      return {
        ...state,
        error: action.error,
        data: null,
      };
    default:
      return state;
  }
};

export default tags;
