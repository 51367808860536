import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  padding-bottom: 15px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid #eee;
  align-items: center;
  .add-circle-icon {
    color: #000;
  }
  > h3 {
    margin-top: 0;
    font-weight: 600;
    padding-bottom: 13px;
    font-size: 14px;
    margin-bottom: 0;
    color: ${COLORS.BODY_TEXT};
  }
  > button {
    /* padding: 0; */
    color: ${COLORS.BLUE};
  }
`;

const AddedProduct = styled.div`
  flex-direction: row;
  margin: 15px 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 10px;
  .delete-icon {
    color: #ff0000;
  }
  /* > div {
    padding-right: 20px;
  } */
  /* @media (max-width: 1057px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 652px) {
    grid-template-columns: repeat(2, 1fr);
    > div {
      padding-right: 10px;
    }
  }
  @media (max-width: 478px) {
    grid-template-columns: repeat(2, 1fr);
  } */
`;

const Update = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  > button {
    background-color: #000;
    font-size: 16px;
    border-radius: 6px;
    padding: 12px;
    line-height: 1.5;
    color: #ffffff;
    text-transform: capitalize;
    :hover {
      background-color: ${COLORS.BLUE};
      color: ${COLORS.WHITE};
    }
  }
`;
const SubHeading = styled.div`
  font-size: 16px;
  color: #000;
  font-family: "Inter600";
  margin: 20px 0;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 870px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const EUFieldWrapper = styled.div`
  display: grid;
  gap: 20px;
`;

export {
  Heading,
  AddedProduct,
  Container,
  Update,
  SubHeading,
  FieldWrapper,
  EUFieldWrapper,
};
