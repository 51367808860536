import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  padding-bottom: 15px;
`;

const CloudWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
  width: 100%;
  position: relative;
  > div {
    width: 100%;
  }
  input {
    padding-right: 97px;
  }
  .upload-icon {
    cursor: pointer;
    color: #ffd363;
    position: absolute;
    right: 9px;
  }
  .logo {
    position: absolute;
    right: 47px;
    width: 34px;
    height: auto;
    max-height: 34px;
  }
  .download-icon {
    cursor: pointer;
    color: #ffd363;
    position: absolute;
    right: 9px;
  }
`;

const ProductInformation = styled.div`
  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 10px;
  }
  .slick-prev,
  .slick-next {
    display: block !important;
    z-index: 999;
  }
  .slick-next:before,
  .slick-prev:before {
    color: #000;
    opacity: 1;
    font-size: 30px;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.5;
  }
  h4 {
    font-size: 20px;
    margin-top: 0;
  }

  > h3 {
    margin-top: 0;
    font-weight: 600;
    padding-bottom: 13px;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    color: ${COLORS.BODY_TEXT};
    padding-top: 15px;
    margin-bottom: 30px;
  }
  .MuiFormControl-root {
    width: 100%;
  }
`;

const SelectCategories = styled.div`
  > h3 {
    margin-top: 0;
    font-weight: 600;
    padding-bottom: 13px;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    color: ${COLORS.BODY_TEXT};
  }
`;

const Update = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  > button {
    background-color: ${COLORS.BLUE};
    font-size: 16px;
    border-radius: 6px;
    padding: 12px;
    line-height: 1.5;
    color: ${COLORS.WHITE};
    text-transform: capitalize;
    :hover {
      background-color: ${COLORS.BLUE};
      color: ${COLORS.WHITE};
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  > div {
    flex: 1;
    min-width: 200px;
    margin-bottom: 30px;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: auto;
  margin: 0 auto;
  width: 565px;
  height: 565px;
  > img {
    width: 565px;
    height: 565px;
  }
`;
const ArtDiv = styled.div`
  position: absolute;
  transition: all 0.25s ease-out 0s;
  border: 1px dashed #000;
  margin: 0 auto;
`;

const StockPartner = styled.h3`
  font-weight: 600;
  padding-bottom: 5px;
  margin-bottom: 0px !important;
  font-size: 14px;
  border-bottom: none !important;
  color: ${COLORS.BODY_TEXT};
`;

const StockPartnerInput = styled.div`
  margin-bottom: 20px;
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  .MuiOutlinedInput-root {
    height: 100%;
  }
  @media (max-width: 621px) {
    grid-template-columns: 1fr;
  }
`;

const ThreeBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 30px;
  width: 100%;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`;
const SubHeading = styled.h2`
  font-size: 16px;
  color: #000;
  font-family: "Inter600";
  margin: 20px 0;
`;

const SubHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin: 0;
  }
`;

const FieldWrapper = styled.div`
  display: grid;
  margin: 30px 0px 0px;
  gap: 20px;
  grid-template-columns: 1fr 1fr 27px;
  align-items: center;
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
`;
const PrintProvideFieldWrapper = styled.div`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  display: grid;
  grid-gap: 30px;
  @media (max-width: 945px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 621px) {
    grid-template-columns: 1fr;
  }
`;

const EditColorFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 23px 1fr 23px;
  grid-gap: 10px;
  align-items: center;
`;
const EditColorInnerField = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
  align-items: center;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const FourBoxIconWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
  .MuiFormGroup-root {
    min-width: 155px;
  }
`;
const EditDtgPlusInnerField = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  flex: 1;
  @media (max-width: 806px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const PrintProviderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .add {
    display: flex;
    align-items: center;
    grid-gap: 20px;

    svg.icon {
      color: #000;
      cursor: pointer;
    }
  }
`;

export {
  Container,
  ProductInformation,
  SelectCategories,
  Update,
  CloudWrapper,
  Wrapper,
  ImageContainer,
  ArtDiv,
  StockPartner,
  StockPartnerInput,
  ThreeBoxWrapper,
  SubHeading,
  SubHeadingWrapper,
  FieldWrapper,
  PrintProvideFieldWrapper,
  EditColorFieldWrapper,
  EditColorInnerField,
  FourBoxIconWrapper,
  EditDtgPlusInnerField,
  PrintProviderWrapper,
};
