import Logo from "assets/images/algoraic.jpeg";
import { StyledFooter } from "styles/pages/ai-mock-up-generator";

const FooterLogo = () => (
  <StyledFooter>
    <h3>Powered by</h3>
    <img src={Logo} />
  </StyledFooter>
);

export default FooterLogo;
