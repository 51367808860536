import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import Button from "components/Button";
import SearchBar from "components/SearchBar";
import COLORS from "shared/constants/colors";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Chip from "@material-ui/core/Chip";
import {
  getDesigns,
  getPreDesigns,
  searchDesigns,
  getBackgroundBurnDesigns,
  getPlusArtFiles,
} from "actions/designer";
import BrowseLibraryTabs from "components/ArtWork/BrowseLibraryTabs";
import ShirtlyPreDesignedDisclaimer from "components/ArtWork/ShirtlyPreDesignedDisclaimer";
import ProcedureDialog from "components/ArtWork/ProcedureDialog";
import {
  Container,
  Header,
  CloseIconStyle,
  SearchWrapper,
  ChipsWrapper,
  ChipsTitle,
  Chips,
  chipStyle,
} from "styles/components/ArtWork/browseLibrary";
import ErrorMessage from "components/ErrorMessage";

const options = [
  "4th of July",
  "Aprons",
  "Autism",
  "Baby",
  "Baseball",
  "Beer",
  "Breast Cancer",
  "Camping",
  "Canada Day",
  "Christmas",
  "Dog Shirts",
  "Easter",
  "Family Fun",
  "Fathers Day",
  "Mothers Day",
  "Football",
  "Gay Pride",
  "Costume",
  "Fun",
  "Pregnancy Halloween",
  "Hanukkah",
  "I am a Proud",
  "Matching Sets",
  "Maternity",
  "New Random",
  "Pregnancy",
  "St Patricks",
  "Thanksgiving",
  "This Guy",
  "Tote Bags",
  "Valentines",
  "Variation",
  "Wedding",
  "What Makes Me Happy",
  "Whats Your Super Power",
  "Workout",
  "World Cup",
  "Aged and Fabulous",
  "Awesome Since",
  "Born in aging finner than wine",
  "Born To Care",
  "Born To Ride",
  "Cheers and Beers",
  "Classic Gamer",
  "Damn I make look so good",
  "Dont be Jealous",
  "Dont be Jealous I look so good",
  "Family",
  "Growing Old is Mandatory",
  "Grump Old Football Fan",
  "Grumpy Old Man Club",
  "Hello Birthday",
  "Hunting and Fishing Legend",
];

const BrowseLibraryDrawer = ({
  open,
  toggle,
  onDrop,
  email,
  viewOnly = false,
  showAdvanceToolTab = false,
  printType,
  activeTabIndex,
}) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(undefined);
  const [libraryFiles, setLibraryFiles] = useState(undefined);
  const [bbLibraryFiles, setBBLibraryFiles] = useState(undefined);
  const [plusLibraryFiles, setPlusLibraryFiles] = useState(undefined);
  const [advancedLibraryFiles, setAdvancedLibraryFiles] = useState(undefined);
  const [advancedDesigns, setAdvancedDesigns] = useState(undefined);
  const [activeTab, setActiveTab] = useState(activeTabIndex || 0);
  const [disclaimerModal, setDisclaimerModal] = useState(false);
  const [procedureModal, setProcedureModal] = useState(false);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  const populateLibraryData = (library) => {
    for (let i = 0; i < library?.resources?.length; i++) {
      const l = library?.resources[i];
      const secureUrl = l.secure_url;
      const splitSecureUrl = secureUrl.split("/");
      if (!!splitSecureUrl?.length) {
        l.name = splitSecureUrl[splitSecureUrl?.length - 1];
      }
    }
  };

  useEffect(() => {
    const loadLibrary = async () => {
      if (!libraryFiles) {
        const library = await dispatch(
          getDesigns({
            user: email || emailid,
          })
        );
        if (library.error) {
          return;
        }
        populateLibraryData(library);
        setLibraryFiles({
          ...library,
          cursors: library?.next_cursor
            ? [undefined, library?.next_cursor]
            : [],
        });
      }
    };
    loadLibrary();
  }, [dispatch, libraryFiles, setLibraryFiles, emailid, email]);

  const searchArtFilesByText = async (
    searchString,
    clearSearchValue = false
  ) => {
    const library = await dispatch(
      searchDesigns({
        user: emailid,
        searchString,
        folder:
          activeTab === 0
            ? "ArtFiles"
            : activeTab === 1
            ? "BackgroundBurnFiles"
            : activeTab === 2
            ? "PlusArtFiles"
            : "AdvanceArtFiles",
      })
    );

    if (library.error) {
      return;
    }

    populateLibraryData(library);

    const payload = {
      ...library,
      cursors: library?.next_cursor ? [undefined, library?.next_cursor] : [],
    };

    if (activeTab === 0) {
      setLibraryFiles(payload);
    } else if (activeTab === 1) {
      setBBLibraryFiles(payload);
    } else if (activeTab === 2) {
      setPlusLibraryFiles(payload);
    } else if (activeTab === 3) {
      setAdvancedLibraryFiles(payload);
    }

    if (clearSearchValue) setSearch(searchString);
  };

  return (
    <Drawer anchor="left" open={open} onClose={toggle}>
      <Container>
        <Header>
          <h2>Design Library</h2>
          <CloseIconStyle onClick={toggle}></CloseIconStyle>
        </Header>
        {!viewOnly ? (
          <SearchWrapper>
            <SearchBar
              placeholder="Search (Case Sensitive)"
              fetchAllOrders={async () => {
                setSearch(undefined);
                const library =
                  activeTab === 0
                    ? await dispatch(
                        getDesigns({
                          user: email || emailid,
                        })
                      )
                    : activeTab === 1
                    ? await dispatch(
                        getBackgroundBurnDesigns({
                          user: email || emailid,
                        })
                      )
                    : await dispatch(
                        getPlusArtFiles({
                          user: email || emailid,
                        })
                      );

                if (library.error) {
                  return;
                }

                populateLibraryData(library);
                const payload = {
                  ...library,
                  cursors: library?.next_cursor
                    ? [undefined, library?.next_cursor]
                    : [],
                };
                if (activeTab === 0) {
                  setLibraryFiles(payload);
                } else if (activeTab === 1) {
                  setBBLibraryFiles(payload);
                } else if (activeTab === 2) {
                  setPlusLibraryFiles(payload);
                } else if (activeTab === 3) {
                  setAdvancedLibraryFiles(payload);
                }
              }}
              searchById={async (searchString) =>
                searchArtFilesByText(searchString, true)
              }
            />
            <Button
              title="Upload"
              containerStyle={{
                backgroundColor: COLORS.DARK_BLUE,
                color: COLORS.WHITE,
                fontWeight: "normal",
                textTransform: "none",
                borderRadius: "6px",
                fontSize: "16px",
                lineHeight: "1.5",
                boxShadow: "none",
              }}
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                const myWidget = window.cloudinary.createUploadWidget(
                  {
                    cloudName: "big-oven-tees-inc",
                    show_powered_by: false,
                    uploadPreset: "tnedst8q",
                    folder: `Shirtly/${emailid}/ArtFiles`,
                    clientAllowedFormats: ["png", "pdf"],
                    sources: ["local", "camera", "url"],
                  },
                  (error, result) => {
                    if (!error && result && result.event === "success") {
                      setLibraryFiles(undefined);
                    }
                  }
                );
                myWidget.open();
              }}
            />
          </SearchWrapper>
        ) : null}

        {!viewOnly && search?.trim()?.length > 4 && (
          <ChipsWrapper>
            <ChipsTitle>Suggested Searches :</ChipsTitle>
            <Chips>
              <Chip
                style={chipStyle}
                label={search?.substring(0, 4)}
                onClick={() => searchArtFilesByText(search?.substring(0, 4))}
              />
              <Chip
                style={chipStyle}
                label={search.charAt(0).toUpperCase() + search.substring(1, 4)}
                onClick={() =>
                  searchArtFilesByText(
                    search.charAt(0).toUpperCase() + search.substring(1, 4)
                  )
                }
              />
              <Chip
                style={chipStyle}
                label={search?.substring(0, 4)?.toUpperCase()}
                onClick={() =>
                  searchArtFilesByText(search?.substring(0, 4)?.toUpperCase())
                }
              />
            </Chips>
          </ChipsWrapper>
        )}

        <div>
          <ErrorMessage className="warning">
            Warning: Please make sure that the Artwork you are deleting is not
            used in any "In-Production" order. If artwork is deleted it will
            delay the Production of the order.
          </ErrorMessage>

          {/* <ErrorMessage className='error'>
            If you have any 'Unverified' Art File, please <b>Verify</b> that Art
            File because soon Shirtly will <b>BLOCK</b> the usage of
            'Unverified' images
          </ErrorMessage> */}
          <BrowseLibraryTabs
            printType={printType}
            toggle={toggle}
            onDrop={onDrop}
            email={email}
            viewOnly={viewOnly}
            libraryFiles={libraryFiles}
            setLibraryFiles={setLibraryFiles}
            setActiveTab={setActiveTab}
            bbLibraryFiles={bbLibraryFiles}
            setBBLibraryFiles={setBBLibraryFiles}
            plusLibraryFiles={plusLibraryFiles}
            setPlusLibraryFiles={setPlusLibraryFiles}
            advancedLibraryFiles={advancedLibraryFiles}
            setAdvancedDesigns={setAdvancedDesigns}
            advancedDesigns={advancedDesigns}
            showAdvanceToolTab={showAdvanceToolTab}
            activeTabIndex={activeTabIndex}
            activeTab={activeTab}
          />
        </div>
      </Container>
      <ProcedureDialog
        open={procedureModal}
        setOpen={(v) => setProcedureModal(v)}
      />
      {disclaimerModal && (
        <ShirtlyPreDesignedDisclaimer
          open={disclaimerModal}
          setOpen={(v) => setDisclaimerModal(v)}
          fetchData={async () => {
            setDisclaimerModal(false);
            const designs = await dispatch(
              getPreDesigns({
                tag: options[0],
              })
            );
            if (designs?.error) {
              return;
            }
            populateLibraryData(designs);
          }}
        />
      )}
    </Drawer>
  );
};

BrowseLibraryDrawer.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
};

export default BrowseLibraryDrawer;
