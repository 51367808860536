import React, { useState, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from 'components/TextField';
import Button from 'components/Button';
import {
  StyledEditProductSku,
  Header,
} from 'styles/pages/edit-products/editProductDialog';

const EditProductSku = ({ product, editSKUInformation }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!data?.length) {
      if (product?.SKUdata) {
        setData(product?.SKUdata);
      } else {
        const colors = product?.Colors;
        const sizes = product?.Sizes;

        let d = [];

        for (let i = 0; i < colors.length; i++) {
          const color = colors[i];

          for (let j = 0; j < sizes.length; j++) {
            const size = sizes[j];
            d.push({
              colorName: color?.Name,
              sizeName: size?.Name,
              skuNumber: '',
            });
          }
        }

        setData(d);
      }
    }
  }, [product]);

  return (
    <>
      <Header>
        <Button
          title="Save"
          onClick={() =>
            editSKUInformation({
              ID: product?.Product?.ID,
              skuData: data,
            })
          }
          // disabled={!!data?.find((d) => !d?.sku)}
        />
      </Header>
      {data?.map((d, i) => (
        <StyledEditProductSku key={`${i}_${d}`}>
          <div>{d?.colorName}</div>
          <div>{d?.sizeName}</div>
          <TextField
            label="Product SKU"
            value={d?.sku || ''}
            size="small"
            onChange={(sku) => {
              setData(
                data?.map((e, j) => {
                  if (i !== j) return e;

                  return {
                    ...e,
                    sku,
                  };
                })
              );
            }}
          />
          <DeleteIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => setData(data?.filter((_, j) => i !== j))}
          />
        </StyledEditProductSku>
      ))}
    </>
  );
};

export default EditProductSku;
