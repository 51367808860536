import { useState, useEffect, useRef } from "react";
import readXlsxFile from "read-excel-file";
import _ from "lodash";
import { CSVLink } from "react-csv";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import Image from "components/Image";
import EditProductDialog from "pages/edit-products/EditProductDialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { getStyles, getBrands, getPromos } from "selectors/tags";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/Button";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SearchBar from "components/SearchBar";
import SubHeader from "components/SubHeader";
import Select from "components/Select";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { DUMMY_PRODUCT } from "pages/edit-products/dumy-product-data";
import {
  editProducts,
  editProductSize,
  editColorsInformation,
  editDesignerSettings,
  editAdditionalTechniquesArtSettings,
  editPrintProvidersInformation,
  deleteProduct,
  editDTGPlusInformation,
  editArtworkSettings,
  editProductAdditionalImages,
  clearAdditionalImages,
  getAllProducts,
  editPrintAreas,
  editProductSubstituteInformation,
  editSKUInformation,
} from "actions/products";
import {
  Container,
  AdminItemGroup,
  Item,
  ItemWrapper,
  ProductLink,
  ProductImage,
  Description,
  Text,
  Amount,
  SearchWrapper,
  HeaderWrapper,
  Premium,
  IconWrapper,
  NotVisibleWrapper,
  NotVisbleColors,
} from "styles/pages/browse-catalog/details.js";
import { duplicateProductInformationPayload } from "pages/edit-products/data";

const EditProducts = () => {
  const inputFileRef = useRef();
  const csvEl = useRef(null);
  const [csvData, setCsvData] = useState(null);
  const products = useSelector((state) => state?.products?.data);
  const categories = useSelector((state) => state?.categories?.data);
  const tags = useSelector((state) => state?.tags?.data);
  const styleTags = getStyles(tags) || [];
  const brandsTags = getBrands(tags) || [];
  const promosTags = getPromos(tags) || [];
  const [selectedProduct, setProduct] = useState(undefined);
  const [search, setSearch] = useState(undefined);
  const [supplier, setSupplier] = useState(undefined);
  const [printProvider, setPrintProvider] = useState(undefined);
  const [dtgPlus, setDtgPlus] = useState(undefined);
  const [decoration, setDecoration] = useState(undefined);

  const [data, setData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!products?.length) {
      setData(
        search
          ? products?.filter((p) =>
              _.includes(
                p?.Product?.DisplayName?.toLowerCase(),
                search.toLowerCase()
              )
            )
          : products
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, search]);

  const createColors = (products) => {
    const colors = [];
    for (let i = 0; i < products.length; i++) {
      const p = products[i];
      const printAreas = p[13]?.replace(/\s\s+/g, " ")?.split(" ");
      const color = {
        ID: Math.floor(Math.random() * 343434232355),
        Name: p[15],
        Image: "",
        HexCode: p[17]?.split("=")[1],
        visible: true,
      };

      for (let j = 0; j < printAreas.length; j++) {
        const printArea = printAreas[j];
        color[`${printArea}Image`] = "";
      }
      colors.push(color);
    }
    return _.unionBy(colors, "Name");
  };

  const createSizes = (products) => {
    const sizes = [];
    for (let i = 0; i < products.length; i++) {
      const p = products[i];
      const productWeight = p[9];
      const printType =
        p[3] === "Thermal Sublimation：3d-pieces"
          ? "AOP"
          : p[3] === "DTG：2d-layers"
          ? "DTG"
          : p[3];

      const size = {
        ID: Math.floor(Math.random() * 18923220),
        Name: p[14],
        WhitePrice: Number(
          ((Number(p[10]) * 15) / 100 + Number(p[10])).toFixed(2)
        ),
        ColoredPrice: Number(
          ((Number(p[11]) * 15) / 100 + Number(p[11])).toFixed(2)
        ),
        [`${printType}Price`]: Number(
          ((Number(p[12]) * 15) / 100 + Number(p[12])).toFixed(2)
        ),
        [`${printType}OriginalPrice`]: Number(Number(p[12]).toFixed(2)),
        visible: true,
        weight: productWeight,
        originalWhitePrice: Number(Number(p[10]).toFixed(2)),
        originalColoredPrice: Number(Number(p[11]).toFixed(2)),
      };

      sizes.push(size);
    }
    return _.unionBy(sizes, "Name");
  };

  const handleBulkImport = async (e) => {
    const file = e?.target?.files[0];
    const rows = await readXlsxFile(file);

    const alreadyImportedProducts = [];

    for (let i = 0; i < rows.length; i++) {
      if (i === 0) continue;

      const row = rows[i];

      const displayName = row[0];
      const name = row[1];
      const sku = row[2];
      const printType =
        row[3] === "Thermal Sublimation：3d-pieces"
          ? "AOP"
          : row[3] === "DTG：2d-layers"
          ? "DTG"
          : row[3];
      const productImage = row[4];
      const description = row[5];
      const maximumFulfillmentDays = row[7];
      const minimumFulfillmentDays = row[8];
      const productWeight = row[9];
      const printAreas = row[13]?.replace(/\s\s+/g, " ")?.split(" ");
      const decorationWidth = Number(row[18]);
      const decorationHeight = Number(row[19]);

      const isAlreadyImported = alreadyImportedProducts.find(
        (a) => a === displayName
      );

      if (!isAlreadyImported) {
        const products = rows?.filter((r) => r[0] === displayName);
        const colors = createColors(products);
        const product = {
          ...DUMMY_PRODUCT,
          Product: {
            ...DUMMY_PRODUCT.Product,
            ID: Math.floor(Math.random() * 1898980),
            SKU: sku,
            PrintType: printType,
            Name: name,
            DisplayName: displayName,
            MarketingImagePath: productImage,
            Description: description,
            defaultMaximumFulfillDays: maximumFulfillmentDays,
            defaultMinimumFulfillDays: minimumFulfillmentDays,
            weight: productWeight,
          },
          Sizes: createSizes(products),
          Colors: colors,
          DesignerSettings: {
            ...DUMMY_PRODUCT.DesignerSettings,
            platenWidthInch: parseInt(decorationWidth),
            platenHeightInch: parseInt(decorationHeight),
            width: parseInt(
              decorationWidth * DUMMY_PRODUCT.DesignerSettings.multiplier
            ),
            height: parseInt(
              decorationHeight * DUMMY_PRODUCT.DesignerSettings.multiplier
            ),
          },
          PrintAreas: printAreas?.map((p) => ({
            printAreaName: p,
            printAreaMarketingImage: productImage,
            editable: false,
            designerSettings: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              multiplier: 16,
              platenWidthInch: decorationWidth,
              platenHeightInch: decorationHeight,
              width:
                decorationWidth * DUMMY_PRODUCT.DesignerSettings.multiplier,
              height:
                decorationHeight * DUMMY_PRODUCT.DesignerSettings.multiplier,
            },
          })),

          SKUdata: products?.map((p) => ({
            colorName: p[15],
            sku: p[2],
            sizeName: p[14],
          })),
        };

        alreadyImportedProducts.push(displayName);
      }
    }
  };

  return (
    <Container>
      <HeaderWrapper>
        <input
          type="file"
          style={{ display: "none" }}
          ref={inputFileRef}
          onChange={handleBulkImport}
        />
        <SubHeader
          title={`Edit Product ${
            products?.length > 0 ? `(${products?.length})` : null
          }`}
        />

        <IconWrapper>
          <Button
            style={{ display: "none" }}
            title="Update DTG Price"
            onClick={async () => {
              let newProducts = [];

              for (let i = 0; i < products?.length; i++) {
                const product = products[i];
                const printProviders = product?.PrintProviders;

                let isValidPrintprovider = true;

                for (let j = 0; j < printProviders.length; j++) {
                  const providerName = printProviders[j]?.ProviderName;

                  if (providerName !== "CA" && providerName !== "US") {
                    isValidPrintprovider = false;
                    break;
                  }
                }

                if (isValidPrintprovider) {
                  newProducts.push(product);
                }
              }

              for (let i = 0; i < newProducts.length; i++) {
                let sizes = [];

                for (let j = 0; j < newProducts[i]?.Sizes.length; j++) {
                  const size = newProducts[i]?.Sizes[j];

                  if (size?.DTGPrice) {
                    size.DTGPrice = 6;
                  }

                  if (size?.DURASOFTPrice) {
                    size.DURASOFTPrice = 6.5;
                  }

                  if (size?.EMBPrice) {
                    size.EMBPrice = 10;
                  }

                  sizes.push(size);
                }

                await dispatch(
                  editProductSize(
                    {
                      ID: newProducts[i]?.Product?.ID,
                      sizeData: sizes,
                    },
                    false
                  )
                );
              }
            }}
          />

          <Tooltip title="Bulk Product Import" arrow={true}>
            <CloudUploadIcon onClick={() => inputFileRef.current.click()} />
          </Tooltip>

          <AddCircleIcon onClick={() => setProduct({})} />

          <Button
            title="Product Details"
            onClick={async () => {
              const rows = [
                [
                  "Name",
                  "SKU",
                  "Visible",

                  "Sizes",
                  "Color",
                  "Coloured Blank Price",
                  "White Blank Price",

                  // "Decorations",
                  "DTG Price",
                  "EMB Price",
                  "SUB Price",
                  "DURASOFT Price",
                  "AOP Price",
                  "SCREENPRINT Price",

                  "Print Providers",
                  "Print Areas",
                ],
              ];

              for (let i = 0; i < products.length; i++) {
                const element = products[i];
                const sizes = element?.Sizes;
                const colors = element?.Colors;

                for (let k = 0; k < colors.length; k++) {
                  const color = colors[k];
                  for (let j = 0; j < sizes?.length; j++) {
                    const size = sizes[j];
                    rows.push([
                      `${element?.Product?.Name?.replace(
                        /[^a-zA-Z0-9 ]/g,
                        ""
                      )}` || "",
                      `${element?.Product?.SKU?.replace(
                        /[^a-zA-Z0-9 ]/g,
                        ""
                      )}` || "",
                      element?.Product?.visible ||
                      element?.Product?.visible === undefined
                        ? "Yes"
                        : "No",

                      size?.Name,
                      color?.Name,
                      size?.ColoredPrice,
                      size?.WhitePrice,

                      // `${element?.Product?.PrintType}=${
                      //   size[`${element?.Product?.PrintType}Price`] || 0
                      // }
                      // ${
                      //   element?.RemainingDecorativeTechniques?.length > 0
                      //     ? `, ${element?.RemainingDecorativeTechniques?.map(
                      //         (r) =>
                      //           `${r?.DisplayName}=${
                      //             size[`${r?.DisplayName}Price`] || 0
                      //           }`
                      //       )?.join(", ")}`
                      //     : ""
                      // }`,
                      size?.DTGPrice || 0,
                      size?.EMBPrice || 0,
                      size?.SUBPrice || 0,
                      size?.DURASOFTPrice || 0,
                      size?.AOPPrice || 0,
                      size?.ScreenPrintPrice || 0,

                      element?.PrintProviders?.map((p) => p?.ProviderName).join(
                        ", "
                      ) || "",
                      element?.PrintAreas?.map((p) => p?.printAreaName).join(
                        ", "
                      ) || "",
                    ]);
                  }
                }

                rows.push([
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ]);
              }

              setCsvData(rows);

              setTimeout(() => {
                csvEl.current.link.click();
              }, 10);
            }}
          />
        </IconWrapper>
      </HeaderWrapper>

      <SearchWrapper>
        <SearchBar
          fetchAllOrders={() => setSearch(undefined)}
          searchById={(value) => setSearch(value || undefined)}
        />

        <Select
          label="Select Supplier"
          value={supplier}
          options={[
            "S&S ACTIVE WEAR",
            "ALPHA BRODER",
            "SANMAR",
            "NO SUPPLIER",
            "ALL",
          ]}
          onChange={(v) => {
            const filteredProducts =
              v === "ALL"
                ? products
                : v === "NO SUPPLIER"
                ? products?.filter(
                    (p) => !!p?.PrintProviders?.find((s) => !s?.supplier)
                  )
                : products?.filter(
                    (p) => !!p?.PrintProviders?.find((s) => s?.supplier === v)
                  );
            setSupplier(v);
            setData(filteredProducts);
          }}
        />

        <Select
          label="Select Print Provider"
          value={printProvider}
          options={["US", "CA", "EU", "AU", "NY"]}
          onChange={(v) => {
            const data = products?.filter((p) =>
              p?.PrintProviders?.find((pp) => pp?.ProviderName === v)
            );

            setPrintProvider(v);
            setData(data);
          }}
        />

        <Select
          label="Select Decoration"
          value={decoration}
          options={[
            "DTG",
            "Plus",
            "DTGPlus",
            "EMB",
            "DURASOFT",
            "AOP",
            "SUB",
            "SCREENPRINT",
          ]}
          onChange={(v) => {
            const data = products?.filter(
              (p) =>
                p?.Product?.PrintType?.toLowerCase() === v?.toLowerCase() ||
                p?.AdditionalTechniquesArtSettings?.filter(
                  (q) => q?.name?.toLowerCase() === v?.toLowerCase()
                )?.length > 0
            );

            setDecoration(v);
            setData(data);
          }}
        />

        <Select
          label="Select DTG Plus Products"
          value={dtgPlus}
          options={["DTG Plus"]}
          onChange={(v) => {
            const data = products?.filter((p) => !!p?.DTGPlus?.length);

            setDtgPlus(v);
            setData(data);
          }}
        />
      </SearchWrapper>

      <AdminItemGroup>
        {data?.map((item, index) => {
          const product = item.Product;
          const premium = product?.premiumProduct;
          const notVisibleSizes = item?.Sizes?.filter((s) => !s?.visible);
          const notVisibleColors = item?.Colors?.filter((s) => !s?.visible);

          return (
            <ItemWrapper key={`productsCatalog${index}`}>
              <Item premium={true} onClick={() => setProduct(item)}>
                <ProductLink>
                  <Tooltip title="Visible" arrow={true}>
                    <div
                      style={{
                        position: "absolute",
                        left: 5,
                        top: 5,
                        zIndex: 9,
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        cursor: "pointer",
                      }}
                      onClick={async (e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        const request = await dispatch(
                          editProducts({
                            productData: {
                              ...product,
                              visible:
                                product?.visible === undefined
                                  ? false
                                  : !product?.visible,
                            },
                          })
                        );
                        if (request.error) {
                          return;
                        }
                        setProduct(undefined);
                      }}
                    >
                      {product?.visible || product?.visible === undefined ? (
                        <CheckBoxIcon style={{ color: "#000" }} />
                      ) : (
                        <CheckBoxOutlineBlankIcon style={{ color: "#000" }} />
                      )}
                      <span>Visible</span>
                    </div>
                  </Tooltip>

                  <Tooltip title="Duplicate" arrow={true}>
                    <FileCopyIcon
                      style={{
                        position: "absolute",
                        right: 5,
                        top: 5,
                        zIndex: 9,
                        color: premium ? "white" : "#000",
                      }}
                      onClick={async (e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        const payload =
                          duplicateProductInformationPayload(item);
                        const request = await dispatch(
                          editProducts(payload, false)
                        );
                        const productId = request?.Product?.ID;

                        await dispatch(
                          editProductSize(
                            {
                              ID: productId,
                              sizeData: item?.Sizes,
                            },
                            false
                          )
                        );

                        await dispatch(
                          editColorsInformation(
                            {
                              ID: productId,
                              colorsData: item?.Colors,
                            },
                            false
                          )
                        );

                        await dispatch(
                          editDesignerSettings(
                            {
                              ID: productId,
                              designerSettingsData: item?.DesignerSettings,
                              backDesignerSettingsData:
                                item?.BackDesignerSettings,
                            },
                            false
                          )
                        );

                        await dispatch(
                          editArtworkSettings(
                            {
                              ID: productId,
                              artworkSettingsData: {
                                greyOut: item?.ArtworkSettings?.greyOut,
                                allColors: item?.ArtworkSettings?.allColors,
                              },
                            },
                            false
                          )
                        );

                        await dispatch(
                          editPrintProvidersInformation(
                            {
                              ID: productId,
                              printProvidersData: item?.PrintProviders,
                            },
                            false
                          )
                        );

                        await dispatch(
                          editDTGPlusInformation(
                            {
                              ID: productId,
                              dtgPlusData: item?.DTGPlus,
                              remainingDecorativeTechniques:
                                item?.RemainingDecorativeTechniques,
                            },
                            false
                          )
                        );

                        await dispatch(
                          editProductAdditionalImages(
                            {
                              ID: productId,
                              additionalImages: item?.AdditionalImages,
                            },
                            false
                          )
                        );

                        await dispatch(getAllProducts());
                      }}
                    />
                  </Tooltip>

                  <ProductImage premium={premium}>
                    <Image
                      src={product?.MarketingImagePath}
                      alt={product?.DisplayName}
                    />
                  </ProductImage>
                  <Description>
                    <Text premium={premium}>{product?.DisplayName}</Text>
                    <Amount premium={premium}>
                      from ${item?.Sizes ? item?.Sizes[0]?.ColoredPrice : 0} USD
                    </Amount>
                  </Description>
                </ProductLink>
                {premium && <Premium>Premium</Premium>}
              </Item>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{ fontSize: "10px" }}>
                    Disabled Sizes / Colors
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <NotVisibleWrapper>
                    <div>
                      {notVisibleSizes?.map((s) => (
                        <div key={`${product?.DisplayName}-${s?.Name}`}>
                          {s?.Name} - Disabled
                        </div>
                      ))}
                    </div>
                    <NotVisbleColors>
                      {notVisibleColors?.map((s) => (
                        <div key={`${product?.DisplayName}-${s?.Name}`}>
                          {s?.Name} - Disabled
                        </div>
                      ))}
                    </NotVisbleColors>
                  </NotVisibleWrapper>
                </AccordionDetails>
              </Accordion>
            </ItemWrapper>
          );
        })}
      </AdminItemGroup>

      {!!selectedProduct && (
        <EditProductDialog
          open={!!selectedProduct}
          setModal={(v) => setProduct(undefined)}
          product={selectedProduct}
          categories={categories}
          styleTags={styleTags}
          brandsTags={brandsTags}
          promosTags={promosTags}
          // editDTGPlusInformation={editDTGPlusInformation}
          updatePrintArea={async (areas, additionalTechniquesArtSettings) => {
            const request = await dispatch(
              editPrintAreas(
                {
                  ID: selectedProduct?.Product?.ID,
                  printAreas: areas,
                },
                false
              )
            );

            if (request.error) {
              return;
            }

            if (additionalTechniquesArtSettings) {
              await dispatch(
                editAdditionalTechniquesArtSettings(
                  {
                    ID: selectedProduct?.Product?.ID,
                    additionalTechniquesArtSettings,
                  },
                  false
                )
              );
            }

            setProduct(undefined);
          }}
          updateProductInformation={async (payload) => {
            const request = await dispatch(
              editProducts({
                productData: {
                  ...payload,
                  Description: draftToHtml(
                    convertToRaw(payload?.Description.getCurrentContent())
                  ),
                },
              })
            );
            if (request.error) {
              return;
            }
            setProduct(undefined);
          }}
          updateProductSizes={async (payload) => {
            const request = await dispatch(editProductSize(payload));
            if (request.error) {
              return;
            }
            setProduct(undefined);
          }}
          editColorsInformation={async (payload) => {
            const request = await dispatch(
              editColorsInformation(payload, false)
            );
            if (request.error) {
              return;
            }
            setProduct(undefined);
          }}
          editDesignerSettings={async (payload) => {
            const request = await dispatch(
              editDesignerSettings(payload, false)
            );
            if (request.error) {
              return;
            }
            setProduct(undefined);
          }}
          editAdditionalTechniquesArtSettings={async (payload) => {
            const request = await dispatch(
              editAdditionalTechniquesArtSettings(payload, false)
            );
            if (request.error) {
              return;
            }
            setProduct(undefined);
          }}
          editProductArtworkSettings={async (payload) => {
            const request = await dispatch(editArtworkSettings(payload));
            if (request.error) {
              return;
            }
            setProduct(undefined);
          }}
          updateProductPrintProviders={async (payload) => {
            const request = await dispatch(
              editPrintProvidersInformation(payload)
            );
            if (request.error) {
              return;
            }
            setProduct(undefined);

            /**
             * @TODO
             */
            // for (let i = 0; i < products.length; i++) {
            //   const product = products[i];
            //   const printProviders = product?.PrintProviders;
            //   let newPrintProviders = [];
            //   for (let j = 0; j < printProviders.length; j++) {
            //     const printProviderName = printProviders[j]?.ProviderName;
            //     if (printProviderName === 'US') {
            //       const isDHL =
            //         printProviders[j]?.ShippingCosts[0]?.Title?.includes('DHL');
            //       if (!isDHL) {
            //         newPrintProviders.push(printProviders[j]);
            //       }
            //     } else {
            //       newPrintProviders.push(printProviders[j]);
            //     }
            //   }
            //   for (let j = 0; j < newPrintProviders.length; j++) {
            //     const printProviderName = newPrintProviders[j]?.ProviderName;
            //     if (printProviderName === 'US') {
            //       const shippingCosts = newPrintProviders[j].ShippingCosts;
            //       for (let k = 0; k < shippingCosts.length; k++) {
            //         if (shippingCosts[k]?.ShippingDestination === 'US') {
            //           newPrintProviders[j].ShippingCosts[k].PrimaryCost = 5.8;
            //           newPrintProviders[j].ShippingCosts[k].SecondaryCost = 3;
            //         } else if (shippingCosts[k]?.ShippingDestination === 'CA') {
            //           newPrintProviders[j].ShippingCosts[k].PrimaryCost = 12.8;
            //           newPrintProviders[j].ShippingCosts[k].SecondaryCost = 8;
            //         } else if (
            //           shippingCosts[k]?.ShippingDestination === 'Intl'
            //         ) {
            //           newPrintProviders[j].ShippingCosts[k].PrimaryCost = 20.8;
            //           newPrintProviders[j].ShippingCosts[k].SecondaryCost = 10;
            //         }
            //         newPrintProviders[j].ShippingCosts[k].Title =
            //           newPrintProviders[j].ShippingCosts[k].Title?.replace(
            //             'USPS First Class Mail',
            //             'USPS First Class Mail'
            //           );
            //         newPrintProviders[j].ShippingCosts[k].carrierCode =
            //           newPrintProviders[j].ShippingCosts[
            //             k
            //           ].carrierCode?.replace('dhl_global_mail', 'stamps_com');
            //       }
            //     }
            //   }
            //   const tempPayload = {
            //     ID: product?.Product?.ID,
            //     printProvidersData: newPrintProviders,
            //   };
            //   await dispatch(editPrintProvidersInformation(tempPayload, false));
            // }
          }}
          editDTGPlusInformation={async (payload) => {
            const request = await dispatch(editDTGPlusInformation(payload));
            if (request.error) {
              return;
            }
            setProduct(undefined);
          }}
          editProductSubstituteInformation={async (payload) => {
            const request = await dispatch(
              editProductSubstituteInformation(payload)
            );
            if (request.error) {
              return;
            }
            setProduct(undefined);
          }}
          editSKUInformation={async (payload) => {
            const request = await dispatch(editSKUInformation(payload));
            if (request.error) {
              return;
            }
            setProduct(undefined);
          }}
          addAdditionalImages={async (payload, clearImages) => {
            if (clearImages) {
              await dispatch(clearAdditionalImages(clearImages));
            }

            const request = await dispatch(
              editProductAdditionalImages(payload)
            );
            if (request.error) {
              return;
            }
          }}
          deleteProduct={async () => {
            if (
              window.confirm(
                `Are you sure, you want to delete this product.\n\nThis step cannot be undone.`
              ) === true
            ) {
              const request = await dispatch(
                deleteProduct({
                  ID: selectedProduct?.Product?.ID,
                })
              );
              if (request.error) {
                return;
              }
              setProduct(undefined);
            }
          }}
        />
      )}

      {csvData && (
        <CSVLink
          style={{ display: "none" }}
          ref={csvEl}
          data={csvData}
          filename="Product_Details.csv"
        />
      )}
    </Container>
  );
};

export default EditProducts;
