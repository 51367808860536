import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  .slick-dots li.slick-active button:before {
    background-color: #282a3b;
  }
`;

const Wrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
  padding: 15px;
`;

const CarouselWrapper = styled.div`
  display: inline-flex !important;
  @media (max-width: 740px) {
    flex-direction: column;
  }
`;
const CarouselImageWrapper = styled.div`
  background-color: #eeeeee;
  padding: 39px 0 39px 26px;
  border-radius: 8px;
  flex: 1;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 740px) {
    padding: 30px;
  }
`;
const CarouselInfoWrapper = styled.div`
  flex: 1;
  padding-left: 50px;
  @media (max-width: 740px) {
    padding: 30px 0;
  }
`;
const CarouselTitle = styled.h2`
  font-family: 'Inter800';
  margin: 0;
  @media (min-width: 1200px) {
    font-size: 36px;
    line-height: 54px;
  }
`;

const CarouselSubTitle = styled.div`
  background-color: #282a3b;
  padding: 15px 40px;
  color: #fff;
  border-radius: 10px;
  font-family: 'Inter700';
  font-size: 22px;
  margin: 32px 0;
  div:nth-child(2) {
    font-family: 'Inter600';
    font-size: 20px;
  }
  @media (min-width: 1200px) {
    font-size: 30px;
    div:nth-child(2) {
      font-size: 23px;
    }
  }
`;

const CarouselDescription = styled.p`
  font-size: 18px;
  line-height: 32px;
`;

export {
  Container,
  Wrapper,
  CarouselWrapper,
  CarouselImageWrapper,
  CarouselInfoWrapper,
  CarouselTitle,
  CarouselSubTitle,
  CarouselDescription,
};
