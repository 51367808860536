import styled from 'styled-components';

export const Container = styled.div``;

export const ItemGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  transition: all ease-out 0.25s;
  column-gap: 40px;
  row-gap: 40px;
  border-radius: 4px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(8, 1fr);
  }
`;

export const Item = styled.div`
  border-radius: 2px;
  transform: scale(1);
  padding: 1em;
  position: relative;
  background: #fff;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;
  transition: all ease-out 0.25s;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

export const ProductLink = styled.div`
  button {
    background: #000;
    color: #fff;
    width: 100%;
    :hover {
      background: #000;
      color: #fff;
      opacity: 0.5;
    }
  }
`;

export const ProductImage = styled.div`
  color: transparent;
  display: inline-block;
  width: 100%;
  margin: 1em 0;
`;

export const Footer = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
`;
