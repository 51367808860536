import styled from "styled-components";

const Container = styled.div``;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: -20px;
  gap: 20px;
`;

const UserEmailID = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: bold;
  font-size: 18px;
`;

const Option = styled.div`
  border: 1px solid black;
  background: white;
  font-size: 18px;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70px;

  background-color: ${({ selected }) => (selected ? "#000" : "#FFF")};
  color: ${({ selected }) => (selected ? "#FFF" : "#000")};

  svg {
    font-size: 50px;
  }

  :hover {
    background-color: #000;
    color: #fff;
    cursor: pointer;
  }
`;

export { Container, Option, Wrapper, UserEmailID };
