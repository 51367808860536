import * as React from 'react';

const Icon = (props) => (
  <svg width={32} height={32} xmlns='http://www.w3.org/2000/svg' {...props}>
    <path fill='none' d='M-1-1h582v402H-1z' />
    <g>
      <path d='M29 6H3a1 1 0 000 2h26a1 1 0 000-2zM3 17h13a1 1 0 000-2H3a1 1 0 000 2zM25 24H3a1 1 0 000 2h22a1 1 0 000-2z' />
    </g>
  </svg>
);

export default Icon;
