import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 20px;
`;
const Wrapper = styled.div`
  min-height: 150px;
  border-radius: 10px;
  border: none !important;
  background-color: #ffffff;
  padding: 0 30px;
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  font-size: 17px;
  font-family: "Inter600";
  padding: 14px 0;
  justify-content: space-between;
`;

const Panel = styled.div``;
const OrderInfo = styled.ul`
  padding: 0;
  margin: 0;
  li {
    display: flex;

    > span {
      font-family: "Inter400";
      font-size: 14px;
      line-height: 31px;
      :first-child {
        width: auto;
        font-family: "Inter600";
        margin-right: 8px;
        line-height: 32px;
      }
    }
  }
`;

const Status = styled.div`
  flex-direction: column;
  position: relative;
  justify-content: center;
  border-radius: 6px;
  padding: 3px 12.5px;
  color: #ff6600;
  background: #ff660033;
  height: 48px;
  width: auto;
  min-width: 175px;
  text-align: center;
  display: inline-flex;
  flex: 0;
  margin: 5px 0;
  p {
    margin: 0;
    color: inherit;
    font-size: 16px;
    font-family: "Inter500";
  }
  &.awaiting {
    color: #ff6600;
    background: #ff660033;
  }
  &.pending-details {
    color: #f4b000;
    background: #f4b00033;
  }
  &.shipped {
    color: green;
    background: #00800033;
  }
  &.cancelled {
    color: red;
    background: #ff000033;
  }
  span {
    font-size: 12px;
  }
`;

const OrderAction = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    Button {
      text-transform: capitalize;
      font-size: 16px;
      line-height: 1.42857;
      padding: 6px 12px;
      background-color: #ff0000;
      color: #fff;
      border-radius: 6px;
      margin: 5px 0;
      height: 48px;
      font-family: "Inter500";
      :first-child {
        margin-left: 0;
      }
      :hover {
        opacity: 0.8;
        background-color: #ff0000;
      }
    }
  }
`;

const MachineStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 800;
`;

const BodySection = styled.div`
  border-top: 1px solid #efefef;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 15px 0;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
export {
  Container,
  Wrapper,
  Heading,
  Panel,
  OrderInfo,
  Status,
  OrderAction,
  MachineStatusWrapper,
  BodySection,
};
