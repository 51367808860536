import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/Button';
import { getAllCards, calculateTax } from 'actions/stripe';
import {
  getOrders,
  submitOrderToCanada,
  submitOrderToUSA,
  updateOrderStatus,
  saveOrderData,
  getEtsyOrders,
  deleteOrder,
  getShopifyOrdersCount,
  getShopifyOrders,
  getShopifyMoreOrders,
  getBigCommerceOrders,
  wooMoreOrders,
  wooAllOrders,
  setPrintProvider,
  editOrderCustomerInformation,
  addLineItem,
  removeLineItem,
  generateMocks,
  getOrderDetail,
  getFulfillmentCost as fetchFulfillmentCost,
  deleteBulkOrders,
  sendEmail,
} from 'actions/orders';
import { syncedProducts } from 'actions/products';
import RetrieveSuccessDialog from 'pages/orders/RetrieveSuccessDialog';
import RetrieveErrorDialog from 'pages/orders/RetrieveErrorDialog';
import { getCountries } from 'actions/countries';
import { bulkStripeCharge } from 'actions/stripe';
import RetrieveLatestOrder from 'pages/orders/RetrieveLatestOrder';
import SyncProductProgressModal from 'pages/orders/SyncProductProgress';
import {
  getOrderXML,
  getBulkOrderStripePayload,
  totalOrderCostInUSD,
  getShippingCostInfo,
  getFinalShippingCost,
  getShippingTaxCost,
} from 'pages/orders/helper';
import { getFulfillmentCost } from 'pages/order-details/OrderSummary';
import { AutomationWrapper } from 'styles/pages/orders';
import { USD_TO_CAD_CONVERSION_RATE } from 'constants/currency';

const addImageProcess = (artwork) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = artwork?.coordinates?.width;
    canvas.height = artwork?.coordinates?.height;
    const image = new Image();

    image.onload = function (e) {
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const reSampledImage = new Image();
      reSampledImage.src = canvas.toDataURL();
      resolve(canvas.toDataURL());
    };
    image.src = artwork?.artwork;
  });
};

const AutomationPayment = ({ fetchAllOrders, user, category }) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state?.products?.data);
  const countries = useSelector((state) => state?.countries?.data);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [taxInfo, setTaxInfo] = useState(undefined);
  const [retrievalErrorModal, setRetrievalErrorModal] = useState(false);
  const [syncProductModal, setSyncProductModal] = useState(false);
  const [retrievalSuccessModal, setRetrievalSuccessModal] = useState(false);

  useEffect(() => {
    const loadCountries = async () => {
      if (!countries?.length) {
        dispatch(getCountries());
      }
    };
    loadCountries();
  }, [dispatch, countries]);

  useEffect(() => {
    const fetchTaxInfo = async () => {
      if (!taxInfo) {
        const tax = await dispatch(calculateTax());

        if (tax.error) {
          return;
        }

        setTaxInfo(tax);
      }
    };
    fetchTaxInfo();
  }, [dispatch, taxInfo]);

  const fetchCards = async () => {
    return await dispatch(
      getAllCards({
        user:
          user?.User === 'haiah123x@gmail.com'
            ? 'snewcoshirtly@gmail.com'
            : user?.User,
      })
    );
  };

  const fetchAwaitingOrders = async (skip = 1) =>
    await dispatch(
      getOrders({
        emailid: user?.User,
        state: 'AwaitingPayment',
        skip: (skip - 1 > 0 ? skip - 1 : 0) * 10,
        take: 10,
      })
    );

  const fetchSyncedProducts = async ({ shopName }) =>
    await dispatch(
      syncedProducts({
        emailid: user?.User,
        shopName,
      })
    );

  const fetchOrdersData = async ({ state, currentPage, take = 10 }) =>
    await dispatch(
      getOrders({
        emailid: user?.User,
        state,
        skip: (currentPage - 1 > 0 ? currentPage - 1 : 0) * take,
        take,
      })
    );

  const updateCustomerInfo = async (payload) =>
    await dispatch(editOrderCustomerInformation(payload));

  return (
    <AutomationWrapper>
      {category === 'PendingDetails' && user?.UserData?.automation && (
        <RetrieveLatestOrder
          emailId={user?.User}
          retrieveShopifyOrders={async (store) => {
            if (user?.UserData?.deleteUnSyncedOrders) {
              await dispatch(
                deleteBulkOrders(
                  {
                    status: 'PendingDetails',
                    user: user?.User,
                  },
                  token
                )
              );
            }

            let response;
            if (store?.MarketplaceType === 'Etsy') {
              response = await dispatch(
                getEtsyOrders(user?.User, store?.ShopID, store?.ID)
              );
            } else if (store?.MarketplaceType === 'Shopify') {
              setSyncProductModal({
                title: 'Retrieving Latest Orders',
                totalOrders: 100,
                currentOrder: 0,
              });

              const count = await dispatch(
                getShopifyOrdersCount(user?.User, store?.ShopID)
              );

              if (count?.count <= 100) {
                response = await dispatch(
                  getShopifyOrders(user?.User, store?.ShopID)
                );
              } else {
                const loop = Math.ceil(count?.count / 100);
                const arr = _.range(loop);

                for await (const index of arr) {
                  setSyncProductModal({
                    title: 'Retrieving Latest Orders',
                    totalOrders: arr?.length,
                    currentOrder: index,
                  });
                  await new Promise(async (resolve, reject) => {
                    if (index === 0) {
                      response = await dispatch(
                        getShopifyOrders(user?.User, store?.ShopID)
                      );
                    } else if (index !== 0) {
                      response = await dispatch(
                        getShopifyMoreOrders(
                          user?.User,
                          store?.ShopID,
                          response?.next_page_info
                        )
                      );
                    }
                    resolve();
                  });
                }
              }
              setSyncProductModal({
                title: 'Retrieving Latest Orders',
                totalOrders: 100,
                currentOrder: 100,
              });
            } else if (store?.MarketplaceType === 'BigCommerce') {
              response = await dispatch(
                getBigCommerceOrders(user?.User, store?.ShopID)
              );
            } else if (store?.MarketplaceType === 'WooCommerce') {
              response = await dispatch(
                wooAllOrders(user?.User, store?.ShopID)
              );

              if (response?.next_page_info) {
                let condition = true;

                while (condition) {
                  await new Promise(async (resolve) => {
                    response = await dispatch(
                      wooMoreOrders(
                        user?.User,
                        store?.ShopID,
                        response?.next_page_info
                      )
                    );

                    if (!response?.next_page_info) {
                      condition = false;
                    }

                    resolve();
                  });
                }
              }
            }

            if (!response?.success || !response?.success) {
              setRetrievalErrorModal(
                response?.message?.errors || response?.data
              );
              return;
            }

            // if (category === 'PendingDetails') {
            //   fetchAllOrders();
            // }

            if (store?.MarketplaceType === 'Shopify') {
              const latestPendingOrders = await fetchOrdersData({
                state: 'PendingDetails',
                currentPage: 1,
                take: 10,
              });

              const loopCount = Math.ceil(
                latestPendingOrders?.orderCounts?.PendingDetails / 10
              );

              const syncedProductsData = await fetchSyncedProducts({
                shopName: store?.ShopID,
              });

              let addLineItemData = [];
              let removeLineItemData = [];

              setSyncProductModal({
                title: 'Validating Orders Batch',
                totalOrders: loopCount,
                currentOrder: 0,
              });

              let orderErrors = { addressMissing: [] };

              for (let i = 1; i <= loopCount; i++) {
                const newOrders = await fetchOrdersData({
                  state: 'PendingDetails',
                  currentPage: i,
                  take: 10,
                });

                for (let j = 0; j < newOrders?.orders.length; j++) {
                  setSyncProductModal({
                    title: `Validating Orders Batch ${i}`,
                    totalOrders:
                      latestPendingOrders?.orderCounts?.PendingDetails,
                    currentOrder: i === 1 ? i : i * 10 + j,
                    childTotalOrders: newOrders?.orders.length,
                    childCurrentOrder: j,
                    childProgressTitle: `Orders validated in Current Batch ${i}`,
                  });

                  let order = newOrders?.orders[j];

                  const {
                    BuyerName,
                    AddressLineOne,
                    City,
                    CountryString,
                    ZipPostal,
                  } = order || {};

                  if (
                    !BuyerName ||
                    !AddressLineOne ||
                    !City ||
                    !CountryString ||
                    !ZipPostal
                  ) {
                    orderErrors.addressMissing.push(order.ReceiptID);
                    // await dispatch(
                    //   sendEmail({
                    //     user: user?.User,
                    //     subject: `Automation Address Missing in order (${order.ID})`,
                    //   })
                    // );
                    continue;
                  }

                  const orderLines = order?.OrderLines[0];

                  const orderLineItems = orderLines?.OrderLineItems;

                  order = {
                    ...order,
                    user: user?.User,
                    ID: order.ID,
                    PrintProvider: order?.CountryString === 'CA' ? 'CA' : 'US',
                  };

                  await updateCustomerInfo(order);

                  for (let k = 0; k < orderLineItems.length; k++) {
                    const orderLineItem = orderLineItems[k];

                    const marketPlaceDetails =
                      orderLineItem?.MarketPlaceDetails;

                    const isProductSynced = syncedProductsData?.find(
                      (s) => s?.id === marketPlaceDetails?.product_id
                    );

                    if (isProductSynced) {
                      const variants = isProductSynced?.variants;
                      const variantId = marketPlaceDetails?.variant_id;
                      const isVariantSynced = variants?.find(
                        (v) => v?.id === variantId
                      );

                      if (isVariantSynced) {
                        const { syncData } = isVariantSynced;
                        const {
                          categoryName,
                          categoryId,
                          ProductID,
                          productName,
                          sizeId,
                          sizeName,
                          ColorID,
                          colorName,
                          PrintType,
                        } = syncData || {};

                        let printAreas = syncData?.printAreas;
                        let payload = {
                          ...orderLineItem,
                          Category: categoryName,
                          CategoryID: categoryId,
                          Product: productName,
                          ProductID,
                          Size: sizeName,
                          SizeID: sizeId,
                          Color: colorName,
                          ColorID,
                          PrintType,
                        };

                        payload.printAreas = printAreas?.filter(
                          (p) => p?.printAreaMarketingImage
                        );

                        const fulfillmentCost = await dispatch(
                          fetchFulfillmentCost({
                            prodId: ProductID,
                            prodSize: sizeName,
                          })
                        );

                        const {
                          ColoredPrice: coloredPrice,
                          WhitePrice: whitePrice,
                        } = fulfillmentCost;

                        const availableArts = printAreas?.filter(
                          (p) => p?.artwork
                        );
                        const availableArtsLength =
                          availableArts?.length > 0 ? availableArts?.length : 1;
                        const printTypePrice =
                          (fulfillmentCost[`${PrintType}Price`] || 0) *
                          availableArtsLength;

                        if (availableArtsLength > 0) {
                          for (let k = 0; k < availableArts.length; k++) {
                            const row = availableArts[k];
                            const {
                              coordinates,
                              artwork,
                              designerSettings,
                              printAreaMarketingImage,
                              printAreaName,
                            } = row;

                            const { width, height, x = 0, y = 0 } = coordinates;
                            const {
                              left,
                              top,
                              width: designerSettingsWidth,
                            } = designerSettings;

                            const artworkImageSplit =
                              artwork?.split('/upload/');
                            const artImageDataUrl =
                              artwork?.indexOf('base64') > 0
                                ? await addImageProcess(row)
                                : null;

                            const isSub = PrintType === 'SUB';

                            const mainImageSplit =
                              printAreaMarketingImage?.split('/upload/');

                            if (mainImageSplit?.length) {
                              const mockUpPayload = {
                                mainImages: [
                                  `${mainImageSplit[0]}/upload/w_565,h_565/${mainImageSplit[1]}`,
                                ],
                                user: user?.User,
                                productName: productName,
                                artImage: artImageDataUrl
                                  ? artImageDataUrl
                                  : artworkImageSplit
                                  ? isSub
                                    ? `${
                                        artworkImageSplit[0]
                                      }/upload/w_${Math.round(
                                        parseInt(width)
                                      )},h_${Math.round(parseInt(height))}/${
                                        artworkImageSplit[1]
                                      }`
                                    : `${
                                        artworkImageSplit[0]
                                      }/upload/w_${Math.round(
                                        width
                                      )},h_${Math.round(height)}/${
                                        artworkImageSplit[1]
                                      }`
                                  : null,

                                x: artwork
                                  ? isSub
                                    ? Math.round(
                                        left / 2 +
                                          x +
                                          (565 - designerSettingsWidth) / 2
                                      )
                                    : Math.round(
                                        (565 - designerSettingsWidth) / 2 +
                                          x +
                                          left / 2
                                      )
                                  : 0,

                                y: artwork
                                  ? isSub
                                    ? parseInt(top + y)
                                    : top + y
                                  : 0,
                              };

                              const response = await dispatch(
                                generateMocks(mockUpPayload)
                              );

                              if (response?.error) {
                                return;
                              }

                              payload.printAreas = payload?.printAreas?.map(
                                (p) => {
                                  if (p?.printAreaName !== printAreaName) {
                                    return p;
                                  }
                                  return {
                                    ...p,
                                    MockupSrc: response[0],
                                  };
                                }
                              );
                            }
                          }
                        }

                        const productSizes = _.find(
                          products,
                          (p) => p?.Product?.ID === ProductID
                        )?.Sizes;

                        const selectedSize = productSizes?.find(
                          (s) => sizeId === s.ID
                        );

                        let price = 0;
                        const quantity = payload?.OliQuantity || 1;

                        // /* Set price according to the white or colored product */
                        price +=
                          colorName !== 'White'
                            ? coloredPrice
                            : whitePrice || coloredPrice;

                        /* Adding Print type price */
                        price += printTypePrice;

                        /* Adding $1 if user used pre-designs */
                        for (let i = 0; i < availableArts?.length; i++) {
                          const p = availableArts[i];
                          const isPreOrPhases =
                            p?.artwork?.includes('Pre-designs') ||
                            p?.artwork?.includes('Shirtly-Phrases');

                          if (isPreOrPhases) {
                            const a = quantity * 1;
                            price += a;
                          }
                        }

                        /* If selected plus print type */
                        if (payload?.Plus) {
                          price += 10;
                        }

                        /* If selected DTG plus print type */
                        if (payload?.DtgPlus) {
                          price += payload?.dtgProcessName?.Price || 0;
                          const b = selectedSize?.DTGPrice
                            ? selectedSize?.DTGPrice * availableArtsLength
                            : 0;
                          price += b;
                        }

                        price *= quantity;

                        const data = {
                          orderId: order.ID,
                          Marketplace: order?.Marketplace,
                          PrintProvider: order?.PrintProvider,
                          user: user?.User,
                          orderLineItemData: {
                            ...payload,
                            FulfillmentCost: price,
                          },
                        };

                        // await dispatch(addLineItem(data));
                        addLineItemData.push(data);
                      }
                    } else {
                      const data = {
                        orderId: order?.ID,
                        user: user?.User,
                        lineItemId: orderLineItem?.ID,
                      };
                      removeLineItemData.push(data);
                    }
                  }
                }
              }

              if (orderErrors?.addressMissing?.length > 0) {
                await dispatch(
                  sendEmail({
                    user: user?.User,
                    subject: `Automation Address Missing`,
                    orderList: orderErrors?.addressMissing,
                  })
                );
              }

              if (removeLineItemData?.length) {
                for (let i = 0; i < removeLineItemData.length; i++) {
                  setSyncProductModal({
                    title: 'Removing Un-Synced Order Line Items',
                    totalOrders: removeLineItemData.length,
                    currentOrder: i,
                  });
                  const element = removeLineItemData[i];
                  await dispatch(removeLineItem(element));
                }
              }

              if (addLineItemData?.length) {
                for (let i = 0; i < addLineItemData.length; i++) {
                  setSyncProductModal({
                    title: 'Syncing Order Line Items',
                    totalOrders: addLineItemData.length,
                    currentOrder: i,
                  });
                  const element = addLineItemData[i];
                  await dispatch(addLineItem(element));
                  const o = await dispatch(
                    getOrderDetail({
                      orderId: element?.orderId,
                      emailid: user?.User,
                      shopName: store?.ShopID,
                      token,
                      productsOk: true,
                    })
                  );

                  const isPolyester = !!o?.OrderLines[0]?.OrderLineItems?.find(
                    (p) => {
                      return (
                        p?.Product?.includes('Polyester') ||
                        p?.Product?.includes('EGMONT')
                      );
                    }
                  );

                  if (isPolyester) {
                    await dispatch(
                      setPrintProvider({
                        ID: element?.orderId,
                        user: user?.User,
                        PrintProvider: 'CA',
                      })
                    );
                  }
                }
              }

              const latestAwaitingOrders = await fetchOrdersData({
                state: 'AwaitingPayment',
                currentPage: 1,
                take: 10,
              });

              const awaitingLoopCount = Math.ceil(
                latestAwaitingOrders?.orderCounts?.AwaitingPayment / 10
              );

              let deletedOrders = [];
              for (let i = 1; i <= awaitingLoopCount; i++) {
                const newOrders = await fetchOrdersData({
                  state: 'AwaitingPayment',
                  currentPage: i,
                  take: 10,
                });

                for (let j = 0; j < newOrders?.orders.length; j++) {
                  const order = newOrders?.orders[j];
                  const orderLines = order?.OrderLines[0];
                  const orderLineItems = orderLines?.OrderLineItems;
                  if (orderLineItems?.length === 0) {
                    deletedOrders.push({
                      user: user?.User,
                      orderId: order?.ID,
                    });
                  }
                }
              }

              if (deletedOrders?.length) {
                for (let j = 0; j < deletedOrders.length; j++) {
                  setSyncProductModal({
                    title: 'Setting Up Orders for Payment',
                    totalOrders: deletedOrders?.length,
                    currentOrder: j,
                  });
                  await dispatch(deleteOrder(deletedOrders[j]));
                }
              }

              setSyncProductModal(null);
            }

            setRetrievalSuccessModal(true);
            fetchAllOrders();
          }}
        />
      )}
      {category === 'AwaitingPayment' && user?.UserData?.automation && (
        <Button
          title="Automation Payment"
          onClick={async () => {
            const cards = await fetchCards();

            if (!cards || !cards?.length) {
              alert('Please add card');
              return;
            }

            const card = cards[0];

            const orders = await fetchAwaitingOrders();

            if (!orders || orders?.orderCounts === 0) {
              return;
            }

            const count = Math.ceil(orders?.orderCounts?.AwaitingPayment / 10);
            let finalOrders = [];
            let charge;

            for (let i = 1; i <= count; i++) {
              const orders = await fetchAwaitingOrders(i);
              for (let j = 0; j < orders?.orders.length; j++) {
                const order = orders?.orders[j];
                const orderLineItems = order?.OrderLines[0]?.OrderLineItems;
                const invalidOrder = orderLineItems?.find(
                  (o) =>
                    !o?.CategoryID || !o?.ProductID || !o?.SizeID || !o?.ColorID
                );

                if (invalidOrder) {
                  continue;
                }

                finalOrders.push(order);
              }
            }

            for (let j = 0; j < finalOrders.length; j++) {
              const order = finalOrders[j];
              const orderLineItems = order?.OrderLines[0]?.OrderLineItems;

              if (j % 10 === 0) {
                let bulkOrders = [];

                for (let k = j; k < j + 10; k++) {
                  if (finalOrders[k]) {
                    bulkOrders.push(finalOrders[k]);
                  }
                }

                charge = await dispatch(
                  bulkStripeCharge(
                    getBulkOrderStripePayload(
                      bulkOrders,
                      products,
                      user?.User,
                      card,
                      taxInfo
                    ),
                    token
                  )
                );
              }

              if (charge.error) {
                await dispatch(
                  sendEmail({
                    user: user?.User,
                    subject: 'Automation Payment Card Error',
                  })
                );
                continue;
              }

              if (
                !charge ||
                !charge?.status ||
                charge?.status !== 'succeeded' ||
                charge?.statusCode === 402 ||
                charge?.statusCode === 400 ||
                charge?.type === 'StripeCardError' ||
                charge?.type === 'StripeInvalidRequestError' ||
                charge?.rawType === 'card_error' ||
                charge?.rawType === 'invalid_request_error' ||
                charge?.code === 'card_declined'
              ) {
                await dispatch(
                  sendEmail({
                    user: user?.User,
                    subject: 'Automation Payment Card Error',
                  })
                );
                continue;
              }

              const shippingCostInfo = getShippingCostInfo(order, products);

              const grandTotalInUsd = totalOrderCostInUSD(
                shippingCostInfo,
                taxInfo,
                order,
                true
              );

              const finalShippingCost = getFinalShippingCost(
                shippingCostInfo,
                taxInfo,
                order
              );

              const totalTax = getShippingTaxCost(
                shippingCostInfo,
                taxInfo,
                order
              );

              const shippingWithoutTax = Number(shippingCostInfo?.cost) || 0;

              const fulfillmentCost = getFulfillmentCost(order);

              const xml = getOrderXML(
                products,
                taxInfo,
                order,
                user?.User,
                countries
              );

              const isEmb = !!orderLineItems?.find(
                (o) => o?.PrintType === 'EMB'
              );

              const submitOrderResponse =
                order?.PrintProvider === 'CA' || isEmb
                  ? await dispatch(submitOrderToCanada(xml, token))
                  : await dispatch(submitOrderToUSA(xml, token));

              const orderStatus = await dispatch(
                await updateOrderStatus({
                  user: user?.User,
                  orderId: order?.ID,
                  status: 'InProduction',
                  productionDate: moment().format('YYYY-MM-DD'),
                })
              );

              await dispatch(
                saveOrderData({
                  orderData: {
                    OrderSubmitToProductionDate: moment().format('YYYY-MM-DD'),
                    ShirtlyOrderData: {
                      ...orderStatus,
                      grandTotalInUSD: grandTotalInUsd,
                      grandTotalInCAD: Number(
                        grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE
                      ).toFixed(2),
                    },
                    StripeChargeInfo: charge,
                    grandTotalInUsd: Number(grandTotalInUsd),
                    grandTotalInCan: Number(
                      (grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE).toFixed(2)
                    ),
                    finalShippingCost,
                    totalTax,
                    shippingWithoutTax,
                    fulfillmentCost,
                  },
                })
              );

              // await dispatch(
              //   orderSubmitNotification({
              //     orderData: order,
              //     grandTotalInUsd: Number(grandTotalInUsd),
              //     grandTotalInCan: Number((grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE).toFixed(2)),
              //     finalShippingCost,
              //     totalTax,
              //     shippingWithoutTax,
              //     fulfillmentCost,
              //   })
              // );
              // const isDuraSoft = orderLineItems?.find(
              //   (p) => p?.PrintType === 'DURASOFT'
              // );

              // const isAOP = orderLineItems?.find((p) => p?.PrintType === 'AOP');

              // if (isDuraSoft) {
              //   await dispatch(
              //     orderDurasoftNotification({
              //       orderData: order,
              //       grandTotalInUsd: Number(grandTotalInUsd),
              //       grandTotalInCan: Number(
              //         (grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE).toFixed(2)
              //       ),
              //       finalShippingCost,
              //       // taxInCad: Number(taxInCad),
              //     })
              //   );
              // }

              // if (isAOP) {
              //   await dispatch(
              //     orderAOPNotification({
              //       orderData: order,
              //       grandTotalInUsd: Number(grandTotalInUsd),
              //       grandTotalInCan: Number(
              //         (grandTotalInUsd * USD_TO_CAD_CONVERSION_RATE).toFixed(2)
              //       ),
              //       finalShippingCost,
              //       // taxInCad: Number(taxInCad),
              //     })
              //   );
              // }
            }

            fetchAllOrders();
          }}
        />
      )}

      {syncProductModal && (
        <SyncProductProgressModal
          open={!!syncProductModal}
          data={syncProductModal}
          setOpen={() => setSyncProductModal(null)}
        />
      )}

      <RetrieveSuccessDialog
        open={retrievalSuccessModal}
        setModal={() => setRetrievalSuccessModal(false)}
      />
      <RetrieveErrorDialog
        open={!!retrievalErrorModal}
        message={retrievalErrorModal}
        setModal={() => setRetrievalErrorModal(false)}
      />
    </AutomationWrapper>
  );
};

export default AutomationPayment;
