import { useState } from "react";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ImageIcon from "@material-ui/icons/Image";
import PaletteIcon from "@material-ui/icons/Palette";
import LayersIcon from "@material-ui/icons/Layers";
import ColorOptions from "pages/customizer-tool/ColorOptions";
import TextOptions from "pages/customizer-tool/TextOptions";
import ImageOption from "pages/customizer-tool/ImageOption";
import LayersOption from "pages/customizer-tool/LayersOption";
import { Container, Options, Option, Body } from "styles/pages/customizer-tool";

const options = { text: 1, image: 2, colors: 3, layers: 4 };

const ModelOptions = ({
  selectedMaterial,
  renderModel,
  setMaterialId,
  garmentPart,
  leftPocket,
  setLeftPocket,
  rightSleeve,
  setRightSleeve,
  back,
  setBack,
}) => {
  const [option, setOption] = useState(options.text);

  return (
    <Container>
      <Options>
        <Option
          onClick={() => setOption(options.text)}
          selected={option === options.text}
        >
          <TextFieldsIcon />
          <h5>Add Text</h5>
        </Option>

        <Option
          selected={option === options.image}
          onClick={() => setOption(options.image)}
        >
          <ImageIcon />
          <h5>Add Image</h5>
        </Option>

        <Option
          selected={option === options.colors}
          onClick={() => setOption(options.colors)}
        >
          <PaletteIcon />
          <h5>Colors</h5>
        </Option>

        <Option
          selected={option === options.layers}
          onClick={() => setOption(options.layers)}
        >
          <LayersIcon />
          <h5>Layers</h5>
        </Option>
      </Options>

      <Body>
        {option === options.colors && (
          <ColorOptions
            selectedMaterial={selectedMaterial}
            renderModel={renderModel}
            setMaterialId={setMaterialId}
          />
        )}

        {option === options.text && (
          <TextOptions
            garmentPart={garmentPart}
            leftPocket={leftPocket}
            setLeftPocket={setLeftPocket}
            rightSleeve={rightSleeve}
            setRightSleeve={setRightSleeve}
            back={back}
            setBack={setBack}
            renderModel={renderModel}
          />
        )}

        {option === options.image && (
          <ImageOption
            garmentPart={garmentPart}
            leftPocket={leftPocket}
            setLeftPocket={setLeftPocket}
            rightSleeve={rightSleeve}
            setRightSleeve={setRightSleeve}
            back={back}
            setBack={setBack}
            renderModel={renderModel}
          />
        )}

        {option === options.layers && (
          <LayersOption
            garmentPart={garmentPart}
            leftPocket={leftPocket}
            setLeftPocket={setLeftPocket}
            rightSleeve={rightSleeve}
            setRightSleeve={setRightSleeve}
            back={back}
            setBack={setBack}
            renderModel={renderModel}
          />
        )}
      </Body>
    </Container>
  );
};

export default ModelOptions;
