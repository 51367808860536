import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  padding: 15px;
`;
const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  margin-bottom: 15px;
`;

export { Container, Wrapper };
