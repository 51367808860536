import { post, get } from '@axios';
import { showLoader, hideLoader } from 'actions/loader';
import { requests } from 'shared/requests';
import { AUTH_LOGOUT } from 'actions/auth';
import { INITIAL_SYNC_PRODUCTS } from 'actions/sync-products';
import { INITIATE_STORES } from 'actions/stores';
import { SHOW_ERROR_MODAL } from 'actions/errorModal';

const stripeCharge = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const stripePayment = await post({
        url: requests.stripeCharge,
        data: data,
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());

      if (stripePayment?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return stripePayment;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const bulkStripeCharge = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const stripePayment = await post({
        url: requests.bulkStripeCharge,
        data: data,
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());

      if (stripePayment?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return stripePayment;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const getAllCards = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const stripePayment = await post({
        url: requests.getAllCards,
        data: data,
      });
      dispatch(hideLoader());
      return stripePayment;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const removeCard = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const card = await post({
        url: requests.removeCard,
        data: data,
      });
      dispatch(hideLoader());
      return card;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const saveCard = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const card = await post({
        url: requests.saveCard,
        data,
      });
      dispatch(hideLoader());
      return card;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updateChargeInformation = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const stripePayment = await post({
        url: requests.updateChargeInformation,
        data: data,
      });
      dispatch(hideLoader());
      return stripePayment;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const paypalCharge = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.paypalCharge,
        data,
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());
      if (request?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return request;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const calculateTax = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await get({
        url: requests.calaculateTax,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const chargeInformation = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.chargeInformation,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const paypalChargeInformation = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.paypalChargeInformation,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const checkJWTtoken = (token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.checkJWTtoken,
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

export {
  stripeCharge,
  getAllCards,
  removeCard,
  updateChargeInformation,
  calculateTax,
  chargeInformation,
  paypalCharge,
  paypalChargeInformation,
  bulkStripeCharge,
  saveCard,
  checkJWTtoken,
};
