import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 60,
    backgroundColor: theme.palette.background.default,
    justifyContent: 'center',
  },
  label: {
    fontFamily: 'Inter600',
    color: '#18191F',
    fontSize: 18,
  },
  img: {
    width: '100%',
  },
  warningImage: {
    marginRight: 10,
    width: 30,
  },
  message: {
    textAlign: 'center',
    fontFamily: 'Inter500',
    fontSize: 16,
  },
}));

const Stepper = ({ order }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = order?.captureImages?.length;

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleStepChange = (step) => setActiveStep(step);

  return (
    <div>
      <AutoPlaySwipeableViews
        autoPlay={false}
        interval={900000000}
        style={{ maxHeight: 350, overflow: 'hidden' }}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents>
        {order?.captureImages?.map((step, index) => {
          const imageSplit = step?.liveImage?.split('/upload/');
          const img =
            Math.abs(activeStep - index) <= 2
              ? imageSplit
                ? `${imageSplit[0]}/upload/${imageSplit[1]}`
                : 'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1631199707/Shirtly/Web_Pages/LandingPage/Header/shirtly-premium-logo_sscytx_royuzk.png'
              : null;

          return (
            <div
              key={`notification${index}`}
              style={{
                backgroundImage: `url(${img})`,
                height: 350,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% 50%',
              }}
            />
          );
        })}
      </AutoPlaySwipeableViews>
      <p className={classes.message}>
        You will see more product images as soon as your order line products
        been printed.
      </p>
      <MobileStepper
        style={{ marginTop: 30, marginBottom: 30 }}
        steps={maxSteps}
        position='static'
        variant='text'
        activeStep={activeStep}
        nextButton={
          <Button
            size='small'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </div>
  );
};

export default Stepper;
