import React from "react";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { showLoader, hideLoader } from "actions/loader";

import Context from "../aiGeneratedImagesContext";
import {
  PrevGeneratedImagesWrapper,
  PrevGeneratedImagesRow,
  PrevGeneratedImages,
  Phase1Value,
  PrevImages,
  StyledSaveIcon,
  PrevImagesWrapper,
  SaveButton,
} from "styles/components/ArtWork/ai-generated-images";
import { ChipsWrapper } from "styles/components/ArtWork/ai-design-phases";

const RenderGeneratedImages = () => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  const { state, setState } = Context();

  const prevAiGeneratedImages = state?.prevAiGeneratedImages;

  return (
    <PrevGeneratedImagesWrapper>
      <PrevGeneratedImagesRow>
        {[...prevAiGeneratedImages]?.reverse()?.map((i, index) => {
          if (index === 0) return null;
          const images = i?.images;
          const phase1SelectedOptions = i?.phase1SelectedOptions;

          return (
            <PrevGeneratedImages>
              <PrevImages>
                {images?.map((q) => (
                  <PrevImagesWrapper>
                    <img src={q?.url} key={index} />

                    <SaveButton
                      title="Save"
                      onClick={async () => {
                        const API_ENDPOINT =
                          "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";
                        const fileData = new FormData();
                        fileData.append("file", q?.url);
                        fileData.append("upload_preset", "tnedst8q");
                        fileData.append(
                          "folder",
                          `Shirtly/${emailid}/AIArtFiles`
                        );
                        dispatch(showLoader());
                        await fetch(API_ENDPOINT, {
                          method: "post",
                          body: fileData,
                        });
                        dispatch(hideLoader());
                        alert(
                          "Your selected image has been saved to AI Library."
                        );
                      }}
                    />
                  </PrevImagesWrapper>
                ))}
              </PrevImages>

              {phase1SelectedOptions?.length > 0 && (
                <ChipsWrapper style={{ margin: "5px 0", overflow: "auto" }}>
                  {phase1SelectedOptions?.map((u) => (
                    <Chip key={u} size="medium" label={u} color="primary" />
                  ))}
                </ChipsWrapper>
              )}

              <Phase1Value style={{ fontSize: "12px" }}>
                {i?.phase1}
              </Phase1Value>
            </PrevGeneratedImages>
          );
        })}
      </PrevGeneratedImagesRow>
    </PrevGeneratedImagesWrapper>
  );
};

export default RenderGeneratedImages;
