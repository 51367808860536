import { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextsLayer from "pages/customizer-tool/TextsLayer";
import ImagesLayer from "pages/customizer-tool/ImagesLayer";
import {
  Container,
  Wrapper,
} from "styles/pages/customizer-tool/layers-options";

const LayersOption = ({
  garmentPart,
  leftPocket,
  setLeftPocket,

  rightSleeve,
  setRightSleeve,
  back,
  setBack,
  renderModel,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Tabs value={value} onChange={handleChange}>
        {["Texts", "Images"]?.map((p) => (
          <Tab key={p} label={p} />
        ))}
      </Tabs>

      <Wrapper>
        {value === 0 && (
          <TextsLayer
            garmentPart={garmentPart}
            leftPocket={leftPocket}
            rightSleeve={rightSleeve}
            back={back}
            setLeftPocket={setLeftPocket}
            setRightSleeve={setRightSleeve}
            setBack={setBack}
            renderModel={renderModel}
          />
        )}

        {value === 1 && (
          <ImagesLayer
            garmentPart={garmentPart}
            leftPocket={leftPocket}
            rightSleeve={rightSleeve}
            back={back}
            setLeftPocket={setLeftPocket}
            setRightSleeve={setRightSleeve}
            setBack={setBack}
            renderModel={renderModel}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default LayersOption;
