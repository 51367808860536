import React from "react";
import Phases from "components/ArtWork/AiDesignPhases";
// import AIGeneratedImagesHeader from "components/ArtWork/AIGeneratedImagesHeader";
import {
  RightWrapper,
  PhasesHeader,
} from "styles/components/ArtWork/ai-generated-images";

const AIGeneratedImagesRight = ({ handleClose }) => (
  <RightWrapper>
    {/* <AIGeneratedImagesHeader handleClose={handleClose} /> */}
    <PhasesHeader>Ai Design Studio</PhasesHeader>
    <Phases />
  </RightWrapper>
);

export default AIGeneratedImagesRight;
