import { useEffect, useState } from "react";
import moment from "moment";
import { showLoader, hideLoader } from "actions/loader";
import Context, { Steps } from "pages/ai-mock-up-generator/context";
import { useDispatch, useSelector } from "react-redux";
import { getAIDesigns } from "actions/designer";
// import { backgroundRemove } from "actions/user";
import {
  Container,
  Wrapper,
  SelectButton,
  BackBurnButton,
} from "styles/pages/ai-mock-up-generator/ai-images-list";

const onloadImageProcess = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const AIDesignList = () => {
  const { setState, state: mState } = Context();
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const [images, setImages] = useState([]);

  const fetchAIDesigns = async () => {
    const aiDesigns = await dispatch(getAIDesigns({ user: emailid }));

    setImages(aiDesigns?.resources);
  };

  useEffect(() => {
    if (!images.length) fetchAIDesigns();
  }, [images]);

  const onSelect = async (file) => {
    let secureUrl = file.secure_url;
    await onloadImageProcess(secureUrl);

    const selectedPrintArea = mState?.artwork?.printAreas?.find(
      (p) => p?.selected
    );

    const designerSettings = selectedPrintArea?.designerSettings;
    const ArtWorkCoordinates = selectedPrintArea?.coordinates || {};
    const { width: fileWidth, height: fileHeight } = file;
    const { width: designedWidth, height: designedHeight } = designerSettings;

    if (designedWidth > designedHeight) {
      const aspectRatio = fileWidth / fileHeight;
      if (fileWidth > fileHeight) {
        if (fileWidth > designedWidth) {
          ArtWorkCoordinates.height = Math.round(designedWidth / aspectRatio);
          ArtWorkCoordinates.width = designedWidth;
          if (ArtWorkCoordinates.height > designedHeight) {
            ArtWorkCoordinates.height = designedHeight;
          }
        } else {
          ArtWorkCoordinates.height = designedHeight;
          ArtWorkCoordinates.width = Math.round(designedHeight * aspectRatio);
        }
      } else {
        ArtWorkCoordinates.height = designedHeight;
        ArtWorkCoordinates.width = Math.round(designedHeight * aspectRatio);
      }
    } else if (fileWidth <= designedWidth && fileHeight <= designedHeight) {
      ArtWorkCoordinates.width = fileWidth;
      ArtWorkCoordinates.height = fileHeight;
    } else if (fileWidth > fileHeight) {
      const aspectRatio = fileWidth / fileHeight;
      ArtWorkCoordinates.width = designedWidth;
      ArtWorkCoordinates.height = Math.round(designedWidth / aspectRatio);
    } else if (fileWidth < fileHeight) {
      const aspectRatio = fileWidth / fileHeight;
      if (designedHeight * aspectRatio > designedWidth) {
        ArtWorkCoordinates.width = designedWidth;
        ArtWorkCoordinates.height = Math.round(designedWidth / aspectRatio);
      } else {
        ArtWorkCoordinates.height = designedHeight;
        ArtWorkCoordinates.width = Math.round(designedHeight * aspectRatio);
      }
    } else if (fileWidth === fileHeight) {
      if (designedWidth > designedHeight) {
        ArtWorkCoordinates.height = designedHeight;
        ArtWorkCoordinates.width = designedHeight;
      } else {
        ArtWorkCoordinates.height = designedWidth;
        ArtWorkCoordinates.width = designedWidth;
      }
    }

    ArtWorkCoordinates.x = 0;
    ArtWorkCoordinates.y = 0;

    setState({
      ...mState,
      step: Steps.MockUp,
      artwork: {
        ...mState.artwork,
        printAreas: mState.artwork?.printAreas?.map((p) =>
          p?.selected
            ? {
                ...p,
                artwork: secureUrl,
                artworkId: file.public_id,
                coordinates: ArtWorkCoordinates,
                aiGenerateImages: true,
                aiTempArtwork: file,
              }
            : p
        ),
      },
    });
  };

  return (
    <Container>
      <h3 style={{ textAlign: "center" }}>Scroll Over for More Options</h3>
      {images?.map((i) => (
        <Wrapper key={i?.asset_id}>
          <img src={i?.secure_url} />
          <SelectButton
            onClick={() => {
              onSelect(i);
            }}
          >
            Select
          </SelectButton>

          <BackBurnButton
            onClick={async () => {
              dispatch(showLoader());

              const secureUrlSplit = i.secure_url.split("/upload/");
              const url = `${secureUrlSplit[0]}/upload/e_background_removal/${secureUrlSplit[1]}`;

              const image = new Image();

              image.onload = () => {
                dispatch(hideLoader());

                onSelect({
                  ...i,
                  secure_url: url,
                });
              };

              image.onerror = () => {
                dispatch(showLoader());
                image.src = `${url}?timestamp=${moment().unix()}`;
              };

              image.src = url;
            }}
          >
            Remove
            <br />
            Background
          </BackBurnButton>
        </Wrapper>
      ))}
    </Container>
  );
};

export default AIDesignList;
