import * as React from 'react';

const Icon = (props) => (
  <svg
    width={27}
    height={27}
    viewBox='0 0 27 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M23.625 0H3.375A3.378 3.378 0 000 3.375v20.25A3.378 3.378 0 003.375 27H13.5v-9.281h-3.375V13.5H13.5v-3.375a5.062 5.062 0 015.063-5.063h3.375v4.22H20.25c-.931 0-1.688-.088-1.688.843V13.5h4.22l-1.688 4.219h-2.532V27h5.063A3.378 3.378 0 0027 23.625V3.375A3.378 3.378 0 0023.625 0z'
      fill='#fff'
    />
  </svg>
);

export default Icon;
