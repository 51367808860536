import { post } from "@axios";
import moment from "moment";
import { showLoader, hideLoader } from "actions/loader";
import { checkAppVersion } from "actions/user";
import { requests } from "shared/requests";
import { AUTH_LOGOUT } from "actions/auth";
import { INITIAL_SYNC_PRODUCTS } from "actions/sync-products";
import { INITIATE_STORES } from "actions/stores";
import { SHOW_ERROR_MODAL } from "actions/errorModal";
import * as a from "actions/ordersChartData";

const getOrders = ({ emailid, state, skip }) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: `${requests.getOrders}?Take=10&Skip=${skip}&State=${state}`,
        data: {
          user: emailid,
        },
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getOrderByID = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.getOrderById,
        data: data,
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getEtsyOrders = (emailid, shopID, id, offset) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.etsyOrders,
        data: {
          user: emailid,
          shopName: shopID,
          shop_id: id,
          offset,
        },
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getShopifyOrdersCount = (emailid, shopName) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.getShopifyOrdersCount,
        data: {
          user: emailid,
          shopName,
        },
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.error };
    }
  };
};

const getShopifyOrders = (emailid, shopName) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.getShopifyOrders,
        data: {
          user: emailid,
          shopName,
        },
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.error };
    }
  };
};

const getShopifyMoreOrders = (emailid, shopName, next_page_info) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.getShopifyMoreOrders,
        data: {
          user: emailid,
          shopName,
          next_page_info,
        },
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.error };
    }
  };
};

const getBigCommerceOrders = (emailid, shopName) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.bcAllOrders,
        data: {
          user: emailid,
          shopName,
        },
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.error };
    }
  };
};

const bigCartelAllOrders = (emailid, shopName, offset) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.bigCartelAllOrders,
        data: {
          user: emailid,
          shopName,
          offset,
        },
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.error };
    }
  };
};

const orderGiftMessageNotification = (payload) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.orderGiftMessageNotification,
        data: payload,
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.error };
    }
  };
};

const wooAllOrders = (emailid, shopName) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.wooAllOrders,
        data: {
          user: emailid,
          shopName,
        },
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.error };
    }
  };
};

const wooMoreOrders = (emailid, shopName, next_page_info) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.wooMoreOrders,
        data: {
          user: emailid,
          shopName,
          next_page_info,
        },
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.error };
    }
  };
};

const bcThisOrder = (emailid, shopName, orderId) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.bcThisOrder,
        data: {
          user: emailid,
          shopName,
          orderId,
        },
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.error };
    }
  };
};

const createOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.createOrder,
        data: data,
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const findGlobalOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await post({
        url: requests.findGlobalOrder,
        data: data,
      });
      dispatch(hideLoader());
      return orders;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getOrderDetail = ({
  orderId,
  emailid,
  shopName,
  printTypeDeleted = false,
  token,
  productsOk = false,
}) => {
  return async (dispatch) => {
    dispatch(showLoader());
    const newAppVersion = await dispatch(checkAppVersion());
    const currentAppVersion = await window?.localStorage?.getItem("appVersion");

    if (newAppVersion?.CurrentAppVersion != currentAppVersion) {
      window.localStorage.setItem(
        "appVersion",
        newAppVersion?.CurrentAppVersion
      );
      window.location.reload();
      return;
    }

    try {
      const order = await post({
        url: `${requests.getOrderDetail}${orderId}`,
        data: {
          user: emailid,
          shopName,
          printTypeDeleted,
          productsOk,
        },
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());

      if (order?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return order;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const machineOrderDetails = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const order = await post({
        url: requests.machineOrderDetails,
        data,
      });
      dispatch(hideLoader());
      return order;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const deleteOrder = (orderId) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const order = await post({
        url: requests.deleteOrder,
        data: orderId,
      });
      dispatch(hideLoader());
      return order;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const deleteBulkOrders = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const order = await post({
        url: requests.deleteBulkOrders,
        data,
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());
      if (order?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return order;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const addLineItem = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    const newAppVersion = await dispatch(checkAppVersion());
    const currentAppVersion = await window?.localStorage?.getItem("appVersion");

    if (newAppVersion?.CurrentAppVersion != currentAppVersion) {
      window.localStorage.setItem(
        "appVersion",
        newAppVersion?.CurrentAppVersion
      );
      window.location.reload();
      return;
    }

    try {
      const request = await post({
        url: requests.addlineItem,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const editOrderCustomerInformation = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.editOrderCustomerInformation,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const sendEmail = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.sendEmail,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const removeLineItem = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.removeLineItem,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updateOrderCost = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.updateCost,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getFulfillmentCost = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    const newAppVersion = await dispatch(checkAppVersion());
    const currentAppVersion = await window?.localStorage?.getItem("appVersion");

    if (newAppVersion?.CurrentAppVersion != currentAppVersion) {
      window.localStorage.setItem(
        "appVersion",
        newAppVersion?.CurrentAppVersion
      );
      window.location.reload();
      return;
    }
    try {
      const request = await post({
        url: requests.getFulfillmentCost,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const submitOrderToCanada = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.submitOrderToCanada,
        data: {
          orderString: data,
        },
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());
      if (request?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const setPrintProvider = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.setPrintProvider,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const submitOrderToUSA = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.submitOrderToUSA,
        data: {
          orderString: data,
        },
        token,
      });
      dispatch(hideLoader());
      if (request?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const submitOrderToEU = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.submitOrderToEU,
        data,
        token,
      });
      dispatch(hideLoader());
      if (request?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const submitOrderToNY = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.submitOrderToNY,
        data,
        token,
      });
      dispatch(hideLoader());
      if (request?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const submitOrderToAU = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.submitOrderToAU,
        data,
        token,
      });
      dispatch(hideLoader());
      if (request?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updateOrderStatus = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.updateStatus,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updateSavedOrderData = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.updateSavedOrderData,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const uploadArtToCloudinary = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.uploadArtToCloudinary,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const saveOrderData = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.saveOrderData,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const orderSubmitNotification = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.orderSubmitNotification,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const orderDurasoftNotification = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.orderDurasoftNotification,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updateOrderPackingMode = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.packingUpdate,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const cancelOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.cancelOrderFromMachine,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const generateMocks = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.generateMocks,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getAllSubmittedOrders = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.allSubmittedOrders,
        data,
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());

      if (request?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const allSubmittedOrdersForProduct = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.allSubmittedOrdersForProduct,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const printPreviewOrders = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.printPreviewOrders,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const dateFilterAllSubmittedOrders = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.dateFilterAllSubmittedOrders,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const searchAllSubmittedOrders = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.searchAllSubmittedOrders,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const userSubmittedOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.userSubmittedOrder,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const changeOrderStatus = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.changeOrderStatus,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const marketPlaceOrderDetails = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.marketPlaceOrderDetails,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const filterAllSubmittedOrders = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.filterAllSubmittedOrders,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const downloadArtFile = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.downloadArtFile,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const saveArtFiles = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.saveArtFiles,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getPaypalTransactionId = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getPaypalTransactionId,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const orderShippedNotification = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.orderShippedNotification,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getTrackingCANumber = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getTrackingCANumber,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getTrackingUSNumber = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getTrackingUSNumber,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getSLAData = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getSLAData,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const generateArtboard = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.generateArtboard,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const allOrderReport = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.allOrderReport,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const allOrderReportForChart = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.allOrderReportForChart,
        data,
      });

      if (moment(data?.endDate).month() === moment().month()) {
        dispatch({ type: a.UPDATE_ORDERS_CHART_DATA, data: request });
      }

      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const allUserOrderReport = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.allUserOrderReport,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const duplicateOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.DuplicateOrder,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const orderAOPNotification = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.orderAOPNotification,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const closeShopifyOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.closeShopifyOrder,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const closeBigCartelOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.closeBigCartelOrder,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const closeWooOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.closeWooOrder,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const closeBcOrder = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.closeBcOrder,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const verifyArtFile = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.verifyArtFile,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const adminVerifyArtFile = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.adminVerifyArtFile,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const saveAdvanceArtFiles = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.saveAdvanceArtFiles,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const saveAllCosts = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.saveAllCosts,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getSSActiveWearProductData = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getSSActiveWearProductData,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getAUSplantStock = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.getAUSplantStock,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const orderTuffPrintTransferNotification = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const request = await post({
        url: requests.orderTuffPrintTransferNotification,
        data,
      });
      dispatch(hideLoader());
      return request;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

export {
  submitOrderToNY,
  saveAllCosts,
  allUserOrderReport,
  getOrders,
  createOrder,
  getOrderDetail,
  deleteOrder,
  addLineItem,
  editOrderCustomerInformation,
  removeLineItem,
  getFulfillmentCost,
  submitOrderToCanada,
  getShopifyOrders,
  getOrderByID,
  getEtsyOrders,
  updateOrderCost,
  generateMocks,
  submitOrderToUSA,
  updateOrderStatus,
  saveOrderData,
  machineOrderDetails,
  updateSavedOrderData,
  getAllSubmittedOrders,
  searchAllSubmittedOrders,
  orderSubmitNotification,
  marketPlaceOrderDetails,
  userSubmittedOrder,
  filterAllSubmittedOrders,
  cancelOrder,
  saveArtFiles,
  downloadArtFile,
  getPaypalTransactionId,
  orderShippedNotification,
  getTrackingCANumber,
  getTrackingUSNumber,
  generateArtboard,
  dateFilterAllSubmittedOrders,
  allOrderReport,
  getSLAData,
  duplicateOrder,
  uploadArtToCloudinary,
  allSubmittedOrdersForProduct,
  orderDurasoftNotification,
  orderAOPNotification,
  allOrderReportForChart,
  getBigCommerceOrders,
  bcThisOrder,
  wooAllOrders,
  getShopifyOrdersCount,
  getShopifyMoreOrders,
  wooMoreOrders,
  setPrintProvider,
  deleteBulkOrders,
  closeShopifyOrder,
  closeWooOrder,
  closeBcOrder,
  bigCartelAllOrders,
  closeBigCartelOrder,
  findGlobalOrder,
  changeOrderStatus,
  sendEmail,
  printPreviewOrders,
  verifyArtFile,
  adminVerifyArtFile,
  saveAdvanceArtFiles,
  updateOrderPackingMode,
  getSSActiveWearProductData,
  submitOrderToEU,
  submitOrderToAU,
  getAUSplantStock,
  orderTuffPrintTransferNotification,
  orderGiftMessageNotification,
};
