import styled from "styled-components";

const Container = styled.div`
  background: #000;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  border: 1px solid #fff;
  padding: 10px;
  margin-bottom: 10px;
  img {
    width: 100%;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
`;

const Wrapper = styled.div`
  flex: 1;
  background-color: #fff;
  overflow: auto;
`;

export { Container, Wrapper, Header };
