import UploadSection from 'pages/create-product/UploadSection';
import ProductNameStyle from 'pages/create-product/ProductNameStyle';
import ProductDescriptionTable from 'pages/create-product/productDescriptionTable';
import { Container, Wrapper } from 'styles/pages/create-product';
const CreateProduct = () => (
  <Container>
    <Wrapper>
      <UploadSection />
      <ProductNameStyle />
    </Wrapper>
    <Wrapper>
      <ProductDescriptionTable />
    </Wrapper>
  </Container>
);

export default CreateProduct;
