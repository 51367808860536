import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles((theme) => ({
  badge: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
}))(Badge);

export default StyledBadge;
