import React, { useState } from 'react';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import CardDetail from 'pages/account-settings/CardDialog';
import Button from 'components/Button';
import ImageSection from 'pages/account-settings/ImageSection';
import DeleteIcon from '@material-ui/icons/Delete';
import CardImage from 'assets/images/cardImage.png';
import {
  Container,
  TextSection,
  CardInstructions,
  NoCardFound,
  CardWrapper,
  DeleteCard,
  CardContainer,
  SuccessIcon,
} from 'styles/pages/account-setting/passwordForm';

const PaymentOptions = ({ cards, removeCard, saveCard }) => {
  const [addCard, setAddCard] = useState(null);
  return (
    <Container>
      <TextSection>
        {!!cards?.length ? (
          <CardInstructions>
            First card is always selected as default payment option for
            Automation
          </CardInstructions>
        ) : (
          <NoCardFound>
            No Card Found and Automation will be turned off automatically
          </NoCardFound>
        )}

        {cards?.map &&
          cards.map((card, index) => {
            const regex = /\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/gm;
            const mask = `**** **** **** `;
            const cardNo = CryptoJS.AES.decrypt(
              card.cardNumber,
              'shirtly'
            ).toString(CryptoJS.enc.Utf8);

            return (
              <CardContainer key={`card${index}`}>
                <CardWrapper selected={index === 0}>
                  {index === 0 && <SuccessIcon />}
                  <img src={CardImage} alt='CardImage' />
                  <h3>{cardNo.replace(regex, mask)}</h3>
                </CardWrapper>
                {_.isFunction(removeCard) && (
                  <DeleteCard>
                    <DeleteIcon onClick={() => removeCard(card)} />
                  </DeleteCard>
                )}
              </CardContainer>
            );
          })}

        <div className='add-payment'>
          <Button title='Add Card' onClick={() => setAddCard(true)} />
        </div>
      </TextSection>
      <ImageSection />

      {addCard && (
        <CardDetail
          open={addCard}
          hide={() => setAddCard(false)}
          saveCard={saveCard}
        />
      )}
    </Container>
  );
};

export default PaymentOptions;
