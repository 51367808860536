import * as React from 'react';

const Icon = (props) => (
  <svg
    width={32}
    height={32}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    // className={className}
    {...props}>
    <path
      d='M27.137 4.863A15.75 15.75 0 104.866 27.137 15.75 15.75 0 0027.138 4.863zm-5.512 15.12a1.125 1.125 0 010 1.642 1.123 1.123 0 01-.799.326 1.125 1.125 0 01-.787-.326L16 17.586l-3.982 4.039a1.125 1.125 0 01-1.587 0 1.125 1.125 0 010-1.586L14.414 16l-3.983-3.982a1.125 1.125 0 011.587-1.587L16 14.414l3.983-3.983a1.14 1.14 0 011.883 1.212 1.14 1.14 0 01-.241.374L17.586 16l4.039 3.983z'
      fill='#000'
      fillOpacity={0.5}
    />
  </svg>
);

export default Icon;
