import {
  Container,
  IconUpload,
  MarketingUpload,
  Description,
} from 'styles/pages/create-product/uploadSection';

const UploadSection = () => (
  <Container>
    <IconUpload>
      <h4>Icon Upload</h4>
      <div></div>
    </IconUpload>
    <MarketingUpload>
      <h4>Marketing Upload</h4>
      <div></div>
    </MarketingUpload>
    <Description>
      <h4>Description</h4>
      <div></div>
    </Description>
  </Container>
);

export default UploadSection;
