import * as a from 'actions/loader';
const initial = {
  visibility: false,
};

const categories = (state = initial, action = null) => {
  switch (action.type) {
    case a.SHOW_LOADER:
      return {
        ...state,
        visibility: true,
      };
    case a.HIDE_LOADER:
      return {
        ...state,
        visibility: false,
      };
    default:
      return state;
  }
};

export default categories;
