import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Button from "components/Button";
import TextField from "components/TextField";
import {
  machineOrderDetails,
  updateSavedOrderData,
  orderShippedNotification,
  closeShopifyOrder,
  closeBigCartelOrder,
  closeWooOrder,
  closeBcOrder,
  getTrackingCANumber,
  getTrackingUSNumber,
  updateOrderStatus,
  getSLAData,
} from "actions/orders";
const XMLParser = require("react-xml-parser");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function UpdateOrderStatusModal({ open, setOpen, csv }) {
  const dispatch = useDispatch();
  const [orderIndex, setOrderIndex] = useState(0);
  const [orders, setOrders] = useState(undefined);
  const [delayDays, setDelayDays] = useState(0);

  useEffect(() => {
    const updateOrder = async () => {
      try {
        for await (let order of orders) {
          const printProvider = order?.ShirtlyOrderData?.PrintProvider;
          const isEU = printProvider === "EU";
          const isAU = printProvider === "AU";
          const isNY = printProvider === "NY";

          setOrderIndex((state) => state + 1);
          await new Promise(async (resolve, reject) => {
            let isOrderPushedToMachine = true;

            if (
              order?.ShirtlyOrderData?.Status === "InProduction" ||
              order?.ShirtlyOrderData?.Status === "Shipped"
            ) {
              const orderLineItems =
                order?.ShirtlyOrderData?.OrderLines[0]?.OrderLineItems;
              const isEmb = !!orderLineItems?.find(
                (o) => o?.PrintType === "EMB"
              );

              const machineStatusData = await dispatch(
                machineOrderDetails({
                  PrintProvider: isEmb ? "CA" : printProvider,
                  orderPo: order?.ShirtlyOrderData?.ReceiptID,
                })
              );

              isOrderPushedToMachine =
                !machineStatusData?.string?._text?.includes("No Order found");

              if (isOrderPushedToMachine) {
                const parsedMachineStatusData =
                  !isEU && !isAU && !isNY
                    ? new XMLParser().parseFromString(
                        machineStatusData?.string?._text
                      )
                    : machineStatusData;

                const status =
                  machineStatusData?.status ||
                  (machineStatusData?.msg
                    ? machineStatusData?.msg[0]?.order_status
                    : null);

                const shipped =
                  isEU || isAU || isNY
                    ? status === "sent" ||
                      status === "shipped" ||
                      status === "Sent" ||
                      status === "Shipped"
                    : parsedMachineStatusData.getElementsByTagName("Shipped");

                const trackingNumber =
                  isEU || isAU || isNY
                    ? machineStatusData?.tracking_number ||
                      machineStatusData?.msg
                      ? machineStatusData?.msg[0]?.tracking_url
                      : ""
                    : parsedMachineStatusData.getElementsByTagName(
                        "trackingNumber"
                      );

                const shipDate =
                  isEU || isAU || isNY
                    ? status === "sent" ||
                      status === "shipped" ||
                      status === "Sent" ||
                      status === "Shipped"
                      ? machineStatusData?.ship_date ||
                        machineStatusData?.msg[0]?.completed_on ||
                        moment().format("YYYY-MM-DD")
                      : null
                    : parsedMachineStatusData.getElementsByTagName("shipDate");

                if (
                  ((!isEU && !isAU && !isNY && shipped[0]?.value === "true") ||
                    ((isEU || isAU || isNY) && !!shipped)) &&
                  order?.ShirtlyOrderData?.Status === "InProduction"
                ) {
                  await dispatch(
                    updateOrderStatus({
                      user: order?.ShirtlyOrderData?.MasterUserEmail,
                      orderId: order?.ShirtlyOrderData?.ID,
                      status: "Shipped",
                      trackingNumber: trackingNumber[0]?.value,
                      shipDate:
                        isEU || isAU || isNY
                          ? shipDate
                          : moment(shipDate[0]?.value).format("YYYY-MM-DD"),
                    })
                  );

                  await dispatch(
                    updateSavedOrderData({
                      orderId: order?.ShirtlyOrderData?.ID,
                      trackingNumber: trackingNumber[0]?.value,
                      shipDate:
                        isEU || isAU || isNY
                          ? shipDate
                          : moment(shipDate[0]?.value).format("YYYY-MM-DD"),
                    })
                  );

                  await dispatch(
                    orderShippedNotification({
                      order: {
                        ReceiptID: order?.ShirtlyOrderData?.ReceiptID,
                        Marketplace: order?.ShirtlyOrderData?.Marketplace,
                        BuyerName: order?.ShirtlyOrderData?.BuyerName,
                        CountryString: order?.ShirtlyOrderData?.CountryString,
                        MasterUserEmail:
                          order?.ShirtlyOrderData?.MasterUserEmail,
                      },
                      orderId: order?.ShirtlyOrderData?.ID,
                      trackingNumber:
                        trackingNumber[0]?.value ||
                        machineStatusData?.tracking_number,
                      shipDate:
                        isEU || isAU || isNY
                          ? shipDate
                          : moment(shipDate[0]?.value).format("YYYY-MM-DD"),
                    })
                  );

                  if (
                    order?.ShirtlyOrderData?.Store?.MarketplaceType ===
                    "Shopify"
                  ) {
                    await dispatch(
                      closeShopifyOrder({
                        shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                        orderId: order?.ShirtlyOrderData?.ID,
                        user: order?.ShirtlyOrderData?.MasterUserEmail,
                      })
                    );
                  } else if (
                    order?.ShirtlyOrderData?.Store?.MarketplaceType ===
                    "WooCommerce"
                  ) {
                    await dispatch(
                      closeWooOrder({
                        shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                        orderId: order?.ShirtlyOrderData?.ID,
                        user: order?.ShirtlyOrderData?.MasterUserEmail,
                      })
                    );
                  } else if (
                    order?.ShirtlyOrderData?.Store?.MarketplaceType ===
                    "BigCommerce"
                  ) {
                    await dispatch(
                      closeBcOrder({
                        shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                        orderId: order?.ShirtlyOrderData?.ID,
                        user: order?.ShirtlyOrderData?.MasterUserEmail,
                        trackingNumber: trackingNumber[0]?.value,
                        item: orderLineItems?.map((o) => ({
                          order_product_id: o?.MarketplaceDetails?.id,
                          quantity: o?.MarketplaceDetails?.quantity,
                        })),
                      })
                    );
                  } else if (
                    order?.ShirtlyOrderData?.Store?.MarketplaceType ===
                    "BigCartel"
                  ) {
                    await dispatch(
                      closeBigCartelOrder({
                        shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                        orderId: order?.ShirtlyOrderData?.ID,
                        user: order?.ShirtlyOrderData?.MasterUserEmail,
                      })
                    );
                  }

                  resolve();
                } else if (order?.ShirtlyOrderData?.Status !== "Shipped") {
                  let response;
                  if (order?.ShirtlyOrderData?.PrintProvider === "US") {
                    response = await dispatch(
                      getTrackingUSNumber({
                        orderId: order?.ShirtlyOrderData?.ReceiptID,
                      })
                    );
                  } else {
                    response = await dispatch(
                      getTrackingCANumber({
                        orderId: order?.ShirtlyOrderData?.ReceiptID,
                      })
                    );
                  }
                  if (response?.shipments?.length > 0) {
                    await dispatch(
                      updateOrderStatus({
                        user: order?.ShirtlyOrderData?.MasterUserEmail,
                        orderId: order?.ShirtlyOrderData?.ID,
                        status: "Shipped",
                        trackingNumber: response?.shipments[0].trackingNumber,
                        shipDate: moment(
                          response?.shipments[0].shipDate
                        ).format("YYYY-MM-DD"),
                      })
                    );

                    await dispatch(
                      updateSavedOrderData({
                        orderId: order?.ShirtlyOrderData?.ID,
                        trackingNumber: response?.shipments[0].trackingNumber,
                        shipDate: moment(
                          response?.shipments[0].shipDate
                        ).format("YYYY-MM-DD"),
                      })
                    );

                    await dispatch(
                      orderShippedNotification({
                        order: {
                          ReceiptID: order?.ShirtlyOrderData?.ReceiptID,
                          Marketplace: order?.ShirtlyOrderData?.Marketplace,
                          BuyerName: order?.ShirtlyOrderData?.BuyerName,
                          CountryString: order?.ShirtlyOrderData?.CountryString,
                          MasterUserEmail:
                            order?.ShirtlyOrderData?.MasterUserEmail,
                        },
                        orderId: order?.ShirtlyOrderData?.ID,
                        trackingNumber: response?.shipments[0].trackingNumber,
                        shipDate: moment(
                          response?.shipments[0].shipDate
                        ).format("YYYY-MM-DD"),
                      })
                    );

                    if (
                      order?.ShirtlyOrderData?.Store?.MarketplaceType ===
                      "Shopify"
                    ) {
                      await dispatch(
                        closeShopifyOrder({
                          shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                          orderId: order?.ShirtlyOrderData?.ID,
                          user: order?.ShirtlyOrderData?.MasterUserEmail,
                        })
                      );
                    } else if (
                      order?.ShirtlyOrderData?.Store?.MarketplaceType ===
                      "WooCommerce"
                    ) {
                      await dispatch(
                        closeWooOrder({
                          shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                          orderId: order?.ShirtlyOrderData?.ID,
                          user: order?.ShirtlyOrderData?.MasterUserEmail,
                        })
                      );
                    } else if (
                      order?.ShirtlyOrderData?.Store?.MarketplaceType ===
                      "BigCommerce"
                    ) {
                      await dispatch(
                        closeBcOrder({
                          shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                          orderId: order?.ShirtlyOrderData?.ID,
                          user: order?.ShirtlyOrderData?.MasterUserEmail,
                          trackingNumber: response?.shipments[0].trackingNumber,
                          item: orderLineItems?.map((o) => ({
                            order_product_id: o?.MarketplaceDetails?.id,
                            quantity: o?.MarketplaceDetails?.quantity,
                          })),
                        })
                      );
                    } else if (
                      order?.ShirtlyOrderData?.Store?.MarketplaceType ===
                      "BigCartel"
                    ) {
                      await dispatch(
                        closeBigCartelOrder({
                          shopName: order?.ShirtlyOrderData?.Store?.ShopName,
                          orderId: order?.ShirtlyOrderData?.ID,
                          user: order?.ShirtlyOrderData?.MasterUserEmail,
                        })
                      );
                    }
                    resolve();
                  }
                }
              }

              resolve();
            }
          });
        }
        setOpen({ open: false, orders });
      } catch (e) {
        setOpen({ open: false });
      }
    };

    if (orders?.length && !csv) {
      updateOrder();
    }
  }, [dispatch, setOrderIndex, orders]);

  return (
    <Dialog disableBackdropClick={true} open={open}>
      <DialogTitle>
        {!orders ? "Please enter the delay days" : "Checking Order Status"}
      </DialogTitle>
      <DialogContent dividers>
        {orders ? (
          <Typography gutterBottom>
            Checking and Updating Order: {orderIndex}/{orders?.length}
          </Typography>
        ) : (
          <TextField
            style={{ width: "100%" }}
            label="Enter delay days"
            type="number"
            value={delayDays}
            onChange={(v) => setDelayDays(v)}
          />
        )}

        {!orders && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gridGap: 10,
            }}
          >
            <Button
              containerStyle={{
                backgroundColor: "red",
                color: "#fff",
                fontWeight: "bold",
                boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
                textTransform: "none",
                borderRadius: "50px",
                marginTop: "12px",
                fontSize: "12px",
                lineHeight: "1.5",
              }}
              onClick={() => setOpen({ open: false })}
              title="Cancel"
            />
            <Button
              disabled={!delayDays}
              containerStyle={{
                backgroundColor: "#000",
                color: "#fff",
                fontWeight: "bold",
                boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
                textTransform: "none",
                borderRadius: "50px",
                marginTop: "12px",
                fontSize: "12px",
                lineHeight: "1.5",
              }}
              onClick={async () => {
                const response = await dispatch(
                  getSLAData({ delayDays: Number(delayDays) })
                );
                if (response?.error) {
                  return;
                }
                setOrders(response?.orders);
                if (csv) {
                  setOpen({
                    open: false,
                    orders: response?.orders,
                  });
                }
              }}
              title="Ok"
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
