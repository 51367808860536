import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { columns } from 'pages/my-stores/stores/columns';
import { rows } from 'pages/my-stores/stores/rows';

const useStyles = makeStyles({
  td: {
    '& img': {
      width: 50,
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'none',
    },
  },
});

const Stores = ({ removeStore }) => {
  const classes = useStyles();
  const stores = useSelector((state) => state?.stores?.data);
  const data = rows(stores, removeStore);

  return data.map((row) => (
    <TableRow hover role='checkbox' tabIndex={-1} key={`tableRow${row.code}`}>
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell
            className={classes.td}
            key={`storeBody${column.id}`}
            align={column.align}>
            {value}
          </TableCell>
        );
      })}
    </TableRow>
  ));
};

export default Stores;
