import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SubHeader from "components/SubHeader";
import { CSVLink } from "react-csv";
import { ROUTES } from "shared/constants/routes";
import SearchBar from "components/SearchBar";
import OrderModal from "pages/warehouse/orderModal";
import UpdateOrderStatusModal from "pages/warehouse/UpdateOrdersModal";
import {
  getAllSubmittedOrders,
  searchAllSubmittedOrders,
  machineOrderDetails,
  updateOrderStatus,
  updateSavedOrderData,
  submitOrderToCanada,
  submitOrderToUSA,
  filterAllSubmittedOrders,
  orderShippedNotification,
  getTrackingCANumber,
  getTrackingUSNumber,
  allSubmittedOrdersForProduct,
  printPreviewOrders,
} from "actions/orders";
import { allCustomGraphicsUsers } from "actions/designer";
import EcoIcon from "@material-ui/icons/Eco";
import Pagination from "components/Pagination";
import USAFlag from "assets/images/usa-flag.png";
import CADFlag from "assets/images/canada-flag.png";
import EUFlag from "assets/images/eu-flag.png";
import AUFlag from "assets/images/aus-flag.jpeg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import Select from "components/Select";
import Stripe from "components/Stripe";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/Button";
import COLORS from "shared/constants/colors";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { default as MUISelect } from "@material-ui/core/Select";
import BulkOrderSummary from "pages/warehouse/BulkOrderSummary";
import StripeLogo from "assets/images/stripe_logo.jpeg";
import PaypalLogo from "assets/images/paypal_logo.png";
import AUSFlag from "assets/images/aus-flag.jpeg";
import CheckIcon from "@material-ui/icons/Check";
import {
  stripeCharge,
  getAllCards,
  updateChargeInformation,
} from "actions/stripe";
import { getSearchedSubmittedUsers } from "actions/user";
import {
  Container,
  Wrapper,
  SearchWrapper,
  Heading,
  OrderWrapper,
  OrderCard,
  Orders,
  PaginationWrapper,
  FilterWrapper,
  DaysAgo,
  ProductsWrapper,
  ProductButtonWrapper,
} from "styles/pages/warehouse";

import {
  CategoriesContainer,
  CategoryBox,
  IconWrapper,
} from "styles/pages/my-stores/products/productCreator";

import { USD_TO_CAD_CONVERSION_RATE } from "constants/currency";

const fulfillmentPlants = ["CA", "US", "EU", "AU", "NY"];

const XMLParser = require("react-xml-parser");
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 400,
  },
}));

const Warehouse = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const csvEl = useRef(null);
  const products = useSelector((state) => state?.products?.data);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [selectedProducts, setProducts] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [selectedModalUser, setUser] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [allSubmittedOrders, setAllSubmittedOrders] = useState(undefined);
  const [category, setCategory] = useState(undefined);
  const [categoryOptions, setCategoryOptions] = useState(undefined);
  const [categoryValue, setCategoryValue] = useState(undefined);
  const [filterPayload, setFilterPayload] = useState(undefined);
  const [selectedOrder, setSelectedOrder] = useState(undefined);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedCards, setSelectedCards] = useState(undefined);
  const [bulkOrderModal, setBulkOrderModal] = useState(false);
  const [
    updateOrderStatusModalVisibility,
    setUpdateOrderStatusModalVisibility,
  ] = useState({ open: false });

  const take = 20;

  useEffect(async () => {
    let query = "";
    if (selectedProducts?.length == 1) {
      query = {
        "ShirtlyOrderData.OrderLines": {
          $elemMatch: {
            OrderLineItems: {
              $elemMatch: { ProductID: selectedProducts[0] },
            },
          },
        },
      };
    } else if (selectedProducts?.length > 1) {
      query = {
        $or: selectedProducts?.map((p) => ({
          "ShirtlyOrderData.OrderLines": {
            $elemMatch: {
              OrderLineItems: { $elemMatch: { ProductID: p } },
            },
          },
        })),
      };
    }

    const orders =
      selectedProducts?.length > 0
        ? await dispatch(
            allSubmittedOrdersForProduct({
              query,
              skip: (currentPage === 0 ? currentPage : currentPage - 1) * take,
              take,
            })
          )
        : filterPayload?.payload
        ? await dispatch(
            filterAllSubmittedOrders({
              ...filterPayload.payload,
              skip: (currentPage === 0 ? currentPage : currentPage - 1) * take,
              take,
            })
          )
        : filterPayload?.query
        ? await dispatch(
            allSubmittedOrdersForProduct({
              query: filterPayload.query,
              skip: (currentPage === 0 ? currentPage : currentPage - 1) * take,
              take,
            })
          )
        : filterPayload?.ordersProductPreview
        ? await dispatch(
            printPreviewOrders({
              skip: (currentPage === 0 ? currentPage : currentPage - 1) * take,
              take,
            })
          )
        : await dispatch(
            getAllSubmittedOrders(
              {
                skip:
                  (currentPage === 0 ? currentPage : currentPage - 1) * take,
                take,
              },
              token
            )
          );
    if (orders.error) {
      return;
    }
    setAllSubmittedOrders(orders);
  }, [dispatch, currentPage, filterPayload]);

  const getGraphicDesigners = async () => {
    const data = await dispatch(allCustomGraphicsUsers());
    setCategoryOptions(data?.map((d) => d?.name));
  };

  useEffect(() => {
    if (category === "Marketplace") {
      setCategoryOptions([
        "Shopify",
        "Shirtly",
        "Etsy",
        "BigCommerce",
        "WooCommerce",
        "BigCartel",
      ]);
    } else if (category === "Status") {
      setCategoryOptions(["InProduction", "Shipped", "Cancelled"]);
    }
    // else if (category === "PrintProvider") {
    //   setCategoryOptions(["CA", "US", "EU", "AU", "NY"]);
    // }
    else if (category === "Print Type") {
      setCategoryOptions(["EMB", "SUB", "AOP", "DURASOFT"]);
    } else {
      getGraphicDesigners();
    }
  }, [category]);

  const removeNodeFromXMLString = (xmlString, key) => {
    if (key === "Status") {
      return xmlString
        ?.replace("<OrderString><Status>", "<OrderString>")
        ?.replace("</Status></OrderString>", "</OrderString>");
    }
    const splitData = xmlString.split(`<${key}>`);
    const splitData1 = xmlString.split(`</${key}>`);

    if (splitData[0] && splitData1[1]) {
      return `${splitData[0]}${splitData1[1]}`;
    }
    return xmlString;
  };

  return (
    <>
      <Container>
        <SubHeader
          title={ROUTES.WAREHOUSE.TITLE}
          links={[
            {
              title: ROUTES.HOME.TITLE,
              to: ROUTES.HOME.LINK,
            },
          ]}
          currentPageTitle={ROUTES.WAREHOUSE.TITLE}
        />

        <Wrapper>
          <SearchWrapper>
            <SearchBar
              searchOnEnter={true}
              searchById={async (v) => {
                if (filterPayload) {
                  setCategory(undefined);
                  setCategoryValue(undefined);
                }

                const order = await dispatch(
                  searchAllSubmittedOrders({
                    ReceiptID: v,
                  })
                );

                if (order.error) {
                  return;
                }

                setAllSubmittedOrders(order);
              }}
              fetchAllOrders={() => {
                if (filterPayload) {
                  setCategory(undefined);
                  setCategoryValue(undefined);
                }
                setCurrentPage(0);
              }}
            />
            <Button
              title="Generate Late Orders Report"
              containerStyle={{
                background: COLORS.BLUE,
                boxShadow: "none",
                textTransform: "capitalize",
                color: COLORS.WHITE,
              }}
              onClick={() =>
                setUpdateOrderStatusModalVisibility({ open: true, csv: true })
              }
            />
            <Button
              title="Update All Orders"
              containerStyle={{
                background: COLORS.BLUE,
                boxShadow: "none",
                textTransform: "capitalize",
                color: COLORS.WHITE,
              }}
              onClick={() =>
                setUpdateOrderStatusModalVisibility({ open: true })
              }
            />
          </SearchWrapper>

          <FilterWrapper>
            <Select
              label="Filter"
              options={[
                "Marketplace",
                "Status",
                // "PrintProvider",
                "Print Type",
                "Custom Graphics",
              ]}
              value={category}
              onChange={(value) => setCategory(value)}
            />
            {!!category && (
              <Select
                label="Filter Value"
                options={categoryOptions}
                value={categoryValue}
                onChange={(value) => {
                  setProducts([]);
                  setCategoryValue(value);
                  let payload = {};
                  if (category === "Marketplace") {
                    payload = {
                      payload: { marketPlace: value },
                    };
                  } else if (category === "Status") {
                    payload = {
                      payload: { status: value },
                    };
                  }
                  // else if (category === "PrintProvider") {
                  //   payload = {
                  //     payload: { printProvider: value },
                  //   };
                  // }
                  else if (category === "Print Type") {
                    payload = {
                      query: {
                        "ShirtlyOrderData.OrderLines": {
                          $elemMatch: {
                            OrderLineItems: {
                              $elemMatch: { PrintType: value },
                            },
                          },
                        },
                      },
                    };
                  } else {
                    payload = {
                      query: {
                        $or: [
                          {
                            "ShirtlyOrderData.OrderLines": {
                              $elemMatch: {
                                OrderLineItems: {
                                  $elemMatch: {
                                    printAreas: {
                                      $elemMatch: {
                                        customGraphicsdData: {
                                          $elemMatch: { user: value },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                          {
                            "ShirtlyOrderData.OrderLines": {
                              $elemMatch: {
                                OrderLineItems: {
                                  $elemMatch: {
                                    printAreas: {
                                      $elemMatch: {
                                        artwork: {
                                          $regex: "CustomGraphics",
                                          $options: "i",
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        ],
                      },
                    };
                  }

                  setFilterPayload(payload);
                  setCurrentPage(0);
                }}
              />
            )}
          </FilterWrapper>

          <CategoriesContainer>
            {fulfillmentPlants?.map((f) => (
              <CategoryBox
                onClick={() => {
                  setCategory("PrintProvider");
                  setCategoryValue(f);
                  setFilterPayload({ payload: { printProvider: f } });
                  setCurrentPage(0);
                }}
              >
                <IconWrapper
                  style={{
                    background: categoryValue === f ? "#000" : "#F4F4F4",
                  }}
                >
                  {categoryValue === f ? <CheckIcon /> : null}
                </IconWrapper>

                <img
                  src={
                    f === "CA"
                      ? CADFlag
                      : f === "US" || f === "NY"
                      ? USAFlag
                      : f === "AU"
                      ? AUSFlag
                      : EUFlag
                  }
                  alt="CAFlag"
                  width="100"
                  height="100"
                />
                <h3 className="country-name">{f} Plant</h3>
              </CategoryBox>
            ))}
          </CategoriesContainer>

          <ProductsWrapper>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel>Products</InputLabel>
                <MUISelect
                  multiple
                  value={selectedProducts}
                  onChange={(event) => setProducts(event.target.value)}
                  input={<Input />}
                >
                  {products.map((p) => (
                    <MenuItem key={p?.Product?.ID} value={p?.Product?.ID}>
                      {p?.Product?.DisplayName}
                    </MenuItem>
                  ))}
                </MUISelect>
              </FormControl>
            </div>
            <ProductButtonWrapper>
              <Button
                title="Orders Product Preview"
                onClick={() => setFilterPayload({ ordersProductPreview: true })}
              />
              <Button
                disabled={selectedProducts?.length === 0}
                title="Get Orders"
                onClick={async () => {
                  let query = "";
                  if (selectedProducts?.length == 1) {
                    query = {
                      "ShirtlyOrderData.OrderLines": {
                        $elemMatch: {
                          OrderLineItems: {
                            $elemMatch: { ProductID: selectedProducts[0] },
                          },
                        },
                      },
                    };
                  } else {
                    query = {
                      $or: selectedProducts?.map((p) => ({
                        "ShirtlyOrderData.OrderLines": {
                          $elemMatch: {
                            OrderLineItems: { $elemMatch: { ProductID: p } },
                          },
                        },
                      })),
                    };
                  }

                  const orders = await dispatch(
                    allSubmittedOrdersForProduct({
                      query,
                      skip: 0,
                      take,
                    })
                  );

                  if (orders.error) {
                    return;
                  }
                  setAllSubmittedOrders(orders);
                }}
              />

              <Button
                disabled={selectedProducts?.length === 0}
                title="Print Orders Summary"
                onClick={async () => {
                  setBulkOrderModal(true);
                }}
              />

              <Button
                disabled={selectedProducts?.length === 0}
                title="Remove Selected Products"
                onClick={async () => {
                  setProducts([]);
                  const orders = await dispatch(
                    getAllSubmittedOrders(
                      {
                        skip: 0,
                        take,
                      },
                      token
                    )
                  );

                  if (orders.error) {
                    return;
                  }

                  setAllSubmittedOrders(orders);
                }}
              />
            </ProductButtonWrapper>
          </ProductsWrapper>
          <Orders>
            <Heading>
              All Orders&nbsp;({allSubmittedOrders?.orderCounts?.TotalOrders})
            </Heading>
            <OrderWrapper>
              {!!allSubmittedOrders?.orders?.length ? (
                allSubmittedOrders?.orders?.map((order, index) =>
                  order?.ShirtlyOrderData?.ReceiptID ? (
                    <OrderCard
                      key={`order${index}`}
                      onClick={async () => {
                        const user = await dispatch(
                          getSearchedSubmittedUsers({
                            searchUser:
                              order?.ShirtlyOrderData?.MasterUserEmail,
                            skip: 1,
                            take: 1,
                          })
                        );
                        setUser({
                          password:
                            user?.users[0]?.UserData?.User?.PasswordHash,
                          email: order?.ShirtlyOrderData?.MasterUserEmail,
                        });

                        let isOrderPushedToMachine = true;
                        let machineStatusData = undefined;

                        if (
                          order?.ShirtlyOrderData?.Status === "InProduction" ||
                          order?.ShirtlyOrderData?.Status === "Shipped"
                        ) {
                          const orderLineItems =
                            order?.ShirtlyOrderData?.OrderLines[0]
                              ?.OrderLineItems;
                          const isEmb = !!orderLineItems?.find(
                            (o) => o?.PrintType === "EMB"
                          );

                          const isEU =
                            order?.ShirtlyOrderData?.PrintProvider === "EU";

                          const isAU =
                            order?.ShirtlyOrderData?.PrintProvider === "AU";

                          const isNY =
                            order?.ShirtlyOrderData?.PrintProvider === "NY";

                          machineStatusData =
                            isEU || isAU
                              ? await dispatch(
                                  machineOrderDetails({
                                    PrintProvider: isAU ? "AU" : "EU",
                                    orderPo: order?.ShirtlyOrderData?.ReceiptID,
                                  })
                                )
                              : await dispatch(
                                  machineOrderDetails({
                                    PrintProvider: isEmb
                                      ? "CA"
                                      : order?.ShirtlyOrderData?.PrintProvider,
                                    orderPo: order?.ShirtlyOrderData?.ReceiptID,
                                  })
                                );

                          isOrderPushedToMachine =
                            isEU || isAU || isNY
                              ? null
                              : !machineStatusData?.string?._text.includes(
                                  "No Order found"
                                );

                          if (
                            (isAU || isEU || isNY) &&
                            order?.ShirtlyOrderData?.Status === "InProduction"
                          ) {
                            const status =
                              machineStatusData?.status ||
                              machineStatusData?.msg[0]?.order_status;

                            if (
                              status === "shipped" ||
                              status === "Shipped" ||
                              status === "sent" ||
                              status === "Sent"
                            ) {
                              const shipDate = isAU
                                ? moment(
                                    machineStatusData?.events?.find(
                                      (e) => e?.action === "shipped"
                                    )?.time
                                  ).format("MM/DD/YYYY")
                                : isNY
                                ? machineStatusData?.msg[0]?.completed_on
                                : machineStatusData?.ship_date ||
                                  moment().format("YYYY-MM-DD");

                              const trackingNumber = isAU
                                ? machineStatusData?.events?.find(
                                    (e) => e?.action === "shipped"
                                  )?.trackingUrl
                                : isNY
                                ? machineStatusData?.msg[0]?.tracking_url
                                : machineStatusData?.tracking_number;

                              await dispatch(
                                updateOrderStatus({
                                  user: order?.ShirtlyOrderData
                                    ?.MasterUserEmail,
                                  orderId: order?.ShirtlyOrderData?.ID,
                                  status: "Shipped",
                                  trackingNumber,
                                  shipDate,
                                })
                              );

                              await dispatch(
                                updateSavedOrderData({
                                  orderId: order?.ShirtlyOrderData?.ID,
                                  trackingNumber,
                                  shipDate,
                                })
                              );

                              await dispatch(
                                orderShippedNotification({
                                  order: order?.ShirtlyOrderData,
                                  orderId: order?.ShirtlyOrderData?.ID,
                                  trackingNumber,
                                  shipDate,
                                })
                              );
                            }
                          }

                          if (isOrderPushedToMachine) {
                            const parsedMachineStatusData =
                              new XMLParser().parseFromString(
                                machineStatusData.string._text
                              );

                            const shipped =
                              parsedMachineStatusData.getElementsByTagName(
                                "Shipped"
                              );

                            const trackingNumber =
                              parsedMachineStatusData.getElementsByTagName(
                                "trackingNumber"
                              );
                            const shipDate =
                              parsedMachineStatusData.getElementsByTagName(
                                "shipDate"
                              );

                            if (
                              shipped[0]?.value === "true" &&
                              order?.ShirtlyOrderData?.Status === "InProduction"
                            ) {
                              await dispatch(
                                updateOrderStatus({
                                  user: order?.ShirtlyOrderData
                                    ?.MasterUserEmail,
                                  orderId: order?.ShirtlyOrderData?.ID,
                                  status: "Shipped",
                                  trackingNumber: trackingNumber[0]?.value,
                                  shipDate: moment(shipDate[0]?.value).format(
                                    "YYYY-MM-DD"
                                  ),
                                })
                              );

                              await dispatch(
                                updateSavedOrderData({
                                  orderId: order?.ShirtlyOrderData?.ID,
                                  trackingNumber: trackingNumber[0]?.value,
                                  shipDate: moment(shipDate[0]?.value).format(
                                    "YYYY-MM-DD"
                                  ),
                                })
                              );

                              await dispatch(
                                orderShippedNotification({
                                  order: order?.ShirtlyOrderData,
                                  orderId: order?.ShirtlyOrderData?.ID,
                                  trackingNumber: trackingNumber[0]?.value,
                                  shipDate: moment(shipDate[0]?.value).format(
                                    "YYYY-MM-DD"
                                  ),
                                })
                              );
                            } else if (
                              order?.ShirtlyOrderData?.Status !== "Shipped"
                            ) {
                              let response;
                              if (
                                order?.ShirtlyOrderData?.PrintProvider === "US"
                              ) {
                                response = await dispatch(
                                  getTrackingUSNumber({
                                    orderId: order?.ShirtlyOrderData?.ReceiptID,
                                  })
                                );
                              } else {
                                response = await dispatch(
                                  getTrackingCANumber({
                                    orderId: order?.ShirtlyOrderData?.ReceiptID,
                                  })
                                );
                              }
                              if (response?.shipments?.length > 0) {
                                await dispatch(
                                  updateOrderStatus({
                                    user: order?.ShirtlyOrderData
                                      ?.MasterUserEmail,
                                    orderId: order?.ShirtlyOrderData?.ID,
                                    status: "Shipped",
                                    trackingNumber:
                                      response?.shipments[0].trackingNumber,
                                    shipDate: moment(
                                      response?.shipments[0].shipDate
                                    ).format("YYYY-MM-DD"),
                                  })
                                );

                                await dispatch(
                                  updateSavedOrderData({
                                    orderId: order?.ShirtlyOrderData?.ID,
                                    trackingNumber:
                                      response?.shipments[0].trackingNumber,
                                    shipDate: moment(
                                      response?.shipments[0].shipDate
                                    ).format("YYYY-MM-DD"),
                                  })
                                );

                                await dispatch(
                                  orderShippedNotification({
                                    order: order?.ShirtlyOrderData,
                                    orderId: order?.ShirtlyOrderData?.ID,
                                    trackingNumber:
                                      response?.shipments[0].trackingNumber,
                                    shipDate: moment(
                                      response?.shipments[0].shipDate
                                    ).format("YYYY-MM-DD"),
                                  })
                                );
                              }
                            }
                          }
                        }

                        setSelectedOrder({
                          ...order,
                          isOrderPushedToMachine,
                          DataForMachine:
                            order?.ShirtlyOrderData?.PrintProvider === "AU"
                              ? machineStatusData
                              : machineStatusData?.string?._text,
                        });
                      }}
                    >
                      {order?.ShirtlyOrderData?.PackingMode === "eco" && (
                        <Tooltip title="Eco Bag Order" placement="top">
                          <EcoIcon
                            style={{
                              color: "green",
                              position: "absolute",
                              top: 5,
                              right: 36,
                              height: 26,
                              width: 26,
                            }}
                          />
                        </Tooltip>
                      )}
                      {order?.StripeChargeInfo?.status === "succeeded" ||
                      order?.PaypalChargeInfo?.status === "APPROVED" ||
                      order?.PaypalChargeInfo?.status === "COMPLETED" ||
                      order?.PaypalChargeInfo?.status === "CAPTURED" ? (
                        <CheckCircleIcon
                          style={{
                            color: "#000",
                            position: "absolute",
                            top: 5,
                            right: 5,
                            height: 26,
                            width: 26,
                          }}
                        />
                      ) : (
                        <HourglassFullIcon
                          style={{
                            color: "orange",
                            position: "absolute",
                            top: 5,
                            right: 5,
                          }}
                        />
                      )}

                      <Tooltip
                        title="Submit To Production Date"
                        placement="top"
                      >
                        <DaysAgo
                          background={
                            order?.ShirtlyOrderData?.Status === "InProduction"
                              ? moment().diff(
                                  order?.OrderSubmitToProductionDate,
                                  "days"
                                ) <= 5
                                ? "orange"
                                : "red"
                              : order?.ShirtlyOrderData?.Status === "Shipped"
                              ? "green"
                              : "red"
                          }
                        >
                          {moment().diff(
                            order?.OrderSubmitToProductionDate,
                            "days"
                          )}
                          &nbsp;days ago
                        </DaysAgo>
                      </Tooltip>

                      <h3>Order {order?.ShirtlyOrderData?.ReceiptID}</h3>
                      <h5>
                        Marketplace:&nbsp;
                        <b
                          style={{
                            fontSize: 17,
                            color:
                              order?.ShirtlyOrderData?.Marketplace == "Etsy"
                                ? "orange"
                                : order?.ShirtlyOrderData?.Marketplace ==
                                  "Shopify"
                                ? "green"
                                : order?.ShirtlyOrderData?.Marketplace ==
                                  "BigCommerce"
                                ? "#121118"
                                : order?.ShirtlyOrderData?.Marketplace ==
                                  "WooCommerce"
                                ? "#7f54b3"
                                : "#000",
                          }}
                        >
                          {order?.ShirtlyOrderData?.Marketplace}
                        </b>
                      </h5>

                      <h5>Print Providers:</h5>
                      <span>
                        <img
                          alt="warehouse"
                          src={
                            order?.ShirtlyOrderData?.PrintProvider === "US" ||
                            order?.ShirtlyOrderData?.PrintProvider === "NY"
                              ? USAFlag
                              : order?.ShirtlyOrderData?.PrintProvider === "CA"
                              ? CADFlag
                              : order?.ShirtlyOrderData?.PrintProvider === "EU"
                              ? EUFlag
                              : AUFlag
                          }
                        />
                        Shirtly {order?.ShirtlyOrderData?.PrintProvider}
                      </span>
                      <h3
                        className={
                          order?.ShirtlyOrderData?.Status == "Shipped"
                            ? "status-green"
                            : order?.ShirtlyOrderData?.Status == "InProduction"
                            ? "status-orange"
                            : "status-red"
                        }
                      >
                        {order?.ShirtlyOrderData?.Status}
                      </h3>

                      <div
                        style={{
                          marginTop: 20,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {order?.PaypalChargeInfo ? (
                            <img src={PaypalLogo} width={25} height={25} />
                          ) : order?.StripeChargeInfo ? (
                            <img src={StripeLogo} width={25} height={25} />
                          ) : null}
                        </div>
                        <div>
                          {order?.PaypalChargeInfo ? (
                            <span style={{ marginTop: 0, marginBottom: 0 }}>
                              <b style={{ fontSize: 14 }}>
                                {
                                  order?.PaypalChargeInfo?.payer?.address
                                    ?.country_code
                                }
                              </b>
                            </span>
                          ) : order?.StripeChargeInfo ? (
                            <span style={{ marginTop: 0, marginBottom: 0 }}>
                              <b style={{ fontSize: 14 }}>
                                {
                                  order?.StripeChargeInfo
                                    ?.payment_method_details?.card?.country
                                }
                              </b>
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </OrderCard>
                  ) : null
                )
              ) : (
                <div>No orders found!</div>
              )}
            </OrderWrapper>
          </Orders>

          {allSubmittedOrders?.orderCounts?.TotalOrders > 0 && (
            <PaginationWrapper>
              <Pagination
                totalCount={allSubmittedOrders?.orderCounts?.TotalOrders}
                take={take}
                page={currentPage}
                onChange={(e, v) => setCurrentPage(v)}
              />
            </PaginationWrapper>
          )}
        </Wrapper>
      </Container>
      {!!selectedOrder && (
        <OrderModal
          user={selectedModalUser}
          order={selectedOrder}
          modal={!!selectedOrder}
          setModal={() => {
            setSelectedOrder(undefined);
            setUser(undefined);
          }}
          sendOrderToPlant={async () => {
            const xml =
              `<AddXml xmlns="http://tempuri.org/">
          <OrderString>` +
              selectedOrder?.DataForMachine?.replaceAll("&", " and ") +
              "</OrderString></AddXml>";

            let updatedXml = removeNodeFromXMLString(xml, "SourceID");
            updatedXml = removeNodeFromXMLString(updatedXml, "CustID");
            updatedXml = removeNodeFromXMLString(updatedXml, "Status");
            updatedXml = removeNodeFromXMLString(updatedXml, "StoreID");
            updatedXml = removeNodeFromXMLString(updatedXml, "Options");
            updatedXml = removeNodeFromXMLString(updatedXml, "Paid");
            updatedXml = removeNodeFromXMLString(updatedXml, "InventoryCheck");
            updatedXml = removeNodeFromXMLString(updatedXml, "ArtAttached");
            updatedXml = removeNodeFromXMLString(updatedXml, "BarcodePrinted");
            updatedXml = removeNodeFromXMLString(updatedXml, "Printed");

            updatedXml = removeNodeFromXMLString(updatedXml, "QualityCheck");
            updatedXml = removeNodeFromXMLString(updatedXml, "Shipped");
            updatedXml = removeNodeFromXMLString(updatedXml, "shipmentInfo");

            const submitOrderResponse =
              selectedOrder?.ShirtlyOrderData?.PrintProvider === "CA"
                ? await dispatch(
                    submitOrderToUSA(updatedXml?.replace(/>\s+</g, "><"), token)
                  )
                : await dispatch(
                    submitOrderToCanada(
                      updatedXml?.replace(/>\s+</g, "><"),
                      token
                    )
                  );

            const parseOrderResponse = new XMLParser().parseFromString(
              submitOrderResponse
            );

            const errorCode =
              parseOrderResponse.getElementsByTagName("ErrorCode");

            if (errorCode?.length) {
              if (errorCode[0].value != 0) {
                alert(
                  "Error with the order, please contact shirtly for more details at support@shirtly.com"
                );
                return;
              }
            }
            setSelectedOrder(undefined);
          }}
          pushOrderToMachine={async () => {
            const xml = selectedOrder?.DataForMachine?.replaceAll("&", " and ");

            const submitOrderResponse =
              selectedOrder?.ShirtlyOrderData?.PrintProvider === "CA"
                ? await dispatch(
                    submitOrderToCanada(xml?.replace(/>\s+</g, "><"), token)
                  )
                : await dispatch(
                    submitOrderToUSA(xml?.replace(/>\s+</g, "><"), token)
                  );

            const parseOrderResponse = new XMLParser().parseFromString(
              submitOrderResponse
            );

            const errorCode =
              parseOrderResponse.getElementsByTagName("ErrorCode");

            if (errorCode?.length) {
              if (errorCode[0].value != 0) {
                alert(
                  "Error with the order, please contact shirtly for more details at support@shirtly.com"
                );
                return;
              }
            }
            setSelectedOrder(undefined);
          }}
          openModalPayment={async () => {
            if (!selectedCards) {
              const cards = await dispatch(
                getAllCards({
                  user: selectedOrder?.ShirtlyOrderData?.MasterUserEmail,
                })
              );

              if (cards.error) {
                return;
              }

              setSelectedCards(cards);
            }

            setPaymentModal(true);
          }}
        />
      )}
      {paymentModal && (
        <Stripe
          payableAmount={
            selectedOrder?.ShirtlyOrderData?.FulfillmentPlusShipping
          }
          open={paymentModal}
          cards={selectedCards}
          showSaveCardCheckbox={false}
          hide={async (data) => {
            if (data) {
              const payload = {
                user: selectedOrder?.ShirtlyOrderData?.MasterUserEmail,
                saveCard: false,
                cardDetails: data?.cardDetails,
                // amount: 100,
                ID: selectedOrder?.ShirtlyOrderData?.ID,
                currency: data?.paymentInCanadian ? "CAD" : "USD",
                ReceiptID: selectedOrder?.ShirtlyOrderData?.ReceiptID,
                amount: data.paymentInCanadian
                  ? parseInt(
                      selectedOrder?.ShirtlyOrderData?.FulfillmentPlusShipping *
                        USD_TO_CAD_CONVERSION_RATE +
                        (selectedOrder?.ShirtlyOrderData
                          ?.FulfillmentPlusShipping *
                          USD_TO_CAD_CONVERSION_RATE *
                          13) /
                          100
                    ).toFixed(2) * 100
                  : parseInt(
                      selectedOrder?.ShirtlyOrderData?.FulfillmentPlusShipping *
                        100
                    ),
              };

              const charge = await dispatch(stripeCharge(payload, token));
              if (charge.error) {
                return;
              }
              if (
                !charge ||
                !charge?.status ||
                charge?.status !== "succeeded" ||
                charge?.statusCode === 402 ||
                charge?.statusCode === 400 ||
                charge?.type === "StripeCardError" ||
                charge?.type === "StripeInvalidRequestError" ||
                charge?.rawType === "card_error" ||
                charge?.rawType === "invalid_request_error" ||
                charge?.code === "card_declined"
              ) {
                alert("Payment declined");
                return;
              }

              await dispatch(
                updateChargeInformation({
                  orderId: selectedOrder?.ShirtlyOrderData?.ID,
                  chargeData: charge,
                })
              );

              setPaymentModal(false);
              setSelectedCards(undefined);
              setCurrentPage(0);
            } else {
              setPaymentModal(false);
              setSelectedCards(undefined);
            }
          }}
        />
      )}
      {updateOrderStatusModalVisibility?.open && (
        <UpdateOrderStatusModal
          open={updateOrderStatusModalVisibility?.open}
          csv={updateOrderStatusModalVisibility?.csv}
          setOpen={({ open, orders }) => {
            if (orders?.length && updateOrderStatusModalVisibility?.csv) {
              let csvData = [
                [
                  "Order#",
                  "Email",
                  "Submit To Production Date",
                  "Ship Date",
                  "Delay Days",
                  "Print Provider",
                  "Tracking Number",
                ],
              ];

              for (let i = 0; i < orders?.length; i++) {
                const order = orders[i];
                const ShirtlyOrderData = order?.ShirtlyOrderData;
                const { ID, MasterUserEmail, PrintProvider } = ShirtlyOrderData;

                const orderSubmitToProductionDate =
                  order?.OrderSubmitToProductionDate;

                const diff = moment().diff(orderSubmitToProductionDate, "days");

                csvData.push([
                  `S-${ID}`,
                  MasterUserEmail,
                  orderSubmitToProductionDate,
                  "",
                  `${diff} Days`,
                  `${PrintProvider} Plant`,
                  "",
                ]);
              }
              setCsvData(csvData);

              setTimeout(() => {
                csvEl.current.link.click();
              }, 10);
            } else {
              setCsvData(undefined);
            }

            setUpdateOrderStatusModalVisibility({
              open: open,
              csv: false,
            });
          }}
        />
      )}

      {bulkOrderModal && (
        <BulkOrderSummary
          modal={bulkOrderModal}
          setModal={(v) => setBulkOrderModal(v)}
          orders={allSubmittedOrders}
        />
      )}
      {csvData && (
        <CSVLink
          style={{ display: "none" }}
          ref={csvEl}
          data={csvData}
          filename={`Shirtly_Late_Orders_Report(${moment().format(
            "MMM DD YYYY"
          )}.xls`}
        />
      )}
    </>
  );
};

export default Warehouse;
