import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  padding: 30px;
  max-width: 705px;
  width: 88vw;
  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  img {
    max-width: 160px;
    width: 100%;
    display: block;
    margin: 15px auto;
  }
  h4 {
    font-size: clamp(1.5rem, 2.5vw, 2.25rem);
    margin: 0;
    font-family: "Inter800";
    margin-bottom: 25px;
    text-align: center;
  }
  p {
    margin-top: 3px;
    font-size: 14px;
    font-family: "Inter400";
    text-align: center;
  }
  Button {
    border-radius: 15px;
    padding: 0.5em 1em;
    background: #237aab;
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 0;
  }
`;

const ForgetPassword = styled.a`
  padding: 15px 0;
  color: #000;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  text-decoration: underline;
  font-size: 15px;
  &:hover {
    color: #ccc;
  }
`;

const ActionButton = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto auto;
  grid-gap: 15px;

  button {
    background-color: #000;
    font-size: 16px;
    border-radius: 6px;
    padding: 8px 12px;
    line-height: 1.5;
    color: ${COLORS.WHITE};
    text-transform: capitalize;
    font-family: "Inter500";
    box-shadow: none;

    :hover {
      background-color: #000;
    }
    :first-child {
      background-color: #fdd500;
      color: #242424;
      text-transform: capitalize;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: auto;
  }
`;

export { Container, Header, Body, ForgetPassword, ActionButton };
