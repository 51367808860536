import React, { useState, useEffect } from "react";
import { driver } from "driver.js";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import WarningIcon from "@material-ui/icons/Warning";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Context from "../aiGeneratedImagesContext";
import MockUpContext from "pages/ai-mock-up-generator/context";
import PolicyIcon from "@material-ui/icons/Policy";
import {
  checkAIDescriptionForBrand,
  generateAIImages,
} from "actions/aiDesignStudio";
import {
  PhaseContainer,
  PhaseHeader,
  PhaseTitle,
  PhaseWrapper,
  PhaseSubTitle,
  DesignInputWrapper,
  DesignInput,
  GoButton,
  ChipsWrapper,
  StyledAddCircleIcon,
  DesignInputWrapper1,
  IpScans,
  IpScan,
  IpScanTitle,
} from "styles/components/ArtWork/ai-design-phases";
import { hideLoader, showLoader } from "actions/loader";

const ipScanStates = Object.freeze({
  loading: 1,
  success: 2,
  error: 3,
});

const moreOptions = [
  "steampunk",
  "fauvism",
  "Cave art",
  "Fish-eye",
  "sepia",
  "Woodcut",
  "Ukiyo-e",
  "Low polygon",
  "Sci-fi",
  "black and white",
  "Kawaii",
  "Victorian",
  "Dystopian",
  "Acrylic",
  "Extreme close-up",
  "Flowers",
  "Close-up",
  "High contrast",
  "35mm film",
  "Film noir",
  "Impressionism",
  "Aerial",
  "Dawn",
  "Electronics",
  "Tapestry",
  "Synthwave",
  "Fantasy",
  "Lithograph",
  "Concept art",
  "Candy",
  "Pastel",
  "Hand-drawn",
  "Ice",
  "Watercolor",
  "Night",
  "Line art",
  "Crayon",
  "Fairy tale",
  "Cyberpunk",
  "3D Wireframe",
  "Stars",
  "Silk screen",
  "Comic strip",
  "Watercolor",
  "Dusk",
  "High angle",
  "Digital art",
  "Feathers",
];

const Phase1 = () => {
  const { state, setState } = Context();
  const { state: mState } = MockUpContext();
  const isAiActivated = mState?.aiActivated;
  const [options, setOptions] = useState(moreOptions.slice(0, 5));
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [showIpScan, setIpScan] = useState(false);
  const [tradeMark, setTradeMark] = useState();
  const [imageCheck, setImageCheck] = useState();

  const renderAiMessage = (message) => {
    return `<div style='display:flex;gap:10px;align-items:center;justify-content:center;width:500px;'>
        <div>
          <img src=${mState.selectedAiCharaterImage} style='width:100px;' />
        </div>
        <div style='font-size:22px;'>
          ${message}
        </div>
      </div>`;
  };

  const [descriptionState, setDescriptionState] = useState(
    ipScanStates.loading
  );

  const [imageContentState, setImageContentState] = useState(
    ipScanStates.loading
  );

  const [tramerMarkState, setTrademarkState] = useState(ipScanStates.loading);

  const generateImages = async () => {
    const phase1 = state?.phase1;
    const phase1SelectedOptions = state?.phase1SelectedOptions;
    let message = "";
    if (phase1?.trim().length > 0) {
      message += `${phase1}. `;
    }
    if (phase1SelectedOptions?.length > 0) {
      message += `Create an artfile with ${phase1SelectedOptions?.join(
        ", "
      )} effect.`;
    }

    setState({
      ...state,
      phase1Completed: false,
      loading: false,
      generatedImages: null,
    });

    if (mState?.securityLayer === "off") {
      dispatch(showLoader());
    }

    const response = await dispatch(
      generateAIImages(
        {
          message,
          size: "1024x1024",
          securityLayer: mState?.securityLayer === "on",
        },
        token
      )
    );

    if (mState?.securityLayer === "off") {
      dispatch(hideLoader());
    }

    if (response?.error) {
      if (response.error?.code === "content_policy_violation") {
        setImageContentState(ipScanStates.error);
        setTrademarkState(ipScanStates.error);
      } else if (response.error?.code === "brand_detected") {
        setImageContentState(ipScanStates.success);
        setTrademarkState(ipScanStates.error);
      }
      setImageCheck(response);
    } else {
      setImageContentState(ipScanStates.success);
      setTrademarkState(ipScanStates.success);

      setState({
        ...state,
        phase1Completed: true,
        loading: false,
        generatedImages: response?.data,
        prevAiGeneratedImages: [
          ...state?.prevAiGeneratedImages,
          { phase1SelectedOptions, phase1, images: response?.data },
        ],
      });

      if (isAiActivated) {
        setTimeout(() => {
          const driverObj = driver({
            allowKeyboardControl: false,
            showButtons: ["close"],
          });
          driverObj.highlight({
            element: "#design-phase-2",
            popover: {
              description: renderAiMessage(
                "Congratulation, your design is complete!<br/><br/>You now have a couple of options:<br/>1. You can save your image for later.<br/>2. You can save and go to Mockup<br/>3. Or, if you are not happy with design begin again and be as descriptive as possible."
              ),
              side: "top",
              align: "start",
            },
          });
        }, 10);
      }
    }
  };

  useEffect(() => {
    if (isAiActivated) {
      const driverObj = driver({
        allowKeyboardControl: false,
        animate: false,
        showProgress: false,

        steps: [
          {
            element: "#ai-design-studio",
            popover: {
              description: renderAiMessage(
                `Welcome to AI Design Studio<br/><br/>This is where you bring your design ideas to reality. Follow the prompts and be as descriptive as possible.<br/><br/>Use the effects buttons to add additional elements to your design.`
              ),
              side: "left",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: "#chips",
            popover: {
              description: renderAiMessage(
                "You can use multiple effects <br/><br/>Try adding effects, select the green plus button to see more options."
              ),
              side: "bottom",
              align: "start",
              showButtons: ["next"],
              nextBtnText: "Skip",
            },
          },
          {
            element: "#ai-input-go",
            popover: {
              description: renderAiMessage(
                "Enter your desgin idea here and be as descriptive as possible and press GO button.<br/><br/>And watch your design come to life."
              ),
              side: "bottom",
              align: "start",
              showButtons: [],
            },
          },
        ],
      });

      driverObj.drive();
    }
  }, []);

  return (
    <PhaseContainer>
      <PhaseHeader>
        <PhaseTitle>Design Phase 1</PhaseTitle>
      </PhaseHeader>

      <PhaseWrapper>
        <PhaseSubTitle>
          <span>
            Choose from the various design effects provided below. For
            additional choices, please click the
          </span>
          <div className="more">
            <StyledAddCircleIcon />
          </div>
          <span>
            and further options will be displayed. Remember, you're welcome to
            select more than one. Don't forget to add a theme like birthday,
            anniversary, graduation.
          </span>
        </PhaseSubTitle>

        <ChipsWrapper id="chips">
          {options?.map((o, i) => (
            <Chip
              key={`${o}_${i}`}
              size="medium"
              label={o}
              clickable
              color={
                !!state?.phase1SelectedOptions?.find((p) => p === o)
                  ? "primary"
                  : "default"
              }
              onClick={() => {
                const isSelected = !!state?.phase1SelectedOptions?.find(
                  (p) => p === o
                );

                setState({
                  ...state,
                  phase1SelectedOptions: isSelected
                    ? state?.phase1SelectedOptions?.filter((p) => p !== o)
                    : [...(state?.phase1SelectedOptions || []), o],
                });
              }}
            />
          ))}
          {options?.length < 6 && (
            <span
              className="more"
              onClick={() => {
                const randomIndexes = Array.from({ length: 5 }, () =>
                  Math.floor(Math.random() * moreOptions.length)
                );

                let randomOptions = randomIndexes.filter(
                  (value, index, self) => self.indexOf(value) === index
                );

                if (randomOptions?.length < 5) {
                  const pending = 5 - randomOptions?.length;

                  const randomNewIndexes = Array.from({ length: pending }, () =>
                    Math.floor(Math.random() * moreOptions.length)
                  );

                  const randomNewOptions = randomNewIndexes.filter(
                    (value, index, self) => self.indexOf(value) === index
                  );

                  randomOptions = [...randomOptions, randomNewOptions];
                }

                setOptions(randomOptions.map((index) => moreOptions[index]));
              }}
            >
              <StyledAddCircleIcon />
            </span>
          )}
        </ChipsWrapper>

        {state?.phase1SelectedOptions?.length > 0 && (
          <ChipsWrapper>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              Selected :
            </span>
            {state?.phase1SelectedOptions?.map((o, i) => (
              <Chip
                key={`${o}_${i}`}
                size="medium"
                label={o}
                clickable
                color="primary"
                onDelete={() => {
                  const isSelected = !!state?.phase1SelectedOptions?.find(
                    (p) => p === o
                  );

                  setState({
                    ...state,
                    phase1SelectedOptions: isSelected
                      ? state?.phase1SelectedOptions?.filter((p) => p !== o)
                      : [...(state?.phase1SelectedOptions || []), o],
                  });
                }}
              />
            ))}
          </ChipsWrapper>
        )}

        <DesignInputWrapper id="ai-input-go">
          <DesignInputWrapper1>
            <DesignInput
              placeholder="Add your design description here"
              value={state?.phase1 || ""}
              onChange={(e) => setState({ ...state, phase1: e.target.value })}
            />
          </DesignInputWrapper1>

          <GoButton
            disabled={!state?.phase1?.trim()?.length || state?.loading}
            onClick={() => {
              if (mState?.securityLayer === "on") {
                setDescriptionState(ipScanStates.loading);
                setImageContentState(ipScanStates.loading);
                setTrademarkState(ipScanStates.loading);
                setTradeMark(null);
                setImageCheck(null);
                setIpScan(true);

                setTimeout(async () => {
                  const tradeMarkInfo = await dispatch(
                    checkAIDescriptionForBrand({ message: state.phase1 }, token)
                  );

                  setTradeMark(tradeMarkInfo);

                  setDescriptionState(
                    tradeMarkInfo.tradeMarkWordsCount === 0
                      ? ipScanStates.success
                      : ipScanStates.error
                  );

                  if (tradeMarkInfo.tradeMarkWordsCount === 0) {
                    generateImages();
                  } else {
                    setImageContentState(ipScanStates.error);
                    setTrademarkState(ipScanStates.error);
                  }
                }, 2000);
              }

              if (mState?.securityLayer === "off") {
                generateImages();
              }

              if (isAiActivated) {
                setTimeout(() => {
                  const driverObj = driver({
                    allowKeyboardControl: false,
                    showButtons: ["close"],
                  });
                  driverObj.highlight({
                    element: "#ip-scan",
                    popover: {
                      description: renderAiMessage(
                        "Our IP scan has been activated and will be checking for any Copyright, Trademark or Content Infringements<br/><br/>Note: If the IP Scan finds an Infringement, you will be notified and have the option you to begin again. Begin again by altering your design description."
                      ),
                      side: "bottom",
                      align: "start",
                    },
                  });
                }, 10);
              }
            }}
          >
            GO
          </GoButton>
        </DesignInputWrapper>
      </PhaseWrapper>

      {showIpScan && (
        <div id="ip-scan">
          <IpScanTitle>
            <PolicyIcon />
            <span>IP Scan Initiated</span>
          </IpScanTitle>

          <IpScans>
            <IpScan>
              {descriptionState === ipScanStates.loading && (
                <>
                  <CircularProgress color="#000" />
                  <div>Evaluating Description...</div>
                </>
              )}

              {descriptionState === ipScanStates.success && (
                <>
                  <VerifiedUserIcon
                    style={{ color: "green", fontSize: "32px" }}
                  />
                  <div>Description Check Complete</div>
                </>
              )}

              {descriptionState === ipScanStates.error && (
                <>
                  <WarningIcon style={{ color: "orange", fontSize: "32px" }} />
                  <div>Description Check Complete</div>
                  <div>
                    Trademark Words found in Description :{" "}
                    <b>{tradeMark?.tradeMarkWords?.join(", ")}</b>
                  </div>
                </>
              )}
            </IpScan>
            <IpScan>
              {imageContentState === ipScanStates.loading && (
                <>
                  <CircularProgress color="#000" />
                  <div>Checking AI Image...</div>
                </>
              )}

              {imageContentState === ipScanStates.success && (
                <>
                  <VerifiedUserIcon
                    style={{ color: "green", fontSize: "32px" }}
                  />
                  <div>Image Check Complete</div>
                </>
              )}

              {imageContentState === ipScanStates.error && (
                <>
                  <WarningIcon style={{ color: "orange", fontSize: "32px" }} />
                  <div>Image Check Complete</div>
                  <div>
                    <b>{imageCheck?.error?.message}</b>
                  </div>
                </>
              )}
            </IpScan>
            <IpScan>
              {tramerMarkState === ipScanStates.loading && (
                <>
                  <CircularProgress color="#000" />
                  <div>Detecting Trademark...</div>
                </>
              )}

              {tramerMarkState === ipScanStates.success && (
                <>
                  <VerifiedUserIcon
                    style={{ color: "green", fontSize: "32px" }}
                  />
                  <div>Trademark Check Complete</div>
                </>
              )}

              {tramerMarkState === ipScanStates.error && (
                <>
                  <WarningIcon style={{ color: "orange", fontSize: "32px" }} />
                  <div>Trademark Check Complete</div>
                  <div>
                    <b>{imageCheck?.error?.message}</b>
                    <br />
                    <br />
                    {imageCheck?.imageAnalysisReport?.google?.items?.length >
                      0 && (
                      <b>
                        Trademarks found:&nbsp;
                        {imageCheck?.imageAnalysisReport?.google?.items
                          ?.map((i) => i?.description)
                          ?.join(", ")}
                      </b>
                    )}
                  </div>
                </>
              )}
            </IpScan>
          </IpScans>
        </div>
      )}
    </PhaseContainer>
  );
};

export default Phase1;
