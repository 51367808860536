import { Button, styled } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import COLORS from "shared/constants/colors";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Alert from "@material-ui/lab/Alert";
import { Tooltip } from "@material-ui/core";
import TextField from "components/TextField";
import { renderImages } from "pages/customizer-tool/utils";
import {
  LayerContainer,
  LayerWrapper,
  LayerRow,
  StyledDeleteIcon,
  AxisTitle,
  Title,
} from "styles/pages/customizer-tool/layers-options";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImagesLayer = ({
  garmentPart,
  leftPocket,
  rightSleeve,
  back,
  setLeftPocket,
  setRightSleeve,
  setBack,
  renderModel,
}) => {
  const selectedGarmentPart =
    garmentPart === "left-pocket"
      ? leftPocket
      : garmentPart === "right-sleeve"
      ? rightSleeve
      : back;

  const title =
    garmentPart === "left-pocket"
      ? "Left Pocket"
      : garmentPart === "right-sleeve"
      ? "Right Sleeve"
      : "Back";

  const images = selectedGarmentPart?.images;

  const setImages = (key, value, index) => {
    const newImages = images?.map((t, j) => {
      if (index !== j) {
        return { ...t };
      }

      return {
        ...t,
        [key]: value,
      };
    });

    if (garmentPart === "left-pocket") {
      setLeftPocket({
        ...leftPocket,
        images: newImages,
      });
      renderImages(newImages, "left-pocket-images");
    } else if (garmentPart === "right-sleeve") {
      setRightSleeve({
        ...rightSleeve,
        images: newImages,
      });
      renderImages(newImages, "right-sleeve-images");
    } else if (garmentPart === "back") {
      setBack({
        ...back,
        images: newImages,
      });
      renderImages(newImages, "back-images");
    }
    renderModel();
  };

  return (
    <LayerContainer>
      <Title>{title} Layer</Title>
      {images?.length > 0 ? (
        images?.map((image, i) => {
          const xMin = garmentPart === "left-pocket" ? 1200 : 250;
          const xMax = garmentPart === "left-pocket" ? 1450 : 450;

          const yMin =
            garmentPart === "left-pocket"
              ? 1100
              : garmentPart === "right-sleeve"
              ? 420
              : 950;
          const yMax =
            garmentPart === "left-pocket"
              ? 1300
              : garmentPart === "right-sleeve"
              ? 620
              : 1150;

          return (
            <LayerWrapper
              key={`text_${i}`}
              style={{ marginBottom: i === images?.length - 1 ? 0 : 10 }}
            >
              <LayerRow>
                <img src={image?.src} alt="img" width="30" />
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  style={{
                    backgroundColor: COLORS.DARK_BLUE,
                    color: COLORS.WHITE,
                    fontWeight: "normal",
                    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
                    textTransform: "none",
                    borderRadius: "3px",
                    fontSize: "16px",
                    lineHeight: "1.5",
                    width: "100%",
                  }}
                >
                  Update File
                  <VisuallyHiddenInput
                    type="file"
                    onClick={(event) => (event.target.value = null)}
                    onChange={(e) => {
                      const file = e?.target?.files[0];

                      let reader = new FileReader();

                      reader.onload = function () {
                        const base64String = reader.result;
                        setImages("src", base64String, i);
                      };

                      reader.readAsDataURL(file);
                    }}
                  />
                </Button>
              </LayerRow>

              <LayerRow>
                <TextField
                  label="IMAGE SIZE (PX)"
                  type="number"
                  min={1}
                  value={image?.width}
                  style={{ width: "100%" }}
                  size="small"
                  onChange={(v) => setImages("width", v > 1 ? v : 1, i)}
                />
              </LayerRow>

              <LayerRow>
                <AxisTitle>Left</AxisTitle>
                <Grid container>
                  <Slider
                    value={image.x || 0}
                    onChange={(_, newValue) => setImages("x", newValue, i)}
                    min={xMin}
                    max={xMax}
                  />
                </Grid>
                <AxisTitle>Right</AxisTitle>
              </LayerRow>

              <LayerRow>
                <AxisTitle>Top</AxisTitle>
                <Grid container>
                  <Slider
                    value={image.y || 0}
                    onChange={(_, newValue) => setImages("y", newValue, i)}
                    min={yMin}
                    max={yMax}
                  />
                </Grid>
                <AxisTitle>Bottom</AxisTitle>
              </LayerRow>

              <Tooltip title="Clear Image">
                <StyledDeleteIcon
                  onClick={() => {
                    const value = images?.filter((_, j) => i !== j);
                    if (garmentPart === "left-pocket") {
                      setLeftPocket({
                        ...leftPocket,
                        images: value,
                      });
                      renderImages(value, "left-pocket-images");
                    } else if (garmentPart === "right-sleeve") {
                      setRightSleeve({
                        ...rightSleeve,
                        images: value,
                      });
                      renderImages(value, "right-sleeve-images");
                    } else if (garmentPart === "back") {
                      setBack({
                        ...back,
                        images: value,
                      });
                      renderImages(value, "back-images");
                    }
                    renderModel();
                  }}
                />
              </Tooltip>
            </LayerWrapper>
          );
        })
      ) : (
        <Alert severity="error">No layer added!</Alert>
      )}
    </LayerContainer>
  );
};

export default ImagesLayer;
