import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  h3,
  h4 {
    margin: 10px 0 0;
    font-family: "Inter600";
    color: #3a3a3c;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 12px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-color: rgba(0, 0, 0, 0.23);
  margin: 20px 0;
  font-family: "Inter500";
  color: #3a3a3c;
  padding: 10px;
`;
