import React from 'react';
import Button from 'components/Button';
import { imagesBaseURL } from 'shared/requests';
import COLORS from 'shared/constants/colors';
import BigCommerceLogo from 'assets/images/big-commerce-lg-logo.png';
import WooCommerceLogo from 'assets/svg/WooCommerceLogo';
import BigCartelLogo from 'assets/images/big-cartel-icon.png';
import { row } from 'pages/my-stores/stores/row';

const rows = (data, removeStore) => {
  let items = [];

  for (let i = 0; i < data.length; i++) {
    items.push(
      row(
        <Button
          containerStyle={{
            fontSize: '12px',
            borderRadius: '3px',
            padding: '1px 5px',
            lineHeight: '1.5',
            textTransform: 'inherit',
            border: '2px solid',
            borderColor: data[i].InActive ? COLORS.LIGHT_RED : COLORS.GREEN,
            color: data[i].InActive ? COLORS.LIGHT_RED : COLORS.GREEN,
            backgroundColor: 'transparent',
            fontWeight: '400',
          }}
          variant='text'
          title='Active'
        />,
        data[i].MarketplaceType === 'WooCommerce' ? (
          <WooCommerceLogo />
        ) : (
          <img
            alt={data[i].ShopName}
            src={
              data[i].MarketplaceType === 'Etsy'
                ? `${imagesBaseURL}/Images/store-logo-etsy_pucy8l.png`
                : data[i].MarketplaceType === 'Shopify'
                ? `${imagesBaseURL}/Images/store-logo-shopify_shdure.png`
                : data[i].MarketplaceType === 'Shirtly'
                ? `${imagesBaseURL}/Images/shirtly-logo-small_kxshec.png`
                : data[i].MarketplaceType === 'BigCommerce'
                ? BigCommerceLogo
                : BigCartelLogo
            }
          />
        ),
        data[i].ShopName,
        <a
          href={`${data[i].ShopURL}`}
          target='_blank'
          rel='noopener noreferrer'>
          {data[i].ShopURL}
        </a>,
        <Button
          containerStyle={{
            fontSize: '12px',
            borderRadius: '3px',
            padding: '7px 5px',
            lineHeight: '1.5',
            textTransform: 'inherit',
            border: '2px solid',
            borderColor: COLORS.RED,
            color: COLORS.WHITE,
            backgroundColor: COLORS.RED,
            fontWeight: '400',
          }}
          onClick={async () => removeStore(data[i])}
          variant='text'
          title='Remove'
        />
      )
    );
  }
  return items;
};

export { rows };
