import { get } from '@axios';
import { showLoader, hideLoader } from 'actions/loader';
import { updateField } from 'actions/common';
import { requests } from 'shared/requests';
import { SHOW_ERROR_MODAL } from 'actions/errorModal';

const SET_TAGS = 'SET_TAGS';
const SET_TAGS_ERROR = 'SET_TAGS_ERROR';

const getTags = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const tags = await get({ url: requests.getTags });
      dispatch(updateField(SET_TAGS, { tags }));
    } catch (error) {
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(SET_TAGS_ERROR, { error }));
    }
    dispatch(hideLoader());
  };
};

export { getTags, SET_TAGS, SET_TAGS_ERROR };
