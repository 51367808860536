import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CustomDialog = ({ open, setOpen, fetchData }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <DialogTitle>Information</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <b>Intellectual Property & Publicity Rights Disclaimer</b>
          <br />
          <br />
          Shirtly is a community built on respect and recognition of artists and
          designers. Users using the artwork database take full responsibility
          for the works they upload and display using Shirtly artwork database.
          This is reflected in the User Agreement. Use of the Shirtly website
          indicates continued acceptance of this Agreement.
          <br />
          <br />
          Several international treaties relate to intellectual property, but
          the laws are not uniform globally. However, it is strongly recommended
          that you check with the trademark/copyright office in your region or
          selling region of any artwork you are using.
          <br />
          <br />
          <b>
            Shirtly is legally not able to provide individual copyright,
            trademark, or publicity rights advice, or give personal opinions on
            these matters.
          </b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={fetchData} color='primary' autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
