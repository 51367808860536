import styled from "styled-components";
import Button from "components/Button";
import Paper from "@material-ui/core/Paper";

const Container = styled.div`
  padding: 20px;
`;

const AddSectionContainer = styled.div`
  margin: 15px 0;
`;

const AddSectionHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  button {
    background-color: #000;
    border-radius: 6px;
    font-size: 16px;
    font-family: "Inter500";
    color: #fff;
    text-transform: capitalize;
    :hover {
      background-color: #000;
      color: #fff;
    }
  }
`;

const AddSectionButton = styled(Button)``;

const Card = styled.div`
  min-height: 200px;
  background: white;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px;
`;

const CardTitle = styled.h2`
  margin: 0;
  font-size: 16px;
  color: #000;
  font-family: "Inter600";
  margin: 15px 0;
`;

const Row = styled.div`
  display: flex;
  grid-gap: 30px;
  margin-bottom: 30px;
  align-items: center;
  .MuiFormControl-root {
    width: 100%;
  }

  textarea {
    width: 100%;
    height: 100px;
  }
`;

const SectionNavbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 30px;
  border-bottom: 2px solid #f0f0f0;
  h2 {
    color: #000;
    font-family: "Inter600";
    font-size: 20px;
  }
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;
const TextAreaWrapper = styled.div`
  margin-top: 20px;
`;

const TextFieldWrapperIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .MuiFormControl-root {
    width: 100%;
  }
  input {
    padding-right: 44px;
  }
  .delete-icon {
    position: absolute;
    right: 9px;
    cursor: pointer;
    color: #ff0000;
  }
`;

const FourBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 30px;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ThreeBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 30px;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`;

const TwoBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 30px;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`;
const OneBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  margin-bottom: 30px;
`;

const DashboardContainer = styled.div`
  width: calc(100% - 300px);
`;
const DashboardNoDataFound = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 20px;
  color: red;
  font-weight: bold;
`;

const DashboardHeader = styled.div`
  text-align: right;
  margin-bottom: 10px;
  svg {
    color: #000;
    cursor: pointer;
  }
`;

const DashboardAddSection = styled.div`
  min-width: 400px;

  .MuiTextField-root {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const DashboardAddSubSection = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  .MuiTextField-root {
    margin-bottom: 10px;
  }
`;

const DashboardSectionContainer = styled.div``;
const Section = styled.div`
  padding: ${(props) => (props?.isAdmin ? "20px" : "0")};
  margin-bottom: 10px;
`;
const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    font-family: "Inter800";
    font-size: ${(props) => (!props?.isAdmin ? "36px" : "auto")};
  }
  svg {
    color: red;
    cursor: pointer;
  }
`;

const SectionBody = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const AddSubSection = styled.div`
  border: 2px dashed #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 200px;
  svg {
    color: #000;
  }
`;

const SubSectionWrapper = styled.div`
  height: 200px;

  .MuiPaper-rounded {
    cursor: pointer;
    height: 100%;
    border-radius: 10px;
    width: 100%;
    position: relative;

    svg.delete-icon {
      color: red;
      position: absolute;
      right: 5px;
      top: 5px;
    }

    svg.edit-icon {
      color: orange;
      position: absolute;
      left: 5px;
      top: 5px;
    }

    .img {
      background-repeat: no-repeat;
      background-size: contain;
      height: 80%;
      background-position: 50% 50%;
      background-color: white;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      margin: 0 auto;
      padding: 10px;
      text-align: center;
      font-weight: bold;
    }

    :hover {
      background-color: #000;
      p {
        color: white;
        font-weight: bold;
      }
    }
  }
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    box-shadow: ${(props) => (props?.isAdmin ? "" : "none")};
    background-color: ${(props) => (props?.isAdmin ? "#fff" : "transparent")};
    margin-bottom: 40px;
  }
`;

export {
  Container,
  AddSectionContainer,
  AddSectionHeader,
  AddSectionButton,
  Card,
  CardTitle,
  Row,
  SectionNavbar,
  TextAreaWrapper,
  TextFieldWrapperIcon,
  FourBoxWrapper,
  ThreeBoxWrapper,
  TwoBoxWrapper,
  OneBoxWrapper,
  DashboardContainer,
  DashboardNoDataFound,
  DashboardHeader,
  DashboardAddSection,
  DashboardSectionContainer,
  Section,
  SectionHeader,
  SectionBody,
  AddSubSection,
  DashboardAddSubSection,
  SubSectionWrapper,
  StyledPaper,
};
