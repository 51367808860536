import styled from 'styled-components';

const Container = styled.div`
  min-height: 100vh;
`;

const Body = styled.div`
  min-height: calc(100vh - 244px);
`;

export { Container, Body };
