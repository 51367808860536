import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const CustomDatePicker = ({ label, format, value, onChange }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      margin='normal'
      label={label}
      format={format}
      value={value}
      onChange={(v) => _.isFunction(onChange) && onChange(v)}
    />
  </MuiPickersUtilsProvider>
);

CustomDatePicker.propTypes = {
  label: PropTypes.string,
  format: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};

CustomDatePicker.defaultProps = {
  value: new Date(),
  format: 'MM/dd/yyyy',
};

export default CustomDatePicker;
