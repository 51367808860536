import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  display: flex;
  @media (max-width: 675px) {
    flex-direction: column;
  }
  > div {
    padding: 15px;
    flex: 1;
  }
`;
const LeftSection = styled.div`
  > div {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 5px;
    font-weight: 700;
    margin-top: 0;
    font-size: 16px;
    color: ${COLORS.BLACK};
  }
  button {
    background-color: ${COLORS.GREEN};
    color: ${COLORS.WHITE};
    text-transform: capitalize;
    :hover {
      background-color: ${COLORS.GREEN};
    }
  }
`;
const RightSection = styled.div`
  p {
    margin-bottom: 5px;
    font-weight: 700;
    margin-top: 0;
    font-size: 16px;
    color: ${COLORS.BLACK};
  }
`;
const RadioSelection = styled.div``;
const Styles = styled.div``;
const Brands = styled.div``;
const Promos = styled.div``;

export {
  Container,
  LeftSection,
  RightSection,
  RadioSelection,
  Styles,
  Brands,
  Promos,
};
