import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props?.tags ? "1fr 1fr 1fr" : "1fr 1fr"};
  align-items: center;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 10px;
  margin-bottom: 10px;
  gap: 20px;

  h3 {
    margin: 0;
  }
`;

export const NoDatafound = styled.p`
  text-align: center;
  color: red;
`;

export const PaginationWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  .MuiPaginationItem-sizeSmall {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
`;
