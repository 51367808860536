import { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Context, { Steps } from "pages/ai-mock-up-generator/context";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import AiRobot1 from "assets/images/ai-assistant.jpeg";
import AiDesignImagesSample from "assets/images/ai-design-images-sample.png";
import {
  Container,
  Header,
  HeaderRobotImg,
  AiAssistantActivatedButton,
  Description,
  Title,
  SubTitle,
  Options,
  Option,
  OptionTitle,
  OptionSubTitle,
  OptionHeader,
  StyledAIImages,
  Iframe,
} from "styles/pages/ai-mock-up-generator/begin-with-ai-assistant";

const BeginWithAiAssitant = () => {
  const [open, setOpen] = useState(false);
  const { setState, state } = Context();

  return (
    <Container>
      <Header>
        <HeaderRobotImg src={AiRobot1} alt="robot" />
        <AiAssistantActivatedButton>
          Ai Assistant Activated
        </AiAssistantActivatedButton>
      </Header>

      <Description>
        <Title>
          Now that you activated Ai Assistant you have a few options
        </Title>

        <SubTitle>
          1. You can watch a tutorial that will walk through the entire process
          from start to end
        </SubTitle>
        <SubTitle>2. You can begin with the help of Ai Assistant </SubTitle>
        <SubTitle>3. Watch the video tutorial then select your Ai </SubTitle>
      </Description>

      <Options>
        <Option>
          <OptionHeader>Video Tutorial</OptionHeader>
          <OptionTitle onClick={() => setOpen(true)}>
            <PlayCircleOutlineIcon />
          </OptionTitle>
          <OptionSubTitle>
            click the play button to watch the tutorial
          </OptionSubTitle>
        </Option>

        <Option>
          <OptionHeader>Begin with Ai Assitant</OptionHeader>
          <OptionTitle
            onClick={() =>
              setState({ ...state, step: Steps.SelectAiAssistant })
            }
          >
            Select your AI Assistant
          </OptionTitle>
          <OptionSubTitle>
            Click the button to select your assistant
          </OptionSubTitle>
        </Option>
      </Options>
      <StyledAIImages>
        <img src={AiDesignImagesSample} alt="ai" />
      </StyledAIImages>

      <Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)}>
        <Iframe>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/4IyLu86WFBg"
          ></iframe>
        </Iframe>
      </Dialog>
    </Container>
  );
};

export default BeginWithAiAssitant;
