import styled from "styled-components";

const PaginationContainer = styled.div`
  .MuiPaginationItem-root {
    color: #fff;
  }
  .MuiPaginationItem-page {
    font-family: "Inter600";
    color: #fff;
  }
  .MuiPaginationItem-textPrimary.Mui-selected {
    background-color: #000;
    font-family: "Inter600";
  }
  .MuiPaginationItem-textPrimary.Mui-selected:hover,
  .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible {
    background-color: #000;
    opacity: 0.5;
    font-family: "Inter600";
  }
`;

export { PaginationContainer };
