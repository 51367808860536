import * as a from 'actions/ordersChartData';
import moment from 'moment';

const initial = {
  timestamp: undefined,
  data: undefined,
};

const ordersChartData = (state = initial, action = null) => {
  switch (action.type) {
    case a.UPDATE_ORDERS_CHART_DATA:
      return {
        ...state,
        timestamp: moment().unix(),
        data: action.data,
        month: moment().month(),
      };
    default:
      return state;
  }
};

export default ordersChartData;
