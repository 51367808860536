import * as a from 'actions/sync-products';

const initial = {
  marketPlaceType: null,
  pageInfo: null,
};

const syncProducts = (state = initial, action = null) => {
  switch (action.type) {
    case a.UPDATE_MARKETPLACE:
      return {
        ...state,
        marketPlaceType: action?.marketPlaceType,
      };
    case a.UPDATE_PAGE_INFO:
      return {
        ...state,
        pageInfo: action?.pageInfo,
      };
    case a.INITIAL_SYNC_PRODUCTS:
      return initial;
    default:
      return state;
  }
};

export default syncProducts;
