import { combineReducers } from 'redux';
import productCategories from 'reducers/productCategories';
import loader from 'reducers/loader';
import tags from 'reducers/tags';
import colorGroups from 'reducers/colorGroups';
import products from 'reducers/products';
import stores from 'reducers/stores';
import auth from 'reducers/auth';
import countries from 'reducers/countries';
import inventory from 'reducers/inventory';
import categories from 'reducers/categories';
import user from 'reducers/user';
import errorModal from 'reducers/errorModal';
import homeWebPage from 'reducers/homeWebPage';
import ordersChartData from 'reducers/ordersChartData';
import syncProducts from 'reducers/sync-products';
import googleFonts from 'reducers/googleFonts';
import yoti from 'reducers/yoti';
import currency from 'reducers/currency';

export default combineReducers({
  productCategories,
  loader,
  tags,
  colorGroups,
  products,
  stores,
  auth,
  countries,
  inventory,
  categories,
  user,
  errorModal,
  homeWebPage,
  syncProducts,
  ordersChartData,
  googleFonts,
  yoti,
  currency,
});
