import styled from "styled-components";

const Container = styled.div``;

const FooterActions = styled.div`
  button {
    background-color: black;
    :hover {
      background-color: black;
    }
  }
`;

const RadiosWrapper = styled.div`
  .MuiRadio-colorSecondary.Mui-checked {
    color: black;
  }

  .MuiRadio-colorSecondary.Mui-checked:hover,
  .MuiIconButton-colorSecondary:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 60%;
  }
`;

const Note = styled.div`
  margin-top: 10px;
  color: red;
  font-weight: bold;
`;

export { Container, FooterActions, RadiosWrapper, StyledImageWrapper, Note };
