import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllNotifications } from "actions/notifications";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Modal from "components/NotificationsDrawer/NotificationDialog";
import WarningImage from "assets/images/warning.png";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    height: 60,
    backgroundColor: theme.palette.background.default,
    justifyContent: "center",
  },
  label: {
    fontFamily: "Inter600",
    color: "#18191F",
    fontSize: 18,
  },
  img: {
    width: "100%",
  },
  warningImage: {
    marginRight: 10,
    width: 30,
  },
  notification: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5,
    },
  },
}));

const Stepper = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = notifications?.length;

  const getNotificationsData = async () => {
    const data = await dispatch(getAllNotifications());
    setNotifications(() => data);
  };

  // useEffect(() => {
  //   getNotificationsData();
  // }, [dispatch]);

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleStepChange = (step) => setActiveStep(step);

  return (
    <div
      style={{
        borderRadius: 10,
        boxShadow:
          "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      }}
    >
      <Paper
        square
        elevation={0}
        className={classes.header}
        style={{ borderRadius: 10 }}
      >
        <img
          src={WarningImage}
          alt="WarningImage"
          className={classes.warningImage}
        />
        <Typography className={classes.label}>Shirtly Updates</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        style={{ maxHeight: 310, overflow: "hidden" }}
        interval={7000}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {notifications?.map((step, index) => {
          const imageSplit = step?.notification?.image?.split("/upload/");
          const img =
            Math.abs(activeStep - index) <= 2
              ? imageSplit
                ? `${imageSplit[0]}/upload/${imageSplit[1]}`
                : "https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1631199707/Shirtly/Web_Pages/LandingPage/Header/shirtly-premium-logo_sscytx_royuzk.png"
              : null;

          return (
            <div
              key={`notification${index}`}
              className={classes.notification}
              style={{
                backgroundImage: `url(${img})`,
                height: 310,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
              }}
              onClick={() => {
                setNotification(step);
              }}
            >
              {/* {Math.abs(activeStep - index) <= 2 ? (
                <img
                  className={classes.img}
                  src={
                    imageSplit
                      ? `${imageSplit[0]}/upload/${imageSplit[1]}`
                      : 'https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1631199707/Shirtly/Web_Pages/LandingPage/Header/shirtly-premium-logo_sscytx_royuzk.png'
                  }
                  alt='notification'
                />
              ) : null} */}
            </div>
          );
        })}
      </AutoPlaySwipeableViews>
      <MobileStepper
        style={{ borderRadius: 10 }}
        steps={maxSteps}
        position="static"
        variant="progress"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
      <Modal
        open={!!notification}
        data={notification}
        setOpen={() => setNotification(null)}
      />
    </div>
  );
};

export default Stepper;
