import Context, { Steps } from "pages/ai-mock-up-generator/context";
import AiDesignImagesSample from "assets/images/ai-design-images-sample.png";
import {
  Container,
  Title,
  StyledAIImages,
  SubTitle,
  Options,
  Option,
  OptionTitle,
  OptionSubTitle,
} from "styles/pages/ai-mock-up-generator/welcome";

const Welcome = () => {
  const { setState, state } = Context();

  return (
    <Container>
      <Title>Welcome to Ai Design Studio</Title>
      <StyledAIImages>
        <img src={AiDesignImagesSample} alt="ai" />
      </StyledAIImages>
      <SubTitle>Choose your path</SubTitle>

      <Options>
        <Option>
          <OptionTitle
            onClick={() =>
              setState({ ...state, step: Steps.BeginWithAiAssitant })
            }
          >
            Help Please
          </OptionTitle>
          <OptionSubTitle>click the button to use Ai Assistant</OptionSubTitle>
        </Option>

        <Option>
          <OptionTitle
            onClick={() => setState({ ...state, step: Steps.Products })}
          >
            No Help Needed
          </OptionTitle>
          <OptionSubTitle>
            click the button to bypass Ai Assistant
          </OptionSubTitle>
        </Option>
      </Options>
    </Container>
  );
};

export default Welcome;
