import _ from 'lodash';

const getProductsByPromos = (products = [], promos) =>
  _.filter(
    products,
    (p) =>
      !!_.filter(p.Product.ProductTags, (c) =>
        promos?.length > 0 ? !!_.find(promos, { Id: c.TagId }) : true
      )?.length
  );

const getProductsByDecoratingTech = (products = [], filter) =>
  _.filter(products, (p) =>
    filter?.length > 0
      ? !!_.filter(filter, (f) => f === p?.Product?.PrintType)?.length ||
        !!_.filter(
          p?.AdditionalTechniquesArtSettings,
          (a) => !!_.find(filter, (k) => k === a?.name)
        )?.length
      : true
  );

const getProductsByType = (products = [], filter) => {
  if (!filter || filter?.length === 0 || filter?.length === 2) return products;

  const isPremium = filter?.find((p) => p === 'premium');

  if (!!isPremium) {
    return _.filter(products, (p) => !!p?.Product?.premiumProduct);
  }

  return _.filter(products, (p) => !p?.Product?.premiumProduct);
};

const getProductsByFulfillmentPlants = (products = [], fulfillmentPlants) =>
  _.filter(
    products,
    (p) =>
      !!_.filter(p.PrintProviders, (c) =>
        fulfillmentPlants?.length > 0
          ? !!_.find(fulfillmentPlants, (f) => f === c?.ProviderName)
          : true
      )?.length
  );

const getProductsByCategoryIds = (products = [], categoryIds) =>
  _.filter(
    products,
    (p) =>
      !!_.filter(p.Product.CategoryIds, (c) =>
        categoryIds?.length > 0
          ? !!_.find(categoryIds, (x) => x === c.CatId)
          : true
      )?.length
  );

const getProductsByPrintProvider = (products = [], printProvider) =>
  _.filter(
    products,
    (p) => !!_.find(p.PrintProviders, (x) => x.ProviderName === printProvider)
  );

const getProdShipCostByDestAndPP = (
  products = [],
  providerName,
  destination,
  highestProduct,
  quantity,
  filterIndex = -1,
  countries
) => {
  let selectedProduct = _.find(
    products,
    (c) => c?.Product?.ID === highestProduct?.ProductID
  );

  let selectedPrintProvider =
    filterIndex === -1
      ? _.find(
          selectedProduct?.PrintProviders,
          (c) => c?.ProviderName === providerName
        )
      : _.filter(
          selectedProduct?.PrintProviders,
          (c) => c?.ProviderName === providerName
        )[filterIndex];

  if (!selectedPrintProvider) {
    selectedProduct =
      _.find(
        products,
        (c) =>
          c?.Product?.SKU === selectedProduct?.Product?.SKU &&
          !!c?.PrintProviders?.find((q) => q?.ProviderName === providerName)
      ) ||
      _.find(
        products,
        (c) =>
          c?.Product?.SKU === 'Gildan 5000-Adult Tee' &&
          !!c?.PrintProviders?.find((q) => q?.ProviderName === providerName)
      );

    selectedPrintProvider =
      filterIndex === -1
        ? _.find(
            selectedProduct?.PrintProviders,
            (c) => c?.ProviderName === providerName
          )
        : _.filter(
            selectedProduct?.PrintProviders,
            (c) => c?.ProviderName === providerName
          )[filterIndex];
  }

  const shippingDestination =
    providerName !== 'EU' && providerName !== 'AU'
      ? destination !== 'US' && destination !== 'CA'
        ? 'Intl'
        : destination
      : providerName === 'EU'
      ? countries?.find((c) => c?.code2 === destination)?.region === 'Europe'
        ? 'EU'
        : 'Intl'
      : providerName === 'AU'
      ? destination !== 'AU' && destination !== 'NZ'
        ? 'Intl'
        : destination
      : 'Intl';

  const shippingCostInfo = _.find(
    selectedPrintProvider?.ShippingCosts,
    (c) => c?.ShippingDestination === shippingDestination
  );

  const cost =
    1 * shippingCostInfo?.PrimaryCost +
    (quantity === 1 ? 0 : quantity - 1) * shippingCostInfo?.SecondaryCost;

  return {
    cost: cost || 0,
    maxDays: shippingCostInfo?.ShippingTimeMaxInDays,
    minDays: shippingCostInfo?.ShippingTimeMinInDays,
  };
};

const getSKUFromProductId = (products = [], productId) =>
  _.find(products, (p) => p?.Product?.ID === productId)?.Product?.SKU;

const getDTGPlusFromProductId = (products = [], productId) =>
  _.find(products, (p) => p?.Product?.ID === productId)?.DTGPlus;

const getPrintTypeFromProductId = (products = [], productId) =>
  _.find(products, (p) => p?.Product?.ID === productId)?.Product?.PrintType;

const getDesignerSettingsFromProductId = (
  products = [],
  productId,
  isBack = false
) => {
  const product = _.find(products, (p) => p?.Product?.ID === productId);

  if (isBack && product?.BackDesignerSettings) {
    return product?.BackDesignerSettings;
  }
  return product?.DesignerSettings;
};

const getAdditionalTechniquesFromProductId = (
  products = [],
  productId,
  isBack = false,
  printType
) => {
  const product = _.find(products, (p) => p?.Product?.ID === productId);
  const additionalTechniquesArtSettings =
    product?.AdditionalTechniquesArtSettings;

  const designerSettings = additionalTechniquesArtSettings?.find(
    (p) => p?.name === printType
  );

  if (designerSettings) {
    const artSettings = designerSettings?.artSettings;
    const back = artSettings?.find((p) => p?.areaName === 'Back');
    const front = artSettings?.find((p) => p?.areaName === 'Front');
    if (isBack && back) {
      return back?.areaSettings;
    }
    return front?.areaSettings;
  }
};

const productHasBackDesignerSettings = (products = [], productId) =>
  _.find(products, (p) => p?.Product?.ID === productId)?.BackDesignerSettings;

const getProductAdditionalImages = (products = [], productId) =>
  _.find(products, (p) => p?.Product?.ID === productId)?.AdditionalImages;

const getMarketingImagePathFromProductId = (
  products = [],
  productId,
  ColorId
) => {
  const product = _.find(products, (p) => p?.Product?.ID === productId);
  return _.find(product?.Colors, (p) => p?.ID === ColorId)?.Image;
};

const isSub = (products = [], productId) =>
  !!_.find(
    products,
    (p) => p?.Product?.ID === productId && p?.Product?.PrintType === 'SUB'
  );

const isAOP = (products = [], productId) =>
  !!_.find(
    products,
    (p) => p?.Product?.ID === productId && p?.Product?.PrintType === 'AOP'
  );

const getArtworkSettingsProductId = (products = [], productId) =>
  _.find(products, (p) => p?.Product?.ID === productId)?.ArtworkSettings;

const getProductByProductId = (products = [], productId) =>
  _.find(products, (p) => p?.Product?.ID === productId);

export {
  getProductsByPromos,
  getProductsByCategoryIds,
  getProductsByPrintProvider,
  getProdShipCostByDestAndPP,
  getSKUFromProductId,
  getDesignerSettingsFromProductId,
  getPrintTypeFromProductId,
  getProductsByFulfillmentPlants,
  isSub,
  getMarketingImagePathFromProductId,
  productHasBackDesignerSettings,
  getDTGPlusFromProductId,
  getArtworkSettingsProductId,
  isAOP,
  getProductAdditionalImages,
  getAdditionalTechniquesFromProductId,
  getProductsByDecoratingTech,
  getProductByProductId,
  getProductsByType,
};
