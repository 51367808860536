import React from 'react';
import { row } from './row';

const rows = () => {
  let items = [];

  items.push(
    row(
      <span>hello</span>,
      <span>hello</span>,
      <span>hello</span>,
      <span>hello</span>
    )
  );

  return items;
};

export { rows };
