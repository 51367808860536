import { useState } from "react";
import { driver } from "driver.js";
import Dialog from "@material-ui/core/Dialog";
import Context, { Steps } from "pages/ai-mock-up-generator/context";
import AiRobot1 from "assets/images/ai-assistant.jpeg";
import AiDesignImagesSample from "assets/images/ai-design-images-sample.png";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import AiRobot2 from "assets/images/ai-robot2.png";
import AiRobot3 from "assets/images/ai-robot3.png";
import AiRobot4 from "assets/images/ai-robot4.png";
import AiRobot5 from "assets/images/ai-robot5.png";

import AiRobot7 from "assets/images/ai-robot7.jpeg";
import AiRobot8 from "assets/images/ai-robot8.jpeg";
import AiRobot9 from "assets/images/ai-robot9.jpeg";
import AiRobot10 from "assets/images/ai-robot10.jpeg";
import AiRobot11 from "assets/images/ai-robot11.jpeg";

import {
  Container,
  Header,
  HeaderRobotImg,
  AiAssistantActivatedButton,
  Title,
  Body,
  Options,
  StyledAIImages,
  Option,
  OptionHeader,
  OptionTitle,
  OptionSubTitle,
  AiAssist,
  VideoOption,
  Iframe,
} from "styles/pages/ai-mock-up-generator/select-ai-assistant";

const renderOption = (img, desc) => {
  return `<div style='display:flex;gap:10px;align-items:center;justify-content:center;'>
      <div>
        <img src=${img} style='width:100%;' />
      </div>
      <div style='font-size:14px;'>
        ${desc}
      </div>
    </div>`;
};

const options = [
  // {
  //   img: AiRobot2,
  //   description: `Hello my name is <b class='ai-assist-name'>Axel</b> ready to assist you.<br/>Click on Next`,
  // },
  // {
  //   img: AiRobot3,
  //   description:
  //     "Hello my name is <b class='ai-assist-name'>Luna</b> ready to assist you.<br/>Click on Next",
  // },
  // {
  //   img: AiRobot4,
  //   description:
  //     "Hello my name is <b class='ai-assist-name'>Kai</b> ready to assist you.<br/>Click on Next",
  // },
  // {
  //   img: AiRobot5,
  //   description:
  //     "Hello my name is <b class='ai-assist-name'>Mia</b> ready to assist you.<br/>Click on Next",
  // },

  {
    img: AiRobot7,
    description:
      "Hello my name is <b class='ai-assist-name'>Juno</b> ready to assist you.<br/>Click on Next",
  },
  {
    img: AiRobot8,
    description:
      "Hello my name is <b class='ai-assist-name'>Whiskers</b> ready to assist you.<br/>Click on Next",
  },
  {
    img: AiRobot9,
    description:
      "Hello my name is <b class='ai-assist-name'>Bolt</b> ready to assist you.<br/>Click on Next",
  },
  {
    img: AiRobot10,
    description:
      "Hello my name is <b class='ai-assist-name'>Milo</b> ready to assist you. Click on Next",
  },
  {
    img: AiRobot11,
    description:
      "Hello my name is <b class='ai-assist-name'>Zeke</b> ready to assist you. Click on Next",
  },
];

const SelectAiAssistant = () => {
  const [open, setOpen] = useState(false);
  const { setState, state } = Context();

  return (
    <Container>
      <Header>
        <AiAssist>
          <HeaderRobotImg src={AiRobot1} alt="robot" />
          <AiAssistantActivatedButton>
            Ai Assistant Activated
          </AiAssistantActivatedButton>
        </AiAssist>

        <VideoOption>
          <OptionHeader>Video Tutorial</OptionHeader>
          <OptionTitle onClick={() => setOpen(true)}>
            <PlayCircleOutlineIcon />
          </OptionTitle>
          <OptionSubTitle>
            click the play button to watch the tutorial
          </OptionSubTitle>
        </VideoOption>
      </Header>

      <Body>
        <Title>Click on the AI assistant you would like to use</Title>

        <Options>
          {options.map((o, i) => (
            <Option key={`ai${i}`} id={`ai${i}`}>
              <img
                src={o.img}
                alt="ai"
                onClick={() => {
                  const driverObj = driver({
                    allowKeyboardControl: false,
                    onNextClick: () => {
                      driverObj.destroy();
                      setState({
                        ...state,
                        step: Steps.Products,
                        aiActivated: true,
                        selectedAiCharaterImage: o.img,
                      });
                    },
                    showButtons: ["next"],
                    steps: [
                      {
                        element: `#ai${i}`,
                        popover: {
                          description: renderOption(o.img, o.description),
                          align: "start",
                          popoverClass: "driver-custom-popover-class",
                        },
                      },
                      {},
                    ],
                  });

                  driverObj.drive();
                }}
              />
            </Option>
          ))}
        </Options>
      </Body>

      <StyledAIImages>
        <img src={AiDesignImagesSample} alt="ai" />
      </StyledAIImages>

      <Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)}>
        <Iframe>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/4IyLu86WFBg"
          ></iframe>
        </Iframe>
      </Dialog>
    </Container>
  );
};

export default SelectAiAssistant;
