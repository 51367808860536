import styled from "styled-components";
import COLORS from "shared/constants/colors";

const Container = styled.div``;
const DialogTitle = styled.div`
  font-weight: 100;
  font-size: 24px;
  margin: 0 23px;
  margin-bottom: 10px;
  color: ${COLORS.BODY_TEXT};
  border-bottom: 2px solid #666;
  padding: 15px 0;
`;

const StyledEditProductSku = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  align-items: center;
  gap: 30px;
`;

const Header = styled.div`
  margin: 10px 0 30px 0;
  display: flex;
  justify-content: flex-end;

  button {
    background-color: #000;
    color: white;
    :hover {
      background-color: #000;
      color: white;
    }
  }
`;

export { Container, DialogTitle, StyledEditProductSku, Header };
