import React from 'react';
import ManageAccount from 'assets/images/manage-account.png';
import { Container } from 'styles/pages/account-setting/imageSection';
const ImageSection = () => {
  return (
    <Container>
      <img src={ManageAccount} alt='ManageAccount' />
    </Container>
  );
};

export default ImageSection;
