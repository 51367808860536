import _ from 'lodash';

const getPromos = (tags = []) =>
  _.find(tags, { TagType: 'Promos' })?.Tags || [];
const getStyles = (tags = []) =>
  _.find(tags, { TagType: 'Styles' })?.Tags || [];
const getBrands = (tags = []) =>
  _.find(tags, { TagType: 'Brands' })?.Tags || [];
const getTagById = (tags = [], id) => _.find(tags, { Id: id });

const getTagByTypeAndId = (tags = [], tag = {}) => {
  const selectedTagType = _.find(tags, { TagTypeNumeric: 5 });
  if (selectedTagType) {
    const selectedTag = getTagById(selectedTagType.Tags, tag.TagId);
    return selectedTag;
  }
};

export { getPromos, getStyles, getTagByTypeAndId, getTagById, getBrands };
