import _ from "lodash";
import { useSelector } from "react-redux";
import { getProductsByCategoryId } from "selectors/inventory";
import { getProductsOptions } from "pages/my-stores/products/product-creator/data";
import CheckIcon from "@material-ui/icons/Check";
import {
  ProductContainer,
  ProductBox,
  IconWrapper,
  Premium,
} from "styles/pages/my-stores/products/productCreator";

const Products = ({ CatId, value, onChange, selectedFulfillmentPant }) => {
  const allProducts = useSelector((state) => state?.products?.data);
  const products = getProductsByCategoryId(allProducts, CatId)?.filter(
    (p) =>
      (p?.Product?.visible === undefined || p?.Product?.visible === true) &&
      !!p?.PrintProviders?.find(
        (q) => q?.ProviderName === selectedFulfillmentPant
      )
  );

  return (
    <ProductContainer>
      {products?.map((c, i) => {
        const premiumProduct = c?.premiumProduct;

        return (
          <ProductBox
            premium={true}
            key={`${c?.Product?.ID}_${i}_artwork`}
            onClick={() => {
              const products = getProductsByCategoryId(allProducts, CatId);
              const product = _.find(
                products,
                (o) => o?.Product?.ID === c?.Product?.ID
              );
              onChange(product);
            }}
          >
            <IconWrapper
              style={{
                background: value === c?.Product?.ID ? "#000" : "#F4F4F4",
              }}
            >
              {value === c?.Product?.ID ? <CheckIcon /> : null}
            </IconWrapper>
            <img
              src={c?.Product?.MarketingImagePath}
              alt={c?.Product?.DisplayName}
              width="100"
              height="100"
            />
            <p>{c?.Product?.DisplayName}</p>

            {premiumProduct && <Premium>Premium</Premium>}
          </ProductBox>
        );
      })}
    </ProductContainer>
  );
};

export default Products;
