import styled from "styled-components";

const WelcomeTitle = styled.div`
  display: flex;
  justify-content: center;
  color: #000;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;

  img {
    width: 80px;
  }
`;

const WelcomeDescription = styled.div`
  padding: 0px 30px;
  text-align: center;
  font-size: 14px;
`;

const WelcomeFooter = styled.div`
  display: flex;
  padding: 30px 20px;
`;

const WelcomeFooterDesciption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
  p {
    margin: 0;
    :first-child {
      font-weight: bold;
    }
  }
`;

const WelcomeFooterImage = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
  padding: 10px 20px;
  font-size: 17px;
  font-weight: bold;
  .MuiButton-containedPrimary {
    background-color: #000;
    font-size: 20px;
    font-weight: bold;
    :hover {
      background-color: #000;
    }
  }
`;

const Footer1 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
  padding: 10px 20px;
  font-size: 17px;
  font-weight: bold;
  .MuiButton-containedPrimary {
    background-color: #000;
    font-size: 20px;
    font-weight: bold;
    :hover {
      background-color: #000;
    }
  }
`;

export {
  WelcomeTitle,
  WelcomeDescription,
  WelcomeFooter,
  WelcomeFooterDesciption,
  WelcomeFooterImage,
  Footer,
  Footer1,
};
