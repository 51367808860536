import * as a from 'actions/currency';

const initial = {
  value: 'USD',
};

const currency = (state = initial, action = null) => {
  switch (action.type) {
    case a.SET_CURRENCY:
      return {
        ...state,
        value: action.currency,
      };

    default:
      return state;
  }
};

export default currency;
