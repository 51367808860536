import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { columns } from './columns';
import { rows } from './rows';

const useStyles = makeStyles({
  // td: {
  //   '& img': {
  //     width: 50,
  //   },
  //   '& a': {
  //     color: '#337ab7',
  //     textDecoration: 'none',
  //   },
  // },
});

const DescriptionsTableBody = () => {
  const classes = useStyles();
  const data = rows();

  return data.map((row) => {
    return (
      <TableRow hover tabIndex={-1} key={row.code}>
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell
              className={classes.td}
              key={column.id}
              align={column.align}>
              {value}
            </TableCell>
          );
        })}
      </TableRow>
    );
  });
};

export default DescriptionsTableBody;
