import { get } from '@axios';
import { showLoader, hideLoader } from 'actions/loader';
import { updateField } from 'actions/common';
import { requests } from 'shared/requests';
import { SHOW_ERROR_MODAL } from 'actions/errorModal';

const SET_COLORS = 'SET_COLORS';
const SET_COLORS_ERROR = 'SET_COLORS_ERROR';

const getColorGroups = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const colors = await get({ url: requests.getColors });
      dispatch(updateField(SET_COLORS, { colors }));
    } catch (error) {
      dispatch({ type: SHOW_ERROR_MODAL });
      dispatch(updateField(SET_COLORS_ERROR, { error }));
    }
    dispatch(hideLoader());
  };
};

export { getColorGroups, SET_COLORS, SET_COLORS_ERROR };
