import { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Context from "pages/ai-mock-up-generator/context";

const ChooseProductRightBody = () => {
  const { state } = Context();
  const [coloursOpen, setColoursOpen] = useState(true);
  const [sizeOpen, setSizeOpen] = useState(true);

  const handleColoursClick = () => setColoursOpen(!coloursOpen);
  const handleSizeClick = () => setSizeOpen(!sizeOpen);

  if (!state?.product) return null;

  return (
    <List component="nav">
      <ListItem button onClick={handleColoursClick}>
        <ListItemText primary="Colours" />
        {coloursOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={coloursOpen} timeout="auto" unmountOnExit>
        {state?.product?.Colors?.map((c) => (
          <List component="div" disablePadding key={c?.Name}>
            <ListItem>
              <div style={{ marginLeft: "10px" }}>{c?.Name}</div>
            </ListItem>
          </List>
        ))}
      </Collapse>

      <ListItem button onClick={handleSizeClick}>
        <ListItemText primary="Sizes" />
        {sizeOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={sizeOpen} timeout="auto" unmountOnExit>
        {state?.product?.Sizes?.map((c) => (
          <List component="div" disablePadding key={c?.Name}>
            <ListItem>
              <div style={{ marginLeft: "10px" }}>{c?.Name}</div>
            </ListItem>
          </List>
        ))}
      </Collapse>
    </List>
  );
};

export default ChooseProductRightBody;
