import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin: 10px 0;
  max-height: 90vh;
  overflow: auto;
`;

const SelectButton = styled.div`
  position: absolute;
  background: #000;
  color: white;
  padding: 10px;
  border-radius: 5px;
  top: 30%;
  display: none;
  cursor: pointer;
  font-weight: bold;
`;

const BackBurnButton = styled.div`
  position: absolute;
  background: #000;
  color: white;
  padding: 10px;
  border-radius: 5px;
  top: 55%;
  display: none;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
`;

const Wrapper = styled.div`
  position: relative;
  justify-content: center;
  display: flex;

  img {
    width: 90%;
    border: 1px dashed #000;
    padding: 4px;
    border-radius: 4px;
  }

  :hover {
    ${BackBurnButton} {
      display: block;
    }
    ${SelectButton} {
      display: block;
    }
  }
`;

export { Container, Wrapper, SelectButton, BackBurnButton };
