import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import BuildIcon from '@material-ui/icons/Build';
import Chip from '@material-ui/core/Chip';
import {
  Container,
  Title,
  Link,
  CurrentPageTitle,
  Breadcrumb,
  Wrapper,
  Subtitle,
} from 'styles/components/SubHeader';

const SubHeader = ({
  title,
  links,
  currentPageTitle,
  rightCornerButtons,
  subTitle,
  betaRelease = false,
}) => (
  <Container>
    <Wrapper>
      <Title>
        {title}
        {betaRelease && (
          <Chip
            icon={
              <BuildIcon
                style={{ color: '#fff', fontSize: 16, marginLeft: 10 }}
              />
            }
            label='Beta'
            style={{ color: '#fff', background: '#ff3c3c', marginLeft: 10 }}
            size='medium'
          />
        )}
      </Title>
      {subTitle ? <Subtitle>{subTitle}</Subtitle> : null}
      {links ? (
        <Breadcrumb aria-label='breadcrumb'>
          {links?.map((link, index) => (
            <Link key={`breadcrumbLink${index}`} to={link.to} key={link.title}>
              {link.title}
            </Link>
          ))}
          <CurrentPageTitle>{currentPageTitle}</CurrentPageTitle>
        </Breadcrumb>
      ) : null}
    </Wrapper>
    {rightCornerButtons?.length
      ? rightCornerButtons?.map((b) => (
          <Button
            disabled={!!b?.disabled}
            title={b?.title}
            key={b?.title}
            onClick={b?.onClick}
          />
        ))
      : null}
  </Container>
);

SubHeader.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      to: PropTypes.string,
    })
  ),
  currentPageTitle: PropTypes.string,
};

export default SubHeader;
