import React from 'react';
import { useSelector } from 'react-redux';
import TextField from 'components/TextField';

const ToolDurasoftFields = ({ orderLine = {}, setCoordinates }) => {
  const products = useSelector((state) => state?.products?.data);
  const printAreas = orderLine?.printAreas;
  const selectedPrintArea = printAreas?.find((p) => p?.selected);
  const designerSettings = selectedPrintArea?.designerSettings || {};

  return orderLine.PrintType === 'DURASOFT' ? (
    <div style={{ display: 'flex', gridGap: 5 }}>
      {selectedPrintArea?.artworkId &&
        selectedPrintArea?.printAreaName === 'Front' && (
          <>
            <TextField
              label='Front Width (inches)'
              type='number'
              style={{ display: 'flex', flex: 1 }}
              onChange={(width) => {
                const widthInPixel =
                  (Number(width) * 255) / designerSettings?.multiplier;
                const v = designerSettings?.width;
                const widthInInches =
                  width < 1
                    ? 255 / designerSettings?.multiplier
                    : widthInPixel > v
                    ? v
                    : widthInPixel;

                setCoordinates({
                  ...selectedPrintArea?.coordinates,
                  width: widthInInches,
                });
              }}
            />
            <TextField
              label='Front Height (inches)'
              type='number'
              style={{ display: 'flex', flex: 1 }}
              onChange={(height) => {
                const heightInPixel =
                  (Number(height) * 255) / designerSettings?.multiplier;
                const v = designerSettings?.height;
                const heightInInches =
                  height < 1
                    ? 255 / designerSettings?.multiplier
                    : heightInPixel > v
                    ? v
                    : heightInPixel;

                setCoordinates({
                  ...selectedPrintArea?.coordinates,
                  height: heightInInches,
                });
              }}
            />
          </>
        )}

      {selectedPrintArea?.artworkId &&
        selectedPrintArea?.printAreaName === 'Back' && (
          <>
            <TextField
              label='Back Width (inches)'
              type='number'
              style={{ display: 'flex', flex: 1 }}
              onChange={(width) => {
                const widthInPixel =
                  (Number(width) * 255) / designerSettings?.multiplier;
                const v = designerSettings?.width;
                const widthInInches =
                  width < 1
                    ? 255 / designerSettings?.multiplier
                    : widthInPixel > v
                    ? v
                    : widthInPixel;

                setCoordinates({
                  ...selectedPrintArea?.coordinates,
                  width: widthInInches,
                });
              }}
            />
            <TextField
              label='Back Height (inches)'
              type='number'
              style={{ display: 'flex', flex: 1 }}
              onChange={(height) => {
                const heightInPixel =
                  (Number(height) * 255) / designerSettings?.multiplier;
                const v = designerSettings?.height;
                const heightInInches =
                  height < 1
                    ? 255 / designerSettings?.multiplier
                    : heightInPixel > v
                    ? v
                    : heightInPixel;

                setCoordinates({
                  ...selectedPrintArea?.coordinates,
                  height: heightInInches,
                });
              }}
            />
          </>
        )}
    </div>
  ) : null;
};

export default ToolDurasoftFields;
