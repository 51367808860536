import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Context from "../aiGeneratedImagesContext";
import {
  GeneratedImagesWrapper,
  GeneratedImage,
  // StyledSaveIcon,
} from "styles/components/ArtWork/ai-generated-images";

const RenderGeneratedImages = () => {
  const { state, setState } = Context();
  const generatedImages = state?.generatedImages;

  if (!generatedImages?.length) return null;

  return (
    <GeneratedImagesWrapper>
      {generatedImages?.map((i, j) => (
        <GeneratedImage key={`${i?.url}_${j}`}>
          <img src={i?.url} />
          {/* <Tooltip arrow title="Save to Shirtly Library">
             <StyledSaveIcon
              onClick={async () =>
                setState({ ...state, saveAIImagesToLibrary: i?.url })
              }
            /> 
          </Tooltip>*/}
        </GeneratedImage>
      ))}
    </GeneratedImagesWrapper>
  );
};

export default RenderGeneratedImages;
