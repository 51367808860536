import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'pages/home/Footer';
import { ROUTES } from 'shared/constants/routes';
import LINKS from 'layout/data';
import { Container, Body } from 'styles/layout';

const Layout = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state?.auth);
  return (
    <Route
      exact
      {...rest}
      render={(routeProps) =>
        auth.authenticated ? (
          <Container>
            <Header />
            <Body>
              <Component {...routeProps} />
            </Body>
            <Footer isHomePage={false} links={LINKS} />
          </Container>
        ) : (
          <Redirect to={ROUTES.HOME.LINK} />
        )
      }
    />
  );
};

export default Layout;
