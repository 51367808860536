import { Button, styled } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import COLORS from "shared/constants/colors";
import { renderImages } from "pages/customizer-tool/utils";
import { Container, Header } from "styles/pages/customizer-tool/text-options";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImageOption = ({
  garmentPart,
  leftPocket,
  setLeftPocket,
  rightSleeve,
  setRightSleeve,
  back,
  setBack,
  renderModel,
}) => {
  const handleImageUpload = (event) => {
    const file = event?.target?.files[0];

    let reader = new FileReader();

    reader.onload = function () {
      const base64String = reader.result;

      if (garmentPart === "left-pocket") {
        const images = [
          ...leftPocket.images,
          {
            src: base64String,
            width: 100,
            height: 100,
            x: 1300,
            y: 1200,
          },
        ];

        renderImages(images, "left-pocket-images");
        setLeftPocket({ ...leftPocket, images });
      } else if (garmentPart === "right-sleeve") {
        const images = [
          ...rightSleeve.images,
          { src: base64String, width: 100, height: 100, x: 350, y: 520 },
        ];

        renderImages(images, "right-sleeve-images");
        setRightSleeve({
          ...rightSleeve,
          images,
        });
      } else if (garmentPart === "back") {
        const images = [
          ...back.images,
          { src: base64String, width: 100, height: 100, x: 350, y: 1050 },
        ];

        renderImages(images, "back-images");
        setBack({
          ...back,
          images,
        });
      }
      renderModel();
    };

    reader.readAsDataURL(file);
  };

  return (
    <Container>
      <Header>
        <h3>Image</h3>
        <h4>Upload your image below.</h4>
      </Header>

      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        style={{
          backgroundColor: COLORS.DARK_BLUE,
          color: COLORS.WHITE,
          fontWeight: "normal",
          boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
          textTransform: "none",
          borderRadius: "3px",
          fontSize: "16px",
          lineHeight: "1.5",
          margin: "20px 0",
          width: "100%",
        }}
      >
        Upload File
        <VisuallyHiddenInput
          type="file"
          onClick={(event) => (event.target.value = null)}
          onChange={(e) => handleImageUpload(e)}
        />
      </Button>
    </Container>
  );
};

export default ImageOption;
