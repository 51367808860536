import React from "react";

const selectedDesignerSettingsStyle = {
  border: "1px solid #000",
  cursor: "pointer",
  textAlign: "center",
  padding: "0 10px",
  zIndex: 9,
};

const ToolPrintAreas = ({ orderLine = {}, setOrderLine, canvas }) => {
  const printAreas = orderLine?.printAreas;
  const isPlusSelected = orderLine?.plus;

  return printAreas?.map((printArea, index) => {
    if (!printArea?.printAreaMarketingImage) {
      return null;
    }

    if (printArea?.printAreaName !== "Front" && isPlusSelected) {
      return null;
    }

    const designerSettings = printArea?.designerSettings;

    if (!designerSettings?.width && !designerSettings?.height) return null;

    return (
      <div
        style={
          printArea?.selected
            ? selectedDesignerSettingsStyle
            : {
                cursor: "pointer",
                border: "1px solid #ccc",
                textAlign: "center",
                padding: "0 10px",
                zIndex: 9,
              }
        }
        key={`${printArea?.printAreaName}_${index}`}
        onClick={() => {
          const printAreas = orderLine?.printAreas?.map((p, j) => ({
            ...p,
            selected: index === j,
          }));

          const selectedPrintArea = printAreas?.find((p) => p?.selected);

          canvas?.clear();

          if (selectedPrintArea?.canvasJSON) {
            canvas?.loadFromJSON(selectedPrintArea?.canvasJSON);
          }

          setOrderLine({
            ...orderLine,
            printAreas,
          });
        }}
      >
        <img
          src={printArea?.printAreaMarketingImage}
          alt="print-area"
          width="50"
          height="50"
        />
        <h5 style={{ margin: 0, textAlign: "center" }}>
          {printArea?.printAreaName}
        </h5>
      </div>
    );

    // return (
    //   <Button
    //     key={`${printArea?.printAreaName}_${index}`}

    //   />
    // );
  });
};

export default ToolPrintAreas;
