import _ from 'lodash';
import { getProdShipCostByDestAndPP } from 'selectors/products';
import {
  getTaxCost,
  getFulfillmentCostWithTax,
  isVatIncluded,
} from 'pages/order-details/OrderSummary';
import { json2xml } from 'utils/jsonToXmlParser';

export const getShippingCostInfo = (order, products) => {
  const orderLineItems = order?.OrderLines[0]?.OrderLineItems;

  if (orderLineItems?.length > 0) {
    const majorProductIds = [1, 2, 446, 450, 454, 455, 457, 459, 1614798309];
    const subMajorIds = [2001];
    let product = orderLineItems[0];
    let quantity = 0;

    let orderLineItmsHasWeight = true;

    for (let i = 0; i < orderLineItems.length; i++) {
      const weight = _.find(
        products,
        (c) => c?.Product?.ID === orderLineItems[i]?.ProductID
      )?.Product?.weight;
      if (!weight) {
        orderLineItmsHasWeight = false;
        break;
      }
    }

    if (!orderLineItmsHasWeight) {
      for (let i = 0; i < orderLineItems.length; i++) {
        if (_.includes(subMajorIds, orderLineItems[i].ProductID)) {
          product = orderLineItems[i];
          break;
        }

        if (_.includes(majorProductIds, orderLineItems[i].ProductID)) {
          product = orderLineItems[i];
          break;
        }
      }
    } else {
      for (let i = 0; i < orderLineItems.length; i++) {
        if (i === 0) product = orderLineItems[i];

        const quantity1 = Number(orderLineItems[i]?.OliQuantity);
        const weight1 = _.find(
          products,
          (c) => c?.Product?.ID === orderLineItems[i]?.ProductID
        )?.Product?.weight;

        for (let j = i + 1; j < orderLineItems.length; j++) {
          const quantity2 = Number(orderLineItems[j]?.OliQuantity);
          const weight2 = _.find(
            products,
            (c) => c?.Product?.ID === orderLineItems[j]?.ProductID
          )?.Product?.weight;

          if (quantity2 * weight2 > quantity1 * weight1) {
            product = orderLineItems[j];
          }
        }
      }
    }

    for (let i = 0; i < orderLineItems.length; i++) {
      quantity += Number(orderLineItems[i]?.OliQuantity) || 0;
    }

    return getProdShipCostByDestAndPP(
      products,
      order?.PrintProvider,
      order?.CountryString,
      product,
      quantity
    );
  }
};

export const totalOrderCostInUSD = (
  shippingCostInfo,
  taxInfo,
  order,
  grand = false
) => {
  const finalShippingCost = getFinalShippingCost(
    shippingCostInfo,
    taxInfo,
    order
  );
  const totalOrderCostInUSD = Number(
    (finalShippingCost + getFulfillmentCostWithTax(order, taxInfo)).toFixed(2)
  );

  if (!grand) {
    return totalOrderCostInUSD;
  }

  const subTotal = getFulfillmentCostWithTax(order, taxInfo);
  return Number(subTotal + finalShippingCost).toFixed(2);
};

export const getOrderXML = (products, taxInfo, order, emailid, countries) =>
  json2xml(order, products, emailid, isVatIncluded(taxInfo, order, countries));

export const getBulkOrderStripePayload = (
  orders,
  products,
  user,
  card,
  taxInfo
) => {
  let payload = {
    amount: 0,
    ordersId: [],
    user,
    currency: 'USD',
    cardDetails: {
      cardNumber: card.cardNumber,
      cardExpMonth: card.cardExpMonth,
      cardExpYear: card.cardExpYear,
      cvv: card.cvv,
    },
  };

  let receiptID = [];

  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    const shippingCostInfo = getShippingCostInfo(order, products);
    const orderLineItems = order?.OrderLines[0]?.OrderLineItems;
    const validOrder = orderLineItems?.find(
      (o) =>
        o?.CategoryID &&
        o?.ProductID &&
        o?.SizeID &&
        o?.ColorID &&
        o?.printAreas?.length > 0
    );

    if (validOrder) {
      payload.amount +=
        totalOrderCostInUSD(shippingCostInfo, taxInfo, order, false) * 100;
      payload.ordersId.push(order?.ID);
      receiptID.push(order?.ReceiptID);
    }
  }
  payload.description = `Shirtly Order for ${user} (${receiptID.join()})`;
  return payload;
};

export const getFinalShippingCost = (shippingCostInfo, taxInfo, order) => {
  const shippingCost = Number(shippingCostInfo?.cost) || 0;
  const shippingTaxCost = getShippingTaxCost(shippingCostInfo, taxInfo, order);
  return shippingCost + shippingTaxCost;
};

export const getShippingTaxCost = (shippingCostInfo, taxInfo, order) => {
  const shippingCost = Number(shippingCostInfo?.cost) || 0;
  const taxPercentageOnOrder = getTaxCost(taxInfo, order);
  return Number(((shippingCost * taxPercentageOnOrder) / 100).toFixed(2));
};
