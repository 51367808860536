import * as a from 'actions/webPages';

const reducer = (state = [], action = null) => {
  switch (action.type) {
    case a.SET_HOME_WEB_PAGE:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
