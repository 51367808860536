import styled from "styled-components";
import Button from "components/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import SaveIcon from "@material-ui/icons/Save";

const Container = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 300px 1fr;
`;

const GetStartedContainer = styled.div`
  background-color: #fff;
`;

const LeftWrapper = styled.div`
  background-color: black;
  padding: 14px;
  display: flex;
  flex-direction: column;
`;

const LeftHeader = styled.div`
  text-align: center;
`;

const RightWrapper = styled.div``;

const HeaderLogo = styled.img`
  width: 100px;
`;

const ArchieveMessageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 20px 0;
`;

const ArchieveMessageTitle = styled.p`
  color: white;
  text-align: center;
  margin-top: 0;
  font-size: 18px;
  font-weight: bold;
`;

const ArchieveMessages = styled.div`
  flex: 1;
  background-color: white;
  border-radius: 5px;
  overflow: auto;
  max-height: 58vh;
`;

const RightWrapperTopHeader = styled.div`
  height: 60px;
  background-color: #000;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  font-size: 14px;
  font-weight: bold;
`;

const PhasesHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;

  img {
    width: 80px;
  }
`;

const GeneratedImagesWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  .robot {
    width: 100px;
    height: 100px;
  }
`;

const GeneratedImage = styled.div`
  border: 2px solid black;
  width: 400px;
  height: 400px;
  border-radius: 4px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;

const DownloadIcon = styled(GetAppIcon)`
  bottom: 5px;
  position: absolute;
  right: 5px;
  background: white;
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
`;

const StyledSaveIcon = styled(SaveIcon)`
  &.MuiSvgIcon-root {
    bottom: 5px;
    position: absolute;
    right: 5px;
    background: white;
    padding: 3px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 30px;
  }
`;

const Phase2Loading = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: 500;
  font-size: 18px;

  .loading {
    border: 2px solid black;
    height: 28px;
    padding: 0 2px;
  }
`;

const PrevGeneratedImagesWrapper = styled.div`
  overflow: auto;
`;

const PrevGeneratedImagesRow = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px;
`;

const PrevGeneratedImages = styled.div`
  width: 100%;
  border: 1px dashed black;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .MuiChip-label {
    font-size: 12px;
  }
`;

const Phase1Value = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
`;

const PrevImages = styled.div``;

const PrevImagesWrapper = styled.div`
  img {
    width: 100%;
    height: 100%;
    border: 1px solid black;
  }
`;

const SaveButton = styled(Button)`
  &.MuiButton-contained {
    background-color: #000;
    color: #fff;
    width: 100%;
    border-radius: 0;

    :hover {
      background-color: #000;
      color: #fff;
    }
  }
`;

export {
  Container,
  GetStartedContainer,
  LeftWrapper,
  RightWrapper,
  LeftHeader,
  HeaderLogo,
  ArchieveMessageWrapper,
  ArchieveMessageTitle,
  ArchieveMessages,
  RightWrapperTopHeader,
  PhasesHeader,
  GeneratedImagesWrapper,
  GeneratedImage,
  DownloadIcon,
  Phase2Loading,
  StyledSaveIcon,
  PrevGeneratedImagesWrapper,
  PrevGeneratedImagesRow,
  PrevGeneratedImages,
  Phase1Value,
  PrevImages,
  PrevImagesWrapper,
  SaveButton,
};
