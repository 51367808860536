import React from 'react';
import Button from 'components/Button';
import Dialog from '@material-ui/core/Dialog';
import { ModalContainer, Footer } from 'styles/pages/my-stores/registration';

const RetrieveSuccessDialog = ({ open, setModal }) => {
  return (
    <Dialog maxWidth='md' open={open} onClose={() => setModal(false)}>
      <ModalContainer>
        <h1>Retrieval Complete</h1>
        <p>Your order has been successfully retrieved</p>
        <Footer>
          <Button variant='text' title='Ok' onClick={() => setModal(false)} />
        </Footer>
      </ModalContainer>
    </Dialog>
  );
};

export default RetrieveSuccessDialog;
