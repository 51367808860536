import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Phase1 from "components/ArtWork/AiDesignPhases/Phase1";
import Phase2 from "components/ArtWork/AiDesignPhases/Phase2";
import Button from "components/Button";
import SaveAIImagesToLibrary from "components/ArtWork/AiDesignPhases/SaveAIImagesToLibrary";
import RenderGeneratedImages from "components/ArtWork/AiDesignPhases/RenderGeneratedImages";
import { default as AIGeneratedContext } from "components/ArtWork/aiGeneratedImagesContext";
import Context, { Steps } from "pages/ai-mock-up-generator/context";
import { generateAIImages } from "actions/aiDesignStudio";
import { showLoader, hideLoader } from "actions/loader";
import {
  PhasesContainer,
  SecurityLayer,
} from "styles/components/ArtWork/ai-design-phases";
import { NextPhaseFooter } from "styles/pages/ai-mock-up-generator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

const onloadImageProcess = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const Phases = () => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const { state } = AIGeneratedContext();
  const { setState, state: mState } = Context();

  const GreenRadio = withStyles({
    root: {
      color: green[400],
      "&$checked": {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const RedRadio = withStyles({
    root: {
      color: red[400],
      "&$checked": {
        color: red[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const generateImages = async () => {
    const phase1 = state?.phase1;
    const phase1SelectedOptions = state?.phase1SelectedOptions;
    let message = "";

    if (phase1?.trim().length > 0) {
      message += `${phase1}. `;
    }

    if (phase1SelectedOptions?.length > 0) {
      message += `Create an artfile with ${phase1SelectedOptions?.join(
        ", "
      )} effect.`;
    }

    setState({
      ...state,
      phase1Completed: true,
      loading: true,
      generatedImages: null,
    });

    const response = await dispatch(
      generateAIImages(
        {
          message,
          size: "1024x1024",
        },
        token
      )
    );

    setState({
      ...state,
      phase1Completed: true,
      loading: false,
      generatedImages: response?.data,
      prevAiGeneratedImages: [
        ...state?.prevAiGeneratedImages,
        { phase1SelectedOptions, phase1, images: response?.data },
      ],
    });
  };

  const save = async (url) => {
    const API_ENDPOINT =
      "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";

    dispatch(showLoader());
    const fileData = new FormData();
    fileData.append("file", url);
    fileData.append("upload_preset", "tnedst8q");
    fileData.append("folder", `Shirtly/${emailid}/AIArtFiles`);

    const data = await fetch(API_ENDPOINT, {
      method: "post",
      body: fileData,
    });
    const file = await data?.json();
    let secureUrl = file.secure_url;
    await onloadImageProcess(secureUrl);

    dispatch(hideLoader());

    const selectedPrintArea = mState?.artwork?.printAreas?.find(
      (p) => p?.selected
    );

    const designerSettings = selectedPrintArea?.designerSettings;
    const ArtWorkCoordinates = selectedPrintArea?.coordinates || {};
    const { width: fileWidth, height: fileHeight } = file;
    const { width: designedWidth, height: designedHeight } = designerSettings;

    if (designedWidth > designedHeight) {
      const aspectRatio = fileWidth / fileHeight;
      if (fileWidth > fileHeight) {
        if (fileWidth > designedWidth) {
          ArtWorkCoordinates.height = Math.round(designedWidth / aspectRatio);
          ArtWorkCoordinates.width = designedWidth;
          if (ArtWorkCoordinates.height > designedHeight) {
            ArtWorkCoordinates.height = designedHeight;
          }
        } else {
          ArtWorkCoordinates.height = designedHeight;
          ArtWorkCoordinates.width = Math.round(designedHeight * aspectRatio);
        }
      } else {
        ArtWorkCoordinates.height = designedHeight;
        ArtWorkCoordinates.width = Math.round(designedHeight * aspectRatio);
      }
    } else if (fileWidth <= designedWidth && fileHeight <= designedHeight) {
      ArtWorkCoordinates.width = fileWidth;
      ArtWorkCoordinates.height = fileHeight;
    } else if (fileWidth > fileHeight) {
      const aspectRatio = fileWidth / fileHeight;
      ArtWorkCoordinates.width = designedWidth;
      ArtWorkCoordinates.height = Math.round(designedWidth / aspectRatio);
    } else if (fileWidth < fileHeight) {
      const aspectRatio = fileWidth / fileHeight;
      if (designedHeight * aspectRatio > designedWidth) {
        ArtWorkCoordinates.width = designedWidth;
        ArtWorkCoordinates.height = Math.round(designedWidth / aspectRatio);
      } else {
        ArtWorkCoordinates.height = designedHeight;
        ArtWorkCoordinates.width = Math.round(designedHeight * aspectRatio);
      }
    } else if (fileWidth === fileHeight) {
      if (designedWidth > designedHeight) {
        ArtWorkCoordinates.height = designedHeight;
        ArtWorkCoordinates.width = designedHeight;
      } else {
        ArtWorkCoordinates.height = designedWidth;
        ArtWorkCoordinates.width = designedWidth;
      }
    }
    setState({
      ...mState,
      step: Steps.MockUp,
      artwork: {
        ...mState.artwork,
        printAreas: mState.artwork?.printAreas?.map((p) =>
          p?.selected
            ? {
                ...p,
                artwork: secureUrl,
                artworkId: file.public_id,
                coordinates: ArtWorkCoordinates,
                aiTempArtwork: file,
                aiGenerateImages: true,
                canvasJSON: null,
              }
            : p
        ),
      },
    });
  };

  return (
    <PhasesContainer>
      <SecurityLayer>
        <FormControl
          component="fieldset"
          style={{ flexDirection: "row", display: "flex" }}
        >
          <h3 style={{ paddingRight: 10 }}>Security Layer</h3>
          <RadioGroup
            name="security-layer"
            value={mState?.securityLayer}
            style={{ flexDirection: "row" }}
            onChange={(e) => {
              setState({ ...mState, securityLayer: e?.target.value });
            }}
          >
            <FormControlLabel value="on" control={<GreenRadio />} label="On" />
            <FormControlLabel value="off" control={<RedRadio />} label="Off" />
          </RadioGroup>
        </FormControl>
      </SecurityLayer>
      <Phase1 generateImages={generateImages} />
      <div id="design-phase-2">
        {state?.phase1Completed && <Phase2 />}
        <RenderGeneratedImages />

        <SaveAIImagesToLibrary
          reload={() => {
            // handleClose();
            // setDesigns();
          }}
        />

        {state?.phase1Completed &&
          !state?.loading &&
          state?.generatedImages?.length > 0 && (
            <NextPhaseFooter>
              <Button
                title="Only Save"
                onClick={async () => {
                  const API_ENDPOINT =
                    "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";
                  const fileData = new FormData();
                  fileData.append("file", state?.generatedImages[0]?.url);
                  fileData.append("upload_preset", "tnedst8q");
                  fileData.append("folder", `Shirtly/${emailid}/AIArtFiles`);
                  dispatch(showLoader());
                  await fetch(API_ENDPOINT, {
                    method: "post",
                    body: fileData,
                  });
                  dispatch(hideLoader());
                  alert("Your selected image has been saved to AI Library.");
                }}
              />

              <Button
                style={{ marginLeft: 10 }}
                title="Save & Use for Mock-Up"
                onClick={() => save(state?.generatedImages[0]?.url)}
              />
            </NextPhaseFooter>
          )}
      </div>
    </PhasesContainer>
  );
};

export default Phases;
