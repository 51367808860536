import styled from 'styled-components';

const Container = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: 410px 1fr;
  grid-gap: 24px;
  @media (max-width: 790px) {
    grid-template-columns: 1fr;
  }
`;

const Form = styled.div`
  margin: 15px 0;
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  margin-top: 0;
  gap: 20px;
  align-items: center;
  @media (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Submit = styled.div`
  text-align: center;
`;
const TextSection = styled.div``;
export { Container, Form, Submit, TextSection };
