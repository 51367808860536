import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import COLORS from "shared/constants/colors";

const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  min-width: 225px;
  input {
    width: 100%;
    background: #ececec;
    border: 1px solid #e5e6e7;
    padding: 8px 48px 8px 12px;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
    font-size: 14px;
    border-radius: 5px;
    height: 30px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const SearchBarIcon = styled(SearchIcon)`
  position: absolute;
  right: 0;
  font-size: 32px !important;
  background: #000;
  color: ${COLORS.WHITE};
  top: 0;
  padding: 4px 8px;
  width: 54px !important;
  border-radius: 0 3px 3px 0;
  height: 100% !important;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 60px;
  top: 20%;
  cursor: pointer;
  background: red;
  color: white;
  border-radius: 50%;
  font-size: 18px;
  padding: 2px;
`;

export { Container, SearchBarIcon, StyledCloseIcon };
