import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllDTGPlus } from 'actions/products';
import Grid from '@material-ui/core/Grid';
import Button from 'components/Button';
import TextField from 'components/TextField';
import Checkbox from 'components/CheckBox';
import { DECORATING_TECHNIQUES } from 'shared/constants/decorating-techniques';
import {
  Container,
  SubHeading,
  SelectCategories,
  Update,
  FourBoxIconWrapper,
  EditDtgPlusInnerField,
} from 'styles/pages/edit-products/editProductInformation';

const EditDtgPlus = ({ product = { Product: {} }, editDTGPlusInformation }) => {
  const dispatch = useDispatch();
  const [dtg, setDtg] = useState(!!product?.DTGPlus?.length);

  const productAdditionalTechniquesArtSettings =
    product?.AdditionalTechniquesArtSettings;

  const dtgPlus = productAdditionalTechniquesArtSettings?.find(
    (p) => p?.name === 'DTGPlus'
  );

  const [dtgMaxFulfillmentDays, setDtgMaxFulfillmentDays] = useState(
    dtgPlus?.maxFulfillmentDays ||
      product?.Product?.defaultMaximumFulfillDays ||
      0
  );
  const [dtgMinFulfillmentDays, setDtgMinFulfillmentDays] = useState(
    dtgPlus?.minFulfillmentDays ||
      product?.Product?.defaultMinimumFulfillDays ||
      0
  );

  const [allDtgs, setDtgs] = useState(null);
  // const [screenPrinting, setScreenPrinting] = useState({});
  const [selectedDecorativeTech, setSelectedDecorativeTech] = useState([]);
  const printType = product?.Product?.PrintType;

  useEffect(async () => {
    const request = await dispatch(getAllDTGPlus());

    if (request?.error) {
      return;
    }
    if (product?.DTGPlus?.length) {
      let data = request?.data;
      for (let i = 0; i < data?.length; i++) {
        const value = product?.DTGPlus?.find(
          (v) => v?.DisplayName === data[i]?.DisplayName
        );
        if (value) {
          data[i] = { ...data[i], selected: true };
        }
      }
      setDtgs(data);
    } else {
      setDtgs(request?.data);
    }
  }, [dispatch, product]);

  // useEffect(() => {
  //   const filteredScreenPrint = product?.AdditionalTechniquesArtSettings?.find(
  //     (p) => p?.name === 'ScreenPrint'
  //   );

  //   if (!Object.keys(screenPrinting)?.length && !!filteredScreenPrint) {
  //     setScreenPrinting(filteredScreenPrint);
  //   }
  // }, [product]);

  useEffect(() => {
    let data = DECORATING_TECHNIQUES.filter(
      (v) => v?.DisplayName !== printType
    );

    data = data.map((v) => ({
      ...v,
      selected: !!product?.RemainingDecorativeTechniques?.filter(
        (r) => r?.DisplayName === v?.DisplayName
      )?.length,
    }));

    setSelectedDecorativeTech(data);
  }, [dispatch]);

  const disabled = dtg ? !allDtgs?.filter((v) => v.selected).length : false;

  if (printType === 'AOP' || printType === 'SUB') {
    return null;
  }

  return (
    <Container>
      <SubHeading>Decorating Techniques</SubHeading>

      <SelectCategories>
        <FourBoxIconWrapper>
          <img
            alt="plus"
            src="https://res.cloudinary.com/big-oven-tees-inc/image/upload/v1620541564/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/clean-clothes_uqthco.png"
            width="50"
            height="50"
          />
          <Checkbox
            label="DTG Plus"
            checked={dtg}
            onChange={(v) => {
              setDtg(v);
              setDtgs(
                allDtgs?.map((v) => ({
                  ...v,
                  selected: false,
                }))
              );
            }}
          />
          <EditDtgPlusInnerField>
            <TextField
              disabled={!dtg}
              label="Minimum Fulfillment Days"
              type="number"
              size="small"
              value={dtgMinFulfillmentDays}
              onChange={(dtgMinFulfillmentDays) =>
                setDtgMinFulfillmentDays(Number(dtgMinFulfillmentDays))
              }
            />

            <TextField
              disabled={!dtg}
              label="Maximum Fulfillment Days"
              type="number"
              size="small"
              value={dtgMaxFulfillmentDays}
              onChange={(dtgMaxFulfillmentDays) =>
                setDtgMaxFulfillmentDays(Number(dtgMaxFulfillmentDays))
              }
            />
          </EditDtgPlusInnerField>
        </FourBoxIconWrapper>

        {dtg && (
          <Grid
            container
            spacing={1}
            style={{ marginLeft: 13, marginBottom: 30 }}
          >
            {allDtgs?.map((d) => (
              <Checkbox
                key={d.DisplayName}
                label={d?.DisplayName}
                checked={!!d?.selected}
                onChange={(v) =>
                  setDtgs(
                    allDtgs?.map((v) => {
                      if (v?.DisplayName !== d?.DisplayName) {
                        return v;
                      }
                      return {
                        ...v,
                        selected: !v.selected,
                      };
                    })
                  )
                }
              />
            ))}
          </Grid>
        )}

        {selectedDecorativeTech &&
          selectedDecorativeTech?.map((d) => {
            const displayName = d?.DisplayName;

            if (
              (printType === 'DTG' ||
                printType === 'DURASOFT' ||
                printType === 'EMB') &&
              (displayName === 'AOP' || displayName === 'SUB')
            ) {
              return null;
            }

            return (
              <FourBoxIconWrapper key={displayName}>
                <img alt="plus" src={d?.ImageUrl} width="50" height="50" />
                <Checkbox
                  label={displayName}
                  checked={!!d?.selected}
                  onChange={(v) =>
                    setSelectedDecorativeTech(
                      selectedDecorativeTech?.map((v) => {
                        if (v?.DisplayName !== displayName) {
                          return v;
                        }
                        return {
                          ...v,
                          selected: !v.selected,
                        };
                      })
                    )
                  }
                />
                <EditDtgPlusInnerField>
                  <TextField
                    label="Minimum Fulfillment Days"
                    disabled={!d?.selected}
                    type="number"
                    size="small"
                    value={
                      d?.minFulfillmentDays ||
                      product?.Product?.defaultMinimumFulfillDays ||
                      0
                    }
                    onChange={(minFulfillmentDays) =>
                      setSelectedDecorativeTech(
                        selectedDecorativeTech?.map((v) => {
                          if (v?.DisplayName !== displayName) {
                            return v;
                          }
                          return {
                            ...v,
                            minFulfillmentDays: Number(minFulfillmentDays),
                          };
                        })
                      )
                    }
                  />

                  <TextField
                    disabled={!d?.selected}
                    label="Maximum Fulfillment Days"
                    type="number"
                    size="small"
                    value={
                      d?.maxFulfillmentDays ||
                      product?.Product?.defaultMaximumFulfillDays ||
                      0
                    }
                    onChange={(maxFulfillmentDays) =>
                      setSelectedDecorativeTech(
                        selectedDecorativeTech?.map((v) => {
                          if (v?.DisplayName !== displayName) {
                            return v;
                          }
                          return {
                            ...v,
                            maxFulfillmentDays: Number(maxFulfillmentDays),
                          };
                        })
                      )
                    }
                  />
                </EditDtgPlusInnerField>
              </FourBoxIconWrapper>
            );
          })}
      </SelectCategories>

      {/* <SelectCategories>
        <FourBoxIconWrapper>
          <img
            alt="plus"
            src="https://images.shirtly.com/image/upload/v1647441505/Shirtly/Shirtly_Products_Mock/Images/decoration-techniques/screen-printer_qrdewd.png"
            width="50"
            height="50"
          />
          <Checkbox
            label="SCREENPRINT"
            checked={screenPrinting?.enabled || false}
            onChange={(v) =>
              setScreenPrinting({ ...screenPrinting, enabled: !!v })
            }
          />
          <EditDtgPlusInnerField>
            <TextField
              disabled={!screenPrinting?.enabled}
              label="Minimum Fulfillment Days"
              type="number"
              size="small"
              value={screenPrinting?.minimumFulfillmentDays || 0}
              onChange={(minimumFulfillmentDays) =>
                setScreenPrinting({
                  ...screenPrinting,
                  minimumFulfillmentDays:
                    minimumFulfillmentDays > 0 ? minimumFulfillmentDays : 0,
                })
              }
            />

            <TextField
              disabled={!screenPrinting?.enabled}
              label="Maximum Fulfillment Days"
              type="number"
              size="small"
              value={screenPrinting?.maximumFulfillmentDays || 0}
              onChange={(maximumFulfillmentDays) =>
                setScreenPrinting({
                  ...screenPrinting,
                  maximumFulfillmentDays:
                    maximumFulfillmentDays > 0 ? maximumFulfillmentDays : 0,
                })
              }
            />
          </EditDtgPlusInnerField>
        </FourBoxIconWrapper>
      </SelectCategories> */}

      <Update>
        <Button
          title="Update"
          disabled={disabled}
          onClick={() => {
            const dtgPlus = allDtgs?.filter((v) => v.selected);
            const remainingTechniques = selectedDecorativeTech.filter(
              (v) => v.selected
            );
            let additionalTechniquesArtSettings = [];

            const front = {
              areaName: 'Front',
              areaSettings: {},
              image: product?.Product?.MarketingImagePath,
            };

            const back = {
              areaName: 'Back',
              areaSettings: {},
              image: product?.Product?.MarketingBackImagePath,
            };

            const isBack = product?.Product?.MarketingBackImagePath;

            const artSettings = isBack ? [front, back] : [front];

            const productAdditionalTechniquesArtSettings =
              product?.AdditionalTechniquesArtSettings;

            if (dtgPlus?.length) {
              const dtgPlus = productAdditionalTechniquesArtSettings?.find(
                (p) => p?.name === 'DTGPlus'
              );

              const plus = productAdditionalTechniquesArtSettings?.find(
                (p) => p?.name === 'Plus'
              );

              additionalTechniquesArtSettings.push(
                dtgPlus
                  ? {
                      ...dtgPlus,
                      maxFulfillmentDays: dtgMaxFulfillmentDays,
                      minFulfillmentDays: dtgMinFulfillmentDays,
                    }
                  : {
                      name: 'DTGPlus',
                      artSettings,
                      maxFulfillmentDays: dtgMaxFulfillmentDays,
                      minFulfillmentDays: dtgMinFulfillmentDays,
                    }
              );

              additionalTechniquesArtSettings.push(
                plus
                  ? {
                      ...plus,
                      maxFulfillmentDays: dtgMaxFulfillmentDays,
                      minFulfillmentDays: dtgMinFulfillmentDays,
                    }
                  : {
                      name: 'Plus',
                      artSettings,
                      maxFulfillmentDays: dtgMaxFulfillmentDays,
                      minFulfillmentDays: dtgMinFulfillmentDays,
                    }
              );
            }

            if (remainingTechniques?.length) {
              for (let i = 0; i < remainingTechniques?.length; i++) {
                const data = productAdditionalTechniquesArtSettings?.find(
                  (p) => p?.name === remainingTechniques[i].DisplayName
                );
                additionalTechniquesArtSettings.push(
                  data
                    ? {
                        ...data,
                        maxFulfillmentDays:
                          remainingTechniques[i].maxFulfillmentDays,
                        minFulfillmentDays:
                          remainingTechniques[i].minFulfillmentDays,
                      }
                    : {
                        name: remainingTechniques[i].DisplayName,
                        artSettings,
                        maxFulfillmentDays:
                          remainingTechniques[i].maxFulfillmentDays,
                        minFulfillmentDays:
                          remainingTechniques[i].minFulfillmentDays,
                      }
                );
              }
            }

            // additionalTechniquesArtSettings.push({
            //   name: 'ScreenPrint',
            //   maximumFulfillmentDays: screenPrinting?.enabled
            //     ? Number(screenPrinting?.maximumFulfillmentDays)
            //     : 0,
            //   minimumFulfillmentDays: screenPrinting?.enabled
            //     ? Number(screenPrinting?.minimumFulfillmentDays)
            //     : 0,
            //   enabled: screenPrinting?.enabled,
            //   artSettings: additionalTechniquesArtSettings[0]?.artSettings,
            // });

            const payload = {
              ID: product?.Product?.ID,
              dtgPlusData: dtgPlus,
              remainingDecorativeTechniques: remainingTechniques,
              additionalTechniquesArtSettings,
            };

            editDTGPlusInformation(payload);
          }}
        />
      </Update>
    </Container>
  );
};

export default EditDtgPlus;
