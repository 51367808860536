import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import COLORS from 'shared/constants/colors';

const StyledCheckbox = withStyles({
  root: {
    color: COLORS.GREY,
  },
  checked: { color: COLORS.DARK_BLUE },
})((props) => <Checkbox color='default' {...props} />);

const CustomCheckbox = ({ label, checked, onChange, disabled = false }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <StyledCheckbox
            checked={checked}
            disabled={disabled}
            onChange={(e) =>
              _.isFunction(onChange) && onChange(e.target.checked)
            }
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

CustomCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CustomCheckbox;
