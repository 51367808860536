import { post, get } from "@axios";
import { showLoader, hideLoader } from "actions/loader";
import { requests } from "shared/requests";
import { login as loginSubmit } from "actions/auth";
import { AUTH_LOGOUT } from "actions/auth";
import { INITIAL_SYNC_PRODUCTS } from "actions/sync-products";
import { INITIATE_STORES } from "actions/stores";
import { SHOW_ERROR_MODAL } from "actions/errorModal";

const UPDATE_USER_FIELD = "UPDATE_USER_FIELD";
const SET_USER_EMAIL = "SET_USER_EMAIL";
const SET_USER_PASSWORD = "SET_USER_PASSWORD";
const USER_INITIATE = "USER_INITIATE";
const SET_USER_CONFIRM_PASSWORD = "SET_USER_CONFIRM_PASSWORD";
const TOGGLE_TERMS_AND_CONDITION = "TOGGLE_TERMS_AND_CONDITION";

const checkUserStatus = (emailId, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.checkUserStatus,
        data: {
          user: emailId,
        },
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const decisionIPArtFile = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.decisionIPArtFile,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updatedResaleCertificate = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.updatedResaleCertificate,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const saveIpArtFiles = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.SaveIpArtFiles,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const searchIpArtFile = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.searchIpArtFile,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const savedIpArtFiles = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.SavedIpArtFiles,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const deleteIpArtFile = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.DeleteIpArtFile,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const sendIPConfirmationEmail = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.sendIPConfirmationEmail,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getUserInformation = (emailid, ipUser = false) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.userData,
        data: {
          user: emailid,
          ipUser,
        },
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const confirmEmail = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const confirm = await post({
        url: requests.notifyNewUser,
        data: data,
      });
      dispatch(hideLoader());
      return confirm;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updateUserInformation = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.updateUserInfo,
        data,
      });
      dispatch(hideLoader());

      await dispatch(
        loginSubmit({
          email: data?.email,
          password: data?.password,
        })
      );
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const deactivateUser = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.deactivateUser,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updatePassword = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.updatePassword,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const forgotPassword = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.forgotPassword,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getAllSubmittedUsers = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.getAllSubmittedUsers,
        data,
        token: `Bearer ${token}`,
      });
      dispatch(hideLoader());

      if (user?.tokenExpired) {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }

      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const checkAppVersion = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const version = await get({
        url: requests.checkAppVersion,
      });
      dispatch(hideLoader());
      return version;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getIpUsers = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const version = await get({
        url: requests.getIpUsers,
      });
      dispatch(hideLoader());
      return version;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getSubmittedUsers = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await get({
        url: requests.getSubmittedUsers,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getAllSubmittedUsersCSV = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await get({
        url: requests.getAllSubmittedUsersCSV,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const getSearchedSubmittedUsers = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.getSearchedSubmittedUsers,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const updateUserStatus = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const user = await post({
        url: requests.updateUserStatus,
        data,
      });
      dispatch(hideLoader());
      return user;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const backgroundRemove = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const response = await post({
        url: requests.backgroundRemove,
        data,
      });
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const setBackgroundBurnQuota = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const response = await post({
        url: requests.setBackgroundBurnQuota,
        data,
      });
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const automationToggle = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const response = await post({
        url: requests.automationToggle,
        data,
      });
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const deleteUnSyncedOrders = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const response = await post({
        url: requests.deleteUnSyncedOrders,
        data,
      });
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const resaleCertificate = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const response = await post({
        url: requests.resaleCertificate,
        data,
      });
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const yotiCredentials = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const version = await post({
        url: requests.yotiCredentials,
        data,
      });
      dispatch(hideLoader());
      return version;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const yotiCheckStatus = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const version = await post({
        url: requests.yotiCheckStatus,
        data,
      });
      dispatch(hideLoader());
      return version;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const yotiVerificationComplete = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const version = await post({
        url: requests.yotiVerificationComplete,
        data,
      });
      dispatch(hideLoader());
      return version;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

const userYotiVerified = (param) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const version = await get({
        url: `${requests.userYotiVerified}?${param}`,
      });
      dispatch(hideLoader());
      return version;
    } catch (error) {
      dispatch(hideLoader());
      dispatch({ type: SHOW_ERROR_MODAL });
      return { error: true, message: error.message };
    }
  };
};

export {
  SET_USER_EMAIL,
  SET_USER_PASSWORD,
  UPDATE_USER_FIELD,
  USER_INITIATE,
  SET_USER_CONFIRM_PASSWORD,
  TOGGLE_TERMS_AND_CONDITION,
  getUserInformation,
  confirmEmail,
  updateUserInformation,
  deactivateUser,
  updatePassword,
  getAllSubmittedUsers,
  getSearchedSubmittedUsers,
  updateUserStatus,
  getSubmittedUsers,
  forgotPassword,
  getAllSubmittedUsersCSV,
  checkAppVersion,
  checkUserStatus,
  backgroundRemove,
  setBackgroundBurnQuota,
  automationToggle,
  deleteUnSyncedOrders,
  sendIPConfirmationEmail,
  saveIpArtFiles,
  savedIpArtFiles,
  deleteIpArtFile,
  decisionIPArtFile,
  searchIpArtFile,
  getIpUsers,
  yotiCredentials,
  userYotiVerified,
  yotiCheckStatus,
  yotiVerificationComplete,
  resaleCertificate,
  updatedResaleCertificate,
};
