import _ from 'lodash';

const getProductsByCategoryId = (products = [], categoryId) => {
  let data = [];

  for (let i = 0; i < products.length; i++) {
    const product = products[i].Product;
    const exist = _.find(product.CategoryIds, (o) => o.CatId === categoryId);
    if (exist) {
      data.push(products[i]);
    }
  }

  return data;
};

export { getProductsByCategoryId };
