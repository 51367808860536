import styled from 'styled-components';
const Container = styled.div``;
const Wrapper = styled.div`
  max-width: 1140px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 15px;
  margin: 0 auto;
  display: flex;
  h2 {
    font-family: 'Inter800';
    margin: 0;
    padding: 10px 0;
    color: #fff;
  }
  .textSection {
    flex: 1;
    > div {
      max-width: 540px;
    }
    h4 {
      margin: 0;
      line-height: 28px;
      color: #18191f;
      font-size: 18px;
      font-family: 'Inter700';
    }
    p {
      margin: 0;
      line-height: 28px;
      color: #18191f;
      font-size: 18px;
      font-family: 'Inter400';
    }
  }
  .mask {
    img {
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    padding: 80px 15px;

    h2 {
      font-size: 36px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    .textSection {
      text-align: center;
    }
    .mask {
      margin-top: 50px;
    }
  }
`;
export { Container, Wrapper };
