import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const { GREY } = COLORS;

const menuItemsRightMargin = 15;

const Nav = styled.nav`
  background: #ffffff;
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 100;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .menu-bar-icon {
    cursor: pointer;
  }
  .MuiAvatar-colorDefault {
    background: #f7f8fa;
    cursor: pointer;
    svg {
      color: #0009;
    }
    :hover {
      background: #e8e8e8;
    }
  }
`;

const Logo = styled.img`
  padding-left: 20px;
  transition: all ease-out 0.5s;
  max-width: 138px;
  @media (max-width: 650px) {
    width: 117px;
    padding-left: 10px;
    padding-right: 5px;
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 700px) {
    display: none;
  }
`;

const Btn = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  color: ${GREY};
  margin-right: ${menuItemsRightMargin}px;
  display: flex;
  @media (max-width: 700px) {
    margin-right: 0;
    text-align: right;
  }
`;
const CurrencyMenu = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Inter400';
  background: #f7f8fa;
  height: 40px;
  padding: 0 15px 0 24px;
  border-radius: 90px;
  margin-right: 15px;
  cursor: pointer;
  @media (max-width: 650px) {
    padding: 0 10px;
    img {
      margin: 0;
    }
  }
`;
const MobileMenu = styled.div`
  display: none;
  @media (max-width: 700px) {
    display: flex;
    align-items: center;
  }
`;

const ProfileImage = styled.div`
  display: flex;
  align-items: center;
`;
const ProfileName = styled.div`
  margin-left: 14px;
  p {
    margin: 0;
    font-family: 'Inter400';
    color: #3a3a3c;
  }
  .full-name {
    font-family: 'Inter600';
  }
  @media (max-width: 650px) {
    margin: 0 14px 0 0;
    text-align: left;
  }
`;

const NotificationIcon = styled.div`
  margin-right: ${menuItemsRightMargin}px;
`;
export {
  Nav,
  Wrapper,
  Logo,
  Menu,
  Btn,
  menuItemsRightMargin,
  UserName,
  CurrencyMenu,
  MobileMenu,
  ProfileImage,
  ProfileName,
  NotificationIcon,
};
