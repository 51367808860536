import { useSelector } from "react-redux";
import { Chip } from "@material-ui/core";
import Model from "./Model";

const CustomizerTool = () => {
  const emailId = useSelector((state) => state?.auth?.profile?.emailid);
  const isAdmin =
    emailId === "support@shirtly.com" || emailId === "mlamothe@docpartners.ca";

  if (!isAdmin) {
    return null;
  }

  return (
    <div>
      <h3 style={{ fontSize: "20px", marginBottom: 0, textAlign: "center" }}>
        <span>3D CUSTOMIZER</span>
        <Chip
          label="Beta"
          style={{
            color: "#fff",
            background: "#ff3c3c",
            marginLeft: 10,
            paddingBottom: "2px",
          }}
          size="small"
        />
      </h3>
      <Model />
    </div>
  );
};

export default CustomizerTool;
