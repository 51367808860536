import React from "react";
import { Container } from "styles/pages/orders/orderFilter";

const OrderFilter = ({ updateCategory, selected, orderCounts = {} }) => (
  <div>
    <Container>
      <div
        className={
          selected === "PendingDetails" ? "selected" : "pending-details"
        }
        onClick={() => updateCategory("PendingDetails")}
      >
        <p>Pending Details</p>
        <span>{orderCounts.PendingDetails || 0}</span>
      </div>
      <div
        className={
          selected === "AwaitingPayment" ? "selected" : "awaiting-payment"
        }
        onClick={() => updateCategory("AwaitingPayment")}
      >
        <p>Awaiting Payment</p>
        <span>{orderCounts.AwaitingPayment || 0}</span>
      </div>
      <div
        className={selected === "InProduction" ? "selected" : "in-production"}
        onClick={() => updateCategory("InProduction")}
      >
        <p>In Production</p>
        <span>{orderCounts.InProduction || 0}</span>
      </div>
      <div
        className={selected === "Shipped" ? "selected" : "shipped"}
        onClick={() => updateCategory("Shipped")}
      >
        <p>Shipped</p>
        <span>{orderCounts.Shipped || 0}</span>
      </div>
      <div
        className={selected === "Cancelled" ? "selected" : "cancelled"}
        onClick={() => updateCategory("Cancelled")}
      >
        <p>Cancelled</p>
        <span>{orderCounts.Cancelled || 0}</span>
      </div>
    </Container>
    {/* <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-end',
      }}>
      {selected === 'AwaitingPayment' && (
        <Button
          title='Pay & Submit Order(s)'
          disabled={!payButtonVisibility}
          onClick={payButtonClick}
          containerStyle={{
            backgroundColor: '#000',
            color: '#fff',
            opacity: payButtonVisibility ? 1 : 0.4,
            height: 34,
            width: 260,
            fontWeight: 'bolder',
            fontSize: 17,
          }}
        />
      )}
    </div> */}
  </div>
);

export default OrderFilter;
