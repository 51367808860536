import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Alert from "@material-ui/lab/Alert";
import { Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "components/TextField";
import useFont from "hooks/fonts";
import { renderTexts } from "pages/customizer-tool/utils";
import {
  LayerContainer,
  LayerWrapper,
  LayerRow,
  StyledDeleteIcon,
  AxisTitle,
  Title,
} from "styles/pages/customizer-tool/layers-options";

const TextsLayer = ({
  garmentPart,
  leftPocket,
  rightSleeve,
  back,
  setLeftPocket,
  setRightSleeve,
  setBack,
  renderModel,
}) => {
  const [fonts] = useFont();

  const selectedGarmentPart =
    garmentPart === "left-pocket"
      ? leftPocket
      : garmentPart === "right-sleeve"
      ? rightSleeve
      : back;

  const title =
    garmentPart === "left-pocket"
      ? "Left Pocket"
      : garmentPart === "right-sleeve"
      ? "Right Sleeve"
      : "Back";

  const texts = selectedGarmentPart?.texts;

  const setTexts = (key, value, index) => {
    const newTexts = texts?.map((t, j) => {
      if (index !== j) {
        return { ...t };
      }

      return {
        ...t,
        [key]: value,
      };
    });

    if (garmentPart === "left-pocket") {
      setLeftPocket({
        ...leftPocket,
        texts: newTexts,
      });
      renderTexts(newTexts, "left-pocket-texts");
    } else if (garmentPart === "right-sleeve") {
      setRightSleeve({
        ...rightSleeve,
        texts: newTexts,
      });
      renderTexts(newTexts, "right-sleeve-texts");
    } else if (garmentPart === "back") {
      setBack({
        ...back,
        texts: newTexts,
      });
      renderTexts(newTexts, "back-texts");
    }
    renderModel();
  };

  return (
    <LayerContainer>
      <Title>{title} Layer</Title>
      {texts?.length > 0 ? (
        texts?.map((text, i) => {
          const xMin = garmentPart === "left-pocket" ? 1250 : 300;
          const xMax = garmentPart === "left-pocket" ? 1450 : 500;

          const yMin =
            garmentPart === "left-pocket"
              ? 1200
              : garmentPart === "right-sleeve"
              ? 520
              : 1050;
          const yMax =
            garmentPart === "left-pocket"
              ? 1400
              : garmentPart === "right-sleeve"
              ? 720
              : 1250;

          return (
            <LayerWrapper
              key={`text_${i}`}
              style={{ marginBottom: i === texts?.length - 1 ? 0 : 10 }}
            >
              <TextField
                label="Enter Text"
                value={text?.text}
                size="small"
                onChange={(v) => setTexts("text", v, i)}
              />

              <LayerRow>
                <TextField
                  size="small"
                  label="Font Size"
                  value={text?.fontSize}
                  type="number"
                  style={{ width: "50%" }}
                  onChange={(v) => {
                    const value = v >= 1 ? v : 1;
                    setTexts("fontSize", value, i);
                  }}
                />

                <Autocomplete
                  options={fonts}
                  value={text.font}
                  style={{ width: "50%" }}
                  onChange={(_, newValue) => setTexts("font", newValue, i)}
                  renderInput={(params) => (
                    <TextField {...params} label="FontFamily" size="small" />
                  )}
                />
              </LayerRow>

              <LayerRow>
                <AxisTitle>Left</AxisTitle>
                <Grid container>
                  <Slider
                    value={text.x || 0}
                    onChange={(_, newValue) => setTexts("x", newValue, i)}
                    min={xMin}
                    max={xMax}
                  />
                </Grid>
                <AxisTitle>Right</AxisTitle>
              </LayerRow>

              <LayerRow>
                <AxisTitle>Top</AxisTitle>
                <Grid container>
                  <Slider
                    value={text.y || 0}
                    onChange={(_, newValue) => setTexts("y", newValue, i)}
                    min={yMin}
                    max={yMax}
                  />
                </Grid>
                <AxisTitle>Bottom</AxisTitle>
              </LayerRow>

              <Tooltip title="Clear Text">
                <StyledDeleteIcon
                  onClick={() => {
                    const value = texts?.filter((_, j) => i !== j);
                    if (garmentPart === "left-pocket") {
                      setLeftPocket({
                        ...leftPocket,
                        texts: value,
                      });
                      renderTexts(value, "left-pocket-texts");
                    } else if (garmentPart === "right-sleeve") {
                      setRightSleeve({
                        ...rightSleeve,
                        texts: value,
                      });
                      renderTexts(value, "right-sleeve-texts");
                    } else if (garmentPart === "back") {
                      setBack({
                        ...back,
                        texts: value,
                      });
                      renderTexts(value, "back-texts");
                    }
                    renderModel();
                  }}
                />
              </Tooltip>
            </LayerWrapper>
          );
        })
      ) : (
        <Alert severity="error">No layer added!</Alert>
      )}
    </LayerContainer>
  );
};

export default TextsLayer;
