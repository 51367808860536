import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  background-color: #f7f8fa;
`;

const Welcome = styled.h1``;

const ModalContainer = styled.div`
  .MuiDialogTitle-root {
    h2 {
      font-size: 24px;
      font-family: "Inter800";
      text-align: center;
    }
  }
  .MuiDialogActions-root {
    button {
      background: #000;
      border-radius: 6px;
      font-size: 16px;
      font-family: "Inter500";
      color: #fff;
    }
  }
  .MuiDialogContent-root {
    p {
      font-family: "Inter400";
    }
  }
`;

const ApprovedKyc = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 20px;
  flex-wrap: wrap;

  button {
    color: #fff;
    background-color: #000;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    flex: 1;
    min-height: 60px;
    border: 1px solid #000;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
    :hover {
      background-color: #fff;
      color: #000;
    }

    @media (max-width: 650px) {
      flex: unset;
    }
  }
`;

export { Container, ModalContainer, Welcome, ApprovedKyc, StyledHeader };
