import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import DescriptionsTableBody from './TableBody';
import DescriptionTableHead from './TableHead';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const ProductDescriptionTable = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table>
          <DescriptionTableHead />
          <TableBody>
            <DescriptionsTableBody />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ProductDescriptionTable;
