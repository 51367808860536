import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "components/Button";
import TextField from "components/TextField";
import Select from "components/Select";
import Checkbox from "components/CheckBox";
import CloudinaryWidget from "components/CloudinaryWidget";
import RichTextEditor, { initiateEditorState } from "components/RichTextEditor";
import { DECORATING_TECHNIQUES } from "shared/constants/decorating-techniques";
import {
  Container,
  ProductInformation,
  SelectCategories,
  Update,
  ThreeBoxWrapper,
  SubHeading,
} from "styles/pages/edit-products/editProductInformation";

const EditProductInformation = ({
  categories,
  styleTags,
  product = { Product: {} },
  brandsTags,
  promosTags,
  updateProductInformation,
}) => {
  const [state, setState] = useState({
    ID: product?.Product?.ID,
    Index: 0,
    Active: true,
    LowestPrice: 0,
    SKU: product?.Product?.SKU || "",
    PrintType: product?.Product?.PrintType || "",
    Name: product?.Product?.Name || "",
    DisplayName: product?.Product?.DisplayName || "",
    Icon: product?.Product?.Icon || "",
    MockupPath: product?.Product?.MockupPath || "",
    ArtworkSpecsPath: product?.Product?.ArtworkSpecsPath || "",
    SizeChartPath: product?.Product?.SizeChartPath || "",
    MarketingImagePath: product?.Product?.MarketingImagePath || "",
    MarketingBackImagePath: product?.Product?.MarketingBackImagePath || "",
    Description: initiateEditorState(product?.Product?.Description),
    CategoryIds: product?.Product?.CategoryIds || [],
    ProductTags: product?.Product?.ProductTags || [],
    MarketingImageBase64: product?.Product?.MarketingImageBase64 || "",
    defaultMaximumFulfillDays: product?.Product?.defaultMaximumFulfillDays || 0,
    defaultMinimumFulfillDays: product?.Product?.defaultMinimumFulfillDays || 0,
    weight: product?.Product?.weight || 0,
    premiumProduct: product?.Product?.premiumProduct || false,
    screenPrinting: !!product?.Product?.screenPrinting,
  });

  const isStyleTagSelected = () => {
    for (let i = 0; i < styleTags?.length; i++) {
      for (let j = 0; j < state?.ProductTags?.length; j++) {
        if (styleTags[i].Id === state?.ProductTags[j]?.TagId) {
          return true;
        }
      }
    }

    return false;
  };

  const disabled =
    !state?.SKU ||
    !state?.PrintType ||
    !state?.Name ||
    !state?.DisplayName ||
    !state?.MarketingImagePath ||
    !state?.CategoryIds?.length ||
    !state?.defaultMaximumFulfillDays ||
    !state?.defaultMinimumFulfillDays ||
    !state?.weight ||
    !isStyleTagSelected();

  return (
    <Container>
      <ProductInformation>
        <SubHeading>Product Information</SubHeading>

        <ThreeBoxWrapper>
          <TextField
            label="SKU"
            size="small"
            value={state?.SKU}
            onChange={(SKU) =>
              setState({
                ...state,
                SKU,
              })
            }
          />

          <TextField
            label="Name"
            size="small"
            value={state?.Name}
            onChange={(Name) =>
              setState({
                ...state,
                Name,
              })
            }
          />

          <TextField
            label="Display Name"
            size="small"
            value={state?.DisplayName}
            onChange={(DisplayName) =>
              setState({
                ...state,
                DisplayName,
              })
            }
          />
        </ThreeBoxWrapper>
        <ThreeBoxWrapper>
          <CloudinaryWidget
            disabled={false}
            label="Mockup Path"
            value={state?.MockupPath}
            setValue={(MockupPath) => {
              const split = MockupPath.split("/upload/");

              // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;
              setState({
                ...state,
                // MockupPath: MockupPath?.includes("c_pad") ? MockupPath : value,
                MockupPath:
                  !!MockupPath && split?.length > 0
                    ? `${split[0]}/upload/${split[1]}`
                    : "",
              });
            }}
            folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${state.Name}`}
          />
          <CloudinaryWidget
            disabled={false}
            label="Artwork Specs Path"
            value={state?.ArtworkSpecsPath}
            setValue={(ArtworkSpecsPath) => {
              const split = ArtworkSpecsPath.split("/upload/");
              // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;
              setState({
                ...state,
                // ArtworkSpecsPath: ArtworkSpecsPath?.includes("c_pad")
                //   ? ArtworkSpecsPath
                //   : value,
                ArtworkSpecsPath:
                  !!ArtworkSpecsPath && split?.length > 0
                    ? `${split[0]}/upload/${split[1]}`
                    : "",
              });
            }}
            folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${state.Name}`}
          />
          <CloudinaryWidget
            disabled={false}
            label="Size Chart Path"
            value={state?.SizeChartPath}
            setValue={(SizeChartPath) => {
              const split = SizeChartPath.split("/upload/");
              // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;
              setState({
                ...state,
                // SizeChartPath: SizeChartPath?.includes("c_pad")
                //   ? SizeChartPath
                //   : value,

                SizeChartPath:
                  !!SizeChartPath && split?.length > 0
                    ? `${split[0]}/upload/${split[1]}`
                    : "",
              });
            }}
            folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${state.Name}`}
          />
        </ThreeBoxWrapper>
        <ThreeBoxWrapper>
          <CloudinaryWidget
            disabled={false}
            label="Marketing Image Path"
            value={state?.MarketingImagePath}
            setValue={(MarketingImagePath) => {
              const split = MarketingImagePath.split("/upload/");
              // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;
              setState({
                ...state,
                // MarketingImagePath: MarketingImagePath?.includes("c_pad")
                //   ? MarketingImagePath
                //   : value,

                MarketingImagePath:
                  !!MarketingImagePath && split?.length > 0
                    ? `${split[0]}/upload/${split[1]}`
                    : "",
              });
            }}
            folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${state.Name}`}
          />
          {state?.PrintType === "SUB" && (
            <CloudinaryWidget
              disabled={false}
              label="Marketing Image Base64"
              value={state?.MarketingImageBase64}
              setValue={(MarketingImageBase64) => {
                const split = MarketingImageBase64.split("/upload/");
                // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;
                setState({
                  ...state,
                  MarketingImageBase64:
                    !!MarketingImageBase64 && split?.length > 0
                      ? `${split[0]}/upload/${split[1]}`
                      : "",

                  // MarketingImageBase64: MarketingImageBase64?.includes("c_pad")
                  //   ? MarketingImageBase64
                  //   : value,
                });
              }}
              folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${state.Name}`}
            />
          )}
          <CloudinaryWidget
            disabled={false}
            label="Marketing Back Image Path"
            value={state?.MarketingBackImagePath}
            setValue={(MarketingBackImagePath) => {
              const split = MarketingBackImagePath.split("/upload/");
              // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;
              setState({
                ...state,

                MarketingBackImagePath:
                  !!MarketingBackImagePath && split?.length > 0
                    ? `${split[0]}/upload/${split[1]}`
                    : "",

                // MarketingBackImagePath: MarketingBackImagePath?.includes(
                //   "c_pad"
                // )
                //   ? MarketingBackImagePath
                //   : value,
              });
            }}
            folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${state.Name}`}
          />
          <CloudinaryWidget
            disabled={false}
            label="Icon URL"
            value={state?.Icon}
            setValue={(Icon) => {
              const split = Icon.split("/upload/");
              // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;
              setState({
                ...state,
                // Icon: Icon?.includes("c_pad") ? Icon : value,

                Icon:
                  !!Icon && split?.length > 0
                    ? `${split[0]}/upload/${split[1]}`
                    : "",
              });
            }}
            folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${state.Name}`}
          />
        </ThreeBoxWrapper>
        <ThreeBoxWrapper>
          <Select
            options={DECORATING_TECHNIQUES}
            labelKey="DisplayName"
            valueKey="DisplayName"
            label="Print Type"
            value={{ DisplayName: state?.PrintType }}
            onChange={(PrintType) =>
              setState({
                ...state,
                PrintType,
              })
            }
          />

          <TextField
            label="Minimum Fulfillment Days"
            type="number"
            size="small"
            value={state?.defaultMinimumFulfillDays}
            onChange={(defaultMinimumFulfillDays) =>
              setState({
                ...state,
                defaultMinimumFulfillDays: Number(defaultMinimumFulfillDays),
              })
            }
          />
          <TextField
            label="Maximum Fulfillment Days"
            size="small"
            type="number"
            value={state?.defaultMaximumFulfillDays}
            onChange={(defaultMaximumFulfillDays) =>
              setState({
                ...state,
                defaultMaximumFulfillDays: Number(defaultMaximumFulfillDays),
              })
            }
          />

          <TextField
            label="Weight (In Pounds)"
            size="small"
            type="number"
            value={state?.weight}
            onChange={(weight) =>
              setState({
                ...state,
                weight: Number(weight),
              })
            }
          />
        </ThreeBoxWrapper>

        <SubHeading>Description</SubHeading>

        <RichTextEditor
          value={state?.Description}
          onChange={(Description) =>
            setState({
              ...state,
              Description,
            })
          }
          title="Description"
        />
      </ProductInformation>

      <SelectCategories>
        <SubHeading>Premium Product</SubHeading>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={3} md={2}>
            <Checkbox
              label="Yes"
              checked={state?.premiumProduct}
              onChange={(premiumProduct) =>
                setState({
                  ...state,
                  premiumProduct,
                })
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Checkbox
              label="No"
              checked={!state?.premiumProduct}
              onChange={(premiumProduct) =>
                setState({
                  ...state,
                  premiumProduct: !premiumProduct,
                })
              }
            />
          </Grid>
        </Grid>
      </SelectCategories>

      <SelectCategories>
        <SubHeading>Select Categories</SubHeading>
        <Grid container spacing={1}>
          {categories?.map((category, index) => {
            const checked = !!state?.CategoryIds?.find(
              (c) => c.CatId === category?.CatId
            );
            return (
              <Grid item xs={6} sm={3} md={2} key={`category${index}`}>
                <Checkbox
                  label={category?.DisplayName}
                  key={category?.DisplayName + category?.CatId}
                  checked={checked}
                  onChange={(v) => {
                    if (v) {
                      setState({
                        ...state,
                        CategoryIds: [...state?.CategoryIds, category],
                      });
                    } else {
                      setState({
                        ...state,
                        CategoryIds: state?.CategoryIds?.filter(
                          (c) => c.CatId !== category?.CatId
                        ),
                      });
                    }
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </SelectCategories>

      <div>
        <SubHeading>Select Style Tags</SubHeading>
        <Grid container spacing={1}>
          {styleTags?.map((tag, index) => {
            const checked = !!state?.ProductTags?.find(
              (c) => c.TagId === tag?.Id
            );
            return (
              <Grid item xs={6} sm={3} md={2} key={`tag${index}`}>
                <Checkbox
                  label={tag?.DisplayName}
                  key={tag?.DisplayName + tag?.Id}
                  checked={checked}
                  onChange={(v) => {
                    if (v) {
                      setState({
                        ...state,
                        ProductTags: [
                          ...state?.ProductTags,
                          { ...tag, TagId: tag?.Id },
                        ],
                      });
                    } else {
                      setState({
                        ...state,
                        ProductTags: state?.ProductTags?.filter(
                          (c) => c.TagId !== tag?.Id
                        ),
                      });
                    }
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>

      <div>
        <SubHeading>Select Brand Tags</SubHeading>
        <Grid container spacing={1}>
          {brandsTags?.map((tag, index) => {
            const checked = !!state?.ProductTags?.find(
              (c) => c.TagId === tag?.Id
            );
            return (
              <Grid item xs={6} sm={4} md={3} key={`productTags${index}`}>
                <Checkbox
                  label={tag?.DisplayName}
                  key={tag?.DisplayName + tag?.Id}
                  checked={checked}
                  onChange={(v) => {
                    if (v) {
                      setState({
                        ...state,
                        ProductTags: [
                          ...state?.ProductTags,
                          { ...tag, TagId: tag?.Id },
                        ],
                      });
                    } else {
                      setState({
                        ...state,
                        ProductTags: state?.ProductTags?.filter(
                          (c) => c.TagId !== tag?.Id
                        ),
                      });
                    }
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>

      <div>
        <SubHeading>Select Promos Tags</SubHeading>
        <Grid container spacing={1}>
          {promosTags?.map((tag, index) => {
            const checked = !!state?.ProductTags?.find(
              (c) => c.TagId === tag?.Id
            );
            return (
              <Grid item xs={6} sm={3} md={2} key={`promoTags${index}`}>
                <Checkbox
                  label={tag?.DisplayName}
                  key={tag?.DisplayName + tag?.Id}
                  checked={checked}
                  onChange={(v) => {
                    if (v) {
                      setState({
                        ...state,
                        ProductTags: [
                          ...state?.ProductTags,
                          { ...tag, TagId: tag?.Id },
                        ],
                      });
                    } else {
                      setState({
                        ...state,
                        ProductTags: state?.ProductTags?.filter(
                          (c) => c.TagId !== tag?.Id
                        ),
                      });
                    }
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
      <Update>
        <Button
          title="Update"
          disabled={disabled}
          onClick={() => updateProductInformation(state)}
        />
      </Update>
    </Container>
  );
};

export default EditProductInformation;
