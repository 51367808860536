import { ROUTES } from 'shared/constants/routes';

const LINKS = [
  {
    title: ROUTES.DASHBOARD.TITLE,
    link: ROUTES.DASHBOARD.LINK,
  },
  {
    title: ROUTES.MY_STORES.TITLE,
    link: ROUTES.MY_STORES.LINK,
  },
  {
    title: ROUTES.ORDERS.TITLE,
    link: ROUTES.ORDERS.LINK,
  },
  {
    title: ROUTES.CATALOG.TITLE,
    link: ROUTES.CATALOG.LINK,
  },
  {
    title: ROUTES.ACCOUNT_SETTINGS.TITLE,
    link: ROUTES.ACCOUNT_SETTINGS.LINK,
  },
];

export default LINKS;
